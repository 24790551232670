import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
export const statusResponses = {
    referencias:{
    "APROBADO": {
        sStatusColor: 'green',
        sStatusMessage: "Referencia valida",
        sIcono: <CheckCircleIcon />
    },
    "VALIDADO": {
        sStatusColor: 'green',
        sStatusMessage: "Referencia valida",
        sIcono: <CheckCircleIcon />
    },
    "RECHAZADO": {
        sStatusColor: 'red',
        sStatusMessage: "Referencia rechazada",
        sIcono: <CancelIcon />
    },
    "ERROR": {
        sStatusColor: 'red',
        sStatusMessage: "Error en la carga de la Referencia",
        sIcono: <CancelIcon />
    },
    "ENVIADO": {
        sStatusColor: 'blue',
        sStatusMessage: "Referencia enviada",
        sIcono: <AccessTimeFilledIcon />
    },
    "PENDIENTE": {
        sStatusColor: 'gray',
        sStatusMessage: "Pendiente",
        sIcono: <AccessTimeFilledIcon />
    },
    "VALIDANDO": {
        sStatusColor: 'orange',
        sStatusMessage: "Validando referencia",
        sIcono: <AccessTimeFilledIcon />
    },
}}