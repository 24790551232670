import { Box, Grid, Pagination } from '@mui/material'
import React from 'react'

/**
 * Componente que muestra la paginación para la lista de unidades. Permite a los usuarios navegar entre diferentes páginas 
 * de resultados basándose en el contador y la página actual.
 * @author ERL 2023-08-07 02:28 pm
 * @param {Object} props - Propiedades del componente
 * @field {number} nContador - Total de páginas disponibles.
 * @field {number} nPaginaActual - Número de la página actual.
 * @field {Function} handlePageChange - Función que maneja el cambio de página.
 * @returns {JSX.Element}
 */
const UnidadesPaginacion = ({nContador, nPaginaActual, handlePageChange}) => {
  return (
  
    
        <Box
          sx={{
            width: '100%'
          }}
          mt={1} display="flex" justifyContent="center">
          <Pagination
            count={nContador}
            page={nPaginaActual}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>


    
  )
}

export default UnidadesPaginacion
