// Importación de los módulos necesarios
import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import './perfil.scss';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useForm } from 'react-hook-form';
import { useLogin } from '../../hooks/usuariosApi/useLogin';
import { StyledButton2 } from '../../components/customButtons/StyledButton';

import { Link } from 'react-router-dom';

// Definición del tema de la aplicación
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});
/**
 * Devuelve el componente para realizar el login
 * @author ERL 2023-06-09 09:39 am 
 * @param {props} newUser identificar si se trata de un nuevo usuario 
 * @returns {JSX.Element} Componente de registro
*/
// Definición del componente Login
export default function Perfil() {
    // Definición del estado del componente    


    const { handleSubmit, setValue, formState: { errors }, control } = useForm();

    // Manejador del evento de envío del formulario
    const { logOutUsuario } = useLogin();
  
    // Renderizado del componente
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container
                component="main"
                maxWidth="sm"
                sx={{
                    maxWidth: {
                        xs: '100%', 
                        sm: '400px', 
                        md: '600px', 
                        lg: '700px', 
                    },
                    minWidth: {
                        xs: '100%', 
                        sm: '200px', 
                        md: '300px', 
                        lg: '400px', 
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        <Link to="/proceso" className='nolink'>
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }} className='google-btn' />
                            Mi perfil
                        </Link>
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit(logOutUsuario)} sx={{ mt: 3, mx: 0 }}>
                        <Grid container sx={{ my: 2, ml: 0, mr: 0, p: 0, mx: 0 }} justifyContent="center" display={"flex"}>
                            <StyledButton2 width="100%">
                                Salir
                            </StyledButton2>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
