// Importando módulos necesarios
import React from 'react';
import '../../../../../styles/registro/global.scss'
import '../../../../../styles/registro/prospectos/pasos/cuestionario.scss'
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Box, Typography, Grid, TextField, Radio, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, MenuItem, FormGroup, FormHelperText, CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, InputAdornment, Select } from '@mui/material';
import { createTheme, ThemeProvider, } from '@mui/material/styles'; // Herramientas de MUI para el tema
import { StyledButton2 } from '../../../../customButtons/StyledButton';
import { regex } from '../../../../../helpers/regex';
import { globalMessage } from '../../../../../helpers/globalMessage';
import { LANG } from './LANG';
import BotonConTemporizador from '../../../../customButtons/BotonConTemporizador';
import { useCuestionario } from '../../../../../hooks/prospectosApi/pasos/useCuestionario';
import SmallCircularLoader from '../../../../loader/SmallCircularLoader';
import SelectLoader from '../../../../loader/SelectLoader';

// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});
/**
 * Componente de cuestionario
 * @author ERL 2023-06-16 08:36 am 
 * @param {function} nextStep - Función para ir al siguiente paso del proceso
 * @param {object} headers - Headers para las llamadas API.
 * @returns {JSX.Element} Componente de cuestionaro de preguntas
*/
const Cuestionario = ({ nextStep, headers }) => {
    const {
        unidadesInteres,
        loading,
        loadingComponent,
        handleSubmit,
        errors,
        control,
        selectedFields,
        setSelectedFields,
        dataCuestionario,
        visibleFields,
        selectedRadio,
        selectedSelect,
        firstInputValues,
        nTelefono,
        loadingReEnvio,
        bCodigoReEnviado,
        openDialog,
        onSubmit,
        handleSelected,
        handleSelectedSelect,
        addRef,
        handleCardClick,
        validarTelefono,
        validarCodigoTelefono,
        handleDialogConfirm,
        handleDialogCancel,
        reEnviarCodigo,
        lada,
        setLada,
        setValue,
        setVisibleFields,
        Controller,
        bTelefonoConfirmado,
        validarClaveVendedor,
        validarCodigoRefereido,
        buscarCiudades,
        loadingEntidad

    } = useCuestionario({ nextStep, headers })

    // Renderizando el componente
    return (
        <ThemeProvider theme={defaultTheme}>
            <Dialog
                open={openDialog}
                onClose={handleDialogCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿{LANG.labelElNumero} <b>{lada} {nTelefono}</b> {LANG.labelEsCorrecto}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancel} color="error" variant='contained'>
                        {LANG.labelCancelar}
                    </Button>
                    <Button onClick={handleDialogConfirm} color="primary" variant='contained'>
                        {LANG.labelConfirmar}
                    </Button>
                </DialogActions>
            </Dialog>
            <Container
                component="main"
                maxWidth="sm"
                sx={{
                    maxWidth: {
                        xs: '100%',
                        sm: '400px',
                        md: '780px',
                        lg: '1600px',
                    },
                    padding: {
                        xl: '0 !important',
                        md: "0 auto !important"
                    },
                    margin: {
                        xl: '0 !important',
                        md: "0 auto !important"
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{
                            mt: 0, mx: 0, mb: 3, minHeight: {
                                lg: '500px',
                            },
                        }}>
                        <Grid container spacing={2} sx={{
                            ml: 0,
                            mt: 0,
                            width: "100%",
                        }}>
                            <Grid container sx={{
                                m: '0 auto',
                                p: 0,
                                maxWidth: {
                                    xl: '660px',
                                }
                            }}
                            >

                                {loadingComponent ?
                                    <Grid
                                        sx={{
                                            height: "50vh",
                                            width: "100%",
                                            display: 'flex',
                                            justifyContent: "center",
                                            alignItems: "center",

                                        }}><CircularProgress color="primary" size={'100px'} /></Grid> :
                                    dataCuestionario.map((question, index) => (
                                        (!question.nIdPadre || selectedFields[question.nIdPadre]) && (
                                            <Grid item xs={12} p="15px 0" key={index}>
                                                {question.sTipo === 'text' && (
                                                    <>
                                                        {question.nIdPadre === 0 &&

                                                            <>
                                                                {question.sNombreCorto === "sTelefono" ?
                                                                    <>

                                                                        <Controller
                                                                            name={`pregunta${question.nIdentificador}`}
                                                                            control={control}
                                                                            defaultValue={question.sNombreCorto === 'sTelefono' ? nTelefono : ''}
                                                                            rules={{
                                                                                required: "Información requerida",
                                                                                pattern: {
                                                                                    value: question.sNombreCorto === 'sTelefono' ? regex.isPhoneNumber : '',
                                                                                    message: question.sNombreCorto === 'sTelefono' ? globalMessage.errorSoloNumeros : '',
                                                                                },
                                                                                minLength: { value: 0, message: "sValorMinimo" },
                                                                            }}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    required
                                                                                    fullWidth
                                                                                    label={question.sValor}
                                                                                    aria-invalid={errors[`pregunta${question.nIdentificador}`] ? "true" : "false"}
                                                                                    id={`${errors[`pregunta${question.nIdentificador}`]?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                                                                    error={Boolean(errors[`pregunta${question.nIdentificador}`])}
                                                                                    helperText={<center>{errors[`pregunta${question.nIdentificador}`]?.message}</center>}
                                                                                    onChange={(e) => {
                                                                                        let val = e.target.value;


                                                                                        if (question.sNombreCorto === 'sTelefono') {
                                                                                            if (val.length > 11) {
                                                                                                val = val.slice(0, 11);
                                                                                            }
                                                                                            val = val.replace(/[^\d]/g, "").replace(/(\d{2})(\d{7})/, "$1-$2").substr(0, 11);

                                                                                            if (e.target.value.length === 11) {
                                                                                                validarTelefono(val, `pregunta${question.nIdentificador}`);
                                                                                            }
                                                                                        }

                                                                                        setValue(`pregunta${question.nIdentificador}`, val);
                                                                                    }}
                                                                                    InputProps={{
                                                                                        readOnly: bTelefonoConfirmado,
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <TextField
                                                                                                    select
                                                                                                    variant="standard"
                                                                                                    value={lada}
                                                                                                    onChange={(event) => setLada(event.target.value)} // actualiza la lada cuando cambie
                                                                                                    style={{ border: "none", borderBottom: "none", }}
                                                                                                    InputProps={{
                                                                                                        readOnly: bTelefonoConfirmado
                                                                                                    }}
                                                                                                >
                                                                                                    <MenuItem value="+52">+52</MenuItem>

                                                                                                </TextField>
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />

                                                                        {
                                                                            (!bTelefonoConfirmado) && firstInputValues[`pregunta${question.nIdentificador}`]?.length >= 11 && question.sNombreCorto === 'sTelefono' &&
                                                                            <>
                                                                                {dataCuestionario.map((q, index) => {
                                                                                    if (q.sTipo === 'number' && q.nIdPadre === question.nIdentificador) {
                                                                                        return (
                                                                                            <Controller
                                                                                                key={index}
                                                                                                name={`codigo${q.nIdentificador}`}
                                                                                                control={control}
                                                                                                defaultValue={q.sCodigo || ''}
                                                                                                rules={{
                                                                                                    required: "Información requerida",
                                                                                                    pattern: {
                                                                                                        value: question.sNombreCorto === 'sTelefono' ? regex.isPhoneNumber : regex.isNumber,
                                                                                                        message: globalMessage.errorSoloNumeros,
                                                                                                    },
                                                                                                    minLength: { value: 4, message: "sValorMinimo" },
                                                                                                }}
                                                                                                render={({ field }) => (
                                                                                                    <TextField
                                                                                                        {...field}
                                                                                                        required
                                                                                                        fullWidth
                                                                                                        label={`${q.sValor}`}
                                                                                                        sx={{ marginTop: '15px' }}
                                                                                                        inputProps={{
                                                                                                            maxLength: 4,
                                                                                                        }}
                                                                                                        onChange={(e) => {
                                                                                                            let val = e.target.value;
                                                                                                            val = val.replace(/[^\d]/g, "");
                                                                                                            if (q.sNombreCorto === "nCodigo" && val.length === 4) {
                                                                                                                validarCodigoTelefono(Number(val), `codigo${q.nIdentificador}`)
                                                                                                            }
                                                                                                            setValue(`codigo${q.nIdentificador}`, val);
                                                                                                        }}

                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    return null;
                                                                                })}

                                                                                <BotonConTemporizador bCodigoReEnviado={bCodigoReEnviado} nTiempo={5}>
                                                                                    <Grid item
                                                                                        mt={1}
                                                                                    >
                                                                                        <Typography variant="p" onClick={() => reEnviarCodigo(nTelefono)}>
                                                                                            {loadingReEnvio ? <CircularProgress color="primary" size={'16px'} /> : <>
                                                                                                <span className='link-style'>
                                                                                                    ¿No recibiste tu código?
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className='link-style'>
                                                                                                    Reenviar
                                                                                                </span>
                                                                                            </>
                                                                                            }
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </BotonConTemporizador>
                                                                            </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    question.sNombreCorto === "sClaveVendedor" ?

                                                                        <Controller
                                                                            name={`${question.sNombreCorto}`}
                                                                            control={control}
                                                                            defaultValue=""
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label={question.sValor}
                                                                                    {...field}
                                                                                    onChange={(e) => {
                                                                                        e.target.value = e.target.value.toUpperCase();
                                                                                        field.onChange(e.target.value);
                                                                                        let value = e.target.value;
                                                                                        if (value.length >= 3) {
                                                                                            validarClaveVendedor(e.target.value)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                        :
                                                                        <Controller
                                                                            name={`pregunta${question.nIdentificador}`}
                                                                            control={control}
                                                                            defaultValue=""
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    required
                                                                                    fullWidth
                                                                                    label={question.sValor}
                                                                                    aria-invalid={errors[`pregunta${question.nIdentificador}`] ? "true" : "false"}
                                                                                    id={`${errors[`pregunta${question.nIdentificador}`]?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                                                                    error={Boolean(errors[`pregunta${question.nIdentificador}`])}
                                                                                    helperText={<><center>{errors[`pregunta${question.nIdentificador}`]?.message} </center></>}
                                                                                    {...field}
                                                                                />
                                                                            )}
                                                                        />
                                                                }

                                                            </>
                                                        }

                                                    </>

                                                )}
                                                {question.sTipo === 'select' && (
                                                    <Grid sx={{position:'relative'}}>
                                                        <Controller
                                                            name={`pregunta${question.nIdentificador}`}
                                                            control={control}
                                                            rules={{ required: "Información requerida" }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    select
                                                                    required
                                                                    fullWidth
                                                                    label={question.sValor}
                                                                    {...field}
                                                                    error={Boolean(errors[`pregunta${question.nIdentificador}`])}
                                                                    helperText={errors[`pregunta${question.nIdentificador}`]?.message}
                                                                    onChange={(e) => {
                                                                        field.onChange(e.target.value);
                                                                        //Si el nombre corto es sEntidad ejecuta funcion buscar unidades
                                                                        if (question.sNombreCorto === "sEntidad") {
                                                                            const selectedValue = e.target.value;
                                                                            const selectedOption = question.respuestas.find(answer => answer.nIdRespMc === selectedValue);
                                                                            const selectedId = selectedOption ? selectedOption.nIdEntidad : null;

                                                                            if (selectedId) {
                                                                                buscarCiudades(selectedId);
                                                                            }
                                                                        }
                                                                        handleSelectedSelect(e.target.value, question);
                                                                        setSelectedFields((prev) => ({
                                                                            ...prev,
                                                                            [question.nIdentificador]: e.target.value,
                                                                        }));
                                                                    }}

                                                                    value={field.value || ""}
                                                                >
                                                                    {question.respuestas
                                                                        .sort((a, b) => a.nOrden - b.nOrden)
                                                                        .map((answer, index) => (
                                                                            <MenuItem key={index} value={answer.nIdRespMc} >{answer.sValor}</MenuItem>
                                                                        ))}
                                                                </TextField>
                                                            )}
                                                        />
                                                        {(question.sNombreCorto === 'sCiudad' && loadingEntidad) &&
                                                            <SelectLoader/>
                                                        }
                                                        {selectedFields[question.nIdentificador] && (
                                                            <>
                                                                {dataCuestionario.map((inputQuestion, index) => {
                                                                    if (inputQuestion.nIdPadre === question.nIdentificador) {
                                                                        // Aquí es donde comprobamos si el nIdentificador del inputQuestion coincide con el selected
                                                                        if (Number(inputQuestion.nIdentificador) === Number(selectedSelect)) {
                                                                            return (
                                                                                <Controller
                                                                                    key={inputQuestion.nIdentificador}
                                                                                    name={`${inputQuestion.sNombreCorto}`}
                                                                                    control={control}
                                                                                    rules={{ required: "Información requerida" }}
                                                                                    render={({ field }) => (
                                                                                        <TextField
                                                                                            sx={{
                                                                                                mt: 3
                                                                                            }}
                                                                                            defaultValue=""
                                                                                            required
                                                                                            label={inputQuestion.sValor}
                                                                                            aria-invalid={errors[`${inputQuestion.sNombreCorto}`] ? "true" : "false"}
                                                                                            id={`${errors[`${inputQuestion.sNombreCorto}`]?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                                                                            error={Boolean(errors[`${inputQuestion.sNombreCorto}`])}
                                                                                            helperText={<><center>{errors[`${inputQuestion.sNombreCorto}`]?.message} </center></>}
                                                                                            {...field}
                                                                                            onChange={(e) => {
                                                                                                e.target.value = e.target.value.toUpperCase();  // Convertir el texto a mayúsculas
                                                                                                field.onChange(e.target.value);
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            );
                                                                        }
                                                                    }
                                                                    return null;
                                                                })}
                                                            </>
                                                        )}

                                                    </Grid>
                                                )}
                                                {question.sTipo === 'radio' && (
                                                    <FormControl component="fieldset" fullWidth>
                                                        <FormLabel component="legend">{question.sValor}</FormLabel>
                                                        <Controller
                                                            name={`pregunta${question.nIdentificador}`}
                                                            control={control}
                                                            rules={{ required: "Información requerida" }}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <>
                                                                    <RadioGroup {...field}>
                                                                        {question.respuestas
                                                                            .sort((a, b) => a.nOrden - b.nOrden)
                                                                            .map((answer, index) => (
                                                                                <FormControlLabel
                                                                                    key={index}
                                                                                    value={answer.nIdRespMc}
                                                                                    control={<Radio />}
                                                                                    label={answer.sValor}
                                                                                    checked={Number(field.value) === Number(answer.nIdRespMc)}
                                                                                    onChange={(e) => {
                                                                                        field.onChange(e.target.value);
                                                                                        handleSelected(answer.nIdPreguntaRel, question.sTipo);

                                                                                        setSelectedFields((prev) => ({
                                                                                            ...prev,
                                                                                            [question.nIdentificador]: Number(e.target.value) === Number(answer.nIdRespMc),
                                                                                        }));

                                                                                        setVisibleFields((prev) => ({
                                                                                            ...prev,
                                                                                            [question.nIdentificador]: Number(e.target.value) === Number(answer.nIdRespMc),
                                                                                        }));
                                                                                    }}
                                                                                />
                                                                            ))}
                                                                    </RadioGroup>


                                                                    {visibleFields[question.nIdentificador] && (
                                                                        <>
                                                                            {dataCuestionario.map((inputQuestion) => {
                                                                                if (inputQuestion.nIdPadre === question.nIdentificador) {
                                                                                    if (Number(inputQuestion.nIdentificador) === Number(selectedRadio)) {
                                                                                        return (
                                                                                            <Controller
                                                                                                key={inputQuestion.nIdentificador}
                                                                                                name={`${inputQuestion.sNombreCorto}`}
                                                                                                control={control}
                                                                                                rules={{ required: "Información requerida" }}
                                                                                                render={({ field }) => (
                                                                                                    <TextField
                                                                                                        sx={{
                                                                                                            margin: '15px 0'
                                                                                                        }}
                                                                                                        required
                                                                                                        fullWidth
                                                                                                        label={inputQuestion.sValor}
                                                                                                        aria-invalid={errors[`${inputQuestion.sNombreCorto}`] ? "true" : "false"}
                                                                                                        id={`${errors[`${inputQuestion.sNombreCorto}`]?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                                                                                        error={Boolean(errors[`${inputQuestion.sNombreCorto}`])}
                                                                                                        helperText={<><center>{errors[`${inputQuestion.sNombreCorto}`]?.message} </center></>}
                                                                                                        {...field}
                                                                                                        inputProps={{
                                                                                                            maxLength: 13
                                                                                                        }}
                                                                                                        onChange={(e) => {


                                                                                                            if (inputQuestion.sNombreCorto === "sCodigoRef") {
                                                                                                                e.target.value = e.target.value.toUpperCase();
                                                                                                            }
                                                                                                            field.onChange(e.target.value);
                                                                                                            let value = e.target.value;
                                                                                                            if (value.length === 13) {
                                                                                                                validarCodigoRefereido(e.target.value)
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        );
                                                                                    }
                                                                                }
                                                                                return null;
                                                                            })}
                                                                        </>
                                                                    )}

                                                                </>
                                                            )}
                                                        />
                                                        {errors[`pregunta${question.nIdentificador}`] && (
                                                            <FormHelperText error>
                                                                {errors[`pregunta${question.nIdentificador}`]?.message}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                )}
                                                {question.sTipo === 'check' && (
                                                    <FormControl>
                                                        <FormLabel component="legend">{question.sValor}</FormLabel>
                                                        <Controller
                                                            name={`pregunta${question.nIdentificador}`}
                                                            control={control}
                                                            rules={{ required: "Información requerida" }}
                                                            defaultValue={""}
                                                            render={({ field }) => (
                                                                <FormGroup>
                                                                    {question.respuestas
                                                                        .sort((a, b) => a.nOrden - b.nOrden)
                                                                        .map((answer, index) => (
                                                                            <FormControlLabel
                                                                                key={index}
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={field.value.includes(answer.nIdRespMc)}
                                                                                        onChange={(e) => {
                                                                                            const newValue = e.target.checked
                                                                                                ? [...field.value, answer.nIdRespMc]
                                                                                                : field.value.filter((item) => Number(item) !== Number(answer.nIdRespMc));
                                                                                            field.onChange(newValue);

                                                                                            setSelectedFields((prev) => ({
                                                                                                ...prev,
                                                                                                [question.nIdentificador]: newValue.includes(Number(answer.nIdRespMc)),
                                                                                            }));

                                                                                            setVisibleFields((prev) => ({
                                                                                                ...prev,
                                                                                                [question.nIdentificador]: newValue.includes(Number(answer.nIdRespMc)),
                                                                                            }));
                                                                                        }}
                                                                                        name={answer.sValor}
                                                                                    />
                                                                                }
                                                                                label={answer.sValor}
                                                                                value={answer.nIdRespMc}
                                                                            />
                                                                        ))}

                                                                    {visibleFields[question.nIdentificador] && (
                                                                        <>
                                                                            {dataCuestionario.map((inputQuestion) => {
                                                                                if (inputQuestion.nIdPadre === question.nIdentificador) {
                                                                                    return (
                                                                                        <Controller
                                                                                            key={inputQuestion.nIdentificador}
                                                                                            name={`${inputQuestion.sNombreCorto}`}
                                                                                            control={control}
                                                                                            defaultValue=""
                                                                                            rules={{ required: "Información requerida" }}
                                                                                            render={({ field }) => (
                                                                                                <TextField
                                                                                                    required
                                                                                                    fullWidth
                                                                                                    label={inputQuestion.sValor}
                                                                                                    aria-invalid={errors[`${inputQuestion.sNombreCorto}`] ? "true" : "false"}
                                                                                                    id={`${errors[`${inputQuestion.sNombreCorto}`]?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                                                                                    error={Boolean(errors[`${inputQuestion.sNombreCorto}`])}
                                                                                                    helperText={<><center>{errors[`${inputQuestion.sNombreCorto}`]?.message} </center></>}
                                                                                                    {...field}
                                                                                                    onChange={(e) => {
                                                                                                        e.target.value = e.target.value.toUpperCase();  // Convertir el texto a mayúsculas
                                                                                                        field.onChange(e.target.value);
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    );
                                                                                }
                                                                                return null;
                                                                            })}
                                                                        </>
                                                                    )}
                                                                </FormGroup>
                                                            )}
                                                        />
                                                        {errors[`pregunta${question.nIdentificador}`] && (
                                                            <FormHelperText error>
                                                                {errors[`pregunta${question.nIdentificador}`]?.message}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                )}
                                            </Grid>
                                        )))
                                }
                            </Grid>
                            <Grid container sx={{ my: 2, ml: 0, mr: 0, p: 0 }} justifyContent="center" display={"flex "}>
                                <div className='unidades-container'>
                                    <div className='unidades-container-title'>
                                        <p>
                                            {`Unidades de interés`}
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="unidades">
                                        <div className="autos-besta-tab">
                                            <div className='unidades-cards'>
                                                {unidadesInteres.map((item, i) => (
                                                    <div className="unidades-card" key={i}>
                                                        <div className='autos-besta-tabs-card' onClick={() => handleCardClick(i)}>

                                                            <div className="unidades-card-top">
                                                                <h2>
                                                                    {item.sMarca}
                                                                    <div className='unidades-radio'>
                                                                        <FormControl component="fieldset"
                                                                            sx={
                                                                                {
                                                                                    mx: 0,
                                                                                    my: 0,
                                                                                }
                                                                            }
                                                                        >
                                                                            <Controller
                                                                                name={`nIdUnidad`}
                                                                                control={control}
                                                                                rules={{ required: "Selecciona una opcion" }}
                                                                                defaultValue=""
                                                                                sx={
                                                                                    {
                                                                                        mx: 0,
                                                                                        my: 0,
                                                                                    }
                                                                                }
                                                                                render={({ field }) => (
                                                                                    <>

                                                                                        <RadioGroup {...field} >
                                                                                            <FormControlLabel

                                                                                                value={item.nId}
                                                                                                control={<Radio inputRef={(el) => addRef(el, i)} />}

                                                                                                sx={{ mx: 0, my: 0 }}
                                                                                            />
                                                                                        </RadioGroup>
                                                                                    </>
                                                                                )}
                                                                            />

                                                                        </FormControl>
                                                                    </div></h2>

                                                                <h3>{item.sModelo}</h3>
                                                                <div className='autos-besta-tabs-card-img'>
                                                                    <img src={item.sImagen} alt="Autos besta" />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                            {errors[`nIdUnidad`] && (

                                                <Grid fullWidth display={"flex"} justifyContent="center">
                                                    <FormHelperText error sx={{
                                                        fontSize: "1.3em",
                                                        pt: 3
                                                    }}>
                                                        {errors[`nIdUnidad`]?.message}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            {bTelefonoConfirmado &&
                                <Grid container sx={{
                                    my: 2, ml: 'auto', mr: 'auto', p: 0, width: '100%', maxWidth: {
                                        xl: '666px',
                                    }
                                }} justifyContent="center" display={"flex"}>
                                    <StyledButton2 width="100%" disabled={loading}>
                                        {loading ? <CircularProgress color="inherit" /> : "Completar cuestionario"}
                                    </StyledButton2>
                                </Grid>

                            }
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );

}
export default Cuestionario; 