import React from 'react'
/**
 * @author ERL 2023-09-20 03:06 pm
 * @param {object} props - Propiedades del componente
 * @field {string} sSucursal - Sucursal del conductor 
 * @returns {JSX.Element}
 */
const DriverBranch = ({ sSucursal }) => {
    return (
        <p className='driver-sucursal'>
            {sSucursal}
        </p>
    )
}

export default DriverBranch
