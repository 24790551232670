// Importando módulos necesarios
import React from 'react';
import '../../../../../styles/registro/global.scss'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import axios from 'axios';
import { toast } from 'sonner';
import { urlsAPIs } from '../../../../../helpers/urlsAPIs';
import { globalMessage } from '../../../../../helpers/globalMessage';
import { createTheme, ThemeProvider } from '@mui/material/styles'; // Herramientas de MUI para el tema
import CargaVideoIndicacionesGrabacion from './CargaVideoIndicaciones';
import { Box } from '@mui/material';
import CargaVideoInputContainer from './CargaVideoInputContainer';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CancelIcon from '@mui/icons-material/Cancel';
import { LANG } from './LANG';
import { ProgressBarContext } from '../../../../../context/ProgressBarContext';
import { useContext } from 'react';
// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});


/**
 * Componente principal para la carga de videos desde un dispositivo móvil.
 * Este componente maneja la carga de documentos de tipo video del usuario, 
 * actualización de estado de los documentos y la validación de los mismos.
 * @author ERL 2023-06-09 09:41 am 
 * @param {number} nIdUsuario - ID del usuario.
 * @param {boolean} bIsMobile - Indica si el dispositivo es móvil.
 * @param {boolean} bTodosPendientes - Indica si todos los documentos están pendientes.
 * @param {array} dataDocumentos - Listado de documentos.
 * @param {function} setDataDocumentos - Actualiza los valores del listado de documentos.
 * @param {boolean} bLoadingComponent - Indica si se muestra el loader.
 * @returns {JSX.Element} - Retorna el componente de React.
 */

const CargaVideoMovil = ({ nIdUsuario, bIsMobile, bTodosPendientes, dataDocumentos, setDataDocumentos, bLoadingComponent, }) => {

    const { headers, logout } = useContext(ProgressBarContext)
    const ContainerStyles = { maxWidth: { xs: '100%', sm: '100%', md: '600px', lg: '700px', }, display: 'flex', flexDirection: 'column', justifyContent: 'start', };
    /**
     * Funcion para subir archivos a google, guardar info en la API y actualizar el status de los documentos
     * @author ERL 2023-07-17 12:36 pm 
     * @param {object} oArchivo - Archivo cargado por el usuario
     * @param {string} sNombreArchivo - Nombre del archivo
     * @param {string} sStatus - Status del documento
     * @param {int} nIdentificador - Id del documento
     * @returns {void} 
    */
    const enviarVideoGoogle = async (oConfig = {}) => {
        const { oArchivo, sNombreArchivo, sStatus, nIdentificador } = oConfig;
        const { type } = oArchivo[0];
        const originalName = sNombreArchivo;
        const sNombreArchivoClean = sNombreArchivo.toString().trim().toUpperCase().replace(/[-\s()]/g, "");
        const sTipo = type.split("/").pop();
        updateDocumentStatus(nIdentificador, 'ENVIADO', originalName);
        // Si el estado es 'VALIDADO' o 'PENDIENTE', no se realiza el envío
        if (["VALIDADO", "PENDIENTE"].includes(sStatus)) {
            return;
        }

        let objeto = {};

        try {
            // Obtener la URL pública y firmada para subir el archivo a Google Cloud Storage
            const response = await axios.get(`${urlsAPIs.urlGoogleStorage}/${nIdUsuario}?sNombre=${sNombreArchivoClean}&sTipo=${sTipo}`, {
                headers:headers
            });

            if (response.status === 200) {
                const { sURLPublica, sUrlFirmada } = response.data;

                let myHeaders = new Headers();
                myHeaders.append("Content-Type", type);
                const fieldName = `video${nIdentificador}`;
                objeto[fieldName] = {
                    nIdDocumento: nIdentificador,
                    sRuta: sURLPublica,
                    nnIdUsuario: nIdUsuario
                };

                let requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: oArchivo[0],
                    redirect: 'follow'
                };

                const responseGoogleStorage = await fetch(sUrlFirmada, requestOptions);
                const { status: uploadStatus } = responseGoogleStorage;

                if (uploadStatus === 200) {


                    // Actualizar el estado del documento a 'PENDIENTE' en la base de datos
                    updateDocumentStatus(nIdentificador, 'VALIDANDO', originalName);
                    objeto[fieldName] = {
                        nIdDocumento: nIdentificador,
                        sRuta: sURLPublica,
                        nnIdUsuario: nIdUsuario,

                    };

                    // Enviar los datos del documento a la API
                    const response = await axios.post(
                        `${urlsAPIs.urlEnviarDocumentos}/${nIdUsuario}`,
                        { ...objeto, sTipo: "V" },  // V = Video
                        { headers: headers }
                    );
                    const { nCodigo, sMensaje } = response.data;
                    if (nCodigo === 0) {
                        toast.success(sMensaje);
                    } else {
                        toast.error(sMensaje);
                    }
                } else {
                    // Si hay un error en la carga del archivo, se actualiza el estado del documento a 'ERROR'
                    updateDocumentStatus(nIdentificador, 'ERROR', originalName);
                }
            } else {
                // Si hay un error al obtener la URL pública y firmada, se actualiza el estado del documento a 'ERROR'
                updateDocumentStatus(nIdentificador, 'ERROR', originalName);
            }
        } catch (error) {

            const { responseCode = 0, message = "" } = error?.response.data
            if (responseCode === '401' && message === 'jwt expired') {
                toast.error(globalMessage.labelSesionExpirada)
                logout()
            } else {

                // Si hay un error en la llamada a la API, se actualiza el estado del documento a 'ERROR'
                updateDocumentStatus(nIdentificador, 'ERROR', originalName);
                toast.error(globalMessage.errorServidor)
            }
        }
    };

    /**
    * Función para actualizar el estado del documento
    * @author ERL 2023-07-17 12:34 pm 
    * @param {int} nIdentificador - Id del documento
    * @param {string} sNewStatus - Nuevo status del documento
    * @returns {void} 
   */
    const updateDocumentStatus = (nIdentificador, sNewStatus) => {
        // Actualizamos dataDocumentos con la nueva lista de documentos
        setDataDocumentos(prevDataDocumentos => {
            const updatedDataDocumentos = prevDataDocumentos.map(document => {
                if (document.nIdentificador === nIdentificador) {
                    // Si es el documento correcto, actualizamos su status
                    return { ...document, sStatus: sNewStatus };
                } else {
                    // Si no es el documento que estamos buscando, lo devolvemos sin cambios
                    return document;
                }
            });
            return updatedDataDocumentos;
        });
    };
    /**
    * Funcion para obtener el status de los documentos
    * @author ERL 2023-07-17 12:33 pm 
    * @param {object} oDocumento - Objeto con el status del documento     
    * @returns {string} Status del documento
   */
    const getStatusDocs = (oDocumento = {}) => {
        const status = oDocumento?.sStatus ? oDocumento.sStatus : "";
        return status.toString().toUpperCase();
    }
    const oStatusResponse = {
        "VALIDADO": {
            sStatusColor: 'green',
            sStatusMessage: "Archivo validado",
            sIcono: <CheckCircleIcon />
        },
        "RECHAZADO": {
            sStatusColor: 'red',
            sStatusMessage: "Archivo rechazado",
            sIcono: <CancelIcon />
        },
        "ERROR": {
            sStatusColor: 'red',
            sStatusMessage: "Error en la carga del archivo",
            sIcono: <CancelIcon />
        },
        "ENVIADO": {
            sStatusColor: 'blue',
            sStatusMessage: "Archivo enviado",
            sIcono: <AccessTimeFilledIcon />
        },
        "REEMPLAZADO": {
            sStatusColor: 'blue',
            sStatusMessage: "Archivo validado",
            sIcono: <CheckCircleIcon />
        },
        "PENDIENTE": {
            sStatusColor: 'orange',
            sStatusMessage: "",
            sIcono: <AccessTimeFilledIcon />
        },
        "VALIDANDO": {
            sStatusColor: 'orange',
            sStatusMessage: "Validando archivo",
            sIcono: <AccessTimeFilledIcon />
        },
        "": {
            sStatusColor: 'black',
            sStatusMessage: "",
            sIcono: <RadioButtonUncheckedIcon />
        },
    }

    return (

        <>

            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="sm" sx={ContainerStyles}>
                    <CssBaseline />
                    <Box>
                        <CargaVideoIndicacionesGrabacion
                            sTitulo={bTodosPendientes ? LANG.labelMensajeCargado : LANG.labelIndicaciones}
                            sDescripcion={""}
                        />
                        <CargaVideoInputContainer
                            bIsMobile={bIsMobile}
                            nIdUsuario={nIdUsuario}
                            bLoadingComponent={bLoadingComponent}
                            dataDocumentos={dataDocumentos}
                            setDataDocumentos={setDataDocumentos}
                            oStatusResponse={oStatusResponse}
                            getStatusDocs={getStatusDocs}
                            enviarVideoGoogle={enviarVideoGoogle}
                        />
                    </Box>
                </Container>
            </ThemeProvider>

        </>

    );
}

export default CargaVideoMovil
