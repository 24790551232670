import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FullScreenLoader from '../../components/loader/FullScreenLoader';

const Contact = lazy(() => import('../../components/marketing/Contact'));

const Campana = () => {
  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const { sIdCapana } = useParams();
  const query = useQuery();
  const [sCanal, setCanal] = useState(query.get('canal'));
  const aCampana = [
    {
      nIdCampana: 1,
      sIdCapana: "landing-page",
      sTitle: "Tu mejor opción de Arrendamiento de Autos para Socios UBER o DIDI",
      sSubtitle:"Registra tus datos para solicitar más información",
      sUrlImgWeb: "assets/marketing/campaign/landing-page/web.jpg",
      sUrlImgMobile: "assets/marketing/campaign/landing-page/mobile.jpg",
    }
  ];

  const [aCampanaFiltered, setCampanaFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (sIdCapana) {
      const filteredCampana = aCampana.filter(c => c.sIdCapana === sIdCapana);      
      if(filteredCampana.length === 0){
          navigate('/')
      }else{
        setCampanaFiltered(filteredCampana);
      }
    }
  }, [sIdCapana]);

  useEffect(() => {
    if (aCampanaFiltered.length > 0) {
      setIsLoading(false);
    }
  }, [aCampanaFiltered]);

  return (
    <Suspense fallback={<FullScreenLoader />}>
      {isLoading ? (
        <FullScreenLoader fullScreen={true} />
      ) : (
        aCampanaFiltered.map(c => (
          <Contact
            key={c.nIdCampana}
            nIdCapana={c.sIdCapana}
            sTitle={c.sTitle}
            sSubtitle={c.sSubtitle}
            sUrlImgWeb={c.sUrlImgWeb}
            sUrlImgMobile={c.sUrlImgMobile}
            sCanal={sCanal}
          />
        ))
      )}
    </Suspense>
  );
};

export default Campana;
