import { Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { toast } from 'sonner';

/**
 * Componente de Botón con Temporizador
 * @author ERL - 2023-06-09 07:75 pm
 * @param {object} props - Propiedades del componente
 * @field {boolean} bCodigoReEnviado - Determina si el codigo ya fue re enviado
 * @param {React.ReactNode} props.children - Los hijos del componente, para renderizar dentro del botón
 * @returns {JSX.Element} - Retorna el componente de Botón con Temporizador
 */
function BotonConTemporizador({ children, bCodigoReEnviado, nTiempo = 2 }) {
    const tiempoInicial = localStorage.getItem('tiempo') ? parseInt(localStorage.getItem('tiempo')) : 0;
    const [tiempo, setTiempo] = useState(tiempoInicial);
    const [disabled, setDisabled] = useState(false);
    const [tiempoRestante, setTiempoRestante] = useState(0);

    const currentDate = new Date().toISOString().split('T')[0];
    const savedDate = localStorage.getItem('currentDate') || "";
    const timesSent = parseInt(localStorage.getItem('timesSent') || "0");

    const [clicks, setClicks] = useState(savedDate === currentDate ? timesSent : 0);
    const minutos = Math.floor(tiempoRestante / 60);
    const segundos = tiempoRestante % 60;
    const tiempoFormateado = `${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;

    // Verificar si ha pasado menos de dos minutos desde la última vez que se presionó el botón
    useEffect(() => {
        const tiempoPasado = Math.floor((Date.now() - tiempo) / 1000);
        if (tiempoPasado < nTiempo * 60) {
            setDisabled(true);
            setTiempoRestante(nTiempo * 60 - tiempoPasado);
        }
    }, [tiempo]);

    // Actualizar el tiempo restante cada segundo
    useEffect(() => {
        let timer;
        if (disabled) {
            timer = setInterval(() => {
                const segundosRestantes = nTiempo * 60 - Math.floor((Date.now() - tiempo) / 1000);
                if (segundosRestantes <= 0) {
                    setDisabled(false);
                    clearInterval(timer);
                    localStorage.removeItem('tiempo');
                } else {
                    setTiempoRestante(segundosRestantes);
                }
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [disabled, tiempo]);

    // Función a ejecutar cuando se hace clic en el botón
    const empezarTemporizador = () => {
        if (clicks >= 3) {
            toast.error('Has alcanzado el límite de envíos para hoy.');
            return;
        }

        setTiempo(Date.now());
        setDisabled(true);
        localStorage.setItem('tiempo', Date.now());

        // Incrementa el conteo de clics y actualiza la fecha actual
        const newClicks = clicks + 1;
        setClicks(newClicks);
        localStorage.setItem('timesSent', newClicks.toString());
        localStorage.setItem('currentDate', currentDate);
    };

    useEffect(() => {
        if (bCodigoReEnviado === true) {
            empezarTemporizador();
        }
    }, [bCodigoReEnviado]);

    useEffect(() => {
        if (currentDate !== savedDate) {
            setClicks(0);
            localStorage.setItem('timesSent', "0");
            localStorage.setItem('currentDate', currentDate);
        }
    }, [currentDate, savedDate]);

    return (
        <Grid textAlign={'center'} my={'10px'}>
            {disabled ? <Typography variant='span'>{tiempoFormateado}</Typography> : <>
                <div disabled={disabled}>
                    {children}
                </div>
            </>}

        </Grid>
    );
}

export default BotonConTemporizador;
