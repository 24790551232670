
/**
 * Custom hook para los estilos de los componentes MUI
 * @author ERL 2023-08-07 02:30 pm 
 * @returns {object}
 */
export function useStyles() {


  const StylesUnidades = {
    "unidades-container": {
      maxWidth: {
        xl: '1420px',
        height: 'auto !important'
      },
      margin: {
        xl: '0 auto'
      },
      width: {
        xl: '100%'
      },
      height: {
        xl: 'auto !important'
      },
      padding: {
        xl: '33px 0',
        md: '33px 0',
        sm: '66px 0'
      }
    },
    'sticky-header': {
      position: 'sticky',
      top: '70px',
      zIndex: 2,
      background: '#fff',
      display: 'flex',
      alignItems: {
        xl: '',
        lg: '',
        md: '',
        sm: '',
        xs: '',
      },
    },
    'sidebar': {
      position: 'sticky',
      top: '100px',
      zIndex: 1,
      background: '#fff',
      width: '85%', margin: '0 auto',
      display: {
        xl: 'flex',
        lg: 'flex',
        md: 'flex',
        sm: 'none',
        xs: 'none',
      }
    },
    'sticky-sidebar': {
      position: 'sticky',
      top: '100px',
      zIndex: 1,
      background: '#fff',
      width: '85%', margin: '0 auto',
    },
    'unidades': {
      display: 'flex',
      width: '100%',
      gap: '25px',
      flexWrap: 'wrap',
      alignContent: 'start',
      padding: '30px 0'
    },
    "menu-sorting-btn": {
      border: 'none',
      color: '#483e6b',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      textTransform: 'capitalize'
    },
    "filtros-container": {
      display: {
        xl: 'flex',
        lg: 'flex',
        md: 'flex',
        sm: 'none',
        xs: 'none',
      },
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    "filtros": {
      width: '85%',
      margin: ' 0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    "filtros-btn": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      cursor: 'pointer'
    },
    "btn-modal": {
      display: {
        xl: 'none',
        lg: 'none',
        md: 'none',
        sm: 'block',
      },
      textAlign: 'center',
      p: {
        xs: '5px 0'
      },
    },
    "resultados-container": {
      display: 'flex',
      justifyContent: {
        xl: 'space-between',
        lg: 'space-between',
        md: 'space-between',
        xs: 'center',
      },
      alignItems: 'center',
      margin: {
        xs: '15px 0'
      }
    },
    "detalles-unidad": {
      gap: { xl: '20px' },
      display: { xl: 'flex' },
      flexDirection: { xl: 'column' },
      width: {
        xl: '',
        lg: '',
        md: '100%',
        sm: '100%',
        xs: '100%',
      }
    }
  }

  const StylesVideo360 = {
    "container": {
      maxWidth: {
        xs: '100%',
        sm: '100%',
        md: '600px',
        lg: '700px',
      },
      padding:'0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: {
        xl: '50vh',
        lg: '50vh',
        md: '50vh',
        sm: 'auto',
      }
    },
    "container-form": {
      padding: "15px 0", mx: "auto", width: {
        xl: '90%',
        sm: '80%',
        xs: '100%'
      }
    },
    "container-inputs": { textAlign: "center", },
    "container-input": {
      textAlign: "center",
      border: '1px solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      padding: {
        xl: '10px 20px',
        md: '10px 20px',
        xs: '10px 10px'
      },
      borderRadius: '10px',
      width: {
        xl: "100%",
        md: "100%",
        xs: '100%',
      },
      maxWidth: {
        xl: "563px",
        md: "563px",
        xs: '100%',
      },
      cursor:'pointer'
    },
    "input-label": {
      fontWeight: "bold",
      fontSize: {
        xl: "16px",
        sm: "16px",
        xs: '12px'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      gap: '10px',
      whiteSpace: "pre-line",
      cursor:'pointer'
    },
    "input-label-tooltip": {
      fontWeight: "light",
      fontSize: "1em",
      display: "flex",
      alignItems: "center",
    },
    "input-label-icon": {
      padding: "0 !important",
      mx: 0,
      mt: 0,
    },
    "input-label-ico": {
      fontSize: {
        xl: 40,
        md: 30,
        sm: 25,
        xs: 20
      }, mx: 0, mt: 0, p: 0,
    },
  }


  return {
    StylesUnidades,
    StylesVideo360
  };
}
