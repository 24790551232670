import React, { useState } from "react";
import { Attach } from '@styled-icons/fluentui-system-regular'
import emailjs from '@emailjs/browser';
import Footer from "./Footer";
import { useRef } from "react";
import CustomInput from "./CustomInput";
import { useEffect } from "react";

function Formulario() {
    const [file, setFile] = useState(null);
    const [done, setDone] = useState(false);
    const form = useRef();
    const [isValidForm, setIsValidForm] = useState(false);
    const [clearInputs, setClearInputs] = useState(false);
    function validarEmail(email) {
        // Expresión regular para validar emails
        var regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regexEmail.test(email);
    }
    const [nombre, setNombre] = useState('');
    const [apellidos, setaApellidos] = useState('');
    const [correo, setCorreo] = useState('');
    const [mensaje, setMensaje] = useState('');
    const nombreRef = useRef(null);
    const apellidosRef = useRef(null);
    const correoRef = useRef(null);
    const mensajeRef = useRef(null);
    const [validEmail, setValidEmail] = useState(false);    
    const handleFormSubmit = (e) => {
        e.preventDefault();
        var nodos = form.current.querySelectorAll('input:not([type="file"]):not([type="hidden"])');
        if (nombre === '' && apellidos === '' && correo === '') {
            setIsValidForm(true);
            setFileSizeMsg('Todos los campos en rojo son obligatorios');
            nodos.forEach(function (nodo) {
                nodo.classList.add('invalid');
            });
            document.querySelector('.file').classList.add('invalid'); 
            return;
        }
        if (nombre === '') {
            const current = nombreRef.current
            current.classList.add('invalid');
            current.focus();
            return;
        }
        if (apellidos === '') {
            const current = apellidosRef.current
            current.classList.add('invalid');
            current.focus();
            return;
        }
        if (correo === '') {
            const current = correoRef.current
            current.classList.add('invalid');
            current.focus();
            return;
        } else {
            if (!validEmail) {
                console.log(validEmail)
                return;
            }
        }
        if (file) {            
            const filesize = checkFileSize(file.size);
            if (!filesize) {
                emailjs.sendForm('service_fst7gdm', 'template_q8xx62h', form.current, 'QQvoOXLmHkR29h36W', {
                    from_name: 'Edgar Ramos'
                })
                    .then(
                        (result) => {
                            console.log(result.text);
                            setDone(true)
                            setFileSizeMsg('');                         
                            setTimeout(() => {
                                setDone(false);
                                window.location.reload();
                            }, 1500);
                        },
                        (error) => {
                            console.log(error.text);
                        });
            } else {
                setFileSizeMsg("El archivo es demasiado grande");
                document.querySelector('.file').classList.add('invalid');
            }
        } else {
            setFileSizeMsg("Adjunta un archivo valido");
            document.querySelector('.file').classList.add('invalid');
        }

    };
    const [fileSizeMsg, setFileSizeMsg] = useState('');
    const checkFileSize = (size) => {
        const limitSize = Number(size) / (1024 * 1024);
        if (limitSize > 2) {
            setFileSizeMsg("El archivo no puede tener mas de 2MB de peso");
            
            return true;
        } else {
            setFileSizeMsg("");
            return false;
        }
    }
    useEffect(() => {
        if (file) {
            if (file.length !== '') {
                document.querySelector('.file').classList.remove('invalid');                
            }
        }
    }, [file]);

    useEffect(() => {        
        
        if(nombre !== '' && apellidos !== '' && correo !== '' && file) {
            setFileSizeMsg("");
        }
    }, [nombre, apellidos, correo, file]);

    const formTitle = "Únete al equipo";
    return (
        <>
            <div className="vacante-form">
                <div className="form-title">
                    <h2>{formTitle}</h2>
                </div>
                <form ref={form}>
                    <div className="form-item">
                        <CustomInput
                            name="nombre"
                            label="nombre"
                            type="input"
                            setIsValidForm={setIsValidForm}
                            isValidForm={isValidForm}
                            value={nombre}
                            callback={setNombre}
                            inputRef={nombreRef}
                        />
                    </div>
                    <div className="form-item">
                        <CustomInput
                            name="apellidos"
                            label="apellidos"
                            type="input"
                            setIsValidForm={setIsValidForm}
                            isValidForm={isValidForm}
                            value={apellidos}
                            callback={setaApellidos}
                            inputRef={apellidosRef}
                        />
                    </div>
                    <div className="form-item">
                        <CustomInput
                            name="correo"
                            label="correo"
                            type="email"
                            setIsValidForm={setIsValidForm}
                            isValidForm={isValidForm}
                            value={correo}
                            callback={setCorreo}
                            inputRef={correoRef}
                            validEmail={validEmail}
                            setValidEmail={setValidEmail}
                        />
                    </div>
                    <div className="form-item txt">
                        <CustomInput
                            name="mensaje"
                            label="mensaje"
                            type="textarea"
                            setIsValidForm={setIsValidForm}
                            isValidForm={isValidForm}
                            value={mensaje}
                            callback={setMensaje}
                            inputRef={mensajeRef}
                        />
                    </div>
                    <div className="form-item fl">
                        <label htmlFor="adjunto" className="file">
                            <b>
                                Adjunta tu CV:
                            </b>
                            <Attach size="33" />
                            <input
                                type="file" name="file" id="adjunto" className="input-style" onChange={(e) => setFile(e.target.files[0])} required />
                            {file !== null && <p>{file.name}</p>}
                            {fileSizeMsg !== '' && <p>{fileSizeMsg}</p>}
                        </label>
                    </div>
                </form>
            </div>
            {
                done &&
                <center style={{ color: '#fff' }}>
                    Mensaje enviado
                </center>
            }
            <Footer onSubmit={handleFormSubmit} clear={clearInputs} />

        </>
    );
}

export default Formulario;
