import React from 'react'
import { globalMessage } from '../../../../../helpers/globalMessage';
import { Grid, Tooltip } from '@mui/material';
import { InputNumber, InputSelect, InputText } from './ReferenciasInputs';
import ReferenciasStep from './ReferenciasStep';
/**
 * Componente Stepper para las referencias pendientes. Este componente mapea cada paso en los datos de la referencia
 * y presenta el formulario para ese paso específico. Además, este componente gestiona el estado actual del paso.
 * 
 * @author ERL 2023-07-19 08:45 pm
 * @param {array} dataReferencia - Datos de referencia del formulario.
 * @param {array} steps - Pasos del formulario.
 * @param {function} getStatusDocs - Función para obtener el estado de los documentos.
 * @param {object} statusResponse - Respuestas de estado de los documentos.
 * @param {function} consultarColonias - Función para consultar colonias.
 * @param {function} seleccionarColonia - Función para seleccionar una colonia.
 * @param {int} nCurrentStep - Función para seleccionar una colonia.
 * @param {boolean} loadingColonias - Determina si se muestra el loader al consultar colonias
 * @returns {JSX.Element}
 */


export const ReferenciasPendientesStepper = ({
    dataReferencia,
    steps,
    getStatusDocs,
    statusResponse,
    consultarColonias,
    seleccionarColonia,
    nCurrentStep,
    loadingColonias
}) => {    
    const Styles = {
        "h1": {
            display: "flex",
            gap: "5px",
            alignItems: "center",
        },
        "i": {

            display: "flex",
            alignItems: "center",

        }
    }
    return (
        <>
            {steps.map((step, i) => {
                return (
                    <React.Fragment key={step.sValor}>
                    <ReferenciasStep nStep={i + 1} nCurrentStep={nCurrentStep}>
                        <>                        
                            <h2 style={Styles["h1"]}>
                                {step.sValor}
                                <Tooltip title={step.sStatus !== "" ? step.sStatus : ""} arrow >
                                    <i style={{ ...Styles["i"], color: statusResponse[getStatusDocs(step)].sStatusColor }}>{statusResponse[getStatusDocs(step)].sIcono}</i>
                                </Tooltip>
                            </h2>
                        </>
                        {dataReferencia.map((question, index) => {
                            if (question.nIdPadre === steps[nCurrentStep - 1].nIdentificador) {
                                return (
                                    <Grid item xs={12} p="15px 0" key={index}>
                                    {question.sTipo === 'text' && (

                                        <>                                        
                                        <InputText
                                            nIdentificadorRefInput={`ref${step.nIdentificador}-${question.nIdentificador}`}
                                            sDefaultValue={question.sRespuesta}
                                            bRequired={globalMessage.errorInformacionRequerida}
                                            consultarColonias={consultarColonias}
                                            sLabel={question.sValor}
                                            sNombreCorto={question.sNombreCorto}
                                            sStatusStep={step.sStatus}
                                            nIdentificadorInput={step.nIdentificador}
                                            nIdentificadorRef={step.nIdentificador}
                                            bReadOnly={question.sTipo === "readonly"}
                                            bMaxLength={["sCodigoPostal"].includes(question.sNombreCorto) ? 5 : ""}
                                            bCodigoPostal={["sCodigoPostal"].includes(question.sNombreCorto)}
                                            bNombreCampo={["sNombre","sApellidoP", "sApellidoM"].includes(question.sNombreCorto)}
                                        />
                                        </>
                                    )}
                                    {question.sTipo === 'readonly' && (
                                        <InputText
                                            nIdentificadorRefInput={`ref${step.nIdentificador}-${question.nIdentificador}`}
                                            sDefaultValue={question.sRespuesta}
                                            bRequired={globalMessage.errorInformacionRequerida}
                                            consultarColonias={consultarColonias}
                                            sLabel={question.sValor}
                                            sNombreCorto={question.sNombreCorto}
                                            sStatusStep={step.sStatus}
                                            nIdentificadorInput={step.nIdentificador}
                                            nIdentificadorRef={step.nIdentificador}
                                            bReadOnly={question.sTipo === "readonly"}
                                            bMaxLength={["sCodigoPostal"].includes(question.sNombreCorto) ? 5 : ""}
                                            bCodigoPostal={["sCodigoPostal"].includes(question.sNombreCorto)}
                                            
                                        />


                                    )}


                                    {(question.sTipo === "number") && (
                                        <>
                                            <InputNumber
                                                nIdentificadorRefInput={`ref${step.nIdentificador}-${question.nIdentificador}`}
                                                sDefaultValue={question.sRespuesta}
                                                bRequired={globalMessage.errorInformacionRequerida}
                                                consultarColonias={consultarColonias}
                                                sLabel={question.sValor}
                                                sNombreCorto={question.sNombreCorto}
                                                sStatusStep={step.sStatus}
                                                nIdentificadorInput={step.nIdentificador}
                                                nIdentificadorRef={step.nIdentificador}
                                                bReadOnly={question.sTipo === "readonly"}
                                                bMaxLength={["sCodigoPostal"].includes(question.sNombreCorto) ? 5 : ""}
                                                bCodigoPostal={["sCodigoPostal"].includes(question.sNombreCorto)}
                                                isTelefono={["sTelefono"].includes(question.sNombreCorto)}
                                       
                                            />

                                        </>

                                    )}

                                    {question.sTipo === 'select' &&
                                        (
                                            <>

                                                <InputSelect
                                                    nIdentificadorRefInput={`ref${step.nIdentificador}-${question.nIdentificador}`}
                                                    bRequired={globalMessage.errorInformacionRequerida}
                                                    consultarColonias={consultarColonias}
                                                    sLabel={question.sValor}
                                                    sNombreCorto={question.sNombreCorto}
                                                    sStatusStep={step.sStatus}
                                                    nIdentificadorInput={step.nIdentificador}
                                                    nIdentificadorRef={step.nIdentificador}
                                                    bReadOnly={question.sTipo === "readonly"}
                                                    bMaxLength={["sCodigoPostal"].includes(question.sNombreCorto) ? 5 : ""}
                                                    bCodigoPostal={["nIdColonia"].includes(question.sNombreCorto)}
                                                    sDefaultValue={question.sRespuesta}
                                                    bIdColonia={["nIdColonia"].includes(question.sNombreCorto)}
                                                    respuestas={question.respuestas}
                                                    seleccionarColonia={seleccionarColonia}
                                                    loadingColonias={loadingColonias}
                                               
                                                />
                                            </>
                                        )}
                                </Grid>
                                );
                            }
                            return null;
                        })}

                    </ReferenciasStep>
                    </React.Fragment>
                );
            })}


        </>
    );
};


export default ReferenciasPendientesStepper
