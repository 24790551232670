import { Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { StyledButton4 } from '../../../../customButtons/StyledButton';
import CompletedStep from '../../CompletedStep';
import { LANG } from './LANG';
import axios from 'axios';
import { urlsAPIs } from '../../../../../helpers/urlsAPIs';
import { toast } from 'sonner';
import { globalMessage } from '../../../../../helpers/globalMessage';
import { ProgressBarContext } from '../../../../../context/ProgressBarContext';
/**
 * PagoCompletado - Componente React encargado de gestionar la vista y lógica 
 * después de que un pago ha sido completado. Provee información relevante y 
 * enlaces dependiendo del sistema operativo y navegador del usuario.
 * 
 * Utiliza funciones internas para determinar el sistema operativo y si el navegador 
 * es Chrome, mostrando contenido específico basado en estos detalles.
 * 
 * @author ERL 2023-09-28 08:23 am
 * @returns {JSX.Element} Retorna un componente que representa la pantalla de pago completado.
 */
const PagoCompletado = () => {
    const [nIsArrendando, setIsArrendando] = useState(0);
    const { headers } = useContext(ProgressBarContext);
    useEffect(() => {
        const getData = async () => {
            const id_cliente = sessionStorage.getItem('ci');
            try {
                const response = await axios.get(`${urlsAPIs.urlReferenciaPago}/${id_cliente}`, {
                    params: {
                        sTipo: "P" // Pago Referencia
                    },
                    headers: headers
                });                
                const { nCodigo, sMensaje, data: oReferenciaForm } = response?.data;

                if (nCodigo === 0) {

                    const { sNombre, sApellidoP, sApellidoM, sRefencia, nMonto, sStatusPagoGarantia, nIdStatusCliente } = oReferenciaForm[0];
                    setIsArrendando(nIdStatusCliente)

                } else {
                    toast.error(sMensaje)
                }
            } catch (error) {
                toast.error(globalMessage.errorServidor)
            } finally {

            }
        }
        getData();
    }, []);
    const androidLogo = "/assets/android-logo.png";
    const chromeLogo = "/assets/chrome-logo.svg";

    /**
    * Función para obtener el sistema operativo del dispositivo del usuario
    * @author ERL 2023-07-17 12:48 pm 
    * @returns {string}
    */
    const getOperatingSystem = () => {
        let userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            return 'ANDROID';
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'IOS';
        } else {
            return 'NOOS';
        }
    };
    /**
     * Función para verificar si el navegador del usuario es Google Chrome
     * @author ERL 2023-07-17 12:40 pm 
     * @returns {boolean} 
     */
    const isChrome = () => {
        return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    };
    const widthCommon = '500px';
    return (
        <Grid sx={{
            maxWidth: {
                xl: widthCommon,
                lg: widthCommon,
                md: widthCommon,
                sm: '400px',
                xs: '300px',
            },
            margin: '0 auto',
            padding: '20px 0'
        }}>




            <>
                <CompletedStep sMensaje={LANG.labelPagado} sDesc={LANG.labelsBienvenido} />
                <Grid className='welcome-msg-succes' flexDirection={'column'}>
                    <p>
                        {LANG.labelsPendiente} <b>{LANG.labelNuevaUnidad}</b>
                    </p>
                    <p>
                        {LANG.labelDescargaApp}
                    </p>
                    <p> {LANG.labelNoPierdas}</p>
                </Grid>
                {["ANDROID"].includes(getOperatingSystem()) ?
                    <Grid width={"80px"} margin={'0 auto'}>
                        <a href="https://play.google.com/store/apps/details?id=com.aplicacion.besta&pli=1" target="_blank" rel="noopener noreferrer">
                            <img src={androidLogo} width={'100%'} alt="" />
                        </a>
                    </Grid> :
                    isChrome() && <Grid width={"auto"}
                        sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: '10px', color: "primary" }}>
                        <a href="https://management.besta.mx/" target="_blank" rel="noopener noreferrer" className='nolink'>
                            <StyledButton4>
                                <p>Besta Management</p>
                            </StyledButton4>
                        </a>
                    </Grid>
                }
                {(["IOS"].includes(getOperatingSystem()) || !isChrome()) &&
                    <Grid width={"auto"}
                        sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: '10px' }}
                    >
                        <img src={chromeLogo} alt="" width={50} />
                        <Typography component={"p"} variant={"p"} sx={{ fontSize: { xl: '16px', sm: '12px', xs: '12px' } }}>
                            Para una mejor experiencia de uso, te recomendamos utilizar el navegador Chrome para acceder al siguiente enlace:
                        </Typography>
                        <a href="https://management.besta.mx/" target="_blank" rel="noopener noreferrer" className='nolink'>
                            <StyledButton4>
                                <p>Besta Management</p>
                            </StyledButton4>
                        </a>
                    </Grid>
                }
            </>

        </Grid>
    )
}

export default PagoCompletado
