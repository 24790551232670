import "./uber.scss";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ContactForm from "../contact/ContactForm";
import Beneficios from "../beneficios/Beneficios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  shadows: ["none"]
}));

/**
 * Componente Uber que muestra información y beneficios relacionados con Uber.
 * @author ERL 2023-10-20 10:09 am
 * @returns {JSX.Element} - Retorna la estructura visual del componente Uber.
 */
const Uber = () => {

  return (


    <div className="c-uber" id="uber">
      <div className="c-wrapper-uber">
        <Box sx={{ flexGrow: 1, width: '100%' }} >

          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={12}>
              <Item style={{ boxShadow: "none" }} className="slider-alianzas">
                <Beneficios mostrarImagen={false}/>
              </Item>
            </Grid>                     
            <Grid item xs={12}>
              <Item style={{ boxShadow: "none" }}>
                <h3>BENEFICIOS</h3>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item style={{ boxShadow: "none" }}>
                <img src="assets/BENEFICIOSCARRO-2023.png" alt="Beneficios besta" className="c-imagen-uber" />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <ContactForm bg={false} />
            </Grid>
          </Grid>
          <Grid item xs={12}>

          </Grid>
        </Box>
      </div>
    </div>

  );
};

export default Uber;