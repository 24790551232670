import "./topbar.scss"
import React, { useEffect, useRef, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from './bestalogo.png';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Modal from "../modal/Modal";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles';
import Registro from "../../pages/registro/usuarios/Registro";
import Login from "../../pages/login/Login";
import { toast } from "sonner";
import axios from "axios";
import { Alert } from "@mui/material";
import Perfil from "../../pages/perfil/Perfil";
import { urlsAPIs } from "../../helpers/urlsAPIs";
import { globalMessage } from "../../helpers/globalMessage";
import { useContext } from "react";
import { ProgressBarContext } from "../../context/ProgressBarContext";


/**
 * Componente de Barra Superior (Topbar)
 * @author ERL 2023-06-09 01:01 pm
 * @param {boolean} menuOpen - Indica si el menú está abierto o cerrado
 * @param {function} setMenuOpen - Función para establecer el estado del menú
 * @returns {JSX.Element} - Retorna el componente de la barra superior
 */
export default function Topbar({ menuOpen, setMenuOpen }) {
    const { headers } = useContext(ProgressBarContext);
    const [isModalOpen, setModalOpen] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const btnRef = useRef(null);
    const btnRef2 = useRef(null);
    const location = useLocation();
    const pathname = location.pathname;
    const navigate = useNavigate();
    const jwt = sessionStorage.getItem('t');


    const handleClose = () => {
        setModalOpen(false);
        setNewUser(false);
    }

    const handleOpen = () => {

        if (jwt && jwt.length > 0) {
            setModalOpen(true);
        } else {
            navigate('/login')
        }

    }


    const theme = createTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (pathname === '/registro' || pathname === '/login' || pathname === '/proceso' || pathname.includes('formulario')) {
            if (btnRef.current) {
                btnRef.current.click();
            }
        }
        if (isMobile) {
            if (pathname !== '/' || pathname.includes("#")) {
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }, 100);
            }
        }
    }, [pathname, isMobile]);   

    return (
        <>
            <div className={"topbar " + (menuOpen && "active")}>
                <div className="wrapper">
                    <div className="left">
                        <HashLink smooth to={{ pathname: "/", hash: "#inicio" }} className="logo" preventScrollReset={true}>
                            <img src={logo} alt="" />
                        </HashLink>
                        <div className="itemContainer"
                            style={{
                                display: pathname === '/proceso' ? 'none' : ''
                            }}
                        >
                            <HashLink smooth to={{ pathname: "/", hash: "#modelos" }}>Modelos</HashLink>
                        </div>
                        <div className="itemContainer"
                            style={{
                                display: pathname === '/proceso' ? 'none' : ''
                            }}
                        >
                            <HashLink smooth to={{ pathname: "/", hash: "#requisitos" }}>¿Cómo funciona?</HashLink>
                        </div>
                        <div className="itemContainer"
                            style={{
                                display: pathname === '/proceso' ? 'none' : ''
                            }}
                        >
                            <HashLink smooth to={{ pathname: "/", hash: "#beneficios" }}>Beneficios</HashLink>
                        </div>
                        {/* <div className="itemContainer"
                            style={{
                                display: pathname === '/proceso' ? 'none' : ''
                            }}
                        >
                            <Link to="/seminuevos" preventScrollReset={true}>Seminuevos</Link>
                        </div> */}
                        <div className="itemContainer"
                            style={{
                                display: pathname === '/proceso' ? 'none' : ''
                            }}
                        >
                            <Link to="/visitanos" preventScrollReset={true}>Contacto</Link>
                        </div>
                    </div>

                    <div className="right">
                        <AccountCircleIcon className="iconUser" onClick={handleOpen} ref={btnRef2}></AccountCircleIcon>

                        <div className="hamburguer" onClick={() => setMenuOpen(!menuOpen)}>
                            <span className="line1"></span>
                            <span className="line2"></span>
                            <span className="line3"></span>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleClose} btnRef={btnRef}>
                {jwt < 1 ? "" : <Perfil btnRefClose={btnRef} />}
            </Modal>
        </>
    )
}
