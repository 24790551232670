import { Button, Grid } from '@mui/material'
import React from 'react'
import { LANG } from '../LANG'
import FilterListIcon from '@material-ui/icons/FilterList';
import UnidadOrdenamiento from './UnidadOrdenamiento';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useStyles } from '../../../hooks/stylesMUI/useStyles';

/**
 * Componente que muestra el encabezado para los filtros de unidades. Este encabezado incluye la opción
 * de ocultar filtros, limpiar los filtros seleccionados, mostrar el número de resultados y un menú desplegable 
 * para ordenar los resultados basados en diferentes criterios.
 * @author ERL 2023-08-08 10:18 am
 * @param {Object} props - Propiedades pasadas al componente.
 * @field {number} nResultados - Número total de resultados filtrados.
 * @field {Array} aListaMenu - Lista de opciones para el menú de ordenamiento.
 * @field {Function} handleOrdenamiento - Función que maneja el ordenamiento basado en la opción seleccionada del menú.
 * @field {Function} mostrarFiltros - Función que maneja la visualizacion de los filtros.
 * @field {boolean} bMostrarFiltros - Boolean para determinar si se muestran los filtros.
 * @field {Function} limpiarFiltros - Función que la limpieza de los filtros.
 * @returns {JSX.Element}
 */
const UnidadesFiltrosHeader = ({ nResultados, aListaMenu, handleOrdenamiento, setMostrarFiltros, bMostrarFiltros, limpiarFiltros }) => {

  const { StylesUnidades } = useStyles();
  return (
    <Grid container>
      <Grid item sx={StylesUnidades['filtros-container']}
        xl={3}
        lg={3}
        md={4}
        sm={5}
      >
        <Grid sx={StylesUnidades['filtros']}>
          <Grid onClick={() => setMostrarFiltros(prev => !prev)}
            sx={StylesUnidades['filtros-btn']}>
            {bMostrarFiltros ?
              <>
                <FilterListOffIcon />
                {LANG.labelOcultarFiltros}
              </>
              :
              <>
                <FilterListIcon />
                {LANG.labelMostrarFiltros}
              </>
            }
          </Grid>
          <Button onClick={limpiarFiltros}>
            {LANG.labelLimpiar}
          </Button>
        </Grid>
      </Grid>
      <UnidadOrdenamiento
        nResultados={nResultados}
        aListaMenu={aListaMenu}
        handleOrdenamiento={handleOrdenamiento}
      />
    </Grid>
  )
}

export default UnidadesFiltrosHeader
