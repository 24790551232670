export const beneficiosData = [
    {
      title: "Taller de",
      title2: "mecánica",
      desc: "Servicio de mantenimiento únicamente para comunidad BESTA",
      sImg:"/assets/beneficios/taller.png"
    },
    {
      title: "Sin buró de",
      title2: "crédito",
      desc: "No revisamos buró de crédito.",
      sImg:"/assets/beneficios/sinburo.png"
    },
    {
      title: "Seguro de",
      title2: "auto",
      desc: "Seguro de conductor privado con deducible más bajo en el mercado (3%).",
      sImg:"/assets/beneficios/seguro.png"
    },
    {
      title: "Equipo con",
      title2: "GNV",
      desc: "Permite ahorrar hasta un 50% en combustible.",
      sImg:"/assets/beneficios/gnv.png"
    },
    {
      title: "Opción de",
      title2: "compra",
      desc: "Podrás comprar el automóvil al terminar tu contrato. Y muchos más...",
      sImg:"/assets/beneficios/opcionCompra.png"
    },
    {
      title: "Siniestros",
      title2: "",
      desc: "Servicio de mantenimiento únicamente para comunidad BESTA",
      sImg:"/assets/beneficios/gestoriaSiniestros.png"
    },
    {
      title: "Créditos en",
      title2: "taller",
      desc: "Acceso a crédito vía recaudo de gnv para arreglar el auto.",
      sImg:"/assets/beneficios/creditoTaller.png"
    },
    {
      title: "Alta y Refrendo",
      title2: "auto",
      desc: "Alta de placas y refrendo durante el contrato.",
      sImg:"/assets/beneficios/altaReferendo.png"
    },
    {
      title: "Prevención",
      title2: "anti-robo",
      desc: "GPS instalado con bloqueo vía remota.",
      sImg:"/assets/beneficios/gpsAntiRobo.png"
    },
    {
      title: "Atención",
      title2: "personalizada",
      desc: "Atención y asesoramiento para cada conductor",
      sImg:"/assets/beneficios/atencion.png"
    },
    {
      title: "Body",
      title2: "shop",
      desc: "Atención y asesoramiento para cada conductor",
      sImg:"/assets/beneficios/bodyShop.png"
    },
  ];