import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { toast } from 'sonner';
import { urlsAPIs } from '../../../../../helpers/urlsAPIs';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { globalMessage } from '../../../../../helpers/globalMessage';
import React from 'react'
import CircularLoader from '../../../../loader/CircularLoader';
import CargaVideoExito from './CargaVideoExito';
import CargaVideoInputContainer from './CargaVideoInputContainer';
import CargaVideoIndicacionesGrabacion from './CargaVideoIndicaciones';
import { useContext } from 'react';
import { ProgressBarContext } from '../../../../../context/ProgressBarContext';
/**
    * Componente formulario carga de video
    * @author ERL 2023-07-17 05:45 pm 
    * @param {boolean} bIsMobile - Boolean para saber si esta en mobile
    * @param {int} nIdUsuario - Numero id del usuario
    * @param {boolean} bLoadingComponent - Boolean para mostrar el loader
    * @param {array} dataDocumentos - Array de los documentos
    * @param {function} setDataDocumentos - Funcion para actualizar la informacion del array de los documentos     
    * @returns {JSX.Element} Componente para grabacion de video
   */
const CargaVideoMovilForm = ({ bIsMobile, nIdUsuario, bLoadingComponent, dataDocumentos, setDataDocumentos }) => {
    const { headers } = useContext(ProgressBarContext);
    /**
     * Funcion para subir archivos a google, guardar info en la API y actualizar el status de los documentos
     * @author ERL 2023-07-17 12:36 pm 
     * @param {object} oArchivo - Archivo cargado por el usuario
     * @param {string} sNombreArchivo - Nombre del archivo
     * @param {string} sStatus - Status del documento
     * @param {int} nIdentificador - Id del documento
     * @returns {void} 
    */
    const enviarVideoGoogle = async (oConfig = {}) => {
        const { oArchivo, sNombreArchivo, sStatus, nIdentificador } = oConfig;
        const { type } = oArchivo[0];
        const originalName = sNombreArchivo;
        const sNombreArchivoClean = sNombreArchivo.toString().trim().toUpperCase().replace(/[-\s()]/g, "");
        const sTipo = type.split("/").pop();
        updateDocumentStatus(nIdentificador, 'ENVIADO', originalName);
        // Si el estado es 'VALIDADO' o 'PENDIENTE', no se realiza el envío
        if (["VALIDADO", "PENDIENTE"].includes(sStatus)) {
            return;
        }

        let objeto = {};

        try {
            // Obtener la URL pública y firmada para subir el archivo a Google Cloud Storage
            const response = await axios.get(`${urlsAPIs.urlGoogleStorage}/${nIdUsuario}?sNombre=${sNombreArchivoClean}&sTipo=${sTipo}`, { headers });

            if (response.status === 200) {
                const { sURLPublica, sUrlFirmada } = response.data;

                let myHeaders = new Headers();
                myHeaders.append("Content-Type", type);
                const fieldName = `video${nIdentificador}`; // Crear fieldName a partir de nIdentificador
                objeto[fieldName] = {
                    nIdDocumento: nIdentificador,
                    sRuta: sURLPublica,
                    nnIdUsuario: nIdUsuario
                };

                let requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: oArchivo[0],
                    redirect: 'follow'
                };

                const responseGoogleStorage = await fetch(sUrlFirmada, requestOptions);
                const { status: uploadStatus } = responseGoogleStorage;

                if (uploadStatus === 200) {


                    // Actualizar el estado del documento a 'PENDIENTE' en la base de datos
                    updateDocumentStatus(nIdentificador, 'VALIDANDO', originalName);
                    objeto[fieldName] = {
                        nIdDocumento: nIdentificador,
                        sRuta: sURLPublica,
                        nnIdUsuario: nIdUsuario,

                    };

                    // Enviar los datos del documento a la API
                    const response = await axios.post(`${urlsAPIs.urlEnviarDocumentos}/${nIdUsuario}`, { ...objeto, sTipo: "V" }, { headers });      //V = Video
                    const { nCodigo, sMensaje } = response.data;
                    if (nCodigo === 0) {
                        toast.success(sMensaje);
                    } else {
                        toast.error(sMensaje);
                    }
                } else {
                    // Si hay un error en la carga del archivo, se actualiza el estado del documento a 'ERROR'
                    updateDocumentStatus(nIdentificador, 'ERROR', originalName);
                }
            } else {
                // Si hay un error al obtener la URL pública y firmada, se actualiza el estado del documento a 'ERROR'
                updateDocumentStatus(nIdentificador, 'ERROR', originalName);
            }
        } catch (error) {
            // Si hay un error en la llamada a la API, se actualiza el estado del documento a 'ERROR'
            updateDocumentStatus(nIdentificador, 'ERROR', originalName);
            toast.error(globalMessage.errorServidor)
        }
    };

    /**
    * Función para actualizar el estado del documento
    * @author ERL 2023-07-17 12:34 pm 
    * @param {int} nIdentificador - Id del documento
    * @param {string} sNewStatus - Nuevo status del documento
    * @returns {void} 
   */
    const updateDocumentStatus = (nIdentificador, sNewStatus) => {
        // Actualizamos dataDocumentos con la nueva lista de documentos
        setDataDocumentos(prevDataDocumentos => {
            const updatedDataDocumentos = prevDataDocumentos.map(document => {
                if (document.nIdentificador === nIdentificador) {
                    // Si es el documento correcto, actualizamos su status
                    return { ...document, sStatus: sNewStatus };
                } else {
                    // Si no es el documento que estamos buscando, lo devolvemos sin cambios
                    return document;
                }
            });
            return updatedDataDocumentos;
        });
    };
    /**
    * Funcion para obtener el status de los documentos
    * @author ERL 2023-07-17 12:33 pm 
    * @param {object} oDocumento - Objeto con el status del documento     
    * @returns {string} Status del documento
   */
    const getStatusDocs = (oDocumento = {}) => {
        const status = oDocumento?.sStatus ? oDocumento.sStatus : "PENDIENTE";
        return status.toString().toUpperCase();
    }
    const oStatusResponse = {
        "VALIDADO": {
            sStatusColor: 'green',
            sStatusMessage: "Archivo validado",
            sIcono: <CheckCircleIcon />
        },
        "RECHAZADO": {
            sStatusColor: 'red',
            sStatusMessage: "Archivo rechazado",
            sIcono: <CancelIcon />
        },
        "ERROR": {
            sStatusColor: 'red',
            sStatusMessage: "Error en la carga del archivo",
            sIcono: <CancelIcon />
        },
        "ENVIADO": {
            sStatusColor: 'blue',
            sStatusMessage: "Archivo enviado",
            sIcono: <AccessTimeFilledIcon />
        },
        "REEMPLAZADO": {
            sStatusColor: 'blue',
            sStatusMessage: "Archivo validado",
            sIcono: <CheckCircleIcon />
        },
        "PENDIENTE": {
            sStatusColor: 'orange',
            sStatusMessage: "",
            sIcono: <AccessTimeFilledIcon />
        },
        "VALIDANDO": {
            sStatusColor: 'orange',
            sStatusMessage: "Validando archivo",
            sIcono: <AccessTimeFilledIcon />
        },
        "": {
            sStatusColor: 'black',
            sStatusMessage: "",
            sIcono: <RadioButtonUncheckedIcon />
        },
    }

    const sMensaje = "Enhorabuena, tu video ha sido validado.";
    const sTitulo = "Ahora, deberás grabar un video siguiendo estas indicaciones:";
    const sDescripcion = "";
    const BoxStyles = {
        width: '100%',
        marginTop: "10px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid lightgrey',
        boxSizing: 'border-box',
        borderRadius: '15px'
    }
    const GridStyles = { maxWidth: '90px', margin: '0 auto', };
    const ImgStyles = { maxWidth: '90px', margin: '0 auto', };
    const ImgLogo = "/assets/bestalogo.png";
    return (
        <>
            <Grid sx={GridStyles}>
                <img src={ImgLogo} alt="" style={ImgStyles} />
            </Grid>
            <>
                {bLoadingComponent ?
                    <CircularLoader />
                    :
                    dataDocumentos.every((doc) => doc.sStatus === "VALIDADO") ?
                        <CargaVideoExito sMensaje={sMensaje} />
                        :
                        <Box sx={BoxStyles}>
                            <CargaVideoIndicacionesGrabacion
                                sTitulo={sTitulo}
                                sDescripcion={sDescripcion}
                            />
                            <CargaVideoInputContainer
                                bIsMobile={bIsMobile}
                                nIdUsuario={nIdUsuario}
                                bLoadingComponent={bLoadingComponent}
                                dataDocumentos={dataDocumentos}
                                setDataDocumentos={setDataDocumentos}
                                oStatusResponse={oStatusResponse}
                                getStatusDocs={getStatusDocs}
                                enviarVideoGoogle={enviarVideoGoogle}
                            />
                        </Box>
                }
            </>
        </>
    )
}

export default CargaVideoMovilForm


