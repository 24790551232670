import { Box, Grid, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react'
/**
    * Componente para mostrar un mensaje de éxito
    * @author ERL 2023-07-17 09:39 pm 
    * @param {string} sMensaje - Mensaje de éxito
    * @param {boolean} bSinBordes - Determina si se muestran los bordes en los estilos del contenedor
    * @returns {JSX.Element} 
   */

const CargaVideoExito = ({ sMensaje, bSinBordes = false }) => {
    const Styles = {
        "box": { marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', border: bSinBordes ? "":'1px solid lightgrey', boxSizing: 'border-box', borderRadius: '15px' },
        "grid-h1-container": { width: '100%', padding: '10px 0px', margin: '15px 0px', borderBottom: bSinBordes ? "" : '1px solid lightgray' },
        "grid-h1": { fontWeight: 'bold', textAlign: 'center', },
        "grid-p-container": { textAlign: 'center', maxWidth: '500px', margin: '0 auto', },
        "grid-p": {
            my: 2, // Margin top - bottom
            mx: 'auto', // Margin left - right
            p: 0, // Padding *
            width: '100%',
            maxWidth: {
                xl: '460px',
            },
            textAlign: 'center',
            justifyContent: 'center',
            display: 'flex'
        },
    }
    return (
        <Box sx={Styles["box"]}>
            <Grid sx={Styles["grid-h1-container"]}>
                <Typography component="h1" sx={Styles["grid-h1"]}>
                    {sMensaje}
                </Typography>
            </Grid>
            <Grid container sx={Styles["grid-p-container"]}>
                <Typography component="p"
                    sx={Styles["grid-p"]}>
                    <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}                    >
                        <CheckCircleIcon sx={{ color: "green", fontSize: 180 }} />
                    </Grid>
                </Typography>

            </Grid>
        </Box>
    )
}

export default CargaVideoExito