import React from 'react'

import axios from 'axios';
import { toast } from 'sonner';
import { useState } from 'react';
import { urlsAPIs } from '../../helpers/urlsAPIs';
import { useNavigate } from 'react-router';
/**
 * Confirmar registro nuevo usuario
 * @author ERL 2023-06-09 12:21 pm 
 * @param {object} data - Datos del usuario para la confirmacion del registro
 * @param {string} sCorreo - Correo del usuario
 * @param {int} nCodigo - Codigo de confirmacion del usuario
 * @returns {object} - Retorna el objeto con las funciones para confirmar el registro y reenviar el código
 */
export function useConfirmar(setLoadingVerificar, setLoadingReEnvio) {

    // Mensajes de exito y error
    let enviarError = "¡Ups! Parece que ha ocurrido un error inesperado al enviar el codigo. Por favor, intenta nuevamente más tarde."
    let codigoValidoError = "El codigo que has ingresado es invalido."
    let reEnviarError = "¡Ups! Parece que ha ocurrido un error inesperado al reenviar el codigo. Por favor, intenta nuevamente más tarde."
    let confirmarRegistroExito = "La confirmación del código de verificación se realizó con éxito. ¡Bienvenido/a a nuestra comunidad!"
    let reEnviarCodigoExito = "Hemos reenviado el código de verificación a tu dirección de correo electrónico. Por favor, revisa tu bandeja de entrada y sigue las instrucciones para completar el proceso."
    const [codigoReEnviado, setCodigoReEnviado] = useState(false);
    const navigate = useNavigate();
    /**
     * Confirma el registro del usuario
     * @param {object} data - Datos del usuario para la confirmacion del registro
     * @returns {boolean} - Retorna false si el código de confirmación es inválido, no hace nada en caso contrario
     */
    const confirmarRegistro = async (data) => {
        try {
            setLoadingVerificar(true);
            if (data.nCodigo === 0 || data.nCodigo === '' || data.nCodigo === null) {                
                return false;
            }
            const response = await axios.put(urlsAPIs.urlReEnviarCodigoApi, data);
            const { nCodigo, sMensaje, validaCodigo } = response.data;
            if (nCodigo === 0) {
                toast.success(confirmarRegistroExito);
                if (!validaCodigo) {
                    localStorage.removeItem('confirmacionPendiente');
                    navigate('/login');
                } else {
                    localStorage.setItem('confirmacionPendiente', true);
                    localStorage.setItem('correoNuevoUsuario', data.sCorreo);
                    navigate("/registro")
                }
            } else {
                toast.error(sMensaje);
                return false;
            }
        } catch (error) {
            
            console.error(error);
            toast.error('Hubo un error al confirmar el registro.');
        } finally {
            setLoadingVerificar(false);
        }
    };
    /**
     * Reenvía el código de confirmación al usuario
     * @param {string} sCorreo - Datos del usuario para el reenvío del código de confirmación
     */
    const reEnviarCodigo = async (sCorreo) => {
        try {
            setLoadingReEnvio(true);
            const response = await axios.post(urlsAPIs.urlReEnviarCodigoApi, { sCorreo });
            const { nCodigo } = response.data;
            if (nCodigo === 0) {
                toast.success(reEnviarCodigoExito);
                setCodigoReEnviado(true);
            } else {
                toast.error(reEnviarCodigoExito);
            }
        } catch(error) {
            
            console.error(error);
            toast.error('Hubo un error al reenviar el código.');
        } finally {
            setLoadingReEnvio(false);
        }
    };
    

    return { confirmarRegistro, reEnviarCodigo, codigoReEnviado }
}
