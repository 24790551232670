import "./beneficios.scss";
import * as React from 'react';

import BeneficioItem from "./BeneficioItem";
import { beneficiosData } from "./beneficiosData";

/**
 * Componente Beneficios que muestra una lista de beneficios.
 * @author ERL 2023-10-20 10:25 am
 * @param {boolean} mostrarImagen - Indica si se debe mostrar o no la imagen.  
 * @returns {JSX.Element} - Retorna la estructura visual del componente Beneficios.
 */
const Beneficios = ({mostrarImagen = true}) => {  
  return (
    <div className="c-beneficios" id="">
      <div id="beneficios" className='hashlink-id'></div>
      <div className="c-wrapper-beneficios">
        <div className={`c-wrapper-beneficios-container ${mostrarImagen ? '' : 'fullContainer'}`}>
          {mostrarImagen && <div className="c-izquierdo">
            <div className="c-izquierdo-container">
              <img src={`/assets/beneficios/propuesta2.webp`} alt="Beneficios besta" className="c-imagen" />
            </div>
          </div>}
          <div className={`c-derecho ${mostrarImagen ? '':'fullCard'}`}>
            <div className="c-derecho-title">
              <h1 className="c-title-beneficios">BESTA es tu mejor opción.</h1>
              <p>Logramos ser tu aliado más importante gracias a nuestros beneficios y la rápida respuesta para maximizar tus ganancias.</p>
            </div>
            <div className="c-derecho-container">
              {
                beneficiosData.map((item, index) => (
                  <BeneficioItem key={index} title={item.title} title2={item.title2} desc={item.desc} sImg={item.sImg} mostrarImagen={mostrarImagen} />
                ))
              }
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Beneficios;