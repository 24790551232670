import React from 'react'
import UnidadDetalles from '../../components/unidades/detalles/UnidadDetalles'


/**
 * Página principal de 'UnidadesDetalles'. Actúa como un contenedor para el componente `UnidadDetalles`.
 * @author ERL 2023-08-10 12:23 pm
 * @returns {JSX.Element}
 */
const UnidadesDetalles = () => {
  return (
    <UnidadDetalles/>
  )
}

export default UnidadesDetalles
