
import React from 'react'
import { Controller, useForm } from 'react-hook-form';

import { FormHelperText, Grid, IconButton, InputLabel, Tooltip } from '@mui/material';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import { globalMessage } from '../../../../../helpers/globalMessage';
import { useStyles } from '../../../../../hooks/stylesMUI/useStyles';
/**
    * Componente de inputs para la carga del video
    * @author ERL 2023-07-17 08:09 pm 
    * @param {array} dataDocumentos - Array de los documentos
    * @param {object} oStatusResponse - Objeto con la lista de status.
    * @param {function} getStatusDocs - Función para obtener el estado del documento.
    * @param {function} enviarVideoGoogle - Función para almacenar archivo de video en Google
    * @returns {JSX.Element} 
   */

const CargaVideoInputs = ({ dataDocumentos, oStatusResponse, getStatusDocs, enviarVideoGoogle }) => {
    const { formState: { errors }, control, setError, clearErrors } = useForm();
    const { StylesVideo360 } = useStyles();
    return (

        dataDocumentos.map(
            (question, index) =>


                <Grid item xl={12} sm={12} key={index} sx={StylesVideo360["container-form"]}

                    mx={"auto"}

                >
                    <Controller
                        name={`doc${question.nIdentificador}`}
                        control={control}
                        defaultValue=""
                        rules={{
                            required: `${globalMessage.errorInformacionRequerida}`,
                            validate: {
                                checkFileType: (value) => {
                                    if (value && value[0]) {
                                        const supportedFormats = [
                                            'image/jpeg',
                                            'image/png'
                                        ];
                                        if (!supportedFormats.includes(value[0].type)) {
                                            return `${globalMessage.errorArchivoNoSoportado}`;
                                        }
                                    }
                                    return true;
                                }
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <Grid fullWidth style={StylesVideo360["container-inputs"]}>
                                <Grid sx={StylesVideo360["container-input"]}>
                                    <InputLabel sx={StylesVideo360["input-label"]} htmlFor={`file${index}`}>
                                        <IconButton aria-label="upload picture" color="primary" component="label" sx={StylesVideo360["input-label-icon"]}

                                        >
                                            <>
                                                {question?.sIcono === "DOM" && <OtherHousesIcon
                                                    mx={0}
                                                    mt={0}
                                                    p={0}
                                                    sx={{
                                                        fontSize: {
                                                            xl: 40,
                                                            md: 30,
                                                            sm: 25,
                                                            xs: 20
                                                        }
                                                    }} />}
                                            </>
                                            <input
                                                type="file"
                                                id={`file${index}`}
                                                hidden
                                                accept='image/jpeg, image/png'
                                                disabled={["VALIDANDO", "PENDIENTE"].includes(question.sStatus)}
                                                onChange={(e) => {
                                                    onChange(e.target.files);
                                                    if (errors[`doc${question.nIdentificador}`]) {
                                                        clearErrors(`doc${question.nIdentificador}`);
                                                    }
                                                    const supportedFormats = [
                                                        'image/jpeg',
                                                        'image/png'
                                                    ];
                                                    if (supportedFormats.includes(e.target.files[0].type)) {

                                                        enviarVideoGoogle({
                                                            oArchivo: e.target.files,
                                                            sNombreArchivo: question.sValor,
                                                            sStatus: question.sStatus,
                                                            nIdentificador: question.nIdentificador,
                                                        })
                                                    } else {
                                                        setError(`doc${question.nIdentificador}`, {
                                                            type: "manual",
                                                            message: globalMessage.errorArchivoNoSoportado
                                                        });
                                                    }
                                                }}
                                                onBlur={onBlur}
                                                ref={ref}
                                                name={name}
                                            />
                                        </IconButton>
                                        {question.sValor}
                                    </InputLabel>

                                    <InputLabel sx={{ ...StylesVideo360["input-label-tooltip"], color: oStatusResponse[getStatusDocs(question)].sStatusColor }}>
                                        <Tooltip title={question.sStatus !== "" ? question.sStatus : ""} arrow >
                                            {oStatusResponse[getStatusDocs(question)].sIcono}
                                        </Tooltip>
                                    </InputLabel>
                                </Grid>
                                <Grid pt={1}>
                                    {(value && value[0]) || (question?.sStatus !== '') ? <> {

                                        oStatusResponse[getStatusDocs(question)].sStatusMessage
                                    }
                                    </> : ""
                                    }
                                </Grid>
                                {errors[`doc${question.nIdentificador}`] && (
                                    <FormHelperText xl={12} error sx={{ textAlign: 'center', }}>
                                        {errors[`doc${question.nIdentificador}`]?.message}
                                    </FormHelperText>
                                )}
                            </Grid>
                        )}
                    />

                </Grid>
        )
    )
}

export default CargaVideoInputs
