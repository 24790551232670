import { toast } from "sonner";
import { urlsAPIs } from "../../../helpers/urlsAPIs";
import { globalMessage } from "../../../helpers/globalMessage";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useLogin } from "../../usuariosApi/useLogin";

/**
* @author ERL 2023-11-02 04:33 pm
* Custom hook para manejar la logica de referencias 
* @returns object
*/
const useReferencias = ({
    nSessionId,
    bUltimaReferencia,
    nextStep,
    nextRef,
    setLoading,
    dataReferencia,
    referenciasRechazadas,
    headers,
    getData,

}) => {

    const { setError, errors } = useForm();  
    const { logOutUsuario:logout } = useLogin();

    /**
   * Función para manejar la presentación de los datos recopilados del formulario.
   * La función organiza los datos del formulario, realiza solicitudes POST o PUT a la API de referencias
   * y gestiona los mensajes de éxito y de error.
   * 
   * @author ERL 2023-07-19 08:25 pm
   * @param {object} data - Datos del formulario
   * @returns {void}
   */
    const onSubmit = async (data) => {        

        let nombresUsados = new Set();
        let telefonosUsados = new Set();
        const dataReferencias = [];
        const objeto = {};
        dataReferencia.sort((a, b) => a.nIdPadre - b.nIdPadre);

        for (const question of dataReferencia) {
            const fieldName = `ref${question.nIdPadre}-${question.nIdentificador}`;
            const fileField = data[fieldName];

            if (fileField) {
                objeto[fieldName] = {
                    nIdPadre: question.nIdPadre,
                    sNombreCorto: question.sNombreCorto,
                    nIdentificador: question.nIdentificador,
                };
            }
        }

        const groupedEntries = Object.values(objeto).reduce((acc, cur) => {
            if (!acc[cur.nIdPadre]) acc[cur.nIdPadre] = [];
            acc[cur.nIdPadre].push(cur);
            return acc;
        }, {});

        for (const [idPadre, entries] of Object.entries(groupedEntries)) {
            const nombreEntry = entries.find(o => o.sNombreCorto === 'sNombre');
            const apellidoPEntry = entries.find(o => o.sNombreCorto === 'sApellidoP');
            const apellidoMEntry = entries.find(o => o.sNombreCorto === 'sApellidoM');
            const telefonoEntry = entries.find(o => o.sNombreCorto === 'sTelefono');

            if (nombreEntry && apellidoPEntry && apellidoMEntry && telefonoEntry) {

                const nombreCompletoActual = `${data[`ref${nombreEntry.nIdPadre}-${nombreEntry.nIdentificador}`] || ''} ${data[`ref${apellidoPEntry.nIdPadre}-${apellidoPEntry.nIdentificador}`] || ''} ${data[`ref${apellidoMEntry.nIdPadre}-${apellidoMEntry.nIdentificador}`] || ''}`;
                const telefonoActual = data[`ref${telefonoEntry.nIdPadre}-${telefonoEntry.nIdentificador}`];

                if (nombresUsados.has(nombreCompletoActual)) {
                    setError("nombre", {
                        type: "manual",
                        message: `El nombre de la referencia ${nombreCompletoActual} ya se ha usado.`
                    });
                    return;
                }
                if (telefonosUsados.has(telefonoActual)) {
                    setError("telefono", {
                        type: "manual",
                        message: `El número de teléfono ${telefonoActual} ya se ha usado.`
                    });
                    return;
                }

                nombresUsados.add(nombreCompletoActual);
                telefonosUsados.add(telefonoActual);

                const newObject = entries.reduce((acc, cur) => {
                    acc[cur.sNombreCorto] = data[`ref${cur.nIdPadre}-${cur.nIdentificador}`];
                    acc["nIdReferencia"] = cur.nIdPadre;
                    return acc;
                }, {});
                dataReferencias.push(newObject);
            }

        }

        const referenciasArray = {
            "nIdUsuario": nSessionId,
            "oReferencias": dataReferencias,
        }

        if (bUltimaReferencia) {
            try {
                setLoading(true);
                let response = await axios.post(`${urlsAPIs.urlApiReferencias}/${nSessionId}`, referenciasArray, { headers: headers });
                const { sMensaje: sMensajeServer, nCodigo, } = response.data;
                if (nCodigo === 0) {
                    toast.success(sMensajeServer, { duration: 3000, });
                    nextStep()
                } else {
                    toast.error(sMensajeServer);
                }
            } catch (error) {
                const { responseCode = 0, message = "" } = error?.response.data
                if (responseCode === '401' && message === 'jwt expired') {                  
                  logout()
                }else{
                  toast.error(globalMessage.errorServidor)
                }
            } finally {
                setLoading(false)

            }
        } else {

            nextRef()
        }
    };


    /**
     * Función para manejar la presentación de los datos recopilados del formulario.
     * La función organiza los datos del formulario, realiza solicitudes POST o PUT a la API de referencias
     * y gestiona los mensajes de éxito y de error.
     * 
     * @author ERL 2023-07-19 08:25 pm
     * @param {object} data - Datos del formulario
     * @returns {void}
     */
    const onSubmitRechazadas = async (data) => {
        let nombresUsados = new Set();
        let telefonosUsados = new Set();
        const dataReferencias = [];
        const objeto = {};
        dataReferencia.sort((a, b) => a.nIdPadre - b.nIdPadre);

        for (const question of dataReferencia) {
            const fieldName = `ref${question.nIdPadre}-${question.nIdentificador}`;
            const fileField = data[fieldName];

            if (fileField) {
                objeto[fieldName] = {
                    nIdPadre: question.nIdPadre,
                    sNombreCorto: question.sNombreCorto,
                    nIdentificador: question.nIdentificador,
                };
            }
        }

        const groupedEntries = Object.values(objeto).reduce((acc, cur) => {
            if (!acc[cur.nIdPadre]) acc[cur.nIdPadre] = [];
            acc[cur.nIdPadre].push(cur);
            return acc;
        }, {});

        for (const [idPadre, entries] of Object.entries(groupedEntries)) {
            const nombreEntry = entries.find(o => o.sNombreCorto === 'sNombre');
            const apellidoPEntry = entries.find(o => o.sNombreCorto === 'sApellidoP');
            const apellidoMEntry = entries.find(o => o.sNombreCorto === 'sApellidoM');
            const telefonoEntry = entries.find(o => o.sNombreCorto === 'sTelefono');

            if (nombreEntry && apellidoPEntry && apellidoMEntry && telefonoEntry) {

                const nombreCompletoActual = `${data[`ref${nombreEntry.nIdPadre}-${nombreEntry.nIdentificador}`] || ''} ${data[`ref${apellidoPEntry.nIdPadre}-${apellidoPEntry.nIdentificador}`] || ''} ${data[`ref${apellidoMEntry.nIdPadre}-${apellidoMEntry.nIdentificador}`] || ''}`;
                const telefonoActual = data[`ref${telefonoEntry.nIdPadre}-${telefonoEntry.nIdentificador}`];

                if (nombresUsados.has(nombreCompletoActual)) {
                    setError("nombre", {
                        type: "manual",
                        message: `El nombre de la referencia ${nombreCompletoActual} ya se ha usado.`
                    });
                    return;
                }
                if (telefonosUsados.has(telefonoActual)) {
                    setError("telefono", {
                        type: "manual",
                        message: `El número de teléfono ${telefonoActual} ya se ha usado.`
                    });
                    return;
                }

                nombresUsados.add(nombreCompletoActual);
                telefonosUsados.add(telefonoActual);

                const newObject = entries.reduce((acc, cur) => {
                    acc[cur.sNombreCorto] = data[`ref${cur.nIdPadre}-${cur.nIdentificador}`];
                    acc["nIdReferencia"] = cur.nIdPadre;
                    return acc;
                }, {});
                dataReferencias.push(newObject);
            }

        }

        const referenciasArray = {
            "nIdUsuario": nSessionId,
            "oReferencias": dataReferencias,
        }
        try {
            setLoading(true);
            const todasPendientes = referenciasRechazadas.length > 0 && referenciasRechazadas.every(ref => ref.sStatus === "PENDIENTE");
            let response = await axios.post(`${urlsAPIs.urlApiReferencias}/${nSessionId}`, referenciasArray, { headers: headers });
            const { sMensaje: sMensajeServer, nCodigo, } = response.data;
            if (nCodigo === 0) {
                toast.success(sMensajeServer, { duration: 3000, });
                if (todasPendientes) {
                    nextStep()
                } else {
                    await getData()
                }
            } else {
                toast.error(sMensajeServer);
            }
        } catch (error) {
            toast.error(globalMessage.errorServidor);
        } finally {
            setLoading(false)
        }

    };
    return {
        onSubmit,
        onSubmitRechazadas
    };
};

export default useReferencias;