import "./contact.scss";
import * as React from 'react';
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { StyledButton } from "../../components/customButtons/StyledButton";
import ModalSimple from "../../components/modal/ModalSimple";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Grid } from "@mui/material";
import CircularLoader from "../../components/loader/CircularLoader";
import { toast } from "sonner";
import { globalMessage } from "../../helpers/globalMessage";
import { LANG } from "./LANG";
import SelectComponent from "../../components/viajes/SelectComponent";
import { opcionesApps, opcionesViajes } from "../../components/viajes/selectOptions";


/**
 * Formulario de contacto
 * @author ERL 2023-09-22 11:36 am
 * @param {boolean} bg - Indica si se muestra el formulario de contacto con fondo
 * @returns {JSX.Element}
 */

const ContactForm = ({bg = true}) => {

  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false)
  /**
   * Envia los datos del fomulario por correo electronico
   * @author ERL 2023-09-22 11:38 am
   * @param {Event} e - Evento asociado al formulario
   * @returns {JSX.Element}
  */
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setOpen(true)
    emailjs.sendForm('service_fst7gdm', 'template_oblxilh', form.current, 'QQvoOXLmHkR29h36W')
      .then(
        (result) => {
          setLoading(false);
        },
        (error) => {
          toast.error(globalMessage.errorServidor)
        });
    form.current.reset()
  };
  return (
    <>
      <ModalSimple
        open={open}
        setOpen={setOpen}
        sTitulo={""}
        bRechazar={false}
      >
        <Grid>
          {loading ? <CircularLoader /> :
            <Grid className="correo-enviado-container">
              <Grid>
                <Grid>
                  <CheckCircleOutlineIcon color="success" className="succes-icon" />
                </Grid>
                <Grid className="loader-msg-1">
                  {LANG.labelListoEstas}
                </Grid>
              </Grid>
              <Grid className="loader-msg-2">
                {LANG.labelTeContactaremos}
              </Grid>
              <Grid className="loader-btn">
                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#483e6b', '&:hover': { backgroundColor: '#483e6b' } }}
                  onClick={() => setOpen(false)}>{globalMessage.labelCerrar}</Button>
              </Grid>
            </Grid>
          }
        </Grid>
      </ModalSimple>
      <div className="c-contact" id="contacto">
        <div className="c-bg"></div>
        <div className="c-wrapper">
          <div className={`c-right-contact ${!bg && 'contact-nobg'}`}>
            <p className="c-desc-contact">
              <h3>¡Déjanos tus datos!</h3>
              <p>Nosotros te contactamos</p>
            </p>
            <form ref={form} onSubmit={handleSubmit}>
              <input className="input-contact" type="text" placeholder="Nombre Completo" name="nombre" required />
              <input className="input-contact" type="text" placeholder="Celular con whatsapp" name="telefono" required />
              <input className="input-contact" type="hidden" placeholder="pagina" name="pagina" value="inicio" required />
              <label htmlFor="">¿Actualmente eres conductor de UBER y DIDI? </label>
              <SelectComponent options={opcionesApps} name="apps"/>

              <label htmlFor="">¿Cuántos viajes llevas realizados?: </label>
              <SelectComponent options={opcionesViajes} name="viajes"/>
              <label htmlFor="">¿En que parte de México vives?: </label>
              <select name="estado" className="select-css" required>
                <option value="0" selected="selected">Selecciona una opción </option>
                <option value="Nuevo León">Nuevo León</option>
                <option value="Guadalajara"> Guadalajara</option>
                <option value="Otro">Otro</option>
              </select>
              <textarea rows="5" placeholder="Mensaje" name="mensaje" className="textarea-contact" />
              <div className="button-contact">

                <StyledButton>Enviar</StyledButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;