import axios from 'axios';
import React from 'react'
import { toast } from 'sonner';
import { urlsAPIs } from '../../helpers/urlsAPIs';
import { useNavigate } from 'react-router';

/**
 * Registrar nuevo usuario
 * @author ERL 2023-06-09 12:21 pm 
 * @param {object} data - Datos del usuario para el registro
 * @param {string} sNombre - Nombre del usuario
 * @param {string} sApellidoP - Apellido del usuario
 * @param {string} sApellidoM  - Apellido del usuario
 * @param {date} dFechaNacimiento - Fecha de nacimiento del usuario
 * @param {string} sCorreo - Correo del usuario
 * @param {string} sClientId - ClientId del usuario
 * @param {string} sPassword  - Password del usuario
 * @param {string} sTipoLogin  - Tipo login del usuario 
 * @returns object
 */
export function useRegistrar(setAccountCreated, setLoading) {
    let error = `¡Ups! Parece que ha ocurrido un error inesperado. Por favor, intenta nuevamente más tardes.`
    let exito = `¡Enhorabuena! Has completado el registro satisfactoriamente. Se ha enviado un código de verificación a tu correo electrónico. Por favor, verifica tu bandeja de entrada.`
    let exitoGoogle = `¡Enhorabuena! Has completado el registro satisfactoriamente.`
    const navigate = useNavigate();
    const registrarUsuario = async (data) => {
        try {
            setLoading(true);
            const responseAlta = await axios.post(urlsAPIs.urlRegistroApi, JSON.stringify(data));
            const { sMensaje, nCodigo } = responseAlta.data;
            if (nCodigo === 0) {
                setAccountCreated(true);
                if (data['sClientId'] === '' || data['sClientId'] === null) {
                    localStorage.setItem('confirmacionPendiente', true);
                    localStorage.setItem('correoNuevoUsuario', data.sCorreo);
                    toast.success(sMensaje);
                } else {
                    const dataLogin = { sCorreo: data.sCorreo, sClientId: data['sClientId'], sTipoLogin: "Google" }
                    setLoading(true);                    
                    const responseAPI = await axios.post(urlsAPIs.urlLoginApi, dataLogin);
                    const { jwt, sMensaje, nIdUsuario, nIdCliente, nIdProspecto } = responseAPI.data;
                    sessionStorage.setItem('t', jwt);
                    sessionStorage.setItem('u', nIdUsuario);
                    sessionStorage.setItem('c', data.sCorreo);
                    sessionStorage.setItem('ci', nIdCliente);
                    sessionStorage.setItem('p', nIdProspecto); // Prospecto id
                    if (jwt.length > 0) {
                        setLoading(false);
                        toast.success(exitoGoogle);
                        navigate('/proceso');
                    } else {
                        toast.error(sMensaje)
                    }
                }
            } else {
                toast.error(sMensaje);
            }
        } catch (error) {
            console.error(error);
            toast.error('Hubo un error al registrar el usuario.');
        } finally {
            setLoading(false);
        }
    };
    /**
     * Convierte texto a mayúsculas
     * @author ERL 2023-06-09 12:24pm
     * @param {string} str 
     * @returns {void}
     */
    const uppercaseText = (str) => {       
        return str.toUpperCase();
    }
      

    return { registrarUsuario, uppercaseText }
}
