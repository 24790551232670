const path = '/assets/autos';
export const dataAutos = [
  {
    id: 1,
    modelo: "ATTITUDE",
    marca: "DODGE",
    imagen: `${path}/ATTITUDE/ATTITUDE2023.png`,
    montoSemanal: "$3,550",
    texto: "36 meses con opción a compra",
    bNuevo:1,
    bEstandar:1,
    bYear:1,
  },
  {
    id: 2,
    modelo: "Versa Sense",
    marca: "NISSAN",
    imagen: `${path}/VERSA/VERSA2023.png`,
    montoSemanal: "$3,975",
    texto: "36 meses con opción a compra",
    bNuevo:1,
    bEstandar:1,
    bYear:1,
  },
  {
    id: 3,
    modelo: "VDRIVE",
    marca: "NISSAN",
    imagen: `${path}/VDRIVE/VDRIVE2023.png`,
    montoSemanal: "$3,550",
    texto: "36 meses con opción a compra",
    bNuevo:1,
    bEstandar:1,
    bYear:1,
  },
  {
    id: 4,
    modelo: "AVEO",
    marca: "Chevrolet",
    imagen: `${path}/AVEO/AVEO2023.png`,
    montoSemanal: "$3,500",
    texto: "36 meses con opción a compra",
    bNuevo:1,
    bEstandar:1,
    bYear:1,
  },
  {
    id: 5,
    modelo: "Jac Ex10(eléctrico)",
    marca: "JAC",
    imagen: `${path}/JAC_EX10/JAC-EX10.png`,
    montoSemanal: "$4,950",
    texto: "42 meses con opción a compra",
    bNuevo:1,
    bEstandar:1,
    bYear:1,
  },
];
