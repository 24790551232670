import { Grid, Typography } from "@mui/material"
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
/**
 * Componente que muestra un paso PENDIENTE en el progress bar de referencias.
 * Incluye un mensaje de éxito, una descripción opcional y un botón opcional de "Siguiente".
 * Mientras se carga, el botón de "Siguiente" se deshabilita y muestra un pequeño círculo de carga.
 * @author ERL 2023-07-19 11:15 am  
 * @param {boolean} bLoading - Indica si el paso está en proceso de carga
 * @param {string} sMensaje - Mensaje a mostrar cuando el paso se ha PENDIENTE
 * @param {boolean} bButton - Indica si se debe mostrar el botón de "Siguiente"
 * @param {string} sDesc - Descripción opcional a mostrar después del mensaje 
 * @returns {JSX.Element} 
 */

const ReferenciasPendientes = ({ sMensaje, }) => {
  const Styles = {
    "grid": {
      my: 2,
      ml: 'auto',
      mr: 'auto',
      p: '15px 0',
      width: '100%',
      maxWidth: {
        xl: '460px',
      },
      textAlign: 'center',
      display: 'flex',
      justifyContent:'center'
    },
    "h6": {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: "5px"
    },
    "check-icon": {
      color: "orange",
      fontSize: 180
    }
  }
  return (
    <Grid container sx={Styles["grid"]}>
      <Typography variant="h6" sx={Styles["h6"]}>
        <AccessTimeFilledIcon sx={Styles['check-icon']} />
        {sMensaje}

      </Typography>      

    </Grid>
  )
}

export default ReferenciasPendientes
