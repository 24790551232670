import React from 'react'
import { CircularProgress, Grid } from '@mui/material'
import { createTheme, ThemeProvider, } from '@mui/material/styles'; // Herramientas de MUI para el tema

// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});
/**
    * Componente loader para los input tipo select
    * @author ERL 2023-09-23 03:21 pm 
    * @param {string} sColor - Color del loader
    * @param {int} nSize - Tamaño del icono
    * @returns {JSX.Element} 
   */
const SelectLoader = ({ sColor = "inherit", nSize = 18}) => {    
    return (
        <Grid
            textAlign={'center'}
            my={'15px'}
            sx={{
                position: 'absolute',
                top: '0',
                width: '100%',
                zIndex:'9'
            }}
        >

            <CircularProgress color={sColor} size={`${nSize}px`} />

        </Grid>
    )
}
export default SelectLoader
