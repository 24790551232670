
import { Box } from "@mui/material";
import CircularLoader from "../../../../loader/CircularLoader";
import CargaVideoInputs from "./CargaVideoInputs";
/**
    * Componente contenedor inputs
    * @author ERL 2023-07-17 07:26 pm 
    * @param {boolean} bLoadingComponent - Boolean para mostrar el loader    
    * @param {array} dataDocumentos - Array de los documentos
    * @param {function} getStatusDocs - Función para obtener el estado del documento.
    * @param {object} oStatusResponse - Objeto con la lista de status.
    * @param {function} enviarVideoGoogle - Función para almacenar archivo de video en Google storage
    * @returns {JSX.Element} 
   */
const CargaVideoInputContainer = ({ bLoadingComponent, dataDocumentos, getStatusDocs, oStatusResponse, enviarVideoGoogle }) => {
    const BoxStyles = {
        maxWidth: { xl: "500px" }, width: "100%", 
        
        padding: {
            xl: '25px auto',
            md:'0 auto',
            xs:'0'
        },
        margin: {
            xl: '25px auto',
            md:'0 auto',
            xs:'0'
        }
    }
    return (
        <Box component="form" noValidate sx={BoxStyles}>
            <>
                {
                    bLoadingComponent ?
                        <CircularLoader />
                        :
                        <CargaVideoInputs
                            oStatusResponse={oStatusResponse}
                            getStatusDocs={getStatusDocs}
                            enviarVideoGoogle={enviarVideoGoogle}
                            dataDocumentos={dataDocumentos}
                        />

                }
            </>
        </Box>

    );
};
export default CargaVideoInputContainer;