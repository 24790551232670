export const opcionesViajes = [
    { value: "No tengo soy nuevo", label: "No tengo soy nuevo" },
    { value: "1 a 499", label: "1 a 499" },
    { value: "500 a 999", label: "500 a 999" },
    { value: "1000 a 1,499", label: "1000 a 1,499" },
    { value: "1,500 o más", label: "1,500 o más" }
  ];
export const opcionesApps = [
  { value: "Si", label: "Si" },
  { value: "No", label: "No" },
  { value: "Fui hace tiempo", label: "Fui hace tiempo" },
  { value: "De Otra aplicación", label: "De Otra aplicación" }
];
  