import React from 'react'
import './requisitos.scss';
import styled from 'styled-components'
import { ArrowNext } from '@styled-icons/fluentui-system-regular/ArrowNext'
import { ChevronDoubleDown } from '@styled-icons/fluentui-system-filled/ChevronDoubleDown'
import { ChevronDoubleRight } from '@styled-icons/fluentui-system-filled/ChevronDoubleRight'
import Requisito from './Rquisito';

function Requisitos() {
    const ArrowNextS = styled(ChevronDoubleRight)`scale: 0.5;`
    const ArrowNextMobile = styled(ChevronDoubleDown)`scale: 0.8;`
    const oRequistos = {
        "seleccionaAtuo": {
            sTitulo: "1. Selecciona tu auto",
            sDesc: "Y comienza tu proceso de renta con opción a compra.",            
            oImgs:{
                desktop:"/assets/pasos/paso1.png",
                mobile:"/assets/pasos/mobile/paso1.png"
            }
        },
        "enviaTuFormulario": {
            sTitulo: "2. Envía tu formulario",
            sDesc: "O manda mensaje de WhatsApp y envía los documentos solicitados.",            
            oImgs:{
                desktop:"/assets/pasos/paso1.png",
                mobile:"/assets/pasos/mobile/paso2.png"
            }
        },
        "agendaCita": {
            sTitulo: "3. Agenda cita",
            sDesc: "Estás por terminar y a punto de ser tu propio jefe.",            
            oImgs:{
                desktop:"/assets/pasos/paso1.png",
                mobile:"/assets/pasos/mobile/paso3.png"
            }
        },
    }

    return (
        <div className="requisitos" id="">
            <div id="requisitos" className='hashlink-id'></div>
            <h1 className='requisitos-title'>Fácil, sencillo y rápido es estrenar con BESTA</h1>
            <h3 className='requisitos-desc'>En nuestro arrendamiento con opción a compra, te ofrecemos un auto a tu medida para trabajarlo en Uber o DiDi</h3>
            <div className="container requisitos-desktop">
            <Requisito
                    sTitulo={oRequistos["seleccionaAtuo"].sTitulo}
                    sDesc={oRequistos["seleccionaAtuo"].sDesc}
                    sImg={oRequistos["seleccionaAtuo"].oImgs.mobile}
                />
                <div className='arrow'>
                    <div className='arrow-container'>
                        <ArrowNextS />
                    </div>
                </div>
                <Requisito
                    sTitulo={oRequistos["enviaTuFormulario"].sTitulo}
                    sDesc={oRequistos["enviaTuFormulario"].sDesc}
                    sImg={oRequistos["enviaTuFormulario"].oImgs.mobile}
                />
                <div className='arrow'>
                    <div className='arrow-container'>
                        <ArrowNextS />
                    </div>
                </div>
                <Requisito
                    sTitulo={oRequistos["agendaCita"].sTitulo}
                    sDesc={oRequistos["agendaCita"].sDesc}
                    sImg={oRequistos["agendaCita"].oImgs.mobile}
                />
            </div>
            <div className="container requisitos-mobile">
                <Requisito
                    sTitulo={oRequistos["seleccionaAtuo"].sTitulo}
                    sDesc={oRequistos["seleccionaAtuo"].sDesc}
                    sImg={oRequistos["seleccionaAtuo"].oImgs.mobile}
                />
                <div className='arrow'>
                    <div className='arrow-container'>
                        <ArrowNextMobile />
                    </div>
                </div>
                <Requisito
                    sTitulo={oRequistos["enviaTuFormulario"].sTitulo}
                    sDesc={oRequistos["enviaTuFormulario"].sDesc}
                    sImg={oRequistos["enviaTuFormulario"].oImgs.mobile}
                />
                <div className='arrow'>
                    <div className='arrow-container'>
                        <ArrowNextMobile />
                    </div>
                </div>
                <Requisito
                    sTitulo={oRequistos["agendaCita"].sTitulo}
                    sDesc={oRequistos["agendaCita"].sDesc}
                    sImg={oRequistos["agendaCita"].oImgs.mobile}
                />
            </div>
        </div>
    )
}

export default Requisitos