import React, { useEffect, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import { List, ListItem, ListItemText, Button, Box, TextField } from '@mui/material';

/**
 * Componente para filtrar unidades basado en marcas y modelos.
 * Permite seleccionar una o varias marcas y, con base en esas marcas seleccionadas, 
 * seleccionar modelos asociados a esas marcas.
 * @author ERL 2023-08-07 08:13 pm
 * @param {array} aMarcas - Lista de marcas disponibles.
 * @param {array} aModelos - Lista de modelos disponibles.
 * @param {function} handleFilterSelect - Función callback para manejar la selección de filtros.
 * @param {array} selectedMarcas - Lista de marcas seleccionadas.
 * @param {array} selectedModelos - Lista de modelos seleccionadas.
 * @returns {JSX.Element}
 */
const UnidadFiltroMarcaModelo = ({
    aMarcas,
    aModelos,
    handleFilterSelect,
    selectedMarcas: initialSelectedMarcas,
    selectedModelos: initialSelectedModelos }) => {
    const [selectedMarcas, setSelectedMarcas] = useState(initialSelectedMarcas || []);
    const [selectedModelos, setSelectedModelos] = useState(initialSelectedModelos || []);
    const [searchTerm, setSearchTerm] = useState('');
    
    /**
     * Efecto que se ejecuta cuando cambia el valor de 'initialSelectedMarcas'.
     * Se encarga de actualizar el estado 'setSelectedMarcas' con el valor de 'initialSelectedMarcas'.
     * Si no se proporciona un 'initialSelectedMarcas', se establece un array vacío por defecto.
     * @author ERL 2023-08-08 03:43 pm
     */
    useEffect(() => {
        setSelectedMarcas(initialSelectedMarcas || []);
    }, [initialSelectedMarcas]);
    /**
     * Efecto que se ejecuta cuando cambia el valor de 'initialSelectedModelos '.
     * Se encarga de actualizar el estado 'setSelectedModelos' con el valor de 'initialSelectedModelos '.
     * Si no se proporciona un 'initialSelectedModelos ', se establece un array vacío por defecto.
     * @author ERL 2023-08-08 03:45 pm
     */
    useEffect(() => {
        setSelectedModelos(initialSelectedModelos || []);
    }, [initialSelectedModelos]);

    /**
     * Maneja la selección o deselección de una marca específica.
     * @author ERL 2023-08-07 08:21 pm
     * @param {string} sNombre - Nombre de la marca.
     * @returns {void}
     */
    const seleccionarMarca = (sNombre) => {
        let updatedSelectedMarcas;
        if (selectedMarcas.includes(sNombre)) {
            updatedSelectedMarcas = selectedMarcas.filter(id => id !== sNombre);
        } else {
            updatedSelectedMarcas = [...selectedMarcas, sNombre];
        }
        setSelectedMarcas(updatedSelectedMarcas);
        handleFilterSelect && handleFilterSelect('sNombreMarca', updatedSelectedMarcas);
    };
    /**
     * Maneja la selección o deselección de un modelo específico.
     * @author ERL 2023-08-07 08:33 pm
     * @param {number} sNombre - Nombre del modelo.
     * @returns {void}
     */
    const seleccionarModelo = (sNombre) => {
        let updatedSelectedModelos;
        if (selectedModelos.includes(sNombre)) {
            updatedSelectedModelos = selectedModelos.filter(id => id !== sNombre);
        } else {
            updatedSelectedModelos = [...selectedModelos, sNombre];
        }
        setSelectedModelos(updatedSelectedModelos);
        handleFilterSelect && handleFilterSelect('sNombreModelo', updatedSelectedModelos);
    };

    return (
        <List>
            <TextField
                label="Buscar por Marca"
                variant="outlined"
                size="small"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                    marginBottom: {
                        xl: '10px'
                    }
                }}
            />
            {aMarcas.filter(marca => marca.sNombre.toLowerCase().includes(searchTerm.toLowerCase())).map(marca => (
                <div key={marca.nIdMarca}>
                    <ListItem
                        button
                        onClick={() => seleccionarMarca(marca.sNombre)}
                        selected={selectedMarcas.includes(marca.sNombre)}
                    >
                        <ListItemText primary={marca.sNombre} />
                    </ListItem>
                    <Collapse in={selectedMarcas.includes(marca.sNombre)} timeout="auto" unmountOnExit>
                        <Box display="flex" flexWrap="wrap" gap={1} padding={1}>
                            {aModelos.filter(modelo => modelo.sNombreMarca === marca.sNombre).map(modelo => (
                                <Button
                                    key={modelo.sNombre}
                                    onClick={() => seleccionarModelo(modelo.sNombre)}
                                    variant={selectedModelos.includes(modelo.sNombre) ? "contained" : "outlined"}
                                    color="primary"
                                >
                                    {modelo.sNombre}
                                </Button>
                            ))}
                        </Box>
                    </Collapse>
                </div>
            ))}
        </List>
    );
}

export default UnidadFiltroMarcaModelo;
