import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/**
 * Componente que muestra un cuadro de diálogo de confirmación 
 * para que el usuario confirme o cancele una acción. El resultado (confirmación o rechazo)
 * se maneja como una Promesa. 
 * @author ERL 2023-08-14 11:46 am
 * @param {object} props - Propiedades del componente
 * @field {JSX.Element} children - Elementos o texto a mostrar en el cuerpo del diálogo.
 * @field {boolean} open - Controla si el cuadro de diálogo está abierto o cerrado.
 * @field {Function} setOpen - Función para establecer el estado de apertura del diálogo.
 * @field {string} sTitulo - Título del cuadro de diálogo.
 * @returns {JSX.Element} 
 */
export default function ModalSimple({ children, open, setOpen, sTitulo }) {
    /**
    * Método que maneja el cierre del modal y rechaza la promesa en caso de haber sido abierta.
    * @author ERL 2023-08-14 12:15 pm
    * @returns {void}
    */
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                      maxWidth: '800px',
                      width: '100%'
                    }
                  }}
            >
                <DialogTitle id="alert-dialog-title">
                    {sTitulo}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    
                </DialogActions>
            </Dialog>
        </div>
    );
}