import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useEffect } from 'react';

/**
 * Componente para filtrar unidades basado en ubicaciones.
 * Permite seleccionar una o varias ubicaciones para filtrar las unidades por su ubicación.
 * @author ERL 2023-08-07 09:16 pm
 * @param {Array} aFiltros - Lista de ubicaciones disponibles.
 * @param {Function} handleFilterSelect - Función callback para manejar la selección de filtros.
 * @param {Array} selectedUbicaciones - Lista de ubicaciones seleccionadas previamente.
 * @returns {JSX.Element}
 */
export const UnidadFiltroUbicacion = ({ aFiltros, handleFilterSelect, selectedUbicaciones: initialSelectedUbicaciones }) => {
  const [selectedUbicaciones, setSelectedUbicaciones] = useState(initialSelectedUbicaciones || []);

  /**
 * Efecto que se ejecuta cuando cambia el valor de 'initialSelectedUbicaciones'.
 * Se encarga de actualizar el estado 'setSelectedUbicaciones' con el valor de 'initialSelectedUbicaciones'.
 * Si no se proporciona un 'initialSelectedUbicaciones', se establece un array vacío por defecto.
 * @author ERL 2023-08-08 03:38 pm
 */
  useEffect(() => {
    setSelectedUbicaciones(initialSelectedUbicaciones || []);
  }, [initialSelectedUbicaciones]);

  /**
   * Maneja la selección o deselección de una ubicación específica.
   * Actualiza la lista de ubicaciones seleccionadas y llama a la función callback 'handleFilterSelect'.
   * @author ERL 2023-08-07 09:28 pm
   * @param {number} nIdUbicacion - ID de la ubicación a seleccionar/deseleccionar.
   * @returns {void}
   */
  const seleccionarUbicacion = (nIdUbicacion) => {
    let updatedSelectedUbicaciones;
    if (selectedUbicaciones.includes(nIdUbicacion)) {
      updatedSelectedUbicaciones = selectedUbicaciones.filter(id => id !== nIdUbicacion);
    } else {
      updatedSelectedUbicaciones = [...selectedUbicaciones, nIdUbicacion];
    }
    setSelectedUbicaciones(updatedSelectedUbicaciones);
    handleFilterSelect && handleFilterSelect('nIdUbicacion', updatedSelectedUbicaciones);
  };

  return (
    <List
      sx={{
        width: '100%'
      }}
    >
      {aFiltros.map(ubicacionObj => (
        <ListItem
          button
          key={ubicacionObj.nIdUbicacion}
          onClick={() => seleccionarUbicacion(ubicacionObj.nIdUbicacion)}
          selected={selectedUbicaciones.includes(ubicacionObj.nIdUbicacion)}
        >
          <ListItemText primary={ubicacionObj.sNombre} />
        </ListItem>
      ))}
    </List>
  );
}

export default UnidadFiltroUbicacion;
