export const LANG = {
  labelDescargarPdf: 'Descargar PDF',
  labelDepositoEnGarantia: 'Depósito en garantía',
  labelReferencia: 'Referencia',
  labelMonto: 'Monto',
  labelUserExperience:
    'Para una mejor experiencia de uso, te recomendamos usar el navegador Chrome para acceder al siguiente enlace',
  labelsBienvenido: '¡Bienvenido a la familia BESTA!',
  labelsPendiente: 'Tu vendedor se pondrá en contacto contigo para agendar la entrega de tu',
  labelNuevaUnidad:'nueva unidad.',
  labelPagado: 'Pagado',
  labelDescargaApp:'¡Descarga nuestra increíble app ahora mismo! Una experiencia única te espera. Inicia sesión hasta que nuestro vendedor te dé las indicaciones para disfrutar al máximo de nuestra app.',
  labelNoPierdas:'¡No te lo pierdas!'
}
