import "./contact.scss";
import * as React from 'react';
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { StyledButton } from "../../components/customButtons/StyledButton";
import MensajeContacto from "./MensajeContacto";
import { urlsAPIs } from "../../helpers/urlsAPIs";
import { toast } from "sonner";
import { globalMessage } from "../../helpers/globalMessage";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import { v4 as uuidv4 } from 'uuid';
import SelectComponent from "../../components/viajes/SelectComponent";
import { opcionesApps, opcionesViajes } from "../../components/viajes/selectOptions";

/**
 * Componente que maneja un formulario de contacto para que los usuarios envíen sus datos.
 * @autor ERL 2024-05-21 04:15 pm
 * @returns {JSX.Element}
 * 
 */
const Contact = () => {
  const form = useRef();
  const [done, setDone] = useState(null);
  const [loading, setLoading] = useState(null)

  /**
   * Función que maneja el envío de datos de un formulario a Google Sheets y el envío de un correo electrónico usando EmailJS.   
   * @autor ERL 2024-05-21 04:20 pm
   * @param {Event} e - Evento de envío del formulario.
   * @returns {void}
   */
  const enviarCorreo = (e) => {
    e.preventDefault();
    setLoading(true)
    const uuid = uuidv4().slice(0, 8);
    // Enviar los datos a Google Sheets
    const fecha = new Date();
    const fechaFormateada = `${fecha.getDate().toString().padStart(2, '0')}/${(fecha.getMonth() + 1).toString().padStart(2, '0')}/${fecha.getFullYear()}`;
    const formData = new FormData(form.current);
    formData.append('uuid', uuid);
    formData.append('fecha', fechaFormateada);
    const data = new URLSearchParams(formData);
    fetch(urlsAPIs.urlGoogleSheetFormContacto, {
      method: 'POST',
      body: data
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error ' + response.statusText);
        }
        return response.text();
      })
      .then(result => {
        if (result) {
          // Enviar el email con emailjs
          emailjs.sendForm('service_fst7gdm', 'template_oblxilh', form.current, 'QQvoOXLmHkR29h36W')
            .then(
              (result) => {
                setDone(true);
                setLoading(false);
              },
              (error) => {
                toast.error(globalMessage.errorServidor)
              }
            );
          form.current.reset();
        }
      })
      .catch(error => toast.error(globalMessage.errorServidor));
  };
  return (
    <>
      { loading && <FullScreenLoader /> }
      <div className="c-contact" id="contacto">
        {done ? <MensajeContacto /> :
          <>
            <h1 className="c-title-contact">¡SÁLTATE LAS COMPLICACIONES!</h1>
            <div className="c-bg"></div>
            <div className="c-wrapper">
              <div className="c-left">
                <div className="c-info-item">
                  <img src="assets/solicitud-enviada.png" alt="Contacto besta" className="c-imagen" />
                </div>
              </div>
              <div className="c-right-contact">
                <p className="c-desc-contact">
                  <h3>¡Déjanos tus datos!</h3>
                  <p>Nosotros te contactamos</p>
                </p>
                <form ref={form} onSubmit={enviarCorreo}>
                  <input className="input-contact" type="text" placeholder="Nombre Completo" name="nombre" required />
                  <input className="input-contact" type="text" placeholder="Teléfono" name="telefono" required />
                  <input className="input-contact" type="hidden" name="pagina" value="inicio" required />
                  <input className="input-contact" type="hidden" name="canal" value="página web" />

                  <label htmlFor="apps">¿Actualmente eres conductor de UBER y DIDI? </label>
                  <SelectComponent options={opcionesApps} name="apps"/>

                  <label htmlFor="viajes">¿Cuántos viajes llevas realizados?: </label>
                  <SelectComponent options={opcionesViajes} name="viajes"/>

                  <label htmlFor="estado">¿En qué parte de México vives?: </label>
                  <select name="estado" className="select-css" required>
                    <option value="" disabled selected>Selecciona una opción </option>
                    <option value="Nuevo León">Nuevo León</option>
                    <option value="Guadalajara">Guadalajara</option>
                    <option value="Otro">Otro</option>
                  </select><br />

                  <textarea rows="5" placeholder="Mensaje" name="mensaje" className="textarea-contact" />
                  <div className="button-contact">
                    <StyledButton disabled={loading}>Enviar</StyledButton>
                  </div>
                </form>
              </div>
            </div>
          </>
        }
      </div>
    </>

  );
};

export default Contact;