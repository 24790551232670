import React from 'react'
/**
 * @author ERL 2023-09-20 03:08 pm
 * @param {object} props - Propiedades del componente
 * @field {string} sComentario - Comentario del conductor 
 * @returns {JSX.Element}
 */
const DriverComment = ({sComentario}) => {
  return (
    <p className='driver-comentario'>
      " {sComentario} "
    </p>
  )
}

export default DriverComment
