import styled from 'styled-components';

// Media query assumptions
const smallMobile = `@media (max-width: 468px)`;
const mobile = `@media (max-width: 768px)`;
const laptop = `@media (max-width: 1024px)`;
const smallTablet = `@media (max-width: 600px)`;
const smallDesktop = `@media (max-width: 1200px)`;
const smallLaptop = `@media (max-width: 1100px)`;
const tablet = `@media (max-width: 900px)`;

export const SlickDots = styled.ul`
  bottom: 0;
  width: 100%;
  margin: 15px 0;
  list-style: none;
  text-align: center;
  position: relative;
  
  ${mobile} {
    display: none;
  }

  li {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 90px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0);

    &.slick-active {
      border: 2px solid #5740a4;
      border-radius: 10px;
      transition: 0.6s all;
    }
  }
`;

export const SliderThumbnailItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    height: 100%;
  }
`;

export const SlickSlide = styled.div`
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  ${laptop} {
    min-height: 500px;
  }

  ${smallTablet} {
    min-height: 400px;
  }

  ${mobile} {
    min-height: 300px;
  }
`;

export const SliderMainImage = styled.div`
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  ${smallDesktop} {
    max-width: 500px;
  }

  ${laptop} {
    max-width: 400px;
  }

  ${smallLaptop} {
    max-width: 300px;
  }

  img {
    width: 80%;

    ${tablet} {
      width: 90%;
    }

    ${mobile} {
      width: 80%;
    }
  }
`;

export const SlickPrevNext = styled.div`
  font-family: "slick";
  content: "";
  font-size: 20px;
  line-height: 1;
  opacity: 0.3;
  transition: all 0.6s;
  width: 35px;
  height: 35px;
  top: 40%;
  position: absolute;
  z-index: 999;

  svg {
    width: 100%;
  }

  &:hover {
    opacity: 1;

    svg {
      //color: #ffffff;
    }
  }

  ${mobile} {
    top: 50%;
  }
`;

export const SlickNext = styled(SlickPrevNext)`
  
  right: ${props => props.right || '160'}px;
  top: ${props => props.top || '40'}%;
  cursor: pointer;

  ${mobile} {
    right: 0px;
  }
  ${smallMobile} {
    right: 1%;
  }
`;

export const SlickPrev = styled(SlickPrevNext)`
  left: ${props => props.left || '160px'};
  top: ${props => props.top || '40'}%;
  cursor: pointer;

  ${mobile} {
    left: 0px;
  }
  ${smallMobile} {
    left: 1%;
  }
`;

