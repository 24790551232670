import React from "react";
/**
 * Componente que renderiza un elemento <select> con opciones de viaje.
 * Las opciones de viaje se pasan desde el módulo "selectOptions" y se muestran en un menú desplegable.
 * @autor ERL 2024-06-13 12:48 pm
 * @returns {JSX.Element}
 */

const SelectComponent = ({options, name}) => {
  return (
    <>
      <select name={name} className="select-css" required>
        <option value="" disabled selected>
          Selecciona una opción{" "}
        </option>
        {options.map((opcion, index) => (
          <option key={index} value={opcion.value}>
            {opcion.label}
          </option>
        ))}
      </select>
      <br />
    </>
  );
};

export default SelectComponent;
