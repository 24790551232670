import React from 'react'
import './notfoundpage.scss';

function NotFoundPage() {

  return (
        <div className="notfoundpage" id="notfoundpage">
        
            <div className="container">
                    <h1>Pagina no encontrada</h1>
                    <h1>Error 404</h1>
            </div>
        </div>
  )
}

export default NotFoundPage