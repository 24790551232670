
import Topbar from "../components/topbar/Topbar";
import Menu from "../components/menu/Menu";
import Iconos from "../pages/visitanos/Iconos";
import DashboardRoutes from "../routes/DashboardRoutes";
import { useState } from "react";
import { useLocation } from "react-router";
import { useContext } from "react";
import { ProgressBarContext } from "../context/ProgressBarContext";
import FooterHome from "../components/footer/FooterHome";
import { useEffect } from "react";

const AppRoutes = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const { pathname } = location;
    const { mostarIcoWhats, showHeaderFooter, setShowHeaderFooter } = useContext(ProgressBarContext)

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }, [pathname]);
    const excludedPaths = ['/proceso', '/cargavideo', '/registro', '/login'];
    const isExcludedPath = excludedPaths.includes(pathname) || pathname.startsWith('/cotizacion');

    return (
        <div className={`app`} style={{
            minHeight: '100vh',
            height: pathname !== '/proceso' ? "" : "auto",
            backgroundImage: pathname !== '/proceso' ? "" : "linear-gradient(to bottom, #483e6b 55%, #6952ba)",
        }}>

            {(!pathname.includes('/cargavideo') && showHeaderFooter) && <>
                <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} /></>}
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            {
                !(pathname === '/proceso' || pathname === '/cargavideo' || pathname.startsWith('/seminuevos')) &&

                <>
                    {mostarIcoWhats && <Iconos />}
                </>
            }

            <div className="sections" style={{
                backgroundColor: pathname !== '/proceso' ? "" : "transparent",
                top: pathname !== '/proceso' ? "" : "0",                
            }}>
                <DashboardRoutes />
            </div>
            {!isExcludedPath && showHeaderFooter ? <FooterHome /> : ''}
        </div>
    )
}

export default AppRoutes
