import styled from 'styled-components'
import { Mail } from '@styled-icons/entypo/Mail';
import { Link } from 'react-router-dom';


const imgArrow = "assets/vacante/arrow2.png";

const MailC = styled(Mail)`
      color: #fff;
    `
const vacanteTopStyle = {
    backgroundImage: "url(/assets/vacante/footer-line.png)",
    minHeight: "419px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    backgroundSize: "contain"
};

export const Footer = ({ onSubmit }) => {
    return (
        <div className="footer" style={vacanteTopStyle}>
            <div className="footer-container">
                <div className="footer-btn">
                    <button className='apply-now-btn' onClick={onSubmit}>Apply <b>Now</b> <img src={imgArrow} alt="" /></button>
                </div>
                <div className="footer-info">
                    <div className="footer-info-img">
                        <MailC />
                    </div>
                    <div className="footer-info-txt">
                        <h3>Recursos Humanos</h3>
                        <a href="mailto:cgarcia@besta.mx">
                            cgarcia@besta.mx
                        </a>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
                <p className='website'>
                    <Link to="/">
                        www.besta.mx
                    </Link>
                </p>
            </div>
        </div>
    )
};

export default Footer;