// Importando módulos necesarios
import React, { useEffect, useState } from 'react';
import '../../../../../styles/registro/global.scss'
import '../../../../../styles/registro/prospectos/pasos/pagoReferencia.scss'
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Box, Typography, Grid, CircularProgress, Card, } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'; // Herramientas de MUI para el tema
import axios from 'axios';
import PagoReferenciaPDF from './PagoReferenciaPDF';
import { urlsAPIs } from '../../../../../helpers/urlsAPIs';

import { StyledButton4 } from '../../../../customButtons/StyledButton';
import { toast } from 'sonner';
import { globalMessage } from '../../../../../helpers/globalMessage';
import CompletedStep from '../../CompletedStep';
import AccordionCustomized from './PagoReferenciaCollapsible';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LANG } from './LANG';
import PagoCompletado from './PagoCompletado';
import { useLogin } from '../../../../../hooks/usuariosApi/useLogin';
// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});
/**
 * Componente pago deposito en garantia
 * @author ERL 2023-07-17 12:40 pm 
 * @param {object} headers - Headers para las llamadas API.
 * @returns {JSX.Element} 
 */
const PagoReferencia = ({ headers }) => {

    const [loadingComponent, setLoadingComponent] = useState(true);
    const { logOutUsuario:logout } = useLogin();
    const [aReferencias, setReferencias] = useState([]);
    const [todosPagados, setTodosPagados] = useState(false);


    /**
      * Este efecto se activa al montar el componente. Realiza una petición GET a la API para obtener 
      * los datos de referencia de pago para el cliente. Una vez obtenidos los datos, se establecen 
      * en el estado del componente para su posterior uso.
      * 
      * @author ERL 2023-07-17 12:40 pm
      * @hook useEffect - Hook de React que se activa al montar el componente.
      * @returns {void}
      */
    useEffect(() => {
        const getData = async () => {
            const id_cliente = sessionStorage.getItem('ci');
            try {
                const response = await axios.get(`${urlsAPIs.urlReferenciaPago}/${id_cliente}`, {
                    params: {
                        sTipo: "P" // Pago Referencia
                    },
                    headers: headers
                });
                const { nCodigo, sMensaje, data: oReferenciaForm } = response?.data;
                if (nCodigo === 0) {
                    setReferencias(oReferenciaForm);
                    const todosEstanPagados = oReferenciaForm?.length > 0 && oReferenciaForm.every(obj => obj.sStatusPagoGarantia === "PAGADA");
                    setTodosPagados(todosEstanPagados);
                } else {
                    toast.error(sMensaje)
                }
            } catch (error) {
                const { responseCode = 0, message = "" } = error?.response.data
                if (responseCode === '401' && message === 'jwt expired') {                    
                    logout()
                } else {                
                    toast.error(globalMessage.errorServidor);                    
                }
            } finally {
                setLoadingComponent(false);
            }
        }
        getData();
    }, []);

    // Renderizando el componente
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container
                component="main"
                maxWidth="sm"
                sx={{ maxWidth: { xs: '100%', sm: '400px', md: '600px', lg: '1600px', }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
                <CssBaseline />


                <Box
                    sx={{ marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', }}
                >
                    <Box

                        sx={{ mt: { xl: 3, xs: 0, }, mx: { xl: 3, xs: 0, }, mb: { xl: 3, xs: 0, }, minHeight: { lg: '500px', }, }}>
                        <Grid container spacing={2} sx={{ ml: 0, mt: 0, width: "100%", }}>
                            <Grid
                                container
                                sx={{ m: '0 auto', p: 0, maxWidth: { xl: '800px', xs: '100%', }, display: "flex", justifyContent: "space-around", gap: '15px' }}>
                                {loadingComponent ?
                                    <Grid
                                        sx={{ height: "50vh", width: "100%", display: 'flex', justifyContent: "center", alignItems: "center", }}><CircularProgress color="primary" size={'100px'} /></Grid> :
                                    todosPagados ?
                                        <Container
                                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {
                                                todosPagados &&
                                                <>
                                                    <PagoCompletado />
                                                </>
                                            }

                                        </Container>
                                        :
                                        <>
                                            {
                                                aReferencias?.map((ref) => {
                                                    const icon = ref?.sStatusPagoGarantia === 'PAGADA' ?
                                                        <CheckCircleOutlineIcon sx={{ color: ref?.sStatusPagoGarantia === 'PAGADA' ? 'green' : 'orange' }} />
                                                        : <WarningAmberIcon sx={{ color: ref?.sStatusPagoGarantia === 'PAGADA' ? 'green' : 'orange' }} />;
                                                    return (

                                                        <div key={ref.sNombre}>
                                                            <AccordionCustomized
                                                                title={ref.sTitulo}
                                                                icon={icon}
                                                                iconColor={ref?.sStatusPagoGarantia === 'PAGADA' ? 'green' : 'orange'}
                                                                status={ref?.sStatusPagoGarantia}
                                                                content={
                                                                    <Grid sx={{ margin: '25px 0' }}>
                                                                        <PagoReferenciaPDF
                                                                            sNombre={`${ref.sNombre} ${ref.sApellidoP} ${ref.sApellidoM}`}
                                                                            nReferencia={ref.sRefencia !== "" ? ref.sRefencia : 0}
                                                                            nMonto={ref.nMonto}
                                                                            sTitulo={ref.sTitulo}
                                                                            nIdProvedorPago={ref.nIdProvedorPago}
                                                                        />
                                                                    </Grid>
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

            </Container>
        </ThemeProvider>
    );
};

export default PagoReferencia;
