// ProgressBarContext.js
import React, { createContext, useState } from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Toaster, toast } from "sonner";
import SmallCircularLoader from "../components/loader/SmallCircularLoader";
import { urlsAPIs } from "../helpers/urlsAPIs";
import axios from "axios";
import { useLogin } from "../hooks/usuariosApi/useLogin";
import { globalMessage } from "../helpers/globalMessage";
import Cuestionario from "../components/registro/prospectos/pasos/cuestionario/Cuestionario";
import Documentos from "../components/registro/prospectos/pasos/documentos/Documentos";

import Referencias from "../components/registro/prospectos/pasos/referencias/Referencias";
import CargaVideo from "../components/registro/prospectos/pasos/video/CargaVideo";
import PagoReferencia from "../components/registro/prospectos/pasos/pago/PagoReferencia";
import Palenca from "../components/registro/prospectos/pasos/palenca/Palenca";

export const ProgressBarContext = createContext();

/**
 * Este archivo contiene las definiciones del contexto y el proveedor
 * para la funcionalidad de la barra de progreso.
 * Aquí, se crean y exportan el contexto y su proveedor para su uso externo.
 * Este módulo ayuda a gestionar y seguir el progreso en un proceso, como subir,
 * validar u otro tipo de procesamiento de archivos. El contexto contiene múltiples estados y configuraciones,
 * como el estado del proceso (por ejemplo, 'Validado', 'Rechazado', 'Cargando'), sus colores asociados,
 * mensajes e íconos.
 * @params {object} children  - Contenido dentro del Contexto
 * @author ERL 2023-09-07 02:18 am
 */
export const ProgressBarProvider = ({ children }) => {
  const [dataDocumentos, setDataDocumentos] = useState([]);
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [mostarIcoWhats, setMostrarIcoWhats] = useState(true);
  const [aNidentificadores, setNIdentificadores] = useState([]);
  const [aRadios, setRadios] = useState([]);

  const [defaultTipoDoc, setTipoDefaultDoc] = useState("");
  const [sTipoDoc, setTipoDoc] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [bIngresosAdicionales, setBIngresosAdicionales] = useState(1);
  const [activeStepDB, setActiveStepDB] = useState(0);
  const [statusResponse, setStatusResponses] = useState({
    VALIDADO: {
      sStatus: "VALIDADO",
      sStatusColor: "green",
      sStatusMessage: "Archivo validado",
      sIcono: <CheckCircleIcon />,
    },
    RECHAZADO: {
      sStatus: "RECHAZADO",
      sStatusColor: "red",
      sStatusMessage: "Archivo rechazado",
      sIcono: <CancelIcon />,
    },
    ERROR: {
      sStatus: "ERROR",
      sStatusColor: "red",
      sStatusMessage: "Error en la carga del archivo",
      sIcono: <CancelIcon />,
    },
    ERROR_METAMAP: {
      sStatus: "ERROR",
      sStatusColor: "red",
      sStatusMessage: "",
      sIcono: <CancelIcon />,
    },
    ERROR_PDF: {
      sStatus: "ERROR",
      sStatusColor: "red",
      sStatusMessage: "Tipo de archivo no soportado",
      sIcono: <CancelIcon />,
    },
    CARGADO: {
      sStatus: "CARGADO",
      sStatusColor: "#04fff2",
      sStatusMessage: "Validando documentos",
      sIcono: <AccessTimeFilledIcon />,
    },
    ENVIADO: {
      sStatus: "ENVIADO",
      sStatusColor: "blue",
      sStatusMessage: "Archivo enviado",
      sIcono: <AccessTimeFilledIcon />,
    },
    REEMPLAZADO: {
      sStatus: "REEMPLAZADO",
      sStatusColor: "blue",
      sStatusMessage: "Archivo validado",
      sIcono: <CheckCircleIcon />,
    },
    PENDIENTE: {
      sStatus: "PENDIENTE",
      sStatusColor: "orange",
      sStatusMessage: "Pendiente",
      sIcono: <AccessTimeFilledIcon />,
    },
    VALIDANDO: {
      sStatus: "VALIDANDO",
      sStatusColor: "orange",
      sStatusMessage: "Validando archivo",
      sIcono: <SmallCircularLoader />,
    },
    VALIDANDO_NOMETA: {
      sStatus: "VALIDANDO",
      sStatusColor: "orange",
      sStatusMessage: "Validando archivo",
      sIcono: <AccessTimeFilledIcon />,
    },
    "": {
      sStatus: "",
      sStatusColor: "black",
      sStatusMessage: "",
      sIcono: <RadioButtonUncheckedIcon />,
    },
  });
  const [stepComponents, setStepComponents] = useState([]);
  const [stepLabels, setStepLabels] = useState([]);
  const [activoLabel, setActivoLabel] = useState();
  const [pasosDB, setPasosDB] = useState([])
  const jwt = sessionStorage.getItem("t");
  const headers = { Authorization: "Bearer " + jwt };
  const [toastPosition, setToastPosition] = useState("top-right");
  const { logOutUsuario } = useLogin();
  const [bSinIngresoAdicional, setSinIngresoAdicional] = useState(null);
  const [bIngresoAdicional, setIngresoAdicional] = useState(null);

  /**
   * Función para ir al siguiente paso de manera local sin llamar a la base de datos
   * @author ERL 2023-11-16 04:31 pm
   * @returns {void}
   */
  const nextStepLocal = (nStep) => {
    setActiveStep(nStep);
    setActiveStepDB(nStep);
  };

  /**
   * Función para obtener los pasos del API y configurar los componentes respectivos.
   * La función también establece el estado activo y la etiqueta activa basándose en la respuesta de la API.
   * Además, gestiona la navegación a diferentes componentes según el estado de la aplicación.  
   * La función realiza una solicitud GET a la API, procesa la respuesta, y establece varios estados basándose en 
   * esa respuesta.
   * En caso de que el estado activo sea "Rechazado", la función redirige al usuario a la página de inicio.
   * Para cada paso recibido de la API, se asigna un componente correspondiente del objeto `stepsComponentsDB`. 
   * Estos componentes son almacenados en el estado `stepComponents`.
   * Finalmente, la función establece el estado `stepLabels` basándose en los datos de los pasos recibidos de la API.
   * En caso de que ocurra un error durante la solicitud a la API, se muestra un mensaje de error.
   * 
   * @author ERL 2023-06-17 02:58 pm
   * 
   * @returns {void}
   */
  const getStepsFromAPI = async () => {
    try {
      const headers = { Authorization: "Bearer " + jwt };
      const id = sessionStorage.getItem('u');
      const response = await axios.get(`${urlsAPIs.urlProgresoEstado}/${id}`, { headers });
      const { sMensaje, nCodigo, data } = response.data;
      if (nCodigo === 0) {
        const { nIdStatusPalenca = 0, pasos: pasosProgressbar } = data;
        const statusPalencaProgressBar = 4;
        const statusPalenca = pasosProgressbar.filter(p => p.nIdStatusProspecto === statusPalencaProgressBar);
        
        const {nIdUber, nIdDidi, bSinIngresoAdicional} = data;
        const { nStatus: nStatusPalenca } = statusPalenca[0];
        const pasos = response.data.data.pasos.sort((a, b) => a.nOrden - b.nOrden);

        const activo = response.data.data.sActivo;
        const pasoActual = response.data.data.nIdStatusActual;
        if (activo === "rechazado") {
          logOutUsuario()
        }
        setPasosDB(pasos);
        setSinIngresoAdicional(activo !== 'cuestionario' ? Boolean(bSinIngresoAdicional) : null);
        setActivoLabel(activo)
        const nIdCliente = response.data.data.nIdCliente;
        const sStatus = response.data.data.nIdStatusActual;
        const sActivo = response.data.data.sActivo;
        const orden = pasos.findIndex(item => item.nIdStatusProspecto === pasoActual);
        sessionStorage.setItem('ci', nIdCliente);
        setActiveStep(orden !== -1 ? orden : pasoActual);
        setActiveStepDB(orden !== -1 ? orden : pasoActual === 9 ? 5 : pasoActual);

        const stepsComponentsDB = {
          2: {
            componente: <Cuestionario nextStep={getStepsFromAPI} headers={headers} />,
          },
          3: {
            componente: <Documentos nextStep={getStepsFromAPI} status={sStatus} sActivo={sActivo} setActiveStep={setActiveStep} headers={headers} />,
          },
          4: {
            componente: <Palenca nextStep={getStepsFromAPI} nextStepLocal={nextStepLocal} headers={headers} nIdStatusPalenca={nIdStatusPalenca} nStatusPalenca={nStatusPalenca} nIdUber={nIdUber} nIdDidi={nIdDidi} />,
          },
          5: {
            componente: <Referencias nextStep={getStepsFromAPI} headers={headers} />,
          },
          6: {
            componente: <CargaVideo nextStep={getStepsFromAPI} status={sStatus} headers={headers} />,
          },
          7: {
            componente: <PagoReferencia nextStep={getStepsFromAPI} headers={headers} />,
          },
        }
        const stepComponents = pasos.map((paso) => stepsComponentsDB[paso.nIdStatusProspecto].componente);

        setStepComponents(stepComponents);
        setStepLabels(pasos.map(paso => {
          return { titulo: paso.sNombre, desc: paso.sDesc, status: paso.nStatus, message: paso.sStatusMsg, nIdPaso: paso.nIdStatusProspecto }
        }));

      } else {
        toast.error(sMensaje)
      }
    }
    catch (error) {
      
      const { responseCode = 0, message = "" } = error.response.data
      if (responseCode === '401' && message === 'jwt expired') {
        toast.error(globalMessage.labelSesionExpirada)
        logOutUsuario()
      }else{
        toast.error(globalMessage.errorServidor)
      }
    }
  };
  const [showHeaderFooter, setShowHeaderFooter] = useState(true)
  const globalState = {
    dataDocumentos,
    setDataDocumentos,
    loadingComponent,
    setLoadingComponent,
    aNidentificadores,
    setNIdentificadores,
    aRadios,
    setRadios,
    sTipoDoc,
    setTipoDoc,
    defaultTipoDoc,
    statusResponse,
    setStatusResponses,
    activeStep,
    setActiveStep,
    toastPosition,
    setToastPosition,
    setTipoDefaultDoc,
    jwt,
    headers,
    mostarIcoWhats,
    setMostrarIcoWhats,
    bIngresosAdicionales,
    setBIngresosAdicionales,
    activeStepDB,
    setActiveStepDB,
    nextStepLocal,
    getStepsFromAPI,
    pasosDB, 
    setPasosDB,
    stepComponents, 
    setStepComponents,
    stepLabels, 
    setStepLabels,
    activoLabel, 
    setActivoLabel,
    bSinIngresoAdicional, 
    setSinIngresoAdicional,
    bIngresoAdicional, 
    setIngresoAdicional,
    logout:logOutUsuario,
    showHeaderFooter, setShowHeaderFooter
  };

  return (
    <ProgressBarContext.Provider value={globalState}>
      <Toaster
        richColors
        position={toastPosition}
        closeButton
        duration={6000}
      />
      {children}
    </ProgressBarContext.Provider>
  );
};
