import React, { useEffect, useState } from 'react';

const CustomSelect = ({
  name,
  label,
  options,
  setIsValidForm,
  callback,
  value,
  inputRef,
  isValidForm,
  submitAttempted,
  setSubmitAttempted
}) => {
  const [touched, setTouched] = useState(value.trim() !== '' && value !== '0');

  useEffect(() => {
    if (isValidForm && (value.length < 1 || value === '0')) {
      setTouched(true);
    }
  }, [value, isValidForm]);

  const handleFocus = () => {
    setTouched(true);
  };

  const handleBlur = (e) => {
    const isValueEmpty = e.target.value.trim() === '' || e.target.value === '0';
    setIsValidForm(!isValueEmpty);
    setTouched(!isValueEmpty);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    callback(newValue);

    const isValueEmpty = newValue.trim() === '' || newValue === '0';
    setTouched(!isValueEmpty);
  };

  const inputClassName = `input-style ${(value.length < 1 || value === '0') && (submitAttempted) ? 'invalid' : ''}`;

  return (
    <div className="form-item">
      <label className={`${touched || (value !== '' && value !== '0') ? 'label-a ' : ''}`} htmlFor={label}>
        {label}:
      </label>
      <select
        className={inputClassName}
        name={name}
        id={label}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        ref={inputRef}
      >
        <option value=""></option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {(value.length < 1 || value === '0') && (touched || submitAttempted) && <span>Campo requerido</span>}
    </div>
  );
};

export default CustomSelect;
