// Importando módulos necesarios
import React, { useEffect, useState } from 'react';
import '../../../../../styles/registro/global.scss'
import '../../../../../styles/registro/prospectos/pasos/cuestionario.scss'
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Box, Grid, } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'; // Herramientas de MUI para el tema
import { useNavigate } from 'react-router-dom'; // Hook para la navegación
import axios from 'axios';
import { toast } from 'sonner';
import { urlsAPIs } from '../../../../../helpers/urlsAPIs';
import CompletedStep from '../../ProgresoComponente';
import { globalMessage } from '../../../../../helpers/globalMessage';
import CircularLoader from '../../../../loader/CircularLoader';
import CollapsibleForm from '../../../../collapsible/CollapsibleForm';
import { statusResponses } from '../../../../../helpers/statusResponses';
import { useForm } from 'react-hook-form';
import ReferenciasForm from './ReferenciasForm';
import ReferenciasEnviadasStepper from './ReferenciasEnviadasStepper';
import ReferenciasPendientesStepper from './ReferenciasPendientesStepper';
import ReferenciasStepperButtonsPend from './ReferenciasStepperButtonsPend';
import ReferenciasPendientes from './ReferenciasPendientes';
import { LANG } from './LANG';
import { useLogin } from '../../../../../hooks/usuariosApi/useLogin';
// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});
/**
 * Componente de referencias personales
 * @author ERL 2023-06-16 08:36 am 
 * @param {function} nextStep - Función para ir al siguiente paso del registro
 * @param {object} headers - Headers para las llamadas API.
 * @returns {JSX.Element} 
*/
const Referencias = ({ nextStep, headers }) => {
    const methods = useForm();
    const { handleSubmit, control, setError, formState: { errors } } = useForm();
    const { logOutUsuario:logout } = useLogin();
    const { setValue } = methods; // Extraer setValue de methods
    const [loadingComponent, setLoadingComponent] = useState(true);

    // Hook para la navegación
    const navigate = useNavigate();

    const jwt = sessionStorage.getItem('t');
    /**
     * Este efecto se activa cada vez que cambia el valor de 'jwt'. Si 'jwt' es nulo o vacío, 
     * redirige al usuario a la página de inicio de sesión. En caso contrario, recupera el 'id' 
     * del usuario de la sesión y lo guarda en el estado. 
     * 
     * @author ERL 2023-07-17 12:40 pm
     * @hook useEffect - Hook de React que se activa al cambiar el valor de 'jwt'.
     * @param {string} jwt - JSON Web Token utilizado para autenticar al usuario.
     * @returns {void} 
     */

    useEffect(() => {
        if (jwt) {
            if (jwt === '' || jwt.length === 0) {
                navigate("/login");
            }
        } else {
            navigate("/login");
        }
    }, [jwt, navigate])
    const [dataReferencia, setDataReferencia] = useState([]);
    const [steps, setSteps] = useState([]);
    const [referenciasPendientes, setReferenciasPendientes] = useState([]);
    const [referenciasRechazadas, setReferenciasRechazadas] = useState([]);

    /**
     * getData - Función asíncrona que recupera y procesa los datos relacionados con las referencias.
     * Esta función hace una llamada a la API para obtener información basada en el ID de sesión. Después
     * procesa y organiza esta información para su posterior uso.
     *
     * @author ERL 2023-08-17 01:00 pm
     * @returns {void}
     */
    const getData = async () => {
        const nIdProspecto = sessionStorage.getItem('p');
        if(nIdProspecto && nIdProspecto > 0){
            try {
                const response = await axios.get(`${urlsAPIs.prospectosReferencias}/${nIdProspecto}`, {
                    headers: headers
                });
                const { nCodigo, sMensaje, data } = response.data;
    
                // Ordena los documentos por su número de orden
                const { aReferencias: formReferencias } = data;
                formReferencias.sort((a, b) => a.nOrden - b.nOrden);
                if (nCodigo === 0) {
                    setSteps(formReferencias.filter((a) => a.nIdPadre === 0 && a.sTipo === "label"))
                    setReferenciasPendientes(formReferencias.filter((a) => a.sTipo === "label" && a.sStatus !== ""));
                    setReferenciasRechazadas(formReferencias.filter((a) => a.sTipo === "label" && a.sStatus === "RECHAZADO"));
                    setDataReferencia(formReferencias);
                } else {
                    toast.error(sMensaje)
                }
            } catch (error) {                
                const { responseCode = 0, message = "" } = error?.response.data
                if (responseCode === '401' && message === 'jwt expired') {                    
                    logout()
                } else {                
                    toast.error(globalMessage.errorServidor);                    
                }
            } finally {
                setLoadingComponent(false);
            }
        }
    }

    /**
     * Efecto que se ejecuta al montar el componente. Se encarga de obtener los datos 
     * @author ERL 2023-07-17 02:50 pm
     * @hook useEffect - React hook que se ejecuta al montar el componente.
     * @returns {void} 
     */
    useEffect(() => {
        getData();
    }, []);

    const sMensaje = "Enhorabuena, todas tus referencias han sido validadas.";
    const Styles = {
        "container": {
            maxWidth: {
                xs: '100%',
                sm: '400px',
                md: '780px',
                lg: '1600px',
            },
            padding: {
                xl: '0 !important',
                md: "0 auto !important"
            },
            margin: {
                xl: '0 !important',
                md: "0 auto !important"
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        "box-container": {
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        "box-div": {
            mt: 0,
            mx: 0,
            mb: 3,
            minHeight: {
                lg: '500px',
            },
            width: "100%",
        },
        "box-div-grid-container": {
            ml: 0,
            mt: 0,
            width: "100%",
        },
        "box-div-grid-container-item": {
            m: '0 auto',
            p: 0,
            maxWidth: {
                xl: '660px',
            }
        },
        "grid-collapsible-container": { display: 'flex', flexDirection: 'column', width: '100%' },
    }

    /**
    * Funcion para obtener el status de la referencia
    * @author ERL 2023-07-17 12:35 pm 
    * @param {object} oReferencia - Objeto con el status de la referencia     
    * @returns {string} 
   */
    const getStatusDocs = (oReferencia = {}) => {
        const status = oReferencia?.sStatus ? oReferencia.sStatus : "PENDIENTE";
        return status.toString().toUpperCase();
    }
    const [nCurrentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);


    /**
     * Función para desplazar la vista de la página al inicio (top).
     * Busca el elemento "body" de la página y utiliza la función `scrollIntoView`
     * para desplazar suavemente la vista hasta la parte superior del elemento seleccionado.
     * Esta función puede ser utilizada para volver al inicio de la página al hacer clic en un botón,
     * o en otros escenarios similares.
     * @author ERL 2023-08-02 01:01 pm
     */
    const scrollToTop = () => {
        const anchor = document.querySelector('body')
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth' })
        }
    }

    /**
     * Función para avanzar al siguiente paso o referencia en un proceso
     * @author ERL 2023-07-17 12:15 pm 
     * @returns {void} 
     */
    const nextRef = () => {

        setCurrentStep(prevStep => prevStep + 1);
        scrollToTop();
    };
    /**
     * Función para retroceder al paso o referencia previa en un proceso
     * @author ERL 2023-07-17 12:17 pm 
     * @returns {void} 
     */
    const prevStep = () => {

        setCurrentStep(prevStep => prevStep - 1);
    };
    const [sessionId, setSessionId] = useState(null);
    /**
     * Hook de efecto que se encarga de verificar y utilizar el ID de la sesión almacenado
     * @author ERL 2023-07-17 12:20 pm 
     * @returns {void}
     */
    useEffect(() => {
        const id = sessionStorage.getItem('u');
        if (id !== null) {
            setSessionId(id);
        }
    }, []);


    /**
     * Función para consultar y procesar información de colonias basado en un código postal
     * @author ERL 2023-07-17 12:30 pm 
     * @param {number} nCodigoPostal - Código postal para buscar colonias correspondientes
     * @param {number} nIdenticiadorRef - Identificador de referencia utilizado para la manipulación de datos
     * @returns {void}
     */
    const [infoColonias, setInfoColonias] = useState([]);
    const [loadingColonias, setLoadingColonias] = useState(false);
    const consultarColonias = async (nCodigoPostal, nIdenticiadorRef) => {
        setLoadingColonias(true)
        try {
            const response = await axios.get(`${urlsAPIs.urlObtenerColonias}/${nCodigoPostal}`, { headers: headers })
            const { sMensaje, nCodigo, data } = response.data;

            if (nCodigo === 0) {
                const coloniasOptions = data.filter(colonias => Number(colonias.sCodigoPostal) === Number(nCodigoPostal));
                const newColonias = [];

                coloniasOptions.forEach((colonias, index) => {
                    newColonias.push({
                        sValor: colonias.sNombreColonia,
                        nIdRespMc: colonias.nIdColonia,
                        nOrden: index + 1,
                    });
                    setInfoColonias(prev => {
                        // Comprueba si la colonia ya existe en el estado.
                        const coloniaYaExiste = prev.some((el) => el.nIdColonia === colonias.nIdColonia);
                        if (!coloniaYaExiste) {
                            // Si la colonia no existe en el estado, la añade.
                            return [
                                ...prev,
                                {
                                    nIdColonia: colonias.nIdColonia,
                                    sNombreColonia: colonias.sNombreColonia,
                                    sCodigoPostal: colonias.sCodigoPostal,
                                    nIdMunicipio: colonias.nIdMunicipio,
                                    sNombreMunicipio: colonias.sNombreMunicipio,
                                    nIdEstado: colonias.nIdEstado,
                                    sNombreEntidad: colonias.sNombreEntidad,
                                    nIdPais: colonias.nIdPais,
                                    sPais: colonias.sPais,
                                }
                            ];
                        } else {
                            // Si la colonia ya existe en el estado, devuelve el estado previo.
                            return prev;
                        }
                    });
                });

                /**
                 * Función para actualizar la lista de colonias dentro del array de referencias
                 * @author ERL 2023-07-20 09:40 am 
                 * @returns {void}
                 */
                setDataReferencia(prevDataReferencia => {
                    const clone = prevDataReferencia.filter(dataReferencia => dataReferencia.nIdPadre === nIdenticiadorRef && dataReferencia.sTipo === "select")
                    let nIdentificador = 0;
                    clone.forEach(dataReferencia => {
                        if (dataReferencia.sNombreCorto === "nIdColonia") {
                            nIdentificador = dataReferencia.nIdentificador;
                        }
                    })
                    const updatedDataDocumentos = prevDataReferencia.map(document => {
                        if (document.nIdentificador === nIdentificador) {
                            // Si es el documento correcto, actualizamos respuestas
                            return { ...document, respuestas: newColonias };
                        } else {
                            // Si no es el documento que estamos buscando, lo devolvemos sin cambios
                            return document;
                        }
                    });
                    return updatedDataDocumentos;
                });
            } else {
                toast.error(sMensaje);
            }

        } catch (error) {
            toast.error(globalMessage.errorServidor)
        } finally {
            setLoadingColonias(false);
        }
    }

    /**
     * Función para seleccionar una colonia específica y actualizar la información correspondiente
     * @author ERL 2023-07-17 12:40 pm 
     * @param {number} nColonia - Identificador de la colonia seleccionada
     * @param {number} nIdenticiadorRef - Identificador de referencia utilizado para la manipulación de datos
     * @returns {void}
     */
    const seleccionarColonia = async (nColonia, nIdenticiadorRef) => {
        const coloniasOptions = infoColonias.filter(colonias2 => Number(colonias2.nIdColonia) === Number(nColonia));
        const {
            sNombreMunicipio,
            sNombreEntidad,
            sPais,
        } = coloniasOptions[0];
        const clone = dataReferencia.filter(cuestionario => cuestionario.nIdPadre === nIdenticiadorRef && cuestionario.sTipo === "readonly");
        clone.forEach(dataReferencia => {
            const { nIdentificador } = dataReferencia;

            if (dataReferencia.sNombreCorto === "sMunicipio") {
                setValue(`ref${nIdenticiadorRef}-${nIdentificador}`, sNombreMunicipio);
            }
            if (dataReferencia.sNombreCorto === "sEntidadF") {
                setValue(`ref${nIdenticiadorRef}-${nIdentificador}`, sNombreEntidad);
            }
            if (dataReferencia.sNombreCorto === "sPais") {
                setValue(`ref${nIdenticiadorRef}-${nIdentificador}`, sPais);
            }
        })

    }
    const [bNuevasReferencias, setNuevasReferencias] = useState(null);

    /**
     * Efecto que se encarga de verificar el estado de todas las referencias contenidas en `steps`.
     * Si todas las referencias tienen un `sStatus` vacío, se actualiza el estado `setNuevasReferencias` a verdadero.               
     * @author ERL 2023-08-17 01:04 pm
     */
    useEffect(() => {

        if (steps.length > 0) {
            setNuevasReferencias(steps.every((refStep) => refStep.sStatus === ""));
        }

    }, [steps]);



    const sSiguiente = LANG.labelSiguiente;
    const sCompletar = LANG.labelEnviarReferencias;
    const sAnterior = LANG.labelAnterior;



    // Renderizando el componente
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container
                component="main"
                maxWidth="sm"
                sx={Styles["container"]}
            >
                <CssBaseline />
                <Box sx={Styles['box-container']}>
                    <Box component="div" sx={Styles['box-div']}>

                        <Grid container spacing={2} sx={Styles['box-div-grid-container']}>
                            <Grid container sx={Styles["box-div-grid-container-item"]}>

                                <ReferenciasForm
                                    dataReferencia={dataReferencia}
                                    bUltimaReferencia={referenciasPendientes.length > 0 ? nCurrentStep === referenciasPendientes.length : nCurrentStep === steps.length}
                                    nSessionId={sessionId}
                                    bNuevasReferencias={bNuevasReferencias}
                                    setLoading={setLoading}
                                    nextRef={nextRef}
                                    handleSubmit={handleSubmit}
                                    methods={methods}
                                    nextStep={nextStep}
                                    referenciasRechazadas={referenciasRechazadas}
                                    referenciasPendientes={referenciasPendientes}
                                    setReferenciasRechazadas={setReferenciasRechazadas}
                                    getData={getData}
                                    control={control}
                                    setError={setError}
                                    errors={errors}
                                >
                                    {
                                        loadingComponent ?
                                            <CircularLoader />
                                            :
                                            steps.length === 0 ?
                                                <CompletedStep bLoading={loadingComponent} sMensaje={sMensaje} bButton step="referencias" />
                                                :
                                                <>
                                                    {steps.length > 0 && steps.every((refStep) => refStep.sStatus !== "") ?

                                                        <>
                                                            {
                                                                steps.length > 0 && steps.every((refStep) => refStep.sStatus === "PENDIENTE") ?

                                                                    <ReferenciasPendientes
                                                                        sMensaje={LANG.labelPendientes}
                                                                    />

                                                                    : <Grid sx={Styles["grid-collapsible-container"]}>
                                                                        {referenciasPendientes.map((referencia, index) => (
                                                                            <CollapsibleForm
                                                                                sStatus={referencia.sStatus}
                                                                                setDataReferencia={setDataReferencia}
                                                                                oReferencia={referencia}
                                                                                oStatusResponse={statusResponses.referencias}
                                                                                getStatusDocs={getStatusDocs}
                                                                                sValor={`${referencia.sValor}`}
                                                                                sReferencia={`${referencia.sNombre}`}
                                                                                nId={index + 1}
                                                                                nCurrentStep={nCurrentStep}
                                                                                setCurrentStep={setCurrentStep}
                                                                                methods={methods}
                                                                                nextStep={nextStep}
                                                                                referenciasRechazadas={referenciasRechazadas}
                                                                                sAnterior={sAnterior}
                                                                                sSiguiente={sSiguiente}
                                                                                sCompletar={sCompletar}
                                                                            >
                                                                                <ReferenciasEnviadasStepper
                                                                                    dataReferencia={dataReferencia}
                                                                                    steps={referenciasPendientes}
                                                                                    nCurrentStep={nCurrentStep}
                                                                                    consultarColonias={consultarColonias}
                                                                                    seleccionarColonia={seleccionarColonia}
                                                                                    handleSubmit
                                                                                    nextStep={nextRef}
                                                                                    loadingColonias={loadingColonias}

                                                                                />


                                                                            </CollapsibleForm>
                                                                        ))}

                                                                    </Grid>

                                                            }

                                                        </>
                                                        :

                                                        <>
                                                            <ReferenciasPendientesStepper
                                                                dataReferencia={dataReferencia}
                                                                steps={steps}
                                                                getStatusDocs={getStatusDocs}
                                                                statusResponse={statusResponses.referencias}
                                                                consultarColonias={consultarColonias}
                                                                seleccionarColonia={seleccionarColonia}
                                                                nCurrentStep={nCurrentStep}
                                                                loadingColonias={loadingColonias}


                                                            />
                                                            <ReferenciasStepperButtonsPend
                                                                Styles={Styles["grid-container-buttons"]}
                                                                isFirstStep={nCurrentStep === 1}
                                                                isLastStep={nCurrentStep === steps.length}
                                                                prevStep={prevStep}
                                                                sAnterior={sAnterior}
                                                                sSiguiente={sSiguiente}
                                                                sCompletar={sCompletar}
                                                                bLoading={loading}
                                                            />
                                                        </>

                                                    }
                                                </>

                                    }
                                </ReferenciasForm>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );

}
export default Referencias;

