import React from 'react'
/**
 * @author ERL 2023-09-20 03:09 pm
 * @param {object} props - Propiedades del componente
 * @field {int} nCalificacion - Calificacion del conductor 
 * @returns {JSX.Element}
 */
const DriverQualification = ({ nCalificacion }) => {
    const sImgEstrella = '/assets/drivers/iconos/estrella.png'
    return (
        <>
            {[...Array(nCalificacion)].map((_, index) => (
                <img key={index} src={sImgEstrella} alt={`Imagen ${index}`} />
            ))}
        </>
    )
}

export default DriverQualification
