import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import { IosArrowLtr, IosArrowRtl } from '@styled-icons/fluentui-system-regular'

import {
    SlickNext,
    SlickPrev,
    SliderThumbnailItem,
    SliderMainImage
} from './StyledSlider';
import { Grid } from '@mui/material';


const ArrowN = styled(IosArrowRtl)`
      color: #000;
    `
const ArrowP = styled(IosArrowLtr)`
      color: #000;
    `

function NextArrow(props) {
    const { onClick } = props;
    return <SlickNext onClick={onClick} ><ArrowN /></SlickNext>;
}

function PrevArrow(props) {
    const { onClick } = props;
    return <SlickPrev onClick={onClick} ><ArrowP /></SlickPrev>
}

function ImageSlider({ images, mostrarMimiaturas = true }) {
    const sliderRef = React.useRef(); // Referencia para el slider    
    const handleThumbnailClick = (index) => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);  // Mueve el slider a la imagen correspondiente
        }
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        customPaging: function (i) {
            return (
                <>
                    {mostrarMimiaturas &&
                        <>
                            <Grid sx={{ position: 'relative' }} className="slider-thumbnail-item" onClick={() => handleThumbnailClick(i)}>

                                <img src={images[i]} alt={`Imagen ${i}`} loading="lazy" />

                            </Grid>
                        </>
                    }
                </>
            );
        },
    };

    return (
        <div className="slider"> {/* If you need this as a styled component, please mention */}
            <Slider {...settings} ref={sliderRef}>
                {images.map((image, index) => (
                    <div key={index}>
                        <SliderMainImage>
                            <img src={image} alt={`Imagen ${index}`} loading="lazy" />
                        </SliderMainImage>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default ImageSlider;
