import { Box, CardActionArea } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

/**
 * Componente de React que muestra una tarjeta de unidad con información relevante como imagen, descripción y precio.
 * @author ERL 2023-08-07 04:33 pm
 * @param {Object} props - Las propiedades del componente.
 * @field {int} nId - Id de la unidad.
 * @field {string} sURL - URL de la imagen de la unidad.
 * @field {string} sDescripcion - Descripción breve de la unidad.
 * @field {number} nPrecio - Precio de la unidad por semana.
 * @field {string} sStatatus - Estado actual de la unidad (Nota: Parece que hay un error tipográfico en el nombre de la propiedad, es "sStatatus" en lugar de "sStatus").
 * @returns {JSX.Element}
 */
const Unidad = ({ nId, sURL, sDescripcion, nPrecio, sStatatus }) => {
    return (
        <Link to={`/seminuevos/${nId}`} style={{ textDecoration: 'none' }}> 
            <Card sx={{ width: 300 }}>
                <CardActionArea>
                    {sStatatus !== "" &&
                        <Box className="cuadrado-recortado">
                            {sStatatus}
                        </Box>
                    }
                    <CardMedia
                        component="img"
                        height="194"
                        image={sURL}
                        alt=""
                    />
                    <CardContent>
                        <Typography variant="body2" color="text.primary" fontSize={{ xl: '1.2rem' }} maxWidth={{ xl: '190px' }}>
                            {sDescripcion}
                        </Typography>
                        <Typography my={{ xs: '10px' }} variant="h6" sx={{ color: '#6fc6d9' }}>
                            Desde {nPrecio}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>
    )
}

export default Unidad
