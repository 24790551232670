
import axios from "axios";
import { toast } from "sonner";
import { useState } from "react";
import { urlsAPIs } from "../../helpers/urlsAPIs";
import { useEffect } from "react";
import { globalMessage } from "../../helpers/globalMessage";


/**
* Custom hook para gestionar el estado y lógica relacionados con las unidades.
* Proporciona funcionalidades para obtener datos de unidades, aplicar filtros,
* paginación, ordenamiento y otras interacciones relacionadas con las unidades.
* Este hook utiliza internamente múltiples useState y useEffect para mantener y
* gestionar el estado y side effects.
* @author ERL 2023-08-07 02:30 pm 
* @returns {object}
*/

export function useUnidades() {
  const [dataUnidades, setDataUnidades] = useState([]);
  const [mostrarFiltros, setMostrarFiltros] = useState(true);
  const [filteredDataUnidades, setFilteredDataUnidades] = useState([]);
  const [filteredDataFiltros, setFilteredDataFiltros] = useState([]);
  const [filtrosAplicados, setFiltrosAplicados] = useState({
    sNombreMarca: [],
    sNombreModelo: [],
    nIdModelo: [],
    nIdUbicacion: [],
    nIdYear: [],
    nIdColor: [],
    nIdCombustible: [],
    nIdStatus: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const currentUnidades = filteredDataUnidades.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const [open, setOpen] = useState(false);
  const [ordenamiento, setOrdenamiento] = useState({
    criterio: null,
    direccion: 'descendente'
  });
  const [loading, setLoading] = useState(true);

  const [ultimoCriterio, setUltimoCriterio] = useState(null);
  const [aUnidadCarrusel, setUnidadCarrusel] = useState([]);
  const [aUidadInfoBasica, setUnidadInfoBasica] = useState([]);
  const [aUnidadImperfeciones, setUnidadImperfeciones] = useState([]);
  const [aCaracteristicas, setUnidadCaracteristicas] = useState([]);
  const [aUnidad360, setUnidad360] = useState([]);
  const [aUnidad360v2, setUnidad360v2] = useState([]);


  /**
  * Función asíncrona que obtiene datos de las unidades a través de una llamada a la API.
  * En caso de que la llamada a la API falle, se recurre a la utilización de datos dummy para los estados
  * relacionados con unidades y filtros.
  * Adicionalmente, se muestran mensajes de error pertinentes con base en la respuesta de la API o
  * en el caso de que haya un error al realizar la solicitud.
  * @author ERL 2023-08-07 02:34 pm   
  * @returns {void}
  */
  const obtenerDatos = async () => {
    try {

      const response = await axios.get(`${urlsAPIs.urlUnidades}`);
      const { sMensaje, nCodigo, data: dataUnidades } = response.data;

      if (nCodigo === 0) {
        setDataUnidades(dataUnidades.oUnidades);
        setFilteredDataUnidades(dataUnidades.oUnidades);
        setFilteredDataFiltros(dataUnidades.oFiltros);
      } else {
        toast.error(sMensaje)
      }
    }
    catch (error) {
      toast.error(globalMessage.errorServidor)
    } finally {
      setLoading(false)
    }
  };

  /**
   * Manejador del cambio de página. Actualiza la página actual con el valor proporcionado.
   * @author ERL 2023-08-08 11:18 am
   * @param {Object} event - El evento que disparó la llamada.
   * @param {number} value - El número de la página seleccionada.
   * @returns {void}
   */
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  /**
   * Filtra las unidades basándose en los filtros aplicados.
   * Itera sobre cada unidad y verifica si cumple con todos los criterios establecidos en 'filtrosAplicados'.
   * Si todos los criterios se cumplen para una unidad, dicha unidad es considerada como "activa" (es decir, que pasa el filtro).
   * @author ERL 2023-08-07 03:23 pm
   * @param {Array} dataUnidades - Lista de unidades a filtrar.
   * @param {Object} filtrosAplicados - Objeto con los filtros aplicados. Cada propiedad representa un criterio de filtro.
   * @returns {Array} - Lista de unidades que cumplen con los criterios establecidos en 'filtrosAplicados'.
   */
  const getActivosFiltrados = dataUnidades.filter(unidad => {
    return Object.keys(filtrosAplicados).every(key => {
      // Si el filtro no tiene elementos, considerarlo como 'true' (es decir, no filtrar por esa propiedad)
      if (filtrosAplicados[key].length === 0) return true;
      // Si el filtro tiene elementos, revisar si el nombre de la marca de la unidad está incluido en el filtro
      return filtrosAplicados[key].includes(unidad[key]);
    });
  });

  /**
 * Efecto que se activa cada vez que el objeto 'filtrosAplicados' cambia. Su propósito es actualizar 
 * el estado 'filteredDataUnidades' con las unidades que cumplan con los criterios de 'filtrosAplicados'. 
 * @author ERL 2023-08-07 04:40 pm
 * @returns {void}
 */
  useEffect(() => {
    setFilteredDataUnidades(getActivosFiltrados);

  }, [filtrosAplicados]);

  /**
 * Función para manejar la selección o deselección de filtros.
 * Dependiendo de la naturaleza del valor pasado (array o valor único), actualiza el estado 'filtrosAplicados' adecuadamente.
 * Si el valor es un array, reemplaza directamente el filtro actual.
 * Si el valor es único, lo agrega o lo quita del filtro dependiendo de si ya está presente o no.
 * @author ERL 2023-08-07 05:34 pm
 * @param {string} key - La clave del filtro a modificar (e.g., 'nIdStatus', 'rangoKilometraje').
 * @param {string} value - El valor o valores a agregar o quitar del filtro.
 * @returns {void}
 */
  const handleFilterSelect = (key, value) => {
    setFiltrosAplicados(prev => {
      if (Array.isArray(value)) {
        return { ...prev, [key]: value };
      } else {
        if (prev[key].includes(value)) {
          // Si el valor ya está seleccionado, quítalo del array
          return { ...prev, [key]: prev[key].filter(item => item !== value) };
        } else {
          // Si no está seleccionado, agrégalo al array
          return { ...prev, [key]: [...prev[key], value] };
        }
      }
    });
  };



  /**
 * Función que maneja el ordenamiento de las unidades filtradas según el criterio seleccionado.
 * Esta función se encarga de ordenar el array 'filteredDataUnidades' basándose en diferentes criterios 
 * como marca, modelo, ubicación, año, color, y estatus. Utiliza los datos de 'filteredDataFiltros' para obtener 
 * los valores de ordenamiento correspondientes. Una vez ordenados los datos, se actualiza el estado 'filteredDataUnidades'.
 * @author ERL 2023-08-08 12:45 pm
 * @param {string} sCriterio - El criterio de ordenamiento seleccionado.
 * @returns {void}
 */
  const handleOrdenamiento = (sCriterio) => {
    let direccionActual;

    // Si el criterio seleccionado es el mismo que el último criterio, invierte la dirección
    if (sCriterio === ultimoCriterio) {
      direccionActual = ordenamiento.direccion === 'ascendente' ? 'descendente' : 'ascendente';
    } else {
      // Si se selecciona un nuevo criterio, establece la dirección a un valor predeterminado
      direccionActual = 'descendente'; // o 'ascendente', según tu preferencia
    }

    const ordenado = [...filteredDataUnidades].sort((a, b) => {
      let valorA, valorB;
      switch (sCriterio) {
        case 'aMarcas':
          const filtroMarca = filteredDataFiltros.find(f => f.sNombreCorto === 'aMarcas');
          if (filtroMarca) {
            valorA = filtroMarca.aFiltros.find(m => m.nIdMarca === a.nIdMarca)?.sNombre || '';
            valorB = filtroMarca.aFiltros.find(m => m.nIdMarca === b.nIdMarca)?.sNombre || '';
          }
          break;

        case 'aModelos':
          const filtroModelo = filteredDataFiltros.find(f => f.sNombreCorto === 'aModelos');
          if (filtroModelo) {
            valorA = filtroModelo.aFiltros.find(m => m.nIdModelo === a.nIdModelo)?.sNombre || '';
            valorB = filtroModelo.aFiltros.find(m => m.nIdModelo === b.nIdModelo)?.sNombre || '';
          }
          break;

        case 'aUbicacion':
          const filtroUbicacion = filteredDataFiltros.find(f => f.sNombreCorto === 'aUbicacion');
          if (filtroUbicacion) {
            valorA = filtroUbicacion.aFiltros.find(u => u.nIdUbicacion === a.nIdUbicacion)?.sNombre || '';
            valorB = filtroUbicacion.aFiltros.find(u => u.nIdUbicacion === b.nIdUbicacion)?.sNombre || '';
          }
          break;

        case 'aYears':
          const filtroYear = filteredDataFiltros.find(f => f.sNombreCorto === 'aYears');
          if (filtroYear) {
            valorA = filtroYear.aFiltros.find(y => y.nIdYear === a.nIdYear)?.sNombre || 0;
            valorB = filtroYear.aFiltros.find(y => y.nIdYear === b.nIdYear)?.sNombre || 0;
            return direccionActual === 'ascendente' ? valorA - valorB : valorB - valorA; // Comparación numérica directa
          }
          break;
        case 'aColores':
          const filtroColor = filteredDataFiltros.find(f => f.sNombreCorto === 'aColores');
          if (filtroColor) {
            valorA = filtroColor.aFiltros.find(c => c.nIdColor === a.nIdColor)?.sNombre || '';
            valorB = filtroColor.aFiltros.find(c => c.nIdColor === b.nIdColor)?.sNombre || '';
          }
          break;

        case 'aStatusUnidad':
          const filtroStatus = filteredDataFiltros.find(f => f.sNombreCorto === 'aStatusUnidad');
          if (filtroStatus) {
            valorA = filtroStatus.aFiltros.find(s => s.nIdStatus === a.nIdStatus)?.sNombre || '';
            valorB = filtroStatus.aFiltros.find(s => s.nIdStatus === b.nIdStatus)?.sNombre || '';
          }
          break;

        default:
          return 0;
      }
      const resultadoComparacion = valorA.localeCompare(valorB);
      return direccionActual === 'ascendente' ? resultadoComparacion : -resultadoComparacion;
    });

    setFilteredDataUnidades(ordenado);
    setOrdenamiento({ criterio: sCriterio, direccion: direccionActual });
    setUltimoCriterio(sCriterio); // Actualiza el último criterio seleccionado
  };

  /**
   * Genera una lista de elementos de 'filteredDataFiltros' que tienen activada la opción de ordenamiento (bOrdenamiento === 1).
   * Este fragmento de código crea un nuevo array 'listaOrdenamiento' que contiene objetos con los campos 'sNombre' y 'sNombreCorto'
   * de aquellos elementos de 'filteredDataFiltros' que tienen habilitado el ordenamiento.
   * @author ERL 2023-08-08 02:06 pm
   * @returns {array} 
   */
  const listaOrdenamiento = filteredDataFiltros
    .filter(item => item.bOrdenamiento === 1)
    .map(item => ({
      sNombre: item.sNombre,
      sNombreCorto: item.sNombreCorto,
    }));

  /**
  * Función para limpiar todos los filtros
  * @author ERL 2023-08-08 03:33 pm
  * @returns {void} 
  */
  const limpiarFiltros = () => {
    setFiltrosAplicados({
      nIdMarca: [],
      nIdModelo: [],
      nIdUbicacion: [],
      nIdYear: [],
      nIdColor: [],
      nIdCombustible: [],
      nIdStatus: [],
    });
  };

  /**
  * Función para abrir y cerrar modal 
  * @author ERL 2023-08-09 09:12 am
  * @returns {void} 
  */
  const handleClickOpen = () => {
    setOpen(true);
  };


  /**
* Función asíncrona que obtiene datos de la unidad seleccionada a través de una llamada a la API.    
  * Adicionalmente, se muestran mensajes de error pertinentes con base en la respuesta de la API o
  * en el caso de que haya un error al realizar la solicitud.
  * @author ERL 2023-08-10 12:32 pm   
  * @pama {int} nIdActivo - Id de la unidad a visualizar 
  * @returns {void}
  */
  const obtenerDatosDetalles = async (nIdActio) => {
    try {
      const response = await axios.get(`${urlsAPIs.urlUnidades}/${nIdActio}`);
      const { sMensaje, nCodigo, data: dataUnidadDetalles } = response.data;
      if (nCodigo === 0) {
        const { aCarrusel, oImperfecciones, oInfoUnidad, o360, aCaracteristicas, listado360 } = dataUnidadDetalles;
        setUnidadCarrusel(aCarrusel)
        setUnidadInfoBasica(oInfoUnidad)
        setUnidadImperfeciones(oImperfecciones)
        setUnidad360(o360)
        const parseList360 = JSON.parse(listado360)
        parseList360.sort((a, b) => {
          const matchA = a.sURL.match(/_(\d+)\.jpg$/);
          const matchB = b.sURL.match(/_(\d+)\.jpg$/);

          const numA = matchA ? parseInt(matchA[1], 10) : 0;
          const numB = matchB ? parseInt(matchB[1], 10) : 0;

          return numA - numB;
        });
        setUnidad360v2(parseList360);
        setUnidadCaracteristicas(aCaracteristicas)

      } else {
        
        toast.error(sMensaje)
      }
    }
    catch (error) {
      toast.error(globalMessage.errorServidor)
    } finally {
      setLoading(false)
    }
  };

  return {
    filteredDataUnidades,
    filteredDataFiltros,
    handleFilterSelect,
    filtrosAplicados,
    currentUnidades,
    handlePageChange,
    totalUnidades: filteredDataUnidades.length,
    itemsPerPage,
    handleOrdenamiento,
    listaOrdenamiento,
    mostrarFiltros,
    setMostrarFiltros,
    limpiarFiltros,
    open,
    setOpen,
    handleClickOpen,
    obtenerDatos,
    obtenerDatosDetalles,
    aUnidadCarrusel,
    aUidadInfoBasica,
    aUnidadImperfeciones,
    loading,
    aUnidad360,
    aCaracteristicas,
    aUnidad360v2
  };
}
