import { Grid } from '@mui/material'
import React from 'react'
import { LANG } from '../LANG'
import CollapsibleMenu from '../../collapsible/CollapsibleMenu'
import { useStyles } from '../../../hooks/stylesMUI/useStyles'

/**
 * Componente de ordenamiento para las unidades. Muestra el número total de resultados y proporciona una opción
 * para ordenar esos resultados utilizando un menú desplegable.
 * @author ERL 2023-08-08 03:49 pm
 * @param {number} nResultados - Número total de resultados.
 * @param {Array} aListaMenu - Lista de opciones para el menú de ordenamiento.
 * @param {Function} handleOrdenamiento - Función que maneja el criterio de ordenamiento seleccionado.
 * @returns {JSX.Element}
 */
const UnidadOrdenamiento = ({ nResultados, aListaMenu, handleOrdenamiento }) => {
  const {StylesUnidades} = useStyles()
  return (
    <>
      <Grid item
        xl={3}
        lg={3}
        md={3}
        sm={6}
        xs={12}

        sx={StylesUnidades['resultados-container']}
      >
        {nResultados} {LANG.labelResultados}
      </Grid>
      <Grid item
        xl={6}
        lg={6}
        md={5}
        sm={6}
        xs={12}
        
        sx={{
          display: 'flex',
          justifyContent: {
            xl: 'end',
            lg: 'end',
            md: 'end',
            sm: 'center',
            xs: 'center',
          },
          alignItems: 'center'
        }}
      >
        {LANG.labelOrdenar}:
        <CollapsibleMenu aListaMenu={aListaMenu} handleOrdenamiento={handleOrdenamiento} />
      </Grid>
    </>
  )
}

export default UnidadOrdenamiento
