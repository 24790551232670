import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Tooltip from '@mui/material/Tooltip';
import '../../../styles/registro/prospectos/progreso.scss'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import StepContent from '@mui/material/StepContent';
import { Grid } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import GroupIcon from '@mui/icons-material/Group';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import PaidIcon from '@mui/icons-material/Paid';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';

import Chip from '@mui/material/Chip';
import { LANG } from '../LANG';
import useDocumentos from '../../../hooks/prospectosApi/pasos/useDocumentos';
import { useContext } from 'react';
import { ProgressBarContext } from '../../../context/ProgressBarContext';
import PagoCompletado from './pasos/pago/PagoCompletado';


/**
 * Componente estilizado para visualizar el ícono de cada paso en un componente tipo Stepper.
 * Establece las propiedades de color, display, altura, alineación y estados específicos
 * para los estados activo y completado.
 * @author ERL 2023-07-17 01:40 pm 
 * @param {object} theme - Objeto que contiene el tema actual del diseño
 * @param {object} ownerState - Objeto que contiene el estado del componente
 * @returns {JSX.Element} 
 */
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '80%',
    backgroundColor: 'currentColor',
  },
}));
/**
 * Componente para visualizar el icono de cada paso en un componente de tipo Stepper
 * @author ERL 2023-07-17 01:53 pm 
 * @param {object} props - Objeto que contiene las propiedades del componente
 * @param {boolean} props.active - Indica si el paso actual está activo
 * @param {boolean} props.completed - Indica si el paso ha sido completado
 * @param {string} props.className - Nombre de la clase CSS para personalizar el estilo del icono
 * @returns {JSX.Element}
 */
function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

/**
 * Componente estilizado para la conexión entre pasos en un componente tipo Stepper.
 * Aplica estilos condicionales basados en varios estados: alternativa, activa, completada.
 * También establece estilos basados en el modo de color del tema y en el tamaño de la pantalla.
 * @author ERL 2023-07-19 10:55 am
 * @param {object} theme - Objeto que contiene el tema actual del diseño
 * @returns {JSX.Element} 
 */
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginLeft: '25px',
  },
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(72, 62, 107) 0%,rgb(119, 64, 163) 80%,rgb(72, 62, 107) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(72, 62, 107) 0%,rgb(119, 64, 163) 80%,rgb(72, 62, 107) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'rgba(72, 62, 107, 0.6)',
    borderRadius: 1,
    [theme.breakpoints.down('md')]: {
      width: '2px',
    },
  },
}));

/**
 * Componente estilizado para el icono de cada paso en un componente tipo Stepper.
 * Establece estilos condicionales basados en los estados del paso: error, advertencia, completado y activo.
 * Utiliza gradientes lineales específicos para cada estado.
 * @author ERL 2023-07-19 10:57 am 
 * @param {object} theme - Objeto que contiene el tema actual del diseño
 * @param {object} ownerState - Objeto que contiene el estado del componente
 * @returns {object} - Objeto con los estilos del componente
 */

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => {
  const { completed, active, error, warning } = ownerState;

  let backgroundImage = '';

  if (error) {
    backgroundImage =
      'linear-gradient( 136deg, rgb(255, 0, 0) 0%, rgb(255, 120, 120) 80%, rgb(255, 0, 0) 100%)';
  } else if (warning) {
    backgroundImage =
      'linear-gradient( 136deg, rgb(255, 165, 0) 0%, rgb(255, 200, 120) 80%, rgb(255, 165, 0) 100%)';
  } else if (completed) {
    backgroundImage =
      'linear-gradient( 136deg, #2e7d32 0%, #48be4e 80%, #2e7d32 100%)';
  } else {
    backgroundImage = 'linear-gradient( 136deg, rgb(72, 62, 107) 0%, rgb(119, 64, 163) 80%, rgb(72, 62, 107) 100%)';
    //   backgroundImage:
    //   'linear-gradient( 136deg, rgb(72, 62, 107) 0%, rgb(119, 64, 163) 80%, rgb(72, 62, 107) 100%)',
    // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }

  return {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '80%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(active && {
      backgroundImage,
      boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.25)',
    }),
    ...(completed && {
      backgroundImage,
    }),
  };
});


/**
 * @author ERL 2023-07-17 01:55 pm 
 * Componente del Progreso del Registro
 * @returns {JSX.Element} - Retorna el componente del progreso del registro
 */
const ProgresoComponente = () => {
  const nIdUsuario = sessionStorage.getItem('u');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const jwt = sessionStorage.getItem("t");
  const {
    activeStep,
    setActiveStep,
    setToastPosition,
    headers,
    bIngresosAdicionales,
    activeStepDB,
    getStepsFromAPI,
    pasosDB,
    stepComponents,
    stepLabels,
    activoLabel,
    setActivoLabel,
    bSinIngresoAdicional,
    bIngresoAdicional

  } = useContext(ProgressBarContext);

  const status = "";
  const sActivo = "";
  const { openSocket } = useDocumentos({ getStepsFromAPI, status, sActivo, headers });
  /**
   * useEffect - Esta función se encarga de inicializar el socket al montar el componente.
   * Está diseñada para ejecutarse una sola vez al inicio, dada la dependencia de arreglo vacío.
   *
   * @author ERL 2023-09-06 11:13 pm
   * @returns {void}
   */
  useEffect(() => {
    setToastPosition("top-center")
    openSocket();

  }, [])


  /**
 * Componente para visualizar el icono de cada paso en un componente de tipo Stepper
 * @author ERL 2023-07-17 12:40 pm 
 * @param {boolean} active - Indica si el paso actual está activo
 * @param {boolean} completed - Indica si el paso ha sido completado
 * @param {boolean} error - Indica si ha ocurrido un error en el paso
 * @param {boolean} warning - Indica si hay una advertencia para el paso
 * @param {number} step - El número del paso actual
 * @param {string} className - Nombre de la clase CSS para personalizar el estilo del icono
 * @returns {JSX.Element}
 */
  function ColorlibStepIcon(props) {
    const { active, completed, error, warning, step, className } = props;
    /**
   * Función para cambiar a la etapa del progress bar
   * @author ERL 2023-07-26 03:20 pm 
   * @param {int} nEtapa - Numero de la etapa 
   * @returns {JSX.Element}
   */
    const cambiarEtapa = (nEtapa) => {
      setActiveStep(nEtapa);
      setActivoLabel(pasosDB[nEtapa].sNombre)
    }
    const icons = {
      1: <FormatAlignJustifyIcon />,
      2: <UploadFileIcon />,
      3: <VideoLabelIcon />,
      4: <GroupIcon />,
      5: <VideoCameraFrontIcon />,
      6: <PaidIcon />,
    };

    let icon;

    if (error) {
      icon = <ErrorIcon sx={{ color: '#fff' }} />;
    } else if (warning) {
      icon = <WarningIcon sx={{ color: '#fff' }} />;
    } else if (completed) {
      icon = <CheckCircleIcon sx={{ color: '#fff' }} />;
    } else {
      icon = icons[step + 1];
    }


    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active, error, warning }}
        className={className} onClick={warning || active ? () => cambiarEtapa(step) : () => { return; }}
        style={{ cursor: `${warning || active ? "pointer" : ""}` }}
      >
        {icon}
      </ColorlibStepIconRoot>
    );
  }


  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  const navigate = useNavigate();

  const nIdStatus = Number(sessionStorage.getItem('s'));


  /**
   * Efecto que se ejecuta cuando el nIdStatus cambia.
   * Si el nIdStatus es igual a 8, el usuario será redirigido a la página de inicio de sesión.
   * @author ERL 2023-08-04 12:40 pm
   * @param {string} nIdStatus - Id del status del usuario
   
   */
  useEffect(() => {
    if (nIdStatus) {
      if (nIdStatus === 8) {
        navigate("/");
      }
    }
  }, [nIdStatus])
  /**
   * Efecto que se ejecuta cuando el token JWT cambia.
   * Si el token JWT está vacío o no existe, el usuario será redirigido a la página de inicio de sesión.
   * @author ERL 2023-07-17 12:40 pm
   * @param {string} jwt - El token JWT del usuario   
   */
  useEffect(() => {

    if (jwt) {
      if (jwt === '' || jwt.length === 0) {
        navigate("/login");
      } else {
        const id = sessionStorage.getItem('u');
      }
    } else {
      navigate("/login");
    }
  }, [jwt])

  /**
 * Esta función comprueba si el status de un paso es 2 después de que se haya completado el paso anterior y haya fallado algo en el proceso.
 * @author ERL 2023-07-17 02:56 pm
 * @param {number} step - El paso que se va a comprobar.
 * @returns {boolean} - Devuelve 'true' si el status del paso actual es igual a 2, de lo contrario, devuelve 'false'.
 */
  const isStepFailedAfterComplete = (step) => {
    return step === 2;
  };

  /**
 * Efecto secundario React (`useEffect`) que se ejecuta inmediatamente después de que el componente se monte (es decir, después del primer renderizado).
 * Este efecto invoca la función `getStepsFromAPI` que realiza una solicitud a la API para obtener los pasos y configurar los componentes respectivos.
 * @author ERL 2023-07-19 02:45 pm
 */
  useEffect(() => {
    getStepsFromAPI();
  }, []);

  /**
 * Función que verifica si el estado del paso es "pendiente" después de estar "completo".
 * Considera que el paso está en este estado si el valor de sStatus es igual a 1.
 * @author ERL 2023-07-17 03:07 pm
 * @param {number} sStatus - El estado actual del paso
 * @returns {boolean} - Verdadero si el paso está "pendiente" después de estar "completo", falso en caso contrario
 */
  const isStepPendienteAfterComplete = (sStatus) => {
    return sStatus === 1;
  };

  /**
   * Función que genera las propiedades del paso en función del estado proporcionado.
   * Si el paso ha fallado después de estar completo, la propiedad error se establece en verdadero.
   * Si el paso está pendiente después de estar completo, la propiedad warning se establece en verdadero.
   * @author ERL 2023-07-17 03:10 pm
   * @param {string} sStatus - El estado actual del paso
   * @returns {Object} - Objeto con las propiedades del paso
   */
  const getStepProps = (sStatus) => {
    const labelProps = {};
    if (isStepFailedAfterComplete(sStatus)) {
      labelProps.error = true;
    } else if (isStepPendienteAfterComplete(sStatus)) {
      labelProps.StepIconProps = { warning: true };
    }
    return labelProps;
  };
  const Styles = {
    "box": {
      padding: {
        xl: '16px 30px',
      },
      margin: {
        xl: '29px auto 0 auto',
        md: '29px auto 0 auto',
        sm: '29px auto 0 auto',

      },
      width: '100%',
      maxWidth: {
        xl: '800px',
        md: '800px',
        sm: '600px',
        xs: '100%',
      },

      borderRadius: '20px',
      backgroundColor: '#ffffff',
      height: 'auto !important',
      marginTop: '0',
      paddingTop: '0'
    },
    "stepper": {
      padding: '16px 30px',
      margin: '29px auto 0 auto',
      width: '100%',
      maxWidth: {
        xl: '708px',
        sm: '100%',
        xs: '100%',
      },
      borderRadius: '20px',
      backgroundColor: '#ffffff',
      height: 'auto',
      marginTop: '0',
      paddingTop: '0',
      boxSizing: 'border-box',
    },
    "box-container-components": {
      minHeight: {
        xl: '600px',
        sm: '100%',

      },
      mt: "30px",
    }
  }
  const sStatusProceso = "Estatus del proceso";
  // Renderizando el componente
  const procesoCompletado = activeStep === 10;
  
  return (
    <Box sx={Styles["box"]}>
      <Grid>
        <div className='unidades-container-title'>
          <p>
            {`${sStatusProceso}:`}
            <span style={{ textAlign: 'center', fontWeight: "lighter", textTransform: 'capitalize' }}>
              {activoLabel}
            </span>
          </p>
          <hr />
        </div>


        <Stepper activeStep={activeStep} orientation={isSmallScreen ? 'vertical' : 'horizontal'}
          sx={Styles["stepper"]}
          alternativeLabel={isSmallScreen ? false : true}
          connector={isSmallScreen ? <ColorlibConnector /> : <ColorlibConnector />}
        >
          {stepLabels.map((label, index) => (

            <Step key={index}>
              <StepLabel
                StepIconComponent={(props) => (
                  <>                  
                    {(isStepFailedAfterComplete(label.status) || isStepPendienteAfterComplete(label.status) || ((index > 0 && label.nIdPaso === 3) && (Boolean(bIngresosAdicionales) && bSinIngresoAdicional === false))) ? (
                      <Tooltip
                        title={label.message}
                        arrow
                      >
                        <div style={{
                          position: 'relative',
                          zIndex: 1
                        }}>
                          <ColorlibStepIcon
                            {...props}
                            completed={true}
                            active={(activoLabel !== 'visita' && nIdStatus !== "") ? index === activeStep : stepLabels.length}
                            error={isStepFailedAfterComplete(label.status)}
                            warning={isStepPendienteAfterComplete(label.status) || (( index > 0 && label.nIdPaso === 3) && ((Boolean(bIngresosAdicionales) && bSinIngresoAdicional === false && bIngresoAdicional === false)))}
                            step={index}
                            statusStep={label.status}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={label.desc}
                        arrow
                      >

                        <div style={{
                          position: 'relative',
                          zIndex: 1
                        }}>

                          <ColorlibStepIcon
                            {...props}
                            completed={(activoLabel !== 'visita' && nIdStatus !== "") ? index < activeStepDB : index < stepLabels.length - 1}
                            active={(activoLabel !== 'visita' && nIdStatus !== "") ? index === activeStepDB : false}
                            error={isStepFailedAfterComplete(label.status)}
                            warning={isStepPendienteAfterComplete(label.status) || (label.nIdPaso === 3 && (Boolean(bIngresosAdicionales) && bSinIngresoAdicional === false))}
                            step={index}
                            statusStep={label.status}

                          />
                        </div>
                      </Tooltip>
                    )}
                  </>
                )}
                {...getStepProps(label.status)}
              >
                {(isSmallScreen
                ) && <span style={{ textAlign: 'center', fontWeight: "lighter", textTransform: 'capitalize' }}> {label.desc} </span>}
              </StepLabel>
              {
                isSmallScreen && index === activeStep && <StepContent sx={{
                  border: '0 !important',
                  padding: '0',
                  margin: {
                    sm: '0 auto'
                  },

                }}>
                  {stepComponents[activeStep]}
                </StepContent>
              }

            </Step>
          ))}
        </Stepper>
      </Grid>
      <div>


        {(['visita', 'validado'].includes(activoLabel) && [12, 9].includes(activeStep)) &&

          <Grid textAlign={"center"}
            sx={{
              pb: {
                xs: '15px'
              },
            }}
          ><Chip
              label={LANG.labelValidando}
              color="warning"
              sx={{
                height: {
                  xl: '32px',
                  md: '32px',
                  xs: 'initial',
                  sm: 'auto'
                },
                margin: '0 25px',
                '& .MuiChip-label': {
                  xs: {
                    display: 'initial',
                    whiteSpace: 'initial',
                    padding: '10px',

                  },
                  sm: {
                    display: 'block',
                    whiteSpace: 'normal',

                  }
                },
              }}
            />

          </Grid>
        }
        {!isSmallScreen ?
          <Box sx={Styles["box-container-components"]}>
            {
              procesoCompletado ? <PagoCompletado /> : stepComponents[activeStep]
            }
          </Box>
          :
          procesoCompletado && <Grid textAlign={"center"}><PagoCompletado /></Grid>
        }

      </div>
    </Box>
  );
};

export default ProgresoComponente;




