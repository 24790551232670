import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';

/**
 * Componente que muestra una lista de botones de colores que el usuario puede seleccionar.
 * Al seleccionar un color, se actualiza el estado local y se envía el ID del color al componente padre
 * a través de la función `handleFilterSelect`.
 * @author ERL 2023-08-08 08:59 am
 * @param {object} props Propiedades pasadas al componente.
 * @field {array} aColores Array de objetos que representan cada color con su `nIdColor` y `sNombre`.
 * @field {function} handleFilterSelect Función que maneja la selección de un color.
 * @field {array} selectedColors Array de colores seleccionados previamente.
 * @returns {JSX.Element} 
 */
export const UnidadFiltroColor = ({ aColores, handleFilterSelect, selectedColors: initialSelectedColors }) => {
  const [selectedColors, setSelectedColors] = useState(initialSelectedColors || []);

  /**
   * Efecto que se ejecuta cuando cambia el valor de 'initialSelectedColors'.
   * Se encarga de actualizar el estado 'setSelectedColors' con el valor de 'initialSelectedColors'.
   * Si no se proporciona un 'initialSelectedColors', se establece un array vacío por defecto.
   * @author ERL 2023-08-08 03:46 pm
   */
  useEffect(() => {
    setSelectedColors(initialSelectedColors || []);
  }, [initialSelectedColors]);

  /**
   * Función para manejar la selección de un color.
   * Si el color ya está seleccionado, lo deselecciona.
   * De lo contrario, lo agrega a la lista de colores seleccionados.
   * Después de actualizar el estado, envía la lista actualizada al componente padre.
   * @author ERL 2023-08-08 09:01 am
   * @param {object} colorObj Objeto que representa el color seleccionado con su `nIdColor` y `sNombre`.
   * @returns {void}
   */
  const seleccionarColor = (colorObj) => {
    let updatedSelectedColors;
    if (selectedColors.includes(colorObj.nIdColor)) {
      updatedSelectedColors = selectedColors.filter(id => id !== colorObj.nIdColor);
    } else {
      updatedSelectedColors = [...selectedColors, colorObj.nIdColor];
    }
    setSelectedColors(updatedSelectedColors);
    handleFilterSelect && handleFilterSelect('nIdColor', updatedSelectedColors);
  };

  return (
    <Box display="flex" flexWrap="wrap" gap={1} padding={1}>
      {aColores.map(colorObj => (
        <Button
          key={colorObj.nIdColor}
          onClick={() => seleccionarColor(colorObj)}
          variant={selectedColors.includes(colorObj.nIdColor) ? "contained" : "outlined"}
          color="primary"
        >
          {colorObj.sNombre}
        </Button>
      ))}
    </Box>
  );
}

export default UnidadFiltroColor;
