import { Grid, Typography } from "@mui/material"
import { StyledButton2 } from "../../customButtons/StyledButton"
import SmallCircularLoader from "../../loader/SmallCircularLoader"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
/**
 * Componente que muestra un paso completado en un Stepper.
 * Incluye un mensaje de éxito, una descripción opcional y un botón opcional de "Siguiente".
 * Mientras se carga, el botón de "Siguiente" se deshabilita y muestra un pequeño círculo de carga.
 * @author ERL 2023-07-19 11:15 am  
 * @param {boolean} bLoading - Indica si el paso está en proceso de carga
 * @param {string} sMensaje - Mensaje a mostrar cuando el paso se ha completado
 * @param {boolean} bButton - Indica si se debe mostrar el botón de "Siguiente"
 * @param {string} sDesc - Descripción opcional a mostrar después del mensaje 
 * @returns {JSX.Element} 
 */

const CompletedStep = ({ bLoading, sMensaje, bButton, sDesc }) => {
  const Styles = {
    "grid": {
      my: 2,
      ml: 'auto',
      mr: 'auto',
      p: 0,
      width: '100%',
      maxWidth: {
        xl: '460px',
      },
      textAlign: 'center',
      display: 'flex',      
      justifyContent: 'center',
    },
    "h6": {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: "5px"
    },
    "check-icon": {
      color: "green",
      fontSize: {
        xl:'180px',
        lg:'160px',
        md:'140px',
        sm:'120px',
        xs:'100px',
      }
    }
  }
  return (
    <Grid container sx={Styles["grid"]}>
      <Typography variant="h6" sx={Styles["h6"]}>
        <CheckCircleIcon sx={Styles['check-icon']} />
        {sMensaje}
        {sDesc && <h2>{sDesc}</h2>}
      </Typography>
      {bButton && <StyledButton2 width="100%" disabled={bLoading === true}>
        {bLoading ? <SmallCircularLoader /> : "Siguiente"}
      </StyledButton2>}

    </Grid>
  )
}

export default CompletedStep
