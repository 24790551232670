import React, { useContext, useEffect } from 'react'
import { useUnidades } from '../../hooks/unidades/useUnidades'
import { Button, Grid, } from '@mui/material';
import Unidad from './Unidad';
import UnidadesFiltrosHeader from './filtros/UnidadesFiltrosHeader';
import UnidadesFiltros from './filtros/UnidadesFiltros';
import { useStyles } from '../../hooks/stylesMUI/useStyles';
import UnidadesPaginacion from './UnidadesPaginacion';
import ModalMUI from '../modal/ModalMUI';
import { LANG } from './LANG';
import CircularLoader from '../loader/CircularLoader';
import { ProgressBarContext } from '../../context/ProgressBarContext';

/**
 * Componente que muestra un listado de unidades y sus filtros correspondientes.
 * Utiliza el hook personalizado 'useUnidades' para obtener y gestionar los datos y comportamientos de las unidades y los filtros.
 * @author ERL 2023-08-07 02:49 pm
 * @returns {JSX.Element}
 */
const UnidadesListado = () => {
    const {
        filteredDataFiltros,
        handleFilterSelect,
        filtrosAplicados,
        currentUnidades,
        totalUnidades,
        itemsPerPage,
        currentPage,
        handlePageChange,
        handleOrdenamiento,
        listaOrdenamiento,
        mostrarFiltros,
        setMostrarFiltros,
        limpiarFiltros,
        handleClickOpen,
        open,
        setOpen,
        obtenerDatos,
        loading

    } = useUnidades();
    const {setToastPosition} = useContext(ProgressBarContext)
    
    /**
   * Efecto para llamar la funcion obtenerDatos cuando se renderiza el componente 
   * @author ERL 2023-08-07 02:37 pm   
   * @returns {void}
   */
    useEffect(() => {
        setToastPosition("top-right")
        obtenerDatos();
    }, []);

    const { StylesUnidades } = useStyles();
    return (
        loading ? <CircularLoader nSize={133} /> :
            <Grid container
                sx={StylesUnidades['unidades-container']}>
                <ModalMUI
                    oOpen={open}
                    setOpen={setOpen}
                    button={
                        <Button onClick={limpiarFiltros} color='inherit'>
                            {LANG.labelLimpiar}
                        </Button>
                    }>
                    <UnidadesFiltros oStyles={StylesUnidades['sticky-sidebar']} aFiltros={filteredDataFiltros} handleFilterSelect={handleFilterSelect} filtrosAplicados={filtrosAplicados} />
                </ModalMUI>
                <Grid container xs={12} sx={StylesUnidades['sticky-header']}>
                    <Grid
                        item
                        xs={12}
                        sx={StylesUnidades['btn-modal']}>
                        <Button variant="outlined" onClick={handleClickOpen}>
                            {LANG.labelFiltros}
                        </Button>
                    </Grid>
                    <UnidadesFiltrosHeader
                        nResultados={totalUnidades}
                        aListaMenu={listaOrdenamiento}
                        handleOrdenamiento={handleOrdenamiento}
                        setMostrarFiltros={setMostrarFiltros}
                        limpiarFiltros={limpiarFiltros}
                        bMostrarFiltros={mostrarFiltros}
                    />
                </Grid>
                {
                    mostrarFiltros &&
                    <Grid item xs={3}
                        sx={StylesUnidades['sidebar']}>
                        <UnidadesFiltros oStyles={StylesUnidades['sticky-sidebar']} aFiltros={filteredDataFiltros} handleFilterSelect={handleFilterSelect} filtrosAplicados={filtrosAplicados} />
                    </Grid>
                }
                <Grid
                    xl={mostrarFiltros ? 9 : 12}
                    lg={mostrarFiltros ? 9 : 12}
                    md={mostrarFiltros ? 9 : 12}
                    sm={12}
                    item
                    sx={{
                        ...StylesUnidades['unidades'],
                        justifyContent: {
                            xl: `${totalUnidades > 3 ? 'center' : mostrarFiltros ? 'start' : 'center'}`,
                            md: `${totalUnidades >= 3 ? 'center' : mostrarFiltros ? 'start' : 'center'}`,
                            sm: `${totalUnidades >= 3 ? 'center' : mostrarFiltros ? 'start' : 'center'}`,
                            xs: `${totalUnidades >= 3 ? 'center' : ''}`,
                        },
                    }}>
                    {
                        currentUnidades.map((unidad, index) => {
                            return (
                                <Grid key={index}>
                                    <Unidad
                                        nId={unidad.nIdActivo}
                                        sURL={unidad.sURL}
                                        sDescripcion={unidad.sDescripcion}
                                        nPrecio={unidad.nPrecio}
                                        sStatatus={unidad.sStatatus}
                                    />
                                </Grid>
                            );
                        })
                    }

                    <Grid
                        item xs={12}
                        mt={1} display="flex" justifyContent="center">
                        <UnidadesPaginacion
                            onChange={handlePageChange}
                            nContador={Math.ceil(totalUnidades / itemsPerPage)}
                            nPaginaActual={currentPage}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        position: "fixed",
                        bottom: "9px",
                        display: "flex",
                        justifyContent: "center",
                        width: "1420px",
                        color: "#a0aec0",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <a
                        href='/privacidad'
                        target='_blank'
                        className='no-link'
                    >
                        * Consulta términos y condiciones

                    </a>


                    * Precios sujetos a cambios sin previo aviso
                </Grid>
            </Grid>
    )
}

export default UnidadesListado
