import "./visitanos.scss";
import * as React from 'react';
import { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import atencion from "./img/atncliente.png";
import gnv from "./img/gnvtaller.png";
import siniestros from "./img/siniestro.png";
import taller from "./img/taller.png";
import ubicacion from "./img/ubicacion.png";
import emergencias from "./img/emergencias.png";
import sucursal from "./img/form-pc.png";
import ventas from "./img/venta.png";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ContactForm from "../contact/ContactForm";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  display: 'flex',
  shadows: ["none"]
}));

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  shadows: ["none"]
}));



const Visitanos = (props) => {


  const form = useRef();
  const [done, setDone] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fst7gdm', 'template_oblxilh', form.current, 'QQvoOXLmHkR29h36W')
      .then(
        (result) => {
          console.log(result.text);
          setDone(true)
        },
        (error) => {
          console.log(error.text);
        });
    form.current.reset()
  };

  return (


    <div className="c-visit" id="contacto">
      <div className="c-wrapper-visitanos">
        <Box sx={{ flexGrow: 1, }}>
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={5}>
              <Item style={{ boxShadow: "none" }}>
                <div className="c-left-visit">
                  <h1 className="o-title-visit"><strong>Visítanos en nuestras sedes</strong></h1>
                  <h2 className="o-title2-visit">Ven a ver un auto o a realizar una inspección</h2>
                  <div className="detalles-form-visit">
                    <img src={ubicacion} alt="" className="imgMini" /> <p className="texto-form">&nbsp; Sucursal en Nuevo León &nbsp; </p>
                    <br />
                    <p>Av. Churubusco #3900, Industrial Benito Juarez, C.P. 64517, Monterrey, Nuevo León, México</p>
                    <br />
                    <Grid sx={{margin:'0 6%'}}>

                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14379.757259752416!2d-100.2687387!3d25.7064332!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bfca945925d9%3A0xe97cb450d17a2f95!2sBESTA Arrendadora!5e0!3m2!1ses!2smx!4v1706802392783!5m2!1ses!2smx"
                        width="400"
                        height="300"
                        style={{ border: '0' }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Grid>

                    <br />
                    <img src={ubicacion} alt="" className="imgMini" /> <p className="texto-form">&nbsp; Sucursal en Jalisco &nbsp;</p> <br />
                    <p>C. 4 2425, Colón Industrial, 44940 Guadalajara, Jalisco.</p>
                    <br />
                    <Grid sx={{margin:'0 6%'}}>

                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14934.608653199486!2d-103.3672926!3d20.6430302!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428af9d61e5c8cf%3A0xb980af76f94a4290!2sBESTA Arrendadora | Guadalajara!5e0!3m2!1ses!2smx!4v1706802728214!5m2!1ses!2smx"
                        width="400"
                        height="300"
                        style={{ border: '0' }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Grid>
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item style={{ boxShadow: "none" }}>
                <img src={sucursal} alt="" className="imgCenter" />
              </Item>

            </Grid>
            <Grid item xs={4}>

              <ContactForm />

            </Grid>
            <Grid item xs={12}>
              <Item style={{ boxShadow: "none" }}>
                <h1>Horarios</h1>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item style={{ boxShadow: "none" }}><img src={ventas} alt="" className="imgServicios" align="left" /><div className="cuadro-texto"><p className="texto-servicios"><strong> Ventas</strong></p>
                <p>L-V de 8:00 a.m a 5:00 p.m.</p>
                <p>81 2104 3804</p></div></Item>
            </Grid>
            <Grid item xs={4}>
              <Item style={{ boxShadow: "none" }}><img src={taller} alt="" className="imgServicios" align="left" /><div className="cuadro-texto"><p className="texto-servicios"><strong> Taller Besta</strong></p>
                <p>L-V de 8:00 a.m a 5:00 p.m.</p>
                <p>Sabados  9:00 am.m a 01:00 p.m.</p></div></Item>
            </Grid>
            <Grid item xs={4}>
              <Item style={{ boxShadow: "none" }}><img src={gnv} alt="" className="imgServicios2" align="left" /><div className="cuadro-texto"><p className="texto-servicios"><strong> Taller GNV</strong></p>
                <p>L-V de 9:00 a.m a 6:00 p.m.</p>
                <p>81 2074 6295</p></div></Item>
            </Grid>
            <Grid item xs={4}>
              <Item style={{ boxShadow: "none" }}><img src={siniestros} alt="" className="imgServicios" align="left" /><div className="cuadro-texto"><p className="texto-servicios"><strong> Siniestros</strong></p>
                <p>Toda la semana 24/7</p>
                <p>81 2108 5695</p></div></Item>
            </Grid>
            <Grid item xs={4}>
              <Item style={{ boxShadow: "none" }}><img src={emergencias} alt="" className="imgServicios" align="left" /><div className="cuadro-texto"><p className="texto-servicios"><strong> Emergencias</strong></p>
                <p>Toda la semana 24/7</p>
                <p>81 2168 5695</p></div></Item>
            </Grid>
            <Grid item xs={4}>
              <Item style={{ boxShadow: "none" }}><img src={atencion} alt="" className="imgServicios" align="left" /><div className="cuadro-texto"><p className="texto-servicios"><strong> Atención al Cliente</strong></p>
                <p>Lunes a Viernes de 8:00 a.m a 5:00 p.m.</p>
                <p>Sabados  9:00 am.m a 01:00 p.m.</p>
                <p>81 3471 5233</p></div></Item>
            </Grid>
            <Grid item xs={2}>
              <Item2 style={{ boxShadow: "none" }}></Item2>
            </Grid>
            <Grid item xs={2}>
              <Item2 style={{ boxShadow: "none" }}></Item2>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>

  );
};

export default Visitanos;