import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { LANG } from './LANG'
import { ProgressBarContext } from '../../../../../context/ProgressBarContext'
import { useState } from 'react'
import { globalMessage } from '../../../../../helpers/globalMessage'

/**
 * DocumentosIngresoAdicional - Componente React para mostrar y seleccionar si el prospecto desea agregar ingresos adicionales
 * Se basa en una lista de radios donde cada radio representa la opcion del usuario. 
 * Si hay algún documento con un 'nIdDocumento' mayor que 0, se determina el flujo correspondiente y se establece el tipo de documento.
 * @author ERL 2023-09-06 05:34 pm
 * @param {props} - Propiedades del componente
 * @field {array} aRadios - Lista de documentos. Cada documento tiene un 'sIdFlujo' que identifica el tipo y un 'sTipo' que describe el tipo.
 * @field {function} setTipoDoc - Función para establecer el tipo de documento seleccionado.
 * @field {string} sTipoDoc - Tipo de documento seleccionado actualmente.
 * @returns {JSX.Element} 
 */
const DocumentosIngresoAdicional = ({ bIngresosAdicionales, setBIngresosAdicionales, sinIngresosAdicionales}) => {
    const {
        dataDocumentos,
    } = useContext(ProgressBarContext)

    const Styles = {
        textAlign: "center",
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        padding: {
            xl: '10px 20px',
            md: '10px 20px',
            xs: '10px 10px'
        },
        borderRadius: '10px',
        width: {
            xl: "100%",
            md: "100%",
            xs: '100%',
        },
        maxWidth: {
            xl: "563px",
            md: "563px",
            xs: '100%',
        },
        mx: "auto"
    }

    /**
     * Efecto que dectecta cuando hay cambios en la variable bIngresosAdicionales y si es igual a 0 ejecuta una funciona para actualizar el status del prospecto
     * @author ERL 2023-11-17 03:08 pm
     * @returns {void} 
     */
    useEffect(() => {
        if(bIngresosAdicionales === 0){
            sinIngresosAdicionales()
        }        
    }, [bIngresosAdicionales])
    

    return (
        <Grid sx={{ ...Styles }}>
            <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">{LANG.labelIngresosAdicionales}</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{
                        justifyContent:'center'
                    }}
                >
                    <FormControlLabel                        
                        sx={{ textTransform: 'capitalize' }}
                        value={1}
                        control={<Radio
                            checked={bIngresosAdicionales === 1}
                            onChange={(e) => setBIngresosAdicionales(Number(e.target.value))}
                            
                        />}
                        label={globalMessage.labelSi}
                        labelPlacement="start"
                    />
                    <FormControlLabel
           
                        sx={{ textTransform: 'capitalize' }}
                        value={0}
                        control={<Radio
                            checked={bIngresosAdicionales === 0}
                            onChange={(e) => setBIngresosAdicionales(Number(e.target.value))}
                       
                        />}
                        label={globalMessage.labelNo}
                        labelPlacement="start"
                    />
                
                </RadioGroup>
            </FormControl>
        </Grid>
    )

}

export default DocumentosIngresoAdicional
