import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { globalMessage } from '../../helpers/globalMessage';
import { useState } from 'react';

/**
 * Transition component para el dialogo
 * @param {object} props - Propiedades del componente
 * @param {object} ref - Referencia al componente
 * @author ERL 2023-08-14 11:33 am
 * @returns {JSX.Element} 
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Componente que muestra un cuadro de diálogo de confirmación 
 * para que el usuario confirme o cancele una acción. El resultado (confirmación o rechazo)
 * se maneja como una Promesa. 
 * @author ERL 2023-08-14 11:46 am
 * @param {object} props - Propiedades del componente
 * @field {JSX.Element} children - Elementos o texto a mostrar en el cuerpo del diálogo.
 * @field {boolean} open - Controla si el cuadro de diálogo está abierto o cerrado.
 * @field {Function} setOpen - Función para establecer el estado de apertura del diálogo.
 * @field {string} sTitulo - Título del cuadro de diálogo.
 * @field {boolean} bAceptar - Controla si el boton aceptar se muestra.
 * @field {boolean} bRechazar - Controla si el boton aceptar se muestra.
 * @returns {JSX.Element} 
 */
function ModalConfirm({children, open, setOpen, sTitulo, bAceptar = true, bRechazar = true}) {
  
    const [resolveFn, setResolveFn] = useState(null);
    const [rejectFn, setRejectFn] = useState(null);
  
    
    /**
     * Método que abre un modal de confirmación y devuelve una promesa 
     * que se resolverá si el usuario confirma o se rechazará si el usuario cierra el modal o pulsa en "Rechazar".
     * @author ERL 2023-08-14 12:09 pm
     * @returns {Promise} 
     */
    ModalConfirm.askConfirmation = () => {
      return new Promise((resolve, reject) => {
        setResolveFn(() => resolve);
        setRejectFn(() => reject);
        setOpen(true);
      });
    };
  
    /**
     * handleClose - Método que maneja el cierre del modal y rechaza la promesa en caso de haber sido abierta.
     * 
     * @author ERL 2023-08-14 12:15 pm
     * @returns {void}
     */
    const handleClose = () => {
      if (rejectFn) {
        rejectFn();
      }
      setOpen(false);
      setResolveFn(null);
      setRejectFn(null);
    };
  
    
    /**
     * handleConfirm - Método que maneja la confirmación del modal y resuelve la promesa en caso de haber sido abierta.
     * 
     * @author ERL 2023-08-14 12:18 pm
     * @returns {void}
     */
    const handleConfirm = () => {
      if (resolveFn) {
        resolveFn();
        setOpen(false);
        setResolveFn(null);
        setRejectFn(null);
      }
    };
  
    return (
      <div>      
        <Dialog
          open={open}
          keepMounted
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle>{sTitulo}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {children}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {bRechazar && <Button onClick={handleClose} variant='contained' color="error">{globalMessage.labelRechazar}</Button>}
            {bAceptar && <Button onClick={handleConfirm} variant='contained' color="success">{globalMessage.labelAceptar}</Button>}            
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  
  export default ModalConfirm;
