import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GlobalStyles } from '@mui/material';

export default function CollapsibleList({ sTitulo, children }) {
    return (
        <>
            <GlobalStyles
                styles={{
                    '.MuiAccordion-root::before': {
                        backgroundColor: 'transparent !important', // Cambia a tu color deseado
                    },
                    '.MuiAccordionSummary-content.Mui-expanded': {
                        margin: '0px !important', // Quita el margen al expandir
                      },
                }}
            />
            <Accordion sx={{ background: 'transparent', boxShadow: '0' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ color: '#fff', borderBottom: '1px solid #fff' }}
                >
                    <Typography sx={{ color: '#fff' }}>{sTitulo}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ color: '#fff', borderTop: '1px solid #fff' }}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </>
    );
}
