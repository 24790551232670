import { Alert } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "sonner";

export const CustomToast = ({ type, message, toastRef }) => {
    return (
        <Alert severity={type}
            sx={{
                fontSize: '20px',
                
                maxWidth: '95vw'
              
            }}
        >
            {message}
            <button className='custom-close-btn' onClick={() => toast.dismiss(toastRef)}
            style={{
                marginTop:'20px'
            }}
            ><CloseIcon /></button>
        </Alert>
    );
}
