export const LANG = {
    labelEnvio: "Hemos reenviado el código de verificación a tu dirección de correo electrónico. Por favor, revisa tu bandeja de entrada y sigue las instrucciones para completar el proceso.",
    labelReEnvio: "Hemos reenviado el código de verificación a tu dirección de correo electrónico. Revisa tu bandeja de entrada y sigue las instrucciones para completar el proceso.",
    labelEnvioTelefono: "Hemos enviado el código de verificación a tu número de teléfono. Por favor, revisa tu bandeja de SMS y sigue las instrucciones para completar el proceso.",
    labelReEnvioTelefono: "Hemos reenviado el código de verificación a tu número de teléfono. Revisa tu bandeja de SMS y sigue las instrucciones para completar el proceso.",
    labelElNumero: "El número",
    labelEsCorrecto: "es correcto",
    errorClaveVendedor: "Ingresa una clave válida",
    labelConfirmar: "Confirmar",
    labelCancelar: "Cancelar",
};
