import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { LANG } from './LANG'
import { ProgressBarContext } from '../../../../../context/ProgressBarContext'
import { useState } from 'react'

/**
 * DocumentosTipo - Componente React para mostrar y seleccionar tipos de documentos.
 * Se basa en una lista de radios donde cada radio representa un tipo de documento.
 * El estilo del componente se define dentro del mismo y se aplica a un Grid.
 * Si hay algún documento con un 'nIdDocumento' mayor que 0, se determina el flujo correspondiente y se establece el tipo de documento.
 * @author ERL 2023-09-06 05:34 pm
 * @param {props} - Propiedades del componente
 * @field {array} aRadios - Lista de documentos. Cada documento tiene un 'sIdFlujo' que identifica el tipo y un 'sTipo' que describe el tipo.
 * @field {function} setTipoDoc - Función para establecer el tipo de documento seleccionado.
 * @field {string} sTipoDoc - Tipo de documento seleccionado actualmente.
 * @returns {JSX.Element} 
 */
const DocumentosTipo = ({ aRadios, setTipoDoc, sTipoDoc }) => {
    const {
        dataDocumentos,
    } = useContext(ProgressBarContext)

    const Styles = {
        textAlign: "center",
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        padding: {
            xl: '10px 20px',
            md: '10px 20px',
            xs: '10px 10px'
        },
        borderRadius: '10px',
        width: {
            xl: "100%",
            md: "100%",
            xs: '100%',
        },
        maxWidth: {
            xl: "563px",
            md: "563px",
            xs: '100%',
        },
        mx: "auto"
    }

    /**
    * obtenerIdFlujo - Función que busca y devuelve el identificador del flujo (`sIdFlujo`) 
    * del primer documento cuyo 'nIdDocumento' sea mayor al valor proporcionado y el estatus sea igual a VALIDADO.
    * @author ERL 2023-09-07 09:53 am
    * @param {int} nIdDocumento - Identificador del documento. Por defecto es 0.
    * @returns {(string|null)} 
    * 
    */
    const obtenerIdFlujo = (nIdDocumento = 0) => {
        const documentoEncontrado = aRadios.find(doc => doc.sStatus === "VALIDADO");

        return documentoEncontrado ? documentoEncontrado.sIdFlujo : null;
    }


    const [sIdFlujoEncontrado, setIdFlujoEncontrado] = useState("");
    useEffect(() => {
        if(dataDocumentos.length > 0){
            
            const sIdFlujoEncontrado = obtenerIdFlujo();
            setIdFlujoEncontrado(sIdFlujoEncontrado)

        }        
    }, [dataDocumentos])
    

    /**
    * useEffect - Este efecto se encarga de actualizar el tipo de documento (`setTipoDoc`) 
    * basado en el identificador del flujo (`sIdFlujoEncontrado`) encontrado.
    * Se ejecuta cada vez que el valor de `sIdFlujoEncontrado` cambia.
    *
    * @author ERL 2023-09-07 08:38 am
    * @returns {void}
    */
    useEffect(() => {

        if (sIdFlujoEncontrado) {
            setTipoDoc(sIdFlujoEncontrado)
        }

    }, [sIdFlujoEncontrado])


    return (
        <Grid sx={{ ...Styles }}>
            <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">{LANG.labelTipoDoc}</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    {aRadios.map((radio) => {
                        return (
                            <FormControlLabel
                                key={radio.sIdFlujo}
                                sx={{ textTransform: 'capitalize' }}
                                value={radio.sIdFlujo}
                                control={<Radio
                                    checked={sIdFlujoEncontrado ? radio.sIdFlujo === sIdFlujoEncontrado : radio.sIdFlujo === sTipoDoc}
                                    onChange={(e) => setTipoDoc(e.target.value)}
                                    disabled={!!sIdFlujoEncontrado}
                                />}
                                label={radio.sTipo}
                                labelPlacement="start"
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>
        </Grid>
    )

}

export default DocumentosTipo
