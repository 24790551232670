const path = "/assets/autos";
export const dataAutos = [
  {
    id: 1,
    modelo: "ATTITUDE",
    marca: "DODGE",
    montoSemanal: "3,550",
    descripcion:"Con Attitude SE MT disfruta de la ciudad al máximo, gracias a su increíble rendimiento de combustible, pero, sobre todo, a que cuenta con un gran diseño exterior por sus modernas líneas y gran aerodinámica atrae las miradas.",
    meses: "36 meses con opción a compra",
    electrico:false,
    imagenesSlider: [
      `${path}/ATTITUDE/ATTITUDE2023.png`,
      `${path}/ATTITUDE/ATTITUDE1.png`,
      `${path}/ATTITUDE/ATTITUDE2.png`,
      `${path}/ATTITUDE/ATTITUDE3.png`,
    ],
  },
  {
    id: 2,
    modelo: "Versa Sense",
    marca: "NISSAN",
    montoSemanal: "3,975",
    descripcion:"Haz tuyo un auto que, como tú, ofrece mucho más de lo esperado. Con el nuevo Versa Sense TM Prepárate para recorrer nuevos caminos a bordo del sedán que cambiará de nuevo las reglas de tecnología y seguridad de su segmento. Cada recorrido será diferente al anterior pero siempre con el más alto estándar de protección.",
    meses: "36 meses con opción a compra",
    electrico:false,
    imagenesSlider: [
      `${path}/VERSA/VERSA2023.png`,
      `${path}/VERSA/VERSA1.png`,
      `${path}/VERSA/VERSA2.png`,
      `${path}/VERSA/VERSA3.png`,
    ],
  },
  {
    id: 3,
    modelo: "VDRIVE",
    marca: "NISSAN",
    montoSemanal: "3,550",
    descripcion:"Nissan V-Drive crea para ti una atmósfera de confort. Emociónate en cada trayecto, disfruta la comodidad del aire acondicionado y los cristales con apertura eléctrica. Más kilómetros con más confort.",
    meses: "36 meses con opción a compra",
    electrico:false,
    imagenesSlider: [
      `${path}/VDRIVE/VDRIVE2023.png`,
      `${path}/VDRIVE/VDRIVE1.png`,
      `${path}/VDRIVE/VDRIVE2.png`,
      `${path}/VDRIVE/VDRIVE3.png`,
    ],
  },
  {
    id: 4,
    modelo: "AVEO",
    marca: "Chevrolet",
    montoSemanal: "3,500",
    descripcion:"El Chevrolet Aveo LS tiene todo lo que necesitas, te permite hacer más de lo que te gusta, gracias a su diseño, espacio interior y rendimiento, tendrás una gran experiencia a bordo de este sedán.",
    meses: "36 meses con opción a compra",
    electrico:false,
    imagenesSlider: [
      `${path}/AVEO/AVEO2023.png`,
      `${path}/AVEO/AVEO1.png`,
      `${path}/AVEO/AVEO2.png`,
      `${path}/AVEO/AVEO3.png`,
    ],
  },
  {
    id: 5,
    modelo: "E1OX(eléctrico)",
    marca: "Jac",
    montoSemanal: "4,950",    
    descripcion:"Si tienes esa curiosidad de sumergirte en la movilidad eléctrica, el E1OX se ubica como la opción a considerar si buscas un precio atractivo y capacidades suficientes. Atrévete a dar el primer paso.",
    meses: "42 meses con opción a compra",
    electrico:true,
    imagenesSlider: [
      `${path}/JAC_EX10/JAC-EX10.png`,
      `${path}/JAC_EX10/JAC-EX101.png`,
      `${path}/JAC_EX10/JAC-EX102.png`,
      `${path}/JAC_EX10/JAC-EX103.png`,
    ],
  },
];
