export const LANG = {
    "labelNuevo":"Nuevo",
    "labelEstandar":"Estandar",
    "labelYear":"Año",
    "labelDisponibles":"Vehículos disponibles",
    "labelTrabajalo":"Trabájalo en plataformas de movibilidad",
    "labelSemanal":"Semanal",
    "labelLoQuiero":"¡Lo quiero!",
    "labelPrecioSujeto":"*Precio sujeto a cambios sin previo aviso",
    "labelVerMas":"Ver Más",
    "labelMty":"Monterrey",
    "labelGdl":"Guadalajara",
}