import { Grid, Typography } from '@mui/material';
import React from 'react'
import { LANG } from '../LANG';

/**
 * Componente contenedor de los detalles de la unidad
 * Utiliza el hook personalizado 'useUnidades' para obtener y gestionar los datos de los detalles de la unidad seleccionada
 * @author ERL 2023-08-10 02:37 pm
 * @param {array} aUidadInfoBasica Información de la unidad
 * @returns {JSX.Element}
 */
const UnidadInfoBasica = ({ aUidadInfoBasica }) => {

    const { sTransmision, sUbicacion } = aUidadInfoBasica;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant='h5' color={"primary"}>                    
                    {LANG.labelInfobasica}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Grid
                    sx={{
                        gap: '0 50px',
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                    }}
                >
                    <Grid sx={{ borderBottom: '1px solid #e9e9e9', padding: '16px 0' }}>
                        <Typography>
                            {LANG.labelCiudad}
                        </Typography>
                        <Typography>
                            {sUbicacion}
                        </Typography>
                    </Grid>
                    <Grid sx={{ borderBottom: '1px solid #e9e9e9', padding: '16px 0' }}>
                        <Typography>
                            {LANG.labelTransmision}
                        </Typography>
                        <Typography>
                            {sTransmision}
                        </Typography>
                    </Grid>
                  
                </Grid>
            </Grid>
        </Grid>
    )
}


export default UnidadInfoBasica
