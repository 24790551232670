import React from 'react'
/**
 * Encargado de mostrar un requisito específico. Se muestra
 * una imagen representativa del requisito, un título y una descripción detallada.
 * 
 * @param {string} sTitulo - Título del requisito.
 * @param {string} sDesc - Descripción detallada del requisito.
 * @param {string} sImg - URL de la imagen representativa del requisito.
 * @author ERL 2023-09-18 11:04 am
 * @returns {JSX.Element} Retorna un componente visual que representa un requisito.
 */
const Requisito = ({sTitulo, sDesc, sImg}) => {
    return (
        <div className="card featured">
            <div className="top">
                <div className='top-img-container'>
                    <img
                        className="requisitosImagen"
                        src={sImg}
                        alt=""
                    />
                </div>
                <div className='top-txt-container'>
                    <h3>{sTitulo}</h3>
                </div>
            </div>
            <div className="bottom">
                <p className="textoRequisitos">{sDesc}</p>
            </div>
        </div>
    )
}

export default Requisito
