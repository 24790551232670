import { Button, Grid, Tooltip } from '@mui/material';
import React from 'react';
import { StyledButton2 } from '../customButtons/StyledButton';

/**
 * El componente CollapsibleForm es un formulario plegable que se expande al hacer clic en él.
 * Se utiliza para presentar un paso en un proceso donde los pasos pueden ser expandidos y contraídos.
 * @author ERL 2023-07-19 01:42 pm
 * @param {number} nId - Identificador único del paso actual en el proceso
 * @param {JSX.Element} children - Los hijos del componente (los elementos que se renderizarán dentro del formulario plegable)
 * @param {string} sStatus - Estado del paso actual en el proceso 
 * @param {string} sValor - Valor del paso actual en el proceso
 * @param {object} oStatusResponse - Objeto que contiene la respuesta de estado para el paso actual en el proceso
 * @param {function} getStatusDocs - Función para obtener el estado de los documentos del paso actual en el proceso
 * @param {object} oReferencia - Referencia del paso actual en el proceso
 * @param {number} nCurrentStep - El paso actual en el proceso
 * @param {function} setCurrentStep - Función para establecer el paso actual en el proceso
 * @param {string} sReferencia - La referencia como string del paso actual en el proceso
 * @param {object} methods - Los métodos libreria react hook form
 * @param {array} referenciasRechazadas - Lista de referencias rechazadas
 * @param {string} sCompletar - Texto para el boton
 * @returns {JSX.Element}
 */
const CollapsibleForm = ({
    nId,
    children,
    sStatus,
    sValor,
    oStatusResponse,
    getStatusDocs,
    oReferencia,
    nCurrentStep,
    setCurrentStep,
    sReferencia,
    methods,
    referenciasRechazadas,
    sCompletar
}) => {

  
    /**
     * Función que establece el paso actual en el proceso al ID del paso que se está manejando.
     * Se utiliza para expandir el formulario al hacer clic en él.
     * @author ERL 2023-07-19 02:17 pm
     * @returns {void}
     */
    const abrirCollapsible = async () => { 
      //  const isValid = await methods.trigger();
       
          // Si el paso actual es igual al ID del paso que se está manejando, entonces se contrae, de lo contrario, se expande
          const isValid = await methods.trigger();
        
            if (isValid) {
                setCurrentStep(nCurrentStep === nId ? null : nId);
            }
      
      }
    const Styles = {
        "h2": {
            display: "flex",
            gap: "5px",
            alignItems: "center",
        },
        "grid-ref-nombre": {
            display: 'flex',
            flexDirection: 'column'
        },
        "button":{
            background: 'none',
            color: 'unset',
            display: 'unset',
            textAlign: 'unset',
            margin: '15px 0',
            padding: '0'
        }
    }
    const validStatuses = ["VALIDADO", "APROBADO", "PENDIENTE"]; 
    return (
        <>
            <Button
            variant='cotained'
            style={Styles['button']} onClick={validStatuses.includes(sStatus)  ? () => { return; } : abrirCollapsible}>
                <h2 style={Styles["h2"]}>
                    {sValor}
                    <Tooltip title={sStatus !== "" ? sStatus : ""} arrow >
                        <i style={{
                            color: oStatusResponse[getStatusDocs(oReferencia)].sStatusColor,
                            display: "flex",
                            alignItems: "center",

                        }}>{oStatusResponse[getStatusDocs(oReferencia)].sIcono}</i>
                    </Tooltip>
                </h2>
                <Grid sx={Styles['grid-ref-nombre']}>
                    <p>
                        {validStatuses.includes(sStatus)  ? `${sReferencia}` : ""}
                    </p>
                </Grid>

            </Button>
            {(nCurrentStep === nId && !validStatuses.includes(sStatus)) && (
                <>
                    {children}

                    {referenciasRechazadas.length > 0 ? <StyledButton2>

                        {sCompletar}

                    </StyledButton2> : ""}
                </>
            )}
        </>
    );
};

export default CollapsibleForm;
