/**
     * Componente ReferenciasStep, nos muestra el contenido del paso actual
     * @author ERL 2023-07-14 12:06 am      
     * @param {number} nStep - Indice del paso 
     * @param {number} nCurrentStep - Paso actual 
     * @param {function} nextStep - Funcion para ir al paso siguiente 
     * @param {JSX.Element} children - Contenido dentro del componente 
     * @returns {JSX.Element} 
*/
const ReferenciasStep = ({ nStep, nCurrentStep, children, nextStep }) => {
    return (
        <div>
            {nStep === nCurrentStep && children}
        </div>
    );
};

export default ReferenciasStep
