import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
/**
 * Componente que muestra un modal (o diálogo) de pantalla completa utilizando MUI (Material-UI).
 * Incorpora un AppBar en la parte superior con un botón de cerrar y un botón opcional proporcionado por los propios usuarios.
 * El contenido del modal es flexible y puede ser proporcionado a través de la propiedad 'children'.
 * @author ERL 2023-08-08 09:45 am
 * @param {Object} props - Propiedades del componente
 * @field {boolean} oOpen - Indica si el modal debe estar abierto o cerrado.
 * @field {Function} setOpen - Función que actualiza el estado de apertura del modal.
 * @field {JSX.Element} children - Contenido que se mostrará dentro del modal.
 * @field {string} sDescripcion - Descripción opcional, que se muestra en la barra de navegacion del modal

 * @returns {JSX.Element}
 */
export default function ModalMUI360({ oOpen, setOpen, children, sDescripcion }) {

    /**
     * Componente que muestra un modal (o diálogo) de pantalla completa utilizando MUI (Material-UI).
     * Incorpora un AppBar en la parte superior con un botón de cerrar y un botón opcional proporcionado por los propios usuarios.
     * El contenido del modal es flexible y puede ser proporcionado a través de la propiedad 'children'.
     * @author ERL 2023-08-08 09:46 am
     * @returns {void}
     */
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={oOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', background: '#483e6b', border: '0', paddingTop: 0, paddingBottom: 0, }}>
                    <Toolbar
                        sx={{
                            minHeight: {
                                xs: '24px'
                            }
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {sDescripcion}
                        </Typography>
                        
                    </Toolbar>
                </AppBar>
                <Grid py={2}>
                    {children}
                </Grid>
            </Dialog>
        </div>
    );
}