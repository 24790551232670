// Importando módulos necesarios
import React, { useEffect, useRef, useState } from 'react';
import '../../../../../styles/registro/global.scss'
import '../../../../../styles/registro/prospectos/pasos/documentos.scss'

import { Grid, InputLabel, IconButton, Tooltip } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import BadgeIcon from '@mui/icons-material/Badge';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DescriptionIcon from '@mui/icons-material/Description';
import { LANG } from './LANG';
import PaidIcon from '@mui/icons-material/Paid';

/**
 * Componente ListItem
 * @param {Object} question - Pregunta del documento.
 * @param {Object} control - Controlador del formulario.
 * @param {Object} errors - Errores del formulario.
 * @param {function} onRemove - Función para eliminar un documento.
 * @param {number} index - Índice del documento.
 * @param {number} delay - Retraso en milisegundos antes de iniciar la eliminación (opcional).
 * @param {int} lastIdFlujo - Ultimo flow id enviado a metamap.
 * @param {string} statusResponse - Status de la respuesta de la carga del documento.
 * @param {function} getStatusDocs - Función para obtener los estatus de los documentos.
 * @returns {JSX.Element} 
 */

export const ListItem = ({ question, onRemove, index, delay = 0, lastIdFlujo, statusResponse, getStatusDocs }) => {
    
    const [isRemoving, setIsRemoving] = useState(false);
    const elementRef = useRef(null);
    const { control, errors } = useFormContext();
    useEffect(() => {
        if (isRemoving && elementRef.current) {
            elementRef.current.addEventListener('animationend', () => onRemove(question.nIdentificador));
        }
        return () => {
            if (elementRef.current) {
                elementRef.current.removeEventListener('animationend', () => onRemove(question.nIdentificador));
            }
        };
    }, [isRemoving, onRemove]);

    // Nuevo efecto secundario para verificar sStatus
    useEffect(() => {
        if (question.sStatus.toUpperCase() === "VALIDADO") {
            // Agregamos un retraso antes de iniciar la eliminación
            const timerId = setTimeout(() => {
                setIsRemoving(true);
            }, delay);
            // Limpiamos el timer en caso de desmontaje del componente
            return () => clearTimeout(timerId);
        }
    }, [question.sStatus, delay]);

    return (
        <>
            <Grid item p="15px 0" key={index}
                mx={"auto"}
                width={'100%'}
                sx={{
                    width: {
                        xl: '90%',
                        sm: '80%',
                        xs: '100%'
                    }
                }}
                ref={elementRef} className={`listItem ${isRemoving ? "fadeOutLeft" : ""}`}
            >

                <Controller
                    name={`doc${question.nIdentificador}`}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <Grid style={{
                            textAlign: "center",
                        }}>
                            <Grid
                                sx={{
                                    textAlign: "center",
                                    border: '1px solid',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    boxSizing: 'border-box',
                                    padding: {
                                        xl: '10px 20px',
                                        md: '10px 20px',
                                        xs: '10px 10px'
                                    },
                                    borderRadius: '10px',
                                    width: {
                                        xl: "100%",
                                        md: "100%",
                                        xs: '100%',
                                    },
                                    maxWidth: {
                                        xl: "563px",
                                        md: "563px",
                                        xs: '100%',
                                    },
                                }}

                                mx={"auto"}
                            >

                                <InputLabel sx={{
                                    fontWeight: "bold",
                                    fontSize: {
                                        xl: "16px",
                                        sm: "16px",
                                        xs: '12px'
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    boxSizing: 'border-box',
                                    gap: '10px',
                                    whiteSpace: "pre-line",
                                }}
                                    htmlFor={`file${index}`}
                                >
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        mx={0}
                                        mt={0}
                                        p={0}
                                        sx={{
                                            padding: "0 !important",
                                        }}

                                    >
                                        <>
                                            {question.sIcono === "INE" && <BadgeIcon
                                                mx={0}
                                                mt={0}
                                                p={0}
                                                sx={{
                                                    fontSize: {
                                                        xl: 40,
                                                        md: 30,
                                                        sm: 25,
                                                        xs: 20
                                                    }
                                                }} />}
                                            {question.sIcono === "LIC" && <DriveEtaIcon
                                                mx={0}
                                                mt={0}
                                                p={0}
                                                sx={{
                                                    fontSize: {
                                                        xl: 40,
                                                        md: 30,
                                                        sm: 25,
                                                        xs: 20
                                                    }
                                                }} />}
                                            {question.sIcono === "DOM" && <OtherHousesIcon
                                                mx={0}
                                                mt={0}
                                                p={0}
                                                sx={{
                                                    fontSize: {
                                                        xl: 40,
                                                        md: 30,
                                                        sm: 25,
                                                        xs: 20
                                                    }
                                                }} />}
                                            {question.sIcono === "CON" && <DescriptionIcon
                                                mx={0}
                                                mt={0}
                                                p={0}
                                                sx={{
                                                    fontSize: {
                                                        xl: 40,
                                                        md: 30,
                                                        sm: 25,
                                                        xs: 20
                                                    }
                                                }} />}
                                            {question?.sIcono === "ING" && <PaidIcon
                                                mx={0}
                                                mt={0}
                                                p={0}
                                                sx={{
                                                    fontSize: {
                                                        xl: 40,
                                                        md: 30,
                                                        sm: 25,
                                                        xs: 20
                                                    }
                                                }} />}

                                        </>
                                        <input
                                            disabled={true}
                                            type="file"
                                            id={`file${index}`}
                                            hidden
                                            onBlur={onBlur}
                                            ref={ref}
                                            name={name}
                                        />
                                    </IconButton>
                                    {question.sValor}
                                </InputLabel>

                                <InputLabel sx={{
                                    fontWeight: "light",
                                    fontSize: "1em",
                                    display: "flex",
                                    alignItems: "center",
                                    color: statusResponse[getStatusDocs(question)].sStatusColor
                                }}>
                                    <Tooltip title={question?.sStatus !== "" ? statusResponse[getStatusDocs(question)].sStatus : ""} arrow >
                                        {
                                            statusResponse[getStatusDocs(question)].sIcono
                                        }
                                    </Tooltip>


                                </InputLabel>
                            </Grid>
                            <Grid pt={1}>
                                {(value && value[0]) || (question.sStatus !== '') ? <> {
                                    statusResponse[getStatusDocs(question)].sStatusMessage
                                }
                                </> :
                                    <Grid pt={1} style={{
                                        fontSize: "12px",
                                        color: 'red'
                                    }}>
                                        {question.sPagina === "back" && lastIdFlujo.includes(question.sIdFlujo) ? LANG.labelCargaFrontal : ""}
                                        {question.sPagina === "front" && lastIdFlujo.includes(question.sIdFlujo) ? LANG.labelCargaTrasera : ""}
                                    </Grid>
                                }
                            </Grid>
                           
                        </Grid>
                    )}
                />

            </Grid>
        </>
    );
}