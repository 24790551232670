import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./autos2.scss";
import { dataAutos as dataAutosMTY } from './dataMTY';
import { dataAutos as dataAutosGDL } from './dataGDL';
import 'swiper/css';

import { useEffect } from 'react';
import { StyledButton } from '../../components/customButtons/StyledButton';
import { LANG } from './LANG';

/**
 * Componente React que presenta una lista de autos basada en sucursales (MTY o GDL).
 * El usuario puede cambiar entre pestañas para ver diferentes conjuntos de datos. El tab activo se persiste 
 * a través del localStorage para mantener la selección del usuario entre las sesiones.
 * @author ERL 2023-09-18 08:27 am
 * @returns {JSX.Element} Retorna un componente que representa una lista de autos basada en la sucursal seleccionada.
 */
export default function VerticalTabs() {
  const [data, setData] = useState(dataAutosMTY);
  const localSucursal = localStorage.getItem('sucursal');
  const [activeTab, setActiveTab] = useState(localSucursal ? localSucursal : "MTY"); 

  /**
   * Escucha los cambios en `localSucursal` y ejecuta la función `SwitchTab` con 
   * el valor actual de `localSucursal` como argumento, cambiando el tab activo al que corresponda 
   * con la sucursal almacenada en localStorage.
   * 
   * @author ERL 2023-09-18 08:35 am
   */
  useEffect(() => {
    if (localSucursal) {
      SwitchTab(localSucursal);
    }
  }, [localSucursal]);

  /**
   * Función encargada de actualizar el estado `activeTab` basado en el valor del parámetro `id`.
   * Además, dependiendo de si el `id` es "MTY" o "GDL", se actualiza el conjunto de datos `data` 
   * y se establece el valor correspondiente en localStorage bajo la clave 'sucursal'.
   * @author ERL 2023-09-18 08:35 am
   * @param {string} id - Identificador que representa una sucursal (ejemplo: "MTY" o "GDL").
   */
  const SwitchTab = (id) => {
    setActiveTab(id); // Actualiza el tab activo

    if (id === "MTY") {
      setData(dataAutosMTY);
      localStorage.setItem('sucursal', id);
    }
    if (id === "GDL") {
      setData(dataAutosGDL);
      localStorage.setItem('sucursal', id);
    }
  }
  const autosIcons = {
    "nuevo":"/assets/autos/iconos/autosNuevo.png",
    "estandar":"/assets/autos/iconos/autosEstandar.png",
    "year":"/assets/autos/iconos/autosYear.png"
  }
  return (
    <div className="autos2 autos-bg-blue" >
      <div id="modelos" className='hashlink-id'></div>
      <h1 className="h1-autos">{LANG.labelDisponibles}</h1>
      <h3 className="h3-autos">{LANG.labelTrabajalo}</h3>
      <div className="autos-besta-tab">
        <div className='autos-besta-tab-menu'>
          <div className={`autos-besta-tab-menu-item ${activeTab === "MTY" ? 'activeTab' : ''}`} onClick={() => SwitchTab("MTY")}>{LANG.labelMty}</div>
          <div className={`autos-besta-tab-menu-item ${activeTab === "GDL" ? 'activeTab' : ''}`} onClick={() => SwitchTab("GDL")}>{LANG.labelGdl}</div>
        </div>
        <div className='autos-besta-tabs'>
          {data.map((item, i) => (
            i < 3 &&
            <Link to={`/cotizacion/${item.id}`} className="link" preventScrollReset={true} key={item.id}>
              <div className="autos-besta-tabs-item">
                <div className='autos-besta-tabs-card'>
                  <div className="autos-besta-tabs-card-top">
                    <h2>{item.marca}</h2>
                    <h3>{item.modelo}</h3>
                    <div className='autos-besta-tabs-card-img'>
                      <img src={item.imagen} alt="Autos besta" />
                    </div>
                  </div>
                  <div className="autos-besta-tabs-card-bottom">
                    <div className='autos-besta-tabs-card-bottom-item-icons'>
                      <div className='car-card-icon-container'>
                        <div className='car-card-icon-img nuevo'>
                            <img src={autosIcons["nuevo"]} alt="" />
                        </div>
                        <div className='car-card-icon-txt'>
                            {LANG.labelNuevo}
                        </div>
                      </div>
                      <div className='car-card-icon-container'>
                        <div className='car-card-icon-img estandar'>
                            <img src={autosIcons["estandar"]} alt="" />
                        </div>
                        <div className='car-card-icon-txt'>
                            {LANG.labelEstandar}
                        </div>
                      </div>
                      <div className='car-card-icon-container'>
                        <div className='car-card-icon-img year'>
                            <img src={autosIcons["year"]} alt="" />
                        </div>
                        <div className='car-card-icon-txt'>
                            {LANG.labelYear}
                        </div>
                      </div>
                    </div>
                    <div className='autos-besta-tabs-card-bottom-item'>
                      <div className='autos-besta-tabs-card-bottom-txt'>
                        <p><strong>{item.montoSemanal}</strong> / {LANG.labelSemanal}</p>
                      </div>
                      <div className='autos-besta-tabs-card-bottom-btn'>
                        <button>{LANG.labelLoQuiero}</button>
                      </div>
                    </div>
                    <div className='autos-besta-tabs-card-bottom-item'>
                      <div className='autos-besta-tabs-card-bottom-txt2'>
                        <br></br><p className="texto">{item.texto}</p>
                        <p>{LANG.labelPrecioSujeto}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className='autos-bottom'>
        {data.length > 3 && <div className='autos-bottom-btn'>
          <StyledButton>
            <Link to={`/autos`} className="nolink" preventScrollReset={true}>
              {LANG.labelVerMas}
            </Link>
          </StyledButton>
        </div>}
      </div>
    </div>
  );
}
