import { Grid, Typography } from '@mui/material'
import React from 'react'
/**
    * Componente indicaciones de grabación
    * @author ERL 2023-07-17 06:33 pm 
    * @param {string} sTitulo - Titulo de las indicaciones
    * @param {string} sDescripcion - Descripción de las indicaciones
    * @returns {JSX.Element} 
   */
const CargaVideoIndicacionesGrabacion = ({ sTitulo, sDescripcion }) => {
    const GridStyles = { width: '100%', padding: '10px 0px', margin: '15px 0px', borderBottom: '1px solid lightgray' };
    const TypographyStyles = {
        "h1": { fontWeight: 'bold', textAlign: 'center', },
        "p": { textAlign: 'center', maxWidth: '500px', margin: '0 auto', }
    };
    return (
        <>
            <Grid sx={GridStyles} >
                <Typography component="h1" sx={TypographyStyles["h1"]}>
                    {sTitulo}
                </Typography>
            </Grid>
            <Typography component="p" sx={TypographyStyles["p"]}>
                {sDescripcion}
            </Typography>
        </>
    )
}

export default CargaVideoIndicacionesGrabacion

/**
    * Componente de indicaciones si el usuario no está en un dispositivo móvil
    * @author ERL 2023-07-17 06:56 pm     
    * @param {string} sMensaje - Mensaje de indicación para el usuario
    * @returns {JSX.Element} 
   */
export const CargaVideoIndicacionesPantalla = ({ sMensaje }) => {
    const GridStyles = {
        "container": { textAlign: 'center', width: '100%', },
        "container-img": { maxWidth: '90px', margin: '0 auto', },
    };
    const ImgStyles = { width: '100%', };
    const ImgLogo = "/assets/bestalogo.png";
    return (
        <Grid sx={GridStyles["container"]}>
            <Grid sx={GridStyles["container-img"]}>
                <img src={ImgLogo} alt="" style={ImgStyles} />
            </Grid>
            <h2>
                {sMensaje}
            </h2>
        </Grid>
    )
}

/**
    * Componente de indicaciones si el usuario ya envio su video
    * @author ERL 2023-07-17 07:13 pm     
    * @param {string} sMensaje - Mensaje para el usuario
    * @returns {JSX.Element} 
   */
export const CargaVideoIndicacionesCargado = ({ sMensaje }) => {
    const GridStyles = {
        "container": {
            textAlign: 'center',
        },
    };

    return (
        <Grid sx={GridStyles["container"]}>
            <h3>
                {sMensaje}
            </h3>
        </Grid>
    )
}



