import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import { IosArrowLtr, IosArrowRtl } from '@styled-icons/fluentui-system-regular'

import {
    SlickNext,
    SlickPrev,
} from './StyledSlider';
import DriverPhoto from '../drivers/DriverPhoto';
import DriverName from '../drivers/DriverName';
import DriverBranch from '../drivers/DriverBranch';
import DriverComment from '../drivers/DriverComment';
import DriverQualification from '../drivers/DriverQualification';


const ArrowN = styled(IosArrowRtl)`
      color: #000;
    `
const ArrowP = styled(IosArrowLtr)`
      color: #000;
    `

function NextArrow(props) {
    const { onClick } = props;
    return <SlickNext onClick={onClick} right="0" top={50}><ArrowN /></SlickNext>;
}

function PrevArrow(props) {
    const { onClick } = props;
    return <SlickPrev onClick={onClick} left="0" top={50}><ArrowP /></SlickPrev>
}

function SliderDrivers({ aSlider }) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [            
            {
              breakpoint: 1324,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 780,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]

    };

    return (
        <div className="slider">
            <Slider {...settings}>
                {aSlider.map((d, i) => {
                    return (
                        <React.Fragment key={`driver-${i}`}>
                            <div className='driver-container' >
                                <div className='driver-info'>
                                    <div className='driver-photo'>
                                        <DriverPhoto sImg={d.sImg} />
                                    </div>
                                    <div className='driver-name-branch'>
                                        <DriverName sNombre={d.sNombre} />
                                        <DriverBranch sSucursal={d.sSucursal} />
                                    </div>
                                </div>
                                <div className='driver-review'>
                                    <div className='driver-comment'>
                                        <DriverComment sComentario={d.sComentario} />
                                    </div>
                                    <div className='driver-qualification'>
                                        <DriverQualification nCalificacion={d.nCalificacion} />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                })}
            </Slider>
        </div>
    );
}

export default SliderDrivers;
