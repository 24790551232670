import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';

/**
 * Componente para filtrar unidades basado en años.
 * Permite seleccionar uno o varios años para filtrar las unidades por su año.
 * @author ERL 2023-08-07 09:43 pm
 * @param {array} aYears - Lista de años disponibles.
 * @param {function} handleFilterSelect - Función callback para manejar la selección de filtros.
 * @param {array} selectedYears - Lista de años seleccionados.
 * @returns {JSX.Element}
 */
export const UnidadFiltroYear = ({ aYears, handleFilterSelect, selectedYears: initialSelectedYears }) => {
  const [selectedYears, setSelectedYears] = useState(initialSelectedYears || []);

  /**
  * Efecto que se ejecuta cuando cambia el valor de 'initialSelectedYears'.
  * Se encarga de actualizar el estado 'setSelectedYears' con el valor de 'initialSelectedYears'.
  * Si no se proporciona un 'initialSelectedYears', se establece un array vacío por defecto.
  * @author ERL 2023-08-08 03:43 pm
  */
  useEffect(() => {
    setSelectedYears(initialSelectedYears || []);
  }, [initialSelectedYears]);

  /**
   * Maneja la selección o deselección de un año específico.
   * Actualiza la lista de años seleccionados y llama a la función callback 'handleFilterSelect'.
   * @author ERL 2023-08-07 10:15 pm
   * @param {object} yearsObj - Objeto que contiene el ID y el año.
   * @returns {void}
   */
  const seleccionarYear = (yearsObj) => {
    let updatedSelectedYears;
    if (selectedYears.includes(yearsObj.nIdYear)) {
      updatedSelectedYears = selectedYears.filter(id => id !== yearsObj.nIdYear);
    } else {
      updatedSelectedYears = [...selectedYears, yearsObj.nIdYear];
    }
    setSelectedYears(updatedSelectedYears);
    handleFilterSelect && handleFilterSelect('nIdYear', updatedSelectedYears);
  };

  return (
    <Box display="flex" flexWrap="wrap" gap={1} padding={1}> {/* Contenedor flex */}
      {aYears.map(yearsObj => (
        <Button
          key={yearsObj.nIdYear}
          onClick={() => seleccionarYear(yearsObj)}
          variant={selectedYears.includes(yearsObj.nIdYear) ? "contained" : "outlined"} // Cambia el estilo según si está seleccionado
          color="primary"
        >
          {yearsObj.sNombre}
        </Button>
      ))}
    </Box>
  );
}

export default UnidadFiltroYear;
