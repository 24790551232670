import { useNavigate } from "react-router-dom";
import { StyledButton3 } from "../../components/customButtons/StyledButton";
import "./intro.scss";
import React from 'react';

export default function Intro() {
  const navigate = useNavigate();
  return (
    <div className='intro'>
      <div id="inicio" className='hashlink-id'></div>
      <div className="left">
        <div className="imgContainer">
          <img src="/assets/INICIO-CARRO-W.webp" alt="" />
        </div>
        <div className="imgContainerM">
          <img src="/assets/INICIO-CARRO.webp" alt="" />
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>¿Trabajas como conductor <br /> en DiDi o Uber? </h2>
          <h1>BESTA tiene el</h1>
          <h1>automóvil <br /> de tus sueños</h1>
          <h3>Despreocúpate de seguir rentando <img src="assets/palomita.png" alt="palomita" className="palomita" /></h3>
          <StyledButton3 width="300px" onClick={() => navigate('/contacto')}>Contactar Asesor</StyledButton3>
        </div>
        <div className="wrapper-mobile">
          <div className="intro-info-container">
            <img src="assets/car-ico.png" alt="car" className="car-ico" />
            <h3>Despreocúpate de seguir rentando</h3>
          </div>
          <div className="intro-info-besta">
            <h1>BESTA tiene el</h1>
            <h1>automóvil de tus sueños</h1>
          </div>          
            <StyledButton3 width="20px" onClick={() => navigate('/contacto')}>Contactar Asesor</StyledButton3>
        </div>
        <a href="#portafolio">
          <img src="assets/down.png" alt="" />
        </a>
      </div>
    </div>
  )
}
