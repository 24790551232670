import Home from "../pages/home/Home";

import Contacto from "../pages/contact/Contact";
import Preguntas from "../pages/preguntas/Preguntas";
import Privacidad from "../pages/privacidad/Privacidad";
import NotFoundPage from "../pages/notfoundpage/NotFoundPage";
import Visitanos from "../pages/visitanos/Visitanos";
import Uber from "../pages/uber/Uber";
import Vacante from "../pages/vacante/Vacante";
import VehiculosDisponibles from "../pages/autos/VehiculosDisponibles";
import Registro from "../pages/registro/usuarios/Registro";
import Login from "../pages/login/Login";
import Cotizacion from '../pages/cotizacion/Cotizacion';
import Progreso from "../pages/registro/prospectos/Progreso";
import CargaVideoMovil from "../components/registro/prospectos/pasos/video/CargaVideoMovil";

import CargaVideoWeb from "../components/registro/prospectos/pasos/video/CargaVideoWeb";
import Perfil from "../pages/perfil/Perfil";
import Unidades from "../pages/unidades/Unidades";
import UnidadesDetalles from "../pages/unidades/UnidadesDetalles";
import Palenca from "../pages/palenca/Palenca";
import Campana from "../pages/marketing/Campana";


export const routes = {
  "/": <Home />,
  "/contacto": <Contacto />,
  "/registro": <Registro />,
  "/proceso": <Progreso />,
  "/login": <Login />,
  "/perfil": <Perfil />,
  "/autos": <VehiculosDisponibles />,
  "/seminuevos": <Unidades />,
  "/seminuevos/:id": <UnidadesDetalles />,
  "/cotizacion/:id": <Cotizacion />,
  "/privacidad": <Privacidad />,
  "/visitanos": <Visitanos />,
  "/alianza-uberbesta": <Uber />,
  "/palenca": <Palenca />,
  "/vacante": <Vacante />,
  "/preguntas-frecuentes": <Preguntas />,
  "*": <NotFoundPage />,
  "/cargavideo/:id/:token": <CargaVideoWeb />,
  /** */
  "/:sIdCapana": <Campana/>
  };
  