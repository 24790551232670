import React from 'react'
/**
 * Componente BeneficioItem que muestra un ítem individual de beneficio.
 * @author ERL 2023-10-20 10:35 am
 * @param {string} title - Primera parte del título del beneficio.
 * @param {string} title2 - Segunda parte del título del beneficio. Se muestra en negrita.
 * @param {string} desc - Descripción detallada del beneficio.
 * @param {string} sImg - URL de la imagen o ícono que representa el beneficio.
 * @returns {JSX.Element} - Retorna la estructura visual del componente BeneficioItem.
 * 
 */
const BeneficioItem = ({ title, title2, desc, sImg }) => {   

    return (
        <div className='beneficio-item-container'>
            <div className='beneficio-item-container-title'>
                <div className='beneficio-ico'>
                    <img src={sImg} alt=""/>
                </div>
                <div className='beneficio-item-container-title-txt'>
                    <span>
                        {title} 
                    </span>
                    <b>
                        {` ${title2}`}
                    </b>
                </div>
            </div>
            <div className='beneficio-item-container-desc'>
                <p>
                    {desc}
                </p>
            </div>
        </div>
    )
}

export default BeneficioItem