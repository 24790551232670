import * as React from 'react';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, ButtonBase } from '@mui/material';


/**
 * Componente IconButton estilizado para representar un botón de expansión.
 * La rotación del ícono depende del valor de la propiedad `expand`. 
 * Si `expand` es verdadero, el ícono gira 180 grados, indicando una expansión.
 * Si es falso, el ícono permanece sin rotación, indicando un estado colapsado.
 * @author ERL 2023-08-07 06:30 pm
 * @param {Object} props Propiedades pasadas al componente.
 * @field {boolean} expand Estado actual de expansión.
 * @returns {JSX.Element} 
 */
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

/**
 * Componente de React para mostrar una tarjeta colapsable con filtros. Esta tarjeta se expande al hacer clic y muestra los filtros
 * o elementos hijos que se le pasen como `children`.
 * @author ERL 2023-08-07 06:25 pm
 * @param {Object} props - Las propiedades del componente.
 * @field {string} sNombre - Nombre del filtro a mostrar en la cabecera del componente.
 * @field {JSX.Element} children - Elementos hijos que se muestran al expandir el componente.
 * @returns {JSX.Element}
 */
const UnidadesFiltro = ({ sNombre, children, sIcono }) => {
    const [expanded, setExpanded] = React.useState(false);


    /**
     * Función que se invoca al hacer clic en el botón de expansión. Invierte el valor actual de `expanded`
     * para expandir o colapsar el contenido.
     * @author ERL 2023-08-07 07:10 pm
     * @returns {void}
     */
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    
    return (
        <Box>
            <ButtonBase 
                onClick={handleExpandClick}
                style={{ width: '100%', textAlign: 'left' }}
            >
                <Grid 
                    className='no-select'
                    button
                    sx={{
                        width:'100%',
                        display: {
                            xs: 'flex'
                        },
                        justifyContent: {
                            xl: 'space-between',
                            xs: 'space-between',
                        },
                        alignItems: {
                            xl: 'center',
                            xs: 'center',
                        },
                        borderBottom: '1px solid #e9e9e9',
                        height: '60px',
                        cursor:'pointer',
                        gap:'10px',
                        textTransform:'capitalize'
                    }}
                >
                    {sIcono && sIcono }
                    {sNombre}
                    <ExpandMore
                        expand={expanded}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </Grid>
            </ButtonBase>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{padding:'0'}}>
                    {children}
                </CardContent>
            </Collapse>
        </Box>
    )
}

export default UnidadesFiltro;
