/**
 * Este módulo define varias URLs de API utilizadas en toda la aplicación.
 * 
 * Las URLs base para diferentes servicios se obtienen de las variables de entorno.
 * Estas variables de entorno generalmente se establecen en un archivo `.env` o a través
 * de la configuración de entorno de la plataforma de alojamiento.
 */

// URL base para la API de Palenca
const APP_API_PALENCA = process.env.REACT_APP_API_PALENCA;

// URL base para operaciones relacionadas con el usuario
const API_BASE_URL_USUARIO = process.env.REACT_APP_API_BASE_URL_USUARIO;

// URL base para operaciones relacionadas con el prospecto
const API_BASE_URL_PROSPECTO = process.env.REACT_APP_API_BASE_URL_PROSPECTO;

// URL base para operaciones relacionadas con documentos
const API_BASE_URL_DOCUMENTOS = process.env.REACT_APP_API_BASE_URL_DOCUMENTOS;

// URL base para operaciones relacionadas con documentos
const API_BASE_URL_IDENTITIES = process.env.REACT_APP_IDENTITIES;

// URL base para operaciones relacionadas con documentos
const API_BASE_URL_METAMAP = process.env.REACT_APP_METAMAP;

// URL base para catálogos
const API_BASE_URL_CATALOGOS = process.env.REACT_APP_API_BASE_URL_CATALOGOS;

// URL base para operaciones relacionadas con correos
const API_BASE_URL_CORREOS = process.env.REACT_APP_API_BASE_URL_CORREOS;

// URL base para operaciones relacionadas con clientes
const API_BASE_URL_CLIENTES = process.env.REACT_APP_API_BASE_URL_CLIENTES;

// URL base para unidades
const API_BASE_URL_UNIDADES = process.env.REACT_APP_API_BASE_URL_UNIDADES;

// URL base para el socket de video
const API_BASE_URL_SOCKET_VIDEO = process.env.REACT_APP_API_BASE_URL_SOCKET_VIDEO;

// URL base para el socket de documentos
const API_BASE_URL_SOCKET_DOCUMENTOS = process.env.REACT_APP_API_BASE_URL_SOCKET_DOCUMENTOS;

// URL de la API de Google People
const API_URL_GOOGLE_PEOPLE = process.env.REACT_APP_API_GOOGLE_PEOPLE;

// URL de la API de Google Scope
const API_URL_GOOGLE_SCOPE = process.env.REACT_APP_API_GOOGLE_SCOPE;

/**
 * El objeto urlsAPIs contiene las URLs completas para varios puntos finales de la API.
 * Estas URLs se construyen añadiendo rutas específicas a las URLs base.
 */
export const urlsAPIs = {
  urlGoogleStorage: `${API_BASE_URL_DOCUMENTOS}prospecto/documentos`,
  urlGooglePeople: API_URL_GOOGLE_PEOPLE,
  urlGoogleScope: API_URL_GOOGLE_SCOPE,
  urlRegistroApi: `${API_BASE_URL_USUARIO}usuario/alta`,
  urlLoginApi: `${API_BASE_URL_USUARIO}usuario/login`,
  urlRecuperarPassword: `${API_BASE_URL_USUARIO}usuario/login`,
  urlReEnviarCodigoApi: `${API_BASE_URL_USUARIO}usuario/codigo`,
  urlEnviarCodigoTelefono: `${API_BASE_URL_PROSPECTO}prospecto/envio/codigo/verificacion`,
  urlConfirmarCodigoApi: `${API_BASE_URL_PROSPECTO}prospecto/codigo/verificacion`,
  urlValidarCodigoReferido: `${API_BASE_URL_PROSPECTO}prospecto/valida/codigo/referenciado`,
  urlProgresoEstado: `${API_BASE_URL_PROSPECTO}prospecto/estados`,
  urlPreguntasCuestionario: `${API_BASE_URL_PROSPECTO}prospecto/cuestionario`,
  urlCompletarCuestionario: `${API_BASE_URL_PROSPECTO}prospecto/cuestionario`,
  urlUnidadesInteres: `${API_BASE_URL_PROSPECTO}prospecto/unidades`,
  urlDocumentos: `${API_BASE_URL_PROSPECTO}prospecto/documentos`,
  prospectosReferencias: `${API_BASE_URL_PROSPECTO}prospecto/validacion/referencias`,
  urlEnviarDocumentos: `${API_BASE_URL_DOCUMENTOS}prospecto/documentos`,
  urlPalenca: APP_API_PALENCA,
  urlEnviarInfoPalenca: `${API_BASE_URL_PROSPECTO}prospecto/palenca`,
  urlApiReferencias: `${API_BASE_URL_PROSPECTO}prospecto/referencias`,
  urlObtenerColonias: `${API_BASE_URL_CATALOGOS}catalogo/colonias`,
  urlObtenerCiudades: `${API_BASE_URL_CATALOGOS}catalogo/municipios`,
  urlReferenciaPago: `${API_BASE_URL_CLIENTES}cliente/referencia`,
  urlEnviarEnlaceVideo: `${API_BASE_URL_CORREOS}correo/video`,
  urlSocketVideo: `${API_BASE_URL_SOCKET_VIDEO}`,
  urlSocketDocumentos: `${API_BASE_URL_SOCKET_DOCUMENTOS}`,
  urlUnidades: `${API_BASE_URL_UNIDADES}unidades/carrusel`,  
  urlGoogleBucket: `${API_BASE_URL_DOCUMENTOS}prospecto/documentos`,
  urlMetamapIdentities: `${API_BASE_URL_IDENTITIES}`,
  urlMetamap: `${API_BASE_URL_METAMAP}`,
  urlDocumentosRechazo: `${API_BASE_URL_DOCUMENTOS}documentos/rechazo`,
  urlGoogleSheetFormContacto: 'https://script.google.com/macros/s/AKfycbwlwfTesDhDwqL-9gtCgnhsq6ZTnVBc8vtRK7NZWkD69ChyqvSj0DDgHI8mURxv3MHV/exec',
  urlGoogleSheetFormAutos: 'https://script.google.com/macros/s/AKfycby247Zixkc_i17Mg6i-57hFSxK5pXu3OcWecnjrcrZizoq5dIAztVNaQrbXMO-2kmp8/exec',
};
