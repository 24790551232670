import { Container, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useUnidades } from '../../../hooks/unidades/useUnidades'
import { useParams } from 'react-router';
import { useStyles } from '../../../hooks/stylesMUI/useStyles';
import UnidadDetallesBasicos from './UnidadDetallesBasicos';
import UnidadInfoBasica from './UnidadInfoBasica';
import UnidadImperfecciones from './UnidadImperfecciones';
import UnidadCaracteristicas from './UnidadCaracteristicas';
import UnidadSugerencias from './UnidadSugerencias';
import UnidadCarrusel from './UnidadCarrusel';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider, } from '@mui/material/styles'; 
import CircularLoader from '../../loader/CircularLoader';
import UnidadGaleria360 from './UnidadGaleria360';
import ModalMUI360 from '../../modal/ModalMUI360';

// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});

/**
 * Componente contenedor de los detalles de la unidad.
 * Muestra todos los detalles de una unidad en específico, incluyendo imágenes, información básica, imperfecciones, características, entre otros.
 * Se hace uso del hook personalizado 'useUnidades' para gestionar los datos de la unidad.
 * @author ERL 2023-08-10 12:11 pm
 * @returns {JSX.Element} 
 */

const UnidadDetalles = () => {

    const {
        obtenerDatosDetalles,
        aUnidadCarrusel,
        aUidadInfoBasica,
        aUnidadImperfeciones,
        aUnidad360,
        aUnidad360v2,
        aCaracteristicas,
        loading,
    } = useUnidades();
    const params = useParams();
    const { id } = params;
    const { sURL, sType, nCantidad } = aUnidad360;
    const [sDescripcionUnidad, setDescripcionUnidad] = useState("")
    const { StylesUnidades } = useStyles();
    const [open, setOpen] = useState(false);
    /**
     * Efecto para llamar la función obtenerDatosDetalles cuando se renderiza el componente.
     * Se encarga de obtener los detalles de la unidad basándose en el ID proporcionado a través de los parámetros del URL.
     * @author ERL 2023-08-07 02:37 pm   
     */
    useEffect(() => {
        obtenerDatosDetalles(id);
    }, [id]);

 
    /**
     * Hook que se ejecuta cuando 'aUidadInfoBasica' cambia.
     * Construye una descripción de la unidad basada en su año, descripción y modelo.
     * Una vez construida, establece esta descripción en el estado local 'setDescripcionUnidad'.
     * @author ERL 2023-08-08 01:04 pm
     */
    useEffect(() => {

        if (aUidadInfoBasica) {
            const { nYear, sDescripcion, sModelo, } = aUidadInfoBasica;
            const sDesc = `${sDescripcion} ${sModelo} ${nYear}`
            setDescripcionUnidad(sDesc);
        }

    }, [aUidadInfoBasica])
    const xl = 12;   
    return (
        <ThemeProvider theme={defaultTheme}>

            {loading ? <CircularLoader nSize={133} nHeight={100} /> : <Container sx={StylesUnidades['unidades-container']}>
                <ModalMUI360 oOpen={open} setOpen={setOpen} sDescripcion={sDescripcionUnidad}>
                    <UnidadGaleria360
                        id="gurkha-suv"
                        folder={sURL}
                        filename={`{index}.${sType}`}
                        amount={`${nCantidad}`}                     
                        magnifierSize="200px"
                        imagesList={aUnidad360v2.map(image => image.sURL)}
                    />
                </ModalMUI360>
                <Grid container sx={{
                    justifyContent: 'space-between', flexDirection: {
                        xl: 'row',
                        lg: 'row',
                        md: 'column-reverse',
                        sm: 'column-reverse',
                        xs: 'column-reverse',
                    },
                    gap: {
                        xl: '0',
                        lg: '0',
                        md: '25px',
                        sm: '25px',
                        xs: '25px',
                    },
                    alignItems: {
                        xl: 'start',
                        lg: 'start',
                        sm: 'center',
                        xs: 'center',

                    }
                }}>
                    <Grid item

                        xl={8}
                        lg={8}
                        md={10}
                        sm={10}
                        xs={12}
                    >
                        <Grid container >
                            <Grid item xs={12}>
                                <UnidadCarrusel aImages={aUnidadCarrusel?.sort((a, b) => Number(b.bVista360) - Number(a.bVista360))} callback={setOpen} />
                            </Grid>
                            <Grid item xs={12}>
                                <UnidadInfoBasica aUidadInfoBasica={aUidadInfoBasica} />
                            </Grid>
                            <Grid item xs={12} mt={'25px'}>
                                <UnidadCaracteristicas aCaracteristicas={aCaracteristicas} />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item 
                    xl={4}
                    lg={3.6}
                    md={10}
                    sm={10}
                    xs={12}
                    
                    sx={StylesUnidades['detalles-unidad']}>
                        <UnidadDetallesBasicos aUidadInfoBasica={aUidadInfoBasica} />                        
                    </Grid>

                </Grid>
                <Grid container
                    sx={{
                        justifyContent: {
                            xs: 'center'
                        }
                    }}
                >

                    <Grid item
                        xl={xl}
                        lg={12}
                        md={12}
                        sm={10}
                        xs={12}

                    >
                        <Divider sx={{ marginTop: '66px', marginBottom: '0' }} />
                        <UnidadImperfecciones aUnidadImperfeciones={aUnidadImperfeciones} />
                    </Grid>
                    <Grid item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={10}
                        xs={12}
                    >
                        <Divider sx={{ margin: '33px 0', }} />
                        <UnidadSugerencias nIdActivo={id} />
                    </Grid>

                </Grid>

            </Container>}
        </ThemeProvider>
    )
}

export default UnidadDetalles
