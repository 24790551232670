// Importando módulos necesarios
import React, { useRef, useState } from 'react';
import '../../../../../styles/registro/global.scss'
import CssBaseline from '@mui/material/CssBaseline';
import sha1 from 'js-sha1';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom'; // Hook para la navegación
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'sonner';
import { urlsAPIs } from '../../../../../helpers/urlsAPIs';
import { globalMessage } from '../../../../../helpers/globalMessage';
import { createTheme, ThemeProvider } from '@mui/material/styles'; // Herramientas de MUI para el tema
import CargaVideoMovilForm from './CargaVideoMovilForm';
import { CargaVideoIndicacionesPantalla } from './CargaVideoIndicaciones';
import { ProgressBarContext } from '../../../../../context/ProgressBarContext';
import { useContext } from 'react';

// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});

/**
 * Componente de carga de video para dispositivos móviles
 * @author ERL 2023-06-09 09:41 am 
 * @returns {JSX.Element} 
*/
const CargaVideoWeb = () => {
    const { headers } = useContext(ProgressBarContext);

    const [bIsMobile, setIsMobile] = useState(false);

    const params = useParams();
    const { id, token } = params;
    const [nIdUsuario, setIdUsuario] = useState(0);
    const socketRef = useRef();

    // Estado del componente
    const [dataDocumentos, setDataDocumentos] = useState([]);
    const [bLoadingComponent, setLoadingComponent] = useState(true);

    /**
     * Este efecto se activa al montar el componente. Realiza varias acciones relacionadas con la conexión WebSocket y la obtención de datos de documentos.
     * Primero, se calcula un hash a partir de la combinación de una semilla, el ID de usuario y se compara con el hash recibido.
     * Si los hashes coinciden, se establece la conexión WebSocket y se determina si el usuario está accediendo desde un dispositivo móvil.
     * Si el dispositivo es móvil, se maneja el evento de apertura de la conexión y se envía un mensaje al servidor.
     * Luego, se obtienen los datos de documentos mediante una solicitud GET a la API y se ordenan por su número de orden.
     * Si la respuesta de la API tiene un código de respuesta igual a 0, se actualiza el estado 'dataDocumentos'.
     * Si ocurre un error durante la solicitud, se maneja en la sección 'catch' del bloque 'try-catch'.
     * A continuación, se configuran los eventos para manejar mensajes entrantes, el cierre de la conexión y errores en la conexión WebSocket.
     * Finalmente, se realiza una limpieza cerrando la conexión cuando el componente se desmonta.
     *      
     * @author ERL 2023-07-17 02:38 pm
     * @returns {void}
     * @hook useEffect - Hook de React que se activa al montar el componente.
     */

    useEffect(() => {
        const userID = id;
        const receivedHash = token;
        const seed = process.env.REACT_APP_HASH_SEED;
        const messageToHash = seed + userID;
        const calculatedHash = sha1(messageToHash);
        if (calculatedHash === receivedHash) {
            // Reemplaza esto con la URL de tu socket
            socketRef.current = new WebSocket(urlsAPIs.urlSocketVideo);
            const userAgent = navigator.userAgent;
            const bIsMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent); //Mover a regrex
            setIsMobile(bIsMobileDevice)
            // Determinar si el usuario está accediendo desde un dispositivo móvil
            if (bIsMobileDevice) {
                // Evento para manejar la conexión
                socketRef.current.onopen = () => {
                    socketRef.current.send(JSON.stringify({ action: 'sendVideo', nIdUsuario: userID }));
                };
            }
            setIdUsuario(userID);
            const getData = async () => {
                try {
                    const response = await axios.get(`${urlsAPIs.urlDocumentos}/${userID}`, {
                        params: {
                            sTipo: "V" // Tipo video 
                        },
                        headers
                    });
                    const { nCodigo, sMensaje, data: formDocumentos } = response.data;
                    // Ordenar los documentos por su número de orden
                    formDocumentos.sort((a, b) => a.nOrden - b.nOrden);

                    if (nCodigo === 0) {
                        setDataDocumentos(formDocumentos); // Descomentar para consumir API
                        //setDataDocumentos(dtDocsV.data.sort((a, b) => a.nOrden - b.nOrden)); // Descomentar para consumir dummy
                    } else {
                        toast.error(sMensaje)
                    }
                } catch (error) {
                    toast.error(globalMessage.errorServidor)
                } finally {
                    setLoadingComponent(false);
                }
            }
            getData();
        } else {
            toast.error(globalMessage.errorServidor);
        }
        // Limpieza después de que el componente se desmonte
        return () => {
            socketRef.current.close();
        };

    }, [id, token]);
    const ContainerStyles = { maxWidth: { xs: '100%', sm: '400px', md: '600px', lg: '700px', }, display: 'flex', flexDirection: 'column', justifyContent: 'start', };
    const sMensaje = "Por favor, accede a tu teléfono para poder grabar el video.";
    return (

        <>
            {bIsMobile ?
                <ThemeProvider theme={defaultTheme}>
                    <Container component="main" maxWidth="sm" sx={ContainerStyles}>
                        <CssBaseline />
                        <CargaVideoMovilForm
                            bLoadingComponent={bLoadingComponent}
                            dataDocumentos={dataDocumentos}
                            bIsMobile={bIsMobile}
                            nIdUsuario={nIdUsuario}
                            setDataDocumentos={setDataDocumentos}
                        />
                    </Container>
                </ThemeProvider>
                :
                <CargaVideoIndicacionesPantalla sMensaje={sMensaje} />
            }
        </>

    );
}

export default CargaVideoWeb
