import React from 'react'

import { StyledButton2 } from '../../../../customButtons/StyledButton'
import SmallCircularLoader from '../../../../loader/SmallCircularLoader'
import { Grid } from '@mui/material'
/**
 * Componente para manejar los botones de envío del Stepper de Referencias.
 * Este componente renderiza el botón de envío final cuando el usuario llega al último paso del Stepper.
 * 
 * @author ERL 2023-07-19 09:20 pm
 * @param {object} Styles - Estilos del componente.
 * @param {boolean} isFirstStep - Indica si el paso actual es el primer paso.
 * @param {boolean} isLastStep - Indica si el paso actual es el último paso.
 * @param {function} prevStep - Función que maneja el paso previo.
 * @param {string} sAnterior - Texto del botón anterior.
 * @param {string} sSiguiente - Texto del botón siguiente.
 * @param {boolean} bLoading - Estado de carga, se usa para deshabilitar los botones durante la carga.
 * @param {string} sCompletar - Texto del botón de completar.
 * 
 * @returns {JSX.Element}
 */

const ReferenciasStepperButtonsPend = ({Styles, isFirstStep, isLastStep, prevStep, sAnterior, sSiguiente, bLoading, sCompletar}) => {
    
    return (
        <Grid sx={Styles}>            
            {!isFirstStep &&
                <StyledButton2
                    width="100%"
                    type="button"
                    onClick={prevStep} >
                    {sAnterior}
                </StyledButton2>
            }
            {!isLastStep && (
                <StyledButton2
                type="submit" 
                width="100%" disabled={bLoading} >                    
                    {bLoading ? <SmallCircularLoader /> : sSiguiente}
                </StyledButton2>
            )}
            {isLastStep && (
                <StyledButton2 width="100%" disabled={bLoading}>
                    {bLoading ? <SmallCircularLoader /> : sCompletar}
                </StyledButton2>
            )}

        </Grid>
    )
}

export default ReferenciasStepperButtonsPend