import { Link, useLocation } from "react-router-dom";
import "./menu.scss"
import React, { useEffect } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles';
import { HashLink } from "react-router-hash-link";

/**
 * Componente de Menú
 * @author ERL 2023-06-09 01:00 pm
 * @param {boolean} menuOpen - Indica si el menú está abierto o cerrado
 * @param {function} setMenuOpen - Función para establecer el estado del menú
 * @returns {JSX.Element} - Retorna el componente del menú
 */
export default function Menu({ menuOpen, setMenuOpen }) {
    const theme = createTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const { pathname } = location;
    const jwt = sessionStorage.getItem('t');
    useEffect(() => {
        if (isMobile) {
            if (pathname !== '/' || pathname.includes("#")) {
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }, 100);
            }
        }
    }, [pathname, isMobile]);

    return (
        <div className={"menu " + (menuOpen && "active")}>
            <ul>
                <li style={{ margin: "5px 25px" }} onClick={() => setMenuOpen(false)}>
                    <Link to={`/${jwt < 1 ? "login" : "perfil"}`}>
                        <AccountCircleIcon className="iconUserMobile"></AccountCircleIcon>
                    </Link>
                </li>
                <li>
                    <HashLink smooth to={{ pathname: "/", hash: "#modelos" }}>Modelos</HashLink>
                </li>
                <li>
                    <HashLink smooth to={{ pathname: "/", hash: "#requisitos" }}>¿Cómo funciona?</HashLink>
                </li>
                <li>
                    <HashLink smooth to={{ pathname: "/", hash: "#beneficios" }}>Beneficios</HashLink>
                </li>
                {/* <li>
                    <Link to="/seminuevos" preventScrollReset={true}>Seminuevos</Link>
                </li> */}
                <li>
                    <Link to="/visitanos" preventScrollReset={true}>Contacto</Link>
                </li>
            </ul>
        </div>
    )
}
