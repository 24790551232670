import React, { useEffect, useState } from 'react';



const CustomTextarea = ({
  name,
  label,
  setIsValidForm,
  callback,
  value,
  inputRef,
  isValidForm,
  setSubmitAttempted
}) => {
  const [touched, setTouched] = useState(value.trim() !== '');

  useEffect(() => {
    if (isValidForm && value.length < 1) {
      setTouched(true);
    }
  }, [value, isValidForm]);

  const handleFocus = () => {
    setTouched(true);
  };

  const handleBlur = (e) => {
    const isValueEmpty = e.target.value.trim() === '';
    setIsValidForm(!isValueEmpty);

    setTouched(!isValueEmpty);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    callback(newValue);

    const isValueEmpty = newValue.trim() === '';

    setTouched(!isValueEmpty);
    setSubmitAttempted(false);
  };


  return (
    <div className="form-item">
      <label className={`${touched || value !== '' ? 'label-a ' : ''}`} htmlFor={label}>
        {label}:
      </label>
      <textarea
        className="input-style"
        name={name}
        id={label}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        ref={inputRef}
      />
    </div>
  );
};

export default CustomTextarea;
