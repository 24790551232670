import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

/**
 * Componente de Modal
 * @author ERL 2023-06-09 12:55 pm
 * @param {boolean} isOpen - Indica si el modal está abierto o cerrado
 * @param {function} onClose - Función para cerrar el modal
 * @param {ReactNode} children - Contenido del modal
 * @param {Ref} btnRef - Referencia al botón de cierre del modal
 * @returns {JSX.Element} - Retorna el componente del modal
 */
const Modal = ({ isOpen, onClose, children, btnRef }) => {
    const [display, setDisplay] = useState(isOpen);

    useEffect(() => {
        if (isOpen) {
            setDisplay(true);
        }
    }, [isOpen]);

    /**
     * Maneja el cierre del modal
     */
    const handleClose = () => {
        setDisplay(false);
        setTimeout(onClose, 500);
    };

    if (!display) {
        return null;
    }
    
    return ReactDOM.createPortal(
        <>
            <div className="modal-overlay">
                <div className={`modal-content ${isOpen ? 'open' : 'close'}`} onClick={(e) => e.stopPropagation()}>
                    <div ref={btnRef} onClick={handleClose} className='modal-close'>
                        <svg viewBox="0 0 24 24" focusable="false" className="chakra-icon css-onkibi" aria-hidden="true">
                            <path fill="currentColor" d="M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"></path>
                        </svg>
                    </div>
                    {children}
                </div>
            </div>
        </>,
        document.getElementById('modal-root')
    );
};

export default Modal;
