import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Unidad from '../Unidad';
import { useUnidades } from '../../../hooks/unidades/useUnidades';
import { LANG } from '../LANG';
import styled from 'styled-components';

/**
 * Componente para mostrar sugerencias de unidades. Las unidades se obtienen de manera aleatoria y se muestran en un carrusel.
 * @author ERL 2023-08-10 04:00 pm
 * @param {object} props - Propiedades del componente
 * @field {int} nIdActivo - Id de la unidad
 * @returns {JSX.Element}
 */
function UnidadSugerencias({ nIdActivo }) {
    const { currentUnidades, obtenerDatos } = useUnidades();
    const [start, setStart] = useState(0);
    const [shuffledUnidades, setShuffledUnidades] = useState([]);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    let displayCount = 4; // Por defecto mostramos 4 elementos para pantallas grandes

    if (isDesktop) {
        displayCount = 3; // En tabletas mostramos 3 elementos
    }

    if (isTablet) {
        displayCount = 2; // En tabletas mostramos 3 elementos
    }
    if (isMobile) {
        displayCount = 1; // En móviles mostramos 1 elemento
    }

    /**
     * Se ejecuta después de que el componente se haya renderizado.
     * Se utiliza para obtener datos inicialmente cuando el componente se monta por primera vez.
     * 
     * @author ERL 2023-08-10 04:23 pm
     */
    useEffect(() => {
        obtenerDatos();
    }, []);


    /**
     * Se ejecuta después de que el componente se haya renderizado y cada vez que 'currentUnidades' cambie.
     * Si 'currentUnidades' tiene datos y 'shuffledUnidades' aún no está configurado, se mezclarán las unidades actuales
     * y se establecerán las primeras 8 unidades mezcladas en 'shuffledUnidades'.
     * 
     * @author ERL 2023-08-10 04:41 pm
     * @returns {void}
     */
    useEffect(() => {
        // Establecer unidades aleatorias si aún no están configuradas
        if (currentUnidades.length && !shuffledUnidades.length) {
            const aUnidades = currentUnidades?.filter(u => u.nIdActivo === Number(nIdActivo))[0];
            if(aUnidades){
                const { nIdUbicacion = 0 } = aUnidades;
                const shuffleUnidades = currentUnidades?.filter(u => u.nIdUbicacion === Number(nIdUbicacion));        
                //setIdUbicacion(nIdUbicacion)

                setShuffledUnidades(shuffleArray(shuffleUnidades).slice(0, 8));
            }
        }
    }, [currentUnidades]);


    /**
      * Mezcla de manera aleatoria los elementos de un array.
      * 
      * @author ERL 2023-08-10 05:11 pm
      * @param {Array} array - El array que se desea mezclar.
      * @returns {Array} - Retorna un nuevo array que es una versión aleatoriamente mezclada del array original.
      */
    const shuffleArray = (array) => {
        let shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    /**
    * Avanza al siguiente conjunto de elementos en la lista.
    * Incrementa el valor de 'start' en base al 'displayCount'.
    *
    * @author ERL 2023-08-10 05:38 pm
    * @returns {void}
    */
    const handleNext = () => {
        setStart(prevStart => prevStart + displayCount);
    };

    /**
     * Retrocede al conjunto anterior de elementos en la lista.
     * Decrementa el valor de 'start' en base al 'displayCount'.
     * 
     * @author ERL 2023-08-10 06:16 pm
     * @returns {void}
     */
    const handleBack = () => {
        setStart(prevStart => prevStart - displayCount);
    };

    return (
        <>

            <StyledBox flexDirection={isMobile ? 'column' : 'row'}>
                <Grid

                    sx={{
                        textAlign: 'center'
                    }}
                >

                    <Typography variant='h5' color={"primary"}>
                        {LANG.labelSugerencia}
                    </Typography>

                </Grid>
                <StyledBox
                    sx={{
                        padding: '10px 0'
                    }}
                >
                    {start > 0 && (
                        <StyledButton left onClick={handleBack}>
                            <KeyboardArrowLeft />
                        </StyledButton>
                    )}
                    {shuffledUnidades.slice(start, start + displayCount).map(unidad => (
                        <Unidad
                            key={unidad.sDescripcion}
                            nId={unidad.nIdActivo}
                            sURL={unidad.sURL}
                            sDescripcion={unidad.sDescripcion}
                            nPrecio={unidad.nPrecio}
                            sStatatus={unidad.sStatatus}
                        />
                    ))}
                    {start + displayCount < shuffledUnidades.length && (
                        <StyledButton right onClick={handleNext}>
                            <KeyboardArrowRight />
                        </StyledButton>
                    )}
                </StyledBox>
            </StyledBox>
        </>
    );
}
const StyledBox = styled(Box)(({ flexDirection = 'row' }) => ({
    display: 'flex',
    flexDirection: flexDirection,
    flexWrap: 'wrap',
    overflow: 'hidden',
    width: '100%',
    justifyContent: 'center',
    position: 'relative',
    gap: '15px',

}));

const StyledButton = styled(Box)(({ theme, left, right }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    left: left ? 0 : 'auto',
    right: right ? 0 : 'auto',
}));

export default UnidadSugerencias;
