import axios from 'axios';
import React from 'react'

import { toast } from 'sonner';
import { urlsAPIs } from '../../helpers/urlsAPIs';
import { useNavigate } from 'react-router';
import { regex } from '../../helpers/regex';
import { globalMessage } from '../../helpers/globalMessage';
/**
 * Logear usuario
 * @author ERL 2023-06-09 12:17 pm 
 * @param {object} data - Datos del usuario para el login
 * @param {string} sCorreo - Correo del usuario
 * @param {string} sClientId - ClientId del usuario
 * @param {string} sTokenGoogle - Token del usuario
 * @param {string} sPassword  - Password del usuario
 * @param {string} sTipoLogin  - Tipo login del usuario
 * @returns object
 */
export function useLogin(setLoading, setLoadingRecuperar, setError,) {
    let error = `¡Ups! Parece que ha ocurrido un error inesperado. Por favor, intenta nuevamente más tarde.`
    let exito = `¡Inicio de sesión exitoso! ¡Bienvenido/a de vuelta!`
    let recuperarExito = `Se ha enviado una contraseña temporal a tu correo`
    const navigate = useNavigate();
    const logearUsuario = async (data) => {
        try {
            setLoading(true);
            const { sCorreo: correoUsuario } = data;
            const response = await axios.post(urlsAPIs.urlLoginApi, data);
            const { jwt, sMensaje, validaCodigo, nCodigo, nIdUsuario, nIdStatusProspecto, nIdCliente, nIdProspecto } = response.data;            
            if (nCodigo === 0) {
                if (validaCodigo) {
                    localStorage.setItem('confirmacionPendiente', true);
                    localStorage.setItem('correoNuevoUsuario', data.sCorreo);
                    //  navigate('/registro');
                    toast.error(sMensaje)
                    return;
                }
                if (jwt.length > 0) { 
                    localStorage.removeItem('confirmacionPendiente');
                    if (nIdStatusProspecto !== 8) {                        
                        sessionStorage.setItem('t', jwt); // Token
                        sessionStorage.setItem('u', nIdUsuario); // Usuario id
                        sessionStorage.setItem('p', nIdProspecto); // Prospecto id
                        sessionStorage.setItem('c', correoUsuario); // Correo usuario
                        sessionStorage.setItem('ci', nIdCliente); // Cliente id
                        sessionStorage.setItem('s', nIdStatusProspecto); // Status
                        toast.success(sMensaje);
                        navigate('/proceso');
                    } else {
                        toast.error(globalMessage.errorProspecto)
                    }

                } else {
                    toast.error(sMensaje)
                }
            } else {
                toast.error(sMensaje)
            }
        } catch (error) {            
            toast.error('Hubo un error al iniciar sesión.')
        } finally {
            setLoading(false);
        }
    };

    const logOutUsuario = async (data) => {
        sessionStorage.removeItem('t');
        sessionStorage.removeItem('u');
        sessionStorage.removeItem('numero');
        sessionStorage.removeItem('numeroValido');

        navigate('/login');
    };

    /**
     * Eenvía un password temporal al usuario
     * @param {string} sCorreo - Datos del usuario para el reenvío del password temporal
     */

    const recuperarPassword = async (sCorreo) => {
        try {
            if (sCorreo === '' || !regex.isValidCorreo.test(sCorreo)) {
                setError("sCorreo", {
                    type: "manual",
                    message: "introduce un correo válido."
                });
                return;
            }
            setLoadingRecuperar(true);
            const response = await axios.post(urlsAPIs.urlRecuperarPassword, { sCorreo });
            const { nCodigo } = response.data;
            if (nCodigo === 0) {
                toast.success(recuperarExito)
            } else {
                toast.error(error)
            }
        } catch (error) {            
            toast.error('Hubo un error al recuperar la contraseña.');
        } finally {
            setLoadingRecuperar(false);
        }
    };
    return { logearUsuario, logOutUsuario, recuperarPassword }
}
