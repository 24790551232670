import React from 'react'
import { StyledButton, StyledButton3 } from "../../components/customButtons/StyledButton";

import { useNavigate } from 'react-router'
import './autosFinal.scss';
const AutosFinal = () => {
    const navigate = useNavigate();
    return (
        <div className='autos-final'>
            <div className='autos-final-container'>
                <div className='autos-final-txt'>
                    <h1>¿Qué estas esperando?</h1>
                </div>
                <div className='autos-final-txt'>                    
                    <p>Somos tu mejor aliado, se parte de la familia BESTA</p>
                </div>
                <div className='autos-final-btn btn-final-desk'>
                    <StyledButton3 width="300px" onClick={() => navigate('/contacto')}>Contáctanos</StyledButton3>
                </div>
            </div>

            <div className='autos-final-img'>
                <img src="/assets/autos/AUTOS3x.png" alt="" />
            </div>
            <div className='autos-final-btn btn-final-mobile'>
                    <StyledButton3 width="300px" onClick={() => navigate('/contacto')}>Contáctanos</StyledButton3>
            </div>
        </div>
    )
}

export default AutosFinal
