import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { dataAutos as dataAutosMTY } from './dataMTY';
import { dataAutos as dataAutosGDL } from './dataGDL';
import { LANG } from './LANG';
const VehiculosDisponibles = () => {
  const [data, setData] = useState(dataAutosMTY);
  const localSucursal = localStorage.getItem('sucursal');
  const [activeTab, setActiveTab] = useState(localSucursal ? localSucursal : "MTY"); // Añade un estado para el tab activo
  useEffect(() => {
    window.scrollTo(0, 0);
    if (localSucursal) {
      SwitchTab(localSucursal);
    }
  }, [localSucursal]);
  const SwitchTab = (id) => {
    setActiveTab(id); // Actualiza el tab activo
    if (id === "MTY") {
      setData(dataAutosMTY);
      localStorage.setItem('sucursal', id);
    }
    if (id === "GDL") {
      setData(dataAutosGDL);
      localStorage.setItem('sucursal', id);
    }
  }
  const autosIcons = {
    "nuevo":"/assets/autos/iconos/autosNuevo.png",
    "estandar":"/assets/autos/iconos/autosEstandar.png",
    "year":"/assets/autos/iconos/autosYear.png"
  }
  return (
    <div className='vehiculos-container'>
      <div className='vehiculos-container-title'>
        <p>
          {`Catalago de autos`}
        </p>
        <hr />
      </div>
      <div className="autos2" id="modelos">        
        <div className="autos-besta-tab">
          <div className='autos-besta-tab-menu'>
            <div className={`autos-besta-tab-menu-item ${activeTab === "MTY" ? 'activeTab' : ''}`} onClick={() => SwitchTab("MTY")}>{LANG.labelMty}</div>
            <div className={`autos-besta-tab-menu-item ${activeTab === "GDL" ? 'activeTab' : ''}`} onClick={() => SwitchTab("GDL")}>{LANG.labelGdl}</div>
          </div>
          <div className='autos-besta-tabs'>
            {data.map((item, i) => (
              <Link to={`/cotizacion/${item.id}`} className="link">
              <div className="autos-besta-tabs-item">
                <div className='autos-besta-tabs-card'>
                  <div className="autos-besta-tabs-card-top">
                    <h2>{item.marca}</h2>
                    <h3>{item.modelo}</h3>
                    <div className='autos-besta-tabs-card-img'>
                      <img src={item.imagen} alt="Autos besta" />
                    </div>
                  </div>
                  <div className="autos-besta-tabs-card-bottom">
                    <div className='autos-besta-tabs-card-bottom-item-icons'>
                      <div className='car-card-icon-container'>
                        <div className='car-card-icon-img nuevo'>
                            <img src={autosIcons["nuevo"]} alt="" />
                        </div>
                        <div className='car-card-icon-txt'>
                            {LANG.labelNuevo}
                        </div>
                      </div>
                      <div className='car-card-icon-container'>
                        <div className='car-card-icon-img estandar'>
                            <img src={autosIcons["estandar"]} alt="" />
                        </div>
                        <div className='car-card-icon-txt'>
                            {LANG.labelEstandar}
                        </div>
                      </div>
                      <div className='car-card-icon-container'>
                        <div className='car-card-icon-img year'>
                            <img src={autosIcons["year"]} alt="" />
                        </div>
                        <div className='car-card-icon-txt'>
                            {LANG.labelYear}
                        </div>
                      </div>
                    </div>
                    <div className='autos-besta-tabs-card-bottom-item'>
                      <div className='autos-besta-tabs-card-bottom-txt'>
                        <p><strong>{item.montoSemanal}</strong> / {LANG.labelSemanal}</p>
                      </div>
                      <div className='autos-besta-tabs-card-bottom-btn'>
                        <button>{LANG.labelLoQuiero}</button>
                      </div>
                    </div>
                    <div className='autos-besta-tabs-card-bottom-item'>
                      <div className='autos-besta-tabs-card-bottom-txt2'>
                        <br></br><p className="texto">{item.texto}</p>
                        <p>{LANG.labelPrecioSujeto}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehiculosDisponibles
