import React from 'react'
import DriversMain from '../../components/drivers/DriversMain'
/**
 * @author ERL 2023-09-20 02:26 pm
 * @returns {JSX.Element}
 */
const Drivers = () => {
  return (
    <DriversMain/>
  )
}

export default Drivers
