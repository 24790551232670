// Importación de módulos y componentes necesarios
import React, { useState } from 'react';
import './registro.scss'; // Importación de estilos
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'; // Herramientas de MUI para el tema
import useMediaQuery from '@mui/material/useMediaQuery'; // Hook para detectar cambios de tamaño de pantalla
import { StyledButton2 } from '../../../components/customButtons/StyledButton'; // Botón personalizado
import { useNavigate } from 'react-router-dom'; // Hook para la navegación

import { useForm } from 'react-hook-form';
import BotonConTemporizador from './BotonConTemporizador';
import { makeStyles } from '@material-ui/core';
import { useConfirmar } from '../../../hooks/usuariosApi/useConfirmar';
import { useRef } from 'react';
import { CircularProgress } from '@mui/material';

// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});

const useStyles = makeStyles({
    centeredInput: {
        textAlign: 'center',
        '& input': {
            textAlign: 'center',
        },
    },
});

/**
 * Componente de Confirmación de Registro
 * @author ERL - 2023-06-09 12:55 pm
 * @param {object} props - Propiedades del componente
 * @param {string} props.correo - Correo del usuario para confirmar el registro
 * @returns {JSX.Element} - Retorna el componente de Confirmación de Registro
 */
export default function ConfirmarRegistro({ correo }) {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const handlePaste = (event) => {
        event.preventDefault();
        const paste = event.clipboardData.getData('text');
        if (paste.length === 6 && /^[0-9]+$/.test(paste)) {
            paste.split("").forEach((digit, index) => {
                setValue(`codigoC${index + 1}`, digit);
            });
        }
    };
    const [loadingVerificar, setLoadingVerificar] = useState(false);
    const [loadingReEnvio, setLoadingReEnvio] = useState(false);
    // Definición de estado inicial para los campos de registro    
    const [codigoRegistro, setCodigoRegistro] = useState(null);

    const { confirmarRegistro, reEnviarCodigo, codigoReEnviado } = useConfirmar(setLoadingVerificar, setLoadingReEnvio);
    // Uso del tema actual
    const theme = useTheme();
    const [correoNuevoUsuario, setCorreoNuevoUsuario] = useState(localStorage.getItem('correoNuevoUsuario') ? localStorage.getItem('correoNuevoUsuario') : "");
    /**
     * Maneja el envío del formulario de confirmación de registro
     * @param {object} data - Datos del formulario
     */
    const onSubmit = (data) => {
        const codigo = `${data.codigoC1}${data.codigoC2}${data.codigoC3}${data.codigoC4}${data.codigoC5}${data.codigoC6}`
        const datosUsuario = {
            sCorreo: correo !== '' ? correo : correoNuevoUsuario,
            nCodigo: codigo,
        }
        confirmarRegistro(datosUsuario);
    };
    const classes = useStyles();
    // Detectando el tamaño del dispositivo para ajustar el estilo del componente
    const mediumDevices = useMediaQuery(theme.breakpoints.down('md'));
    const smallDevices = useMediaQuery(theme.breakpoints.down('sm'));

    // Definición de variables de estilo en función del tamaño del dispositivo
    const fontSizeC = mediumDevices ? 'h5' : 'p';
    const fontSizeV = mediumDevices ? 'h5' : 'h6';
    const marginSidesAndBottom = mediumDevices ? 3 : 0;
    const marginTop = mediumDevices ? 3 : 3;
    const marginTop2 = mediumDevices ? 8 : 0;
    const displayStyle = smallDevices ? 'flex' : 'none';

    // Hook para la navegación
    const navigate = useNavigate();
    const inputsRef = useRef([]);

    const handleInput = (event, index) => {
        if (event.target.value.length === 1 && index < 5) {
            inputsRef.current[index + 1].focus();
        }
    };


    // Renderizando el componente
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container
                component="main"
                maxWidth="sm"
                sx={{
                    maxWidth: {
                        xs: '100%', // Para dispositivos extra pequeños, el contenedor tomará el ancho completo
                        sm: '400px', // Para dispositivos pequeños y mayores, el contenedor tendrá un ancho mínimo de 400px
                        md: '600px', // Para dispositivos pequeños y mayores, el contenedor tendrá un ancho mínimo de 400px
                        lg: '700px', // Para dispositivos pequeños y mayores, el contenedor tendrá un ancho mínimo de 400px
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: marginTop2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component={fontSizeC} variant={fontSizeV}>
                        Código de confirmación
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: marginTop, mx: marginSidesAndBottom, mb: marginSidesAndBottom }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} xl={12} justifyContent="space-between" display="flex" style={{
                                gap: "15px"
                            }} >
                                {[...Array(6)].map((_, index) => (
                                    <TextField
                                        required
                                        fullWidth
                                        label=""
                                        name={`codigoC${index + 1}`}
                                        inputMode="numeric"
                                        {...register(`codigoC${index + 1}`, {
                                            required: "Invalido.",
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: "solo números",
                                            }
                                        })}
                                        aria-invalid={errors[`codigoC${index + 1}`] ? "true" : "false"}
                                        id={`${errors[`codigoC${index + 1}`]?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                        placeholder='0'
                                        error={Boolean(errors[`codigoC${index + 1}`])}
                                        helperText={<><center>{errors[`codigoC${index + 1}`]?.message} </center></>}
                                        className={classes.centeredInput}
                                        inputProps={{ maxLength: 1, textAlign: "center" }}
                                        onPaste={handlePaste}
                                        onChange={(event) => handleInput(event, index)}
                                        inputRef={(ref) => inputsRef.current[index] = ref}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                        <Grid container sx={{ my: 2, ml: 0, mr: 0, p: 0 }} justifyContent="center" display={"flex"}>
                            <StyledButton2 width="100%">
                                {loadingVerificar ? <CircularProgress color="inherit"/>:"Confirmar"}
                            </StyledButton2>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} display="flex" justifyContent={'center'} textAlign="center">
                                <BotonConTemporizador codigoReEnviado={codigoReEnviado}>
                                    <Grid item>
                                        <Typography variant="p" className='link-style' onClick={() => reEnviarCodigo(correoNuevoUsuario)}>
                                            {loadingReEnvio ? <CircularProgress color="inherit"/>: "¿No recibiste tu código? Reenviar"}
                                        </Typography>
                                    </Grid>
                                </BotonConTemporizador>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
