import React from 'react'

import axios from 'axios';
import { toast } from 'sonner';
import { useState } from 'react';
import { urlsAPIs } from '../../helpers/urlsAPIs';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import { globalMessage } from '../../helpers/globalMessage';
/**
 * Confirmar registro nuevo usuario
 * @author ERL 2023-06-09 12:21 pm 
 * @param {object} data - Datos del usuario para la confirmacion del registro
 * @param {string} sCorreo - Correo del usuario
 * @param {int} nCodigo - Codigo de confirmacion del usuario
 * @returns {object} - Retorna el objeto con las funciones para confirmar el registro y reenviar el código
 */
export function useVideo() {

    const [bIsMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const bIsMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent); //Mover a regrex
        setIsMobile(bIsMobileDevice)

    }, []);


    return { bIsMobile }
}
