// Importando módulos necesarios
import React, { useState } from 'react';
import '../../../../../styles/registro/global.scss'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles'; // Herramientas de MUI para el tema
import { useEffect } from 'react';
import { toast } from 'sonner';
import axios from 'axios';
import { urlsAPIs } from '../../../../../helpers/urlsAPIs';
import { globalMessage } from '../../../../../helpers/globalMessage';
import CircularLoader from '../../../../loader/CircularLoader';
import CargaVideoExito from './CargaVideoExito';
import { useVideo } from '../../../../../hooks/usuariosApi/useVideo';
import CargaVideoMovil from './CargaVideoMovil';
import { LANG } from './LANG';
import { useStyles } from '../../../../../hooks/stylesMUI/useStyles';
import { useLogin } from '../../../../../hooks/usuariosApi/useLogin';

// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});

/**
 * Componente carga de imagenes del entorno 
 * @author ERL 2023-06-09 09:41 am 
 * @param {object} headers - Headers para las llamadas API.
 * @returns {JSX.Element} 
*/
const CargaVideo = ({ headers }) => {

    const { bIsMobile } = useVideo();
    const { logOutUsuario:logout } = useLogin();
    const [dataDocumentos, setDataDocumentos] = useState([]);
    const [loadingComponent, setLoadingComponent] = useState(true);

    /**
     * Este efecto se activa al montar el componente. Realiza una solicitud GET a la API para obtener los documentos del usuario.
     * Los documentos se ordenan por su número de orden y se establecen en el estado 'dataDocumentos'.
     * @author ERL 2023-07-17 03:34 pm 
     * @returns {void}
     * @hook useEffect - Hook de React que se activa al montar el componente.
     */
    const [nIdUsuario, setIdUsuario] = useState(0);
    useEffect(() => {

        const getData = async () => {
            const id = sessionStorage.getItem('u');
            setIdUsuario(id)
            try {
                const response = await axios.get(`${urlsAPIs.urlDocumentos}/${id}`, {
                    params: {
                        sTipo: "V"
                    },
                    headers:headers
                });
                const { nCodigo, sMensaje, data: formDocumentos } = response.data;
                formDocumentos.sort((a, b) => a.nOrden - b.nOrden);

                if (nCodigo === 0) {
                    setDataDocumentos(formDocumentos);
                } else {
                    toast.error(sMensaje)
                }
            } catch (error) {
                const { responseCode = 0, message = "" } = error?.response.data
                if (responseCode === '401' && message === 'jwt expired') {                    
                    logout()
                } else {                
                    toast.error(globalMessage.errorServidor);                    
                }
            } finally {
                setLoadingComponent(false);
            }
        }
        getData();
    }, []);

    const { StylesVideo360 } = useStyles();
    const [bTodosPendientes, setTodosPendientes] = useState(null);
    useEffect(() => {
        if (dataDocumentos.length > 0) {
            setTodosPendientes(dataDocumentos.length > 0 && dataDocumentos.every((doc) => ["PENDIENTE", "VALIDANDO"].includes(doc.sStatus)));
        }
    }, [dataDocumentos])


    // Renderizando el componente
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="sm" sx={StylesVideo360["container"]}>
                <CssBaseline />
                {loadingComponent ? <CircularLoader /> :
                    dataDocumentos.length > 0 && dataDocumentos.every((doc) => doc.sStatus === "VALIDADO") ?
                        <>
                            <CargaVideoExito sMensaje={LANG.labelMensajeExito} bSinBordes={true} />
                        </> :
                        <CargaVideoMovil
                            nIdUsuario={nIdUsuario}
                            bIsMobile={bIsMobile}
                            bTodosPendientes={bTodosPendientes}
                            dataDocumentos={dataDocumentos}
                            setDataDocumentos={setDataDocumentos}
                            bLoadingComponent={loadingComponent}
                            setLoadingComponent={setLoadingComponent}

                        />
                }
            </Container>
        </ThemeProvider>
    );
}
export default CargaVideo;