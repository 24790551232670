import { CircularProgress } from '@mui/material'
import React from 'react'
/**
 * Componente que muestra un pequeño círculo de carga (loader) con el color heredado del tema actual.
 * @author ERL 2023-07-19 11:13 am
 * @returns {JSX.Element} 
 */

const SmallCircularLoader = () => {
    return (
        <CircularProgress color="inherit" size={"18px"} />
    )
}

export default SmallCircularLoader
