import { Chip, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SliderDetalles from '../../slider/SliderDetalles';
import { LANG } from '../LANG';

/**
 * Componente que muestra las imperfecciones de una unidad. 
 * Las imperfecciones se pueden filtrar y se presentan en un slider. 
 * El usuario puede cambiar entre diferentes filtros para ver diferentes tipos de imperfecciones.
 * 
 * @author ERL 2023-08-10 04:42 pm
 * @param {Object} aUnidadImperfeciones - Objeto que contiene la información sobre los filtros y las imperfecciones.
 * @field {Object} aFiltros - Lista de filtros disponibles para las imperfecciones.
 * @field {Object} aImperfecciones - Lista de imperfecciones para la unidad.
 * @returns {JSX.Element}
 */
const UnidadImperfecciones = ({ aUnidadImperfeciones }) => {

    const { aFiltros, aImperfecciones = [] } = aUnidadImperfeciones;
    const [activeFiltro, setActiveFiltro] = useState(0);
    const filteredImages = aImperfecciones ? aImperfecciones.filter(f => Number(f.nIdTipo) === Number(activeFiltro)) : [];
    /**
     * Efecto que escucha cambios en `aFiltros`. Al detectar un cambio, 
     * actualiza el filtro activo basándose en el primer elemento de `aFiltros`.
     * 
     * @author ERL 2023-08-11 10:05 am
     * @returns {void}
     */
    useEffect(() => {
        if (aFiltros) {
            setActiveFiltro(aFiltros[0]?.nIdTipo)
        }

    }, [aFiltros])

 
    return (
        <Grid container sx={{ background: '#f7f7f70', margin: '15px 0', padding: '15px 0' }} >
            <Grid item xs={12} textAlign={'center'}>
                <Typography variant='h5' color={"primary"}>
                    {LANG.labelInperfecciones}
                </Typography>

            </Grid>

            <Grid item xs={12}>
                <Grid container
                    sx={{
                        justifyContent: 'center',
                        margin: '15px 0'
                    }}
                >
                    {aFiltros?.map((data, index) => {

                        return (
                            <Grid item
                                xl={1}
                                lg={1}
                                md={2}
                                sm={2}
                                xs={4}

                                textAlign={'center'} key={data?.nIdTipo}>
                                <Chip icon={Number(activeFiltro) === Number(data.nIdTipo) ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />} label={data.sNombre} variant={Number(activeFiltro) === Number(data.nIdTipo) ? "contained" : "outlined"} color='primary' onClick={() => setActiveFiltro(data.nIdTipo)} />
                            </Grid>
                        )

                    })}
                </Grid>
            </Grid>
            <Grid item
                xl={12}
                lg={12}
                md={12}
                sm={10}
                xs={10}
                sx={{
                    maxWidth: {
                        xl: '800px',
                        lg: '800px',
                        md: '800px',
                        sm: '100%',
                        xs: '100%',
                    },
                    margin: ' 0 auto'
                }}
            >
                <SliderDetalles images={filteredImages} mostrarMimiaturas={false} />
            </Grid>
        </Grid>
    )
}

export default UnidadImperfecciones
