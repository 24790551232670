import * as React from 'react';
import MenuButton from '@mui/joy/MenuButton';
import ListDivider from '@mui/joy/ListDivider';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Dropdown from '@mui/joy/Dropdown';
import { LANG } from '../unidades/LANG';
import { useStyles } from '../../hooks/stylesMUI/useStyles';

/**
 * CollapsibleMenu - Componente que muestra un menú desplegable permitiendo al usuario 
 * seleccionar una opción de ordenamiento para los elementos en la vista.
 * También muestra una dirección de ordenamiento ascendente o descendente.
 * 
 * @author ERL 2023-08-07 08:15 pm
 * @param {array} aListaMenu - Lista de opciones del menú.
 * @param {Function} handleOrdenamiento - Función que maneja la lógica del ordenamiento seleccionado.
 * @returns {JSX.Element}
 */
export default function CollapsibleMenu({ aListaMenu, handleOrdenamiento }) {
    const [selectedItem, setSelectedItem] = React.useState('');
    const [selectedItemCorto, setSelectedItemCorto] = React.useState('');
    const [ordenamientoDireccion, setOrdenamientoDireccion] = React.useState('ascendente');

    const {StylesUnidades} = useStyles();

    /**
     * 
     * Maneja el evento click en un elemento del menú.
     * @author ERL 2023-08-07 08:27 pm
     * @param {string} nombre - Nombre del item seleccionado.
     * @param {string} nombreCorto - Nombre corto del item seleccionado.
     */
    const handleMenuItemClick = (nombre, nombreCorto) => {
        setSelectedItem(nombre);
        setSelectedItemCorto(nombreCorto);
        if (nombreCorto === selectedItemCorto) {
            setOrdenamientoDireccion(prev => prev === 'ascendente' ? 'descendente' : 'ascendente');
        } else {
            setOrdenamientoDireccion('ascendente');
        }
        handleOrdenamiento(nombreCorto);
    };

    return (
        <Dropdown>
            <MenuButton
                endDecorator={<ArrowDropDown />}
                sx={StylesUnidades['menu-sorting-btn']}                
                onMouseOver={(e) => e.stopPropagation()}
            >
                {selectedItem || LANG.labelSeleccionSort}
            </MenuButton>
            <Menu sx={{ minWidth: 160, '--ListItemDecorator-size': '24px' }}>
                {aListaMenu.map((item, i) => (
                    <React.Fragment key={item.sNombre}>
                        <MenuItem
                            role="menuitemradio"
                            aria-checked={item.sNombreCorto === selectedItemCorto ? 'true' : 'false'}
                            onClick={() => handleMenuItemClick(item.sNombre, item.sNombreCorto)}
                            sx={{
                                backgroundColor: item.sNombreCorto === selectedItemCorto ? '#f0f0f0' : 'transparent',
                                textTransform:'capitalize'
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                {item.sNombre}
                                {item.sNombreCorto === selectedItemCorto && (ordenamientoDireccion === 'ascendente' ? 
                                <ArrowUpward fontSize="small" /> : 
                                <ArrowDownward fontSize="small" />)}
                            </div>
                        </MenuItem>
                        {(i !== aListaMenu.length - 1) && <ListDivider />}
                    </React.Fragment>
                ))}
            </Menu>
        </Dropdown>
    );
}
