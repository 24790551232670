export const globalMessage = {
    errorServidor: "Error al consultar la información, favor de contactar al administrador",
    errorComponente: "Error al consultar el componente, favor de contactar al administrador",
    errorCrearPDF: "Error al generar el PDF",
    errorArchivoNoSoportado: "Tipo de archivo no soportado.",
    errorInformacionRequerida: "Información requerida",
    errorSoloNumeros: "Formato incorrecto, solo se permiten numeros",
    errorProspecto: "Se ha rechazado al prospecto",
    labelAceptar:"Aceptar",
    labelRechazar:"Rechazar",
    labelCerrar:"Cerrar",
    labelCorreoInvalido:"Correo invalido",
    labelSi:"Si",
    labelNo:"No",
    labelSesionExpirada: 'Tu sesión ha expirado. Por favor, inicia sesión de nuevo.'
}