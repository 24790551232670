    export const errorsMetamap = {
    "documentPhoto.badText": {
        "response": "La validación del campo del documento falló"
    },
    "documentPhoto.blurryText": {
        "response": "La foto del documento está demasiado borrosa"
    },
    "documentPhoto.smallImageSize": {
        "response": "La resolución de la foto del documento es demasiado baja"
    },
    "documentPhoto.unexpectedData": {
        "response": "Error inesperado en la lectura del documento"
    },
    "documentPhoto.noText": {
        "response": "La foto del documento no tiene texto"
    },
    "documentPhoto.noFace": {
        "response": "La foto del documento no tiene rostro"
    },
    "documentPhoto.grayscaleImage": {
        "response": "La foto del documento está en escala de grises"
    },
    "documentPhoto.screenPhoto": {
        "response": "La foto del documento es una captura de pantalla. El usuario debe subir una foto diferente"
    },
    "documentPhoto.noDocument": {
        "response": "La foto del documento no coincide con una plantilla de documento"
    },
    "documentPhoto.missingFields": {
        "response": "Faltan campos requeridos en la foto del documento"
    },
    "documentPhoto.wrongFormat": {
        "response": "Algunos campos requeridos del documento usan un formato inválido"
    },
    "documentPhoto.noMrz": {
        "response": "La foto del documento no tiene Zona de Lectura Mecánica"
    },
    "documentPhoto.badMrz": {
        "response": "La Zona de Lectura Mecánica (MRZ) de la foto del documento está dañada"
    },
    "documentPhoto.noPdf417": {
        "response": "La foto del documento no tiene código de barras PDF417"
    },
    "documentPhoto.badPdf417": {
        "response": "El código de barras PDF417 de la foto del documento está dañado"
    },
    "documentPhoto.typeMismatch": {
        "response": "El tipo de documento reclamado por el usuario y el tipo de documento detectado en la foto son diferentes"
    },
    "documentPhoto.countryMismatch": {
        "response": "El país del documento indicado por el usuario y el país detectado en la foto del documento son diferentes"
    },
    "documentPhoto.croppedDocument": {
        "response": "Los bordes del documento no son completamente visibles en la foto"
    },
    "documentPhoto.sideMismatch": {
        "response": "El lado del documento reclamado (frente o reverso) no coincide con el lado presentado"
    },
    "documentPhoto.similarImages": {
        "response": "La imagen enviada es demasiado similar a otra previamente procesada. Por favor, asegúrate de enviar una imagen actual y claramente distinta para continuar con la validación."
    },
    "voiceVideo.conversionFailed": {
        "response": "La conversión de video falló"
    },
    "voiceVideo.multipleFaces": {
        "response": "Se detectaron múltiples rostros simultáneamente en el video"
    },
    "voiceVideo.noFace": {
        "response": "No se detectaron rostros en el video"
    },
    "bankDocument.passwordRequired": {
        "response": "El estado de cuenta bancario está protegido por contraseña. Por favor, proporciona la contraseña para abrir y leer el archivo"
    },
    "bankDocument.passwordFailed": {
        "response": "El estado de cuenta bancario está protegido por contraseña. Proporciona la contraseña correcta para leer el archivo"
    },
    "bankDocument.badFileName": {
        "response": "El nombre del archivo del documento bancario es diferente del nombre de archivo de entrada"
    },
    "bankDocument.invalidMetadata": {
        "response": "Por favor, sube el estado de cuenta bancario original. No se aceptarán archivos alterados"
    },
    "bankDocument.statementNotSupported": {
        "response": "La plantilla del estado de cuenta bancario no es compatible. Contacta al soporte de MetaMap para ayuda"
    },
    "CustomDocumentPhoto.multiPageInputError": {
        "response": "El archivo enviado como Documento Personalizado tiene múltiples páginas, cuando en la Configuración de Flujo, el documento de múltiples páginas no es compatible"
    }
}