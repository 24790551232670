import React from 'react'
import UnidadesListado from '../../components/unidades/UnidadesListado'


/**
 * Página principal de 'Unidades'. Actúa como un contenedor para el componente `UnidadesListado`.
 * @author ERL 2023-08-07 02:16 pm
 * @returns {JSX.Element}
 */
const Unidades = () => {
  return (
    <UnidadesListado/>
  )
}

export default Unidades
