import styled from 'styled-components';


export const StyledButton = styled.button`
    margin-top: 10px;
    border: none;
    color: white;
    
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 18px;
    cursor: pointer;
    background-image: linear-gradient(to right,#483e6b 0%,#7740a3 51%,#483e6b 100%);
    margin: 10px;
    width: ${props => props.width ? props.width : "150px"};
    height: 50px;
    text-align: center;
    transition: all 0.5s;
    background-size: 200% auto;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22.16px;
    color: #fff;
    border-radius: 25px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    padding: 0;
    &:hover{
        background-position: right center;
    }
    @media (max-width:480px){
        //width: 100%;
    }
`;
export const StyledButton2 = styled.button`
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    vertical-align: middle;
 
    line-height: 1.2;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    font-weight: 600;    
    background-image: linear-gradient(95.31deg, rgb(72 62 107) 35.27%, rgb(134 99 255) 100%);
    background-size: 120%;
    outline: none;
    border-radius:  0.375rem;
    transition: all 0.5s ease 0s;
    color: #fff;
    &:hover{
        background-position: right center;
    }
    @media (max-width:480px){
        width: 100%;
    }
`;

export const StyledButton3 = styled.button`
    margin-top: 10px;
    border: none;
    color: white;
    
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 18px;
    cursor: pointer;
    background-image: linear-gradient(to right,#483e6b 0%,#7740a3 51%,#483e6b 100%);
    margin: 10px;
    width: ${props => props.width ? props.width : "150px"};
    height: 69px;
    text-align: center;
    transition: all 0.5s;
    background-size: 200% auto;
    align-items: center;
    font-weight: 600;
    font-size: 25px;
    line-height: 22.16px;
    color: #fff;
    border-radius: 25px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    padding: 0;
    
    &:hover{
        background-position: right center;
    }
    @media (max-width:680px){        
        width: 80% !important;
        height: 50px !important;
        font-size: 20px !important;
        margin: 24px auto !important;
    }
`;

export const StyledButton4 = styled.div`
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    user-select: none;
    position: relative;
    vertical-align: middle;
    padding: 0 25px;
    line-height: 1.2;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    font-weight: 600;    
    background-image: linear-gradient(95.31deg, rgb(72 62 107) 35.27%, rgb(134 99 255) 100%);
    background-size: 120%;
    outline: none;
    border-radius:  0.375rem;
    transition: all 0.5s ease 0s;
    color: #fff;
    box-sizing: border-box;
    &:hover{
        background-position: right center;
    }
    @media (max-width:480px){
        width: 100%;
    }
`;