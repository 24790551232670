import React from 'react'
import UnidadesFiltro from '../filtros/UnidadesFiltro'
import SpeedIcon from '@mui/icons-material/Speed';
import { Grid, Typography } from '@mui/material';
import { LANG } from '../LANG';

/**
 * Componente para mostrar las características de una unidad.
 * Utiliza el componente UnidadesFiltro para presentar las características en una grilla con íconos y descripciones.
 * @author ERL 2023-08-10 02:11 pm
 * @param {object} props - Propiedades del componente
 * @field {array} aCaracteristicas - Lista de caracteristicas de la unidad
 * @returns {JSX.Element}
 */

const UnidadCaracteristicas = ({aCaracteristicas}) => {
    return (
        <Grid>
            <Grid>
                <Typography variant='h5' color={"primary"}>
                    {LANG.labelCaracteristicas}
                </Typography>
            </Grid>
            <UnidadesFiltro sNombre={"General"} sIcono={<SpeedIcon />}>
                <Grid item xs={12} padding={0}>
                    <Grid
                        sx={{
                            gap: '0 50px',
                            display: 'grid',
                            gridTemplateColumns: {
                                xl:'1fr 1fr',
                                sm:'1fr 1fr',
                                xs:'1fr',
                            },
                            padding: 0
                        }}>
                        {aCaracteristicas.map((car) => {
                            return (
                                <Grid sx={{ borderBottom: '1px solid #e9e9e9', padding: '16px 0' }} key={car.sNombre}>
                                    <Typography>
                                        {car.sNombre}
                                    </Typography>
                                    <Typography>
                                        {car.sDescripcion}
                                    </Typography>
                                </Grid>

                            )

                        })}
                    </Grid>
                </Grid>
            </UnidadesFiltro>
        </Grid>
    )
}

export default UnidadCaracteristicas
