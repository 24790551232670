// store.js
import { configureStore } from '@reduxjs/toolkit';
import documentosReducer from './documentosSlice';

const store = configureStore({
  reducer: {
    documentos: documentosReducer
  }
});

export default store;
