import { Grid, MenuItem, TextField } from '@mui/material';
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { regex } from '../../../../../helpers/regex';
import { globalMessage } from '../../../../../helpers/globalMessage';

import { useMemo } from 'react';
import SelectLoader from '../../../../loader/SelectLoader';
import useReferencias from '../../../../../hooks/prospectosApi/pasos/useReferencias';
import { toast } from 'sonner';
import { LANG } from './LANG';
import { isEmail, validaRFC } from '../../../../../helpers/utilities';
import { useEffect } from 'react';
/**
 * Componente de entrada de texto que forma parte de los campos de entrada en el formulario.
 * Se utiliza dentro de un formulario con el control del contexto de un formulario de React Hook.
 * La entrada de texto puede tener una lógica de manejo de cambios adicional si se trata de un código postal.
 * 
 * @author ERL 2023-07-19 06:50 pm
 * @param {function} consultarColonias - Función que maneja la consulta de colonias.
 * @param {number} nIdentificadorInput - Identificador único del campo de entrada.
 * @param {string} nIdentificadorRefInput - Identificador de referencia del campo de entrada.
 * @param {number} nIdentificadorRef - Identificador de referencia.
 * @param {string} sDefaultValue - Valor por defecto del campo de entrada.
 * @param {boolean} bRequired - Indica si el campo de entrada es requerido.
 * @param {string} sLabel - Etiqueta para el campo de entrada.
 * @param {string} sNombreCorto - Nombre corto para el campo de entrada.
 * @param {string} sStatusStep - Estado del paso actual.
 * @param {boolean} bReadOnly - Indica si el campo de entrada es de solo lectura.
 * @param {number} bMaxLength - Longitud máxima de la entrada de texto.
 * @param {boolean} bCodigoPostal - Indica si el campo de entrada es para el código postal.
 * @param {boolean} bNombreCampo - Indica si el campo de entrada es para el Nombre, Apellido Paterno o Materno.
 * @returns {JSX.Element}
 */

export const InputText = ({
    consultarColonias,
    nIdentificadorInput,
    nIdentificadorRefInput,
    nIdentificadorRef,
    sDefaultValue,
    bRequired,
    sLabel,
    sStatusStep,
    bReadOnly,
    bMaxLength,
    bCodigoPostal,
    bNombreCampo,
    sNombreCorto
}) => {
    const { formState: { errors }, control, setError } = useFormContext();


    const validacionesExtra = (sValor, sTipo, nIdentificadorRefInput) => {
        switch (sTipo) {
            case 'sRFC':
                const bValidoRFC = validaRFC(sValor);
                if (!bValidoRFC) {
                    return LANG?.labelRFCInvalido;
                }
                break;
            case 'sCorreo':
                const bValidoCorreo = isEmail(sValor);
                if (!bValidoCorreo) {
                    return globalMessage?.labelCorreoInvalido;
                }
                break;

            default:
                break;
        }        
        return true;

    }
    const rfcMaxLength = sNombreCorto === 'sRFC' ? 13 : null;
    return (
        <>
            { }
            <Controller
                name={`${nIdentificadorRefInput}`}
                control={control}
                defaultValue={sDefaultValue}
                rules={{
                    required: bRequired,
                    validate: value => validacionesExtra(value, sNombreCorto, nIdentificadorRefInput)
                }}
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        label={sLabel}
                        aria-invalid={errors[`${nIdentificadorRefInput}`] ? "true" : "false"}
                        id={`${errors[`${nIdentificadorRefInput}`]?.type === "required" ? "outlined-error-helper-text" : ""}`}
                        error={Boolean(errors[`${nIdentificadorRefInput}`])}
                        helperText={<center>{errors[`${nIdentificadorRefInput}`]?.message}</center>}
                        {...field}
                        inputProps={{
                            maxLength: bMaxLength ? bMaxLength : rfcMaxLength,
                            readOnly: bReadOnly ? bReadOnly : ["APROBADO", "VALIDANDO"].includes(sStatusStep)
                        }}
                        onChange={(e) => {
                            if (bNombreCampo || sNombreCorto === 'sRFC') {
                                e.target.value = e.target.value.toUpperCase();
                            }
                            field.onChange(e.target.value);
                            if (bCodigoPostal && sDefaultValue) {
                                if (e.target.value.length === 5) {
                                    consultarColonias(e.target.value, nIdentificadorRef, nIdentificadorInput)
                                }
                            }                           
                        }}
                    />
                )}
            />

        </>

    )
}

/**
 * Componente de entrada de número que forma parte de los campos de entrada en el formulario.
 * Se utiliza dentro de un formulario con el control del contexto de un formulario de React Hook.
 * La entrada de número puede tener una lógica de manejo de cambios adicional si se trata de un código postal.
 * 
 * @author ERL 2023-07-19 07:15 pm
 * @param {function} consultarColonias - Función que maneja la consulta de colonias.
 * @param {number} nIdentificadorInput - Identificador único del campo de entrada.
 * @param {string} nIdentificadorRefInput - Identificador de referencia del campo de entrada.
 * @param {number} nIdentificadorRef - Identificador de referencia.
 * @param {number} sDefaultValue - Valor por defecto del campo de entrada.
 * @param {boolean} bRequired - Indica si el campo de entrada es requerido.
 * @param {string} sLabel - Etiqueta para el campo de entrada.
 * @param {string} sStatusStep - Estado del paso actual.
 * @param {boolean} bReadOnly - Indica si el campo de entrada es de solo lectura.
 * @param {number} bMaxLength - Longitud máxima de la entrada de número.
 * @param {number} nMinLength - Longitud mínima de la entrada de número.
 * @param {boolean} bCodigoPostal - Indica si el campo de entrada es para el código postal.
 * @param {boolean} loadingColonias - Determina si se muestra el loader al consultar colonias
 * 
 * @returns {JSX.Element}
 */

export const InputNumber = ({
    consultarColonias,
    nIdentificadorInput,
    nIdentificadorRefInput,
    nIdentificadorRef,
    sDefaultValue,
    bRequired,
    sLabel,
    sStatusStep,
    bReadOnly,
    bMaxLength,
    nMinLength,
    bCodigoPostal,
    isTelefono,
    loadingColonias
}) => {
    const { formState: { errors }, control, setValue, watch } = useFormContext();

    const sValorMinimo = `El valor minimo es de ${nMinLength} digitos`;
    const value = watch(nIdentificadorRefInput, sDefaultValue);
    
    return (
        <Controller
            name={`${nIdentificadorRefInput}`}
            control={control}
            defaultValue={sDefaultValue}
            rules={{
                required: bRequired,
                pattern: {
                    value: isTelefono ? regex.isPhoneNumber : regex.isNumber,
                    message: globalMessage.errorSoloNumeros,
                },
                minLength: { value: 0, message: sValorMinimo },
            }}
            render={({ field }) => (

                <>
                    <TextField
                        required
                        fullWidth
                        label={sLabel}
                        aria-invalid={errors[`${nIdentificadorRefInput}`] ? "true" : "false"}
                        id={`${errors[`${nIdentificadorRefInput}`]?.type === "required" ? "outlined-error-helper-text" : ""}`}
                        error={Boolean(errors[`${nIdentificadorRefInput}`])}
                        helperText={<center>{errors[`${nIdentificadorRefInput}`]?.message}</center>}
                        {...field}
                        value={value}
                        inputProps={{
                            maxLength: bMaxLength,
                            readOnly: bReadOnly ? bReadOnly : ["APROBADO", "VALIDANDO"].includes(sStatusStep)
                        }}
                        onChange={(e) => {
                            let val = e.target.value;

                            // limit the length
                            if (val.length > 11) {
                                val = val.slice(0, 11);
                            }

                            if (isTelefono) {
                                val = val.replace(/[^\d]/g, "").replace(/(\d{2})(\d{7})/, "$1-$2").substr(0, 11);
                            } else {
                                // If not a phone number, remove any non-numeric characters
                                val = val.replace(/[^\d]/g, "");
                            }

                            setValue(nIdentificadorRefInput, val);

                            if (bCodigoPostal) {
                                if (val.length === 5) {
                                    consultarColonias(val, nIdentificadorRef, nIdentificadorInput);
                                }
                            }
                        }}

                    />



                </>
            )}
        />
    )
}


/**
 * Componente de entrada select que forma parte de los campos de entrada en el formulario.
 * Se utiliza dentro de un formulario con el control del contexto de un formulario de React Hook.
 * La entrada select puede tener una lógica de manejo de cambios adicional si está relacionada con la selección de una colonia.
 * 
 * @author ERL 2023-07-19 07:33 pm
 * @param {number} nIdentificadorInput - Identificador único del campo de entrada.
 * @param {string} nIdentificadorRefInput - Identificador de referencia del campo de entrada.
 * @param {number} nIdentificadorRef - Identificador de referencia.
 * @param {number} sDefaultValue - Valor por defecto del campo de entrada.
 * @param {boolean} bRequired - Indica si el campo de entrada es requerido.
 * @param {string} sLabel - Etiqueta para el campo de entrada.
 * @param {string} sStatusStep - Estado del paso actual.
 * @param {boolean} bReadOnly - Indica si el campo de entrada es de solo lectura.
 * @param {boolean} bIdColonia - Indica si el campo de entrada está relacionado con la selección de una colonia.
 * @param {function} seleccionarColonia - Función que maneja la selección de una colonia.
 * @param {array} respuestas - Array de posibles respuestas para seleccionar.
 * @param {string} sNombreCorto - Nombre corto del campo de entrada.
 * @param {boolean} loadingColonias - Determina si se muestra el loader al consultar colonias
 * @returns {JSX.Element}
 */

export const InputSelect = ({
    nIdentificadorInput,
    nIdentificadorRefInput,
    nIdentificadorRef,
    sDefaultValue,
    bRequired,
    sLabel,
    sStatusStep,
    bReadOnly,
    bIdColonia,
    seleccionarColonia,
    respuestas,
    sNombreCorto,
    loadingColonias,
    bCodigoPostal

}) => {
    const { formState: { errors }, control } = useFormContext();
    const sortedRespuestas = useMemo(() => {
        return respuestas?.sort((a, b) => a.nOrden - b.nOrden);
    }, [respuestas]);

    return (
        <>
            <Controller
                name={`${nIdentificadorRefInput}`}
                control={control}
                defaultValue={sDefaultValue}
                rules={{ required: bRequired }}
                render={({ field }) => (
                    <Grid sx={{ position: 'relative' }}>
                        <TextField
                            select
                            required
                            fullWidth
                            label={sLabel}
                            aria-invalid={errors[`${nIdentificadorRefInput}`] ? "true" : "false"}
                            id={`${errors[`${nIdentificadorRefInput}`]?.type === "required" ? "outlined-error-helper-text" : ""}`}
                            error={Boolean(errors[`${nIdentificadorRefInput}`])}
                            helperText={<center>{errors[`${nIdentificadorRefInput}`]?.message}</center>}
                            {...field}
                            inputProps={{
                                readOnly: bReadOnly ? bReadOnly : ["APROBADO", "VALIDANDO"].includes(sStatusStep),
                            }}
                            disabled={loadingColonias && bCodigoPostal}
                            onChange={(e) => {
                                field.onChange(e.target.value);
                                if (bIdColonia) {
                                    seleccionarColonia(e.target.value, nIdentificadorRef)
                                }
                            }}
                        >
                            {sortedRespuestas && sortedRespuestas.length > 0
                                ? sortedRespuestas.map((answer, index) => (
                                    <MenuItem key={index} value={["sParentesco"].includes(sNombreCorto) ? answer.sValor : answer.nIdRespMc}>{answer.sValor}</MenuItem>
                                ))
                                : <MenuItem value="" disabled>No hay opciones disponibles</MenuItem>
                            }
                        </TextField>
                        {(loadingColonias && bCodigoPostal) &&
                            <SelectLoader />
                        }
                    </Grid>
                )}
            />

        </>
    )
}
