export const LANG = {
    labelOrdenar:"Ordenar",
    labelResultados:"resultados",
    labelSeleccionSort:"Selecciona una opción",
    labelMostrarFiltros:"Mostrar filtros",
    labelOcultarFiltros:"Ocultar filtros",
    labelLimpiar:"Limpiar",
    labelFiltros:"Filtros",
    labelYear:"Año",
    labelTransmision:"Transmisión",
    labelInfobasica:"Información básica",
    labelInperfecciones:"Imperfecciones",
    labelSugerencia:"También te podría interesar",
    labelCiudad:"Ciudad",
    labelCaracteristicas:"Características",
    labelVista360:"Aquí te brindamos los elementos estéticos más relevantes. Visita nuestras sedes para explorar el vehículo en su totalidad. ",
    labelVer360:"Ver 360"
}