import React, { useState, useRef } from "react";
import CustomInput from "./CustomInput";
import './customForm.scss';
import CustomTextarea from "./CustomTextarea";
import CustomSelect from "./CustomSelect";
import { StyledButton } from "../customButtons/StyledButton";
import emailjs from '@emailjs/browser';
import { toast } from "sonner";
import { globalMessage } from "../../helpers/globalMessage";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularLoader from "../loader/CircularLoader";
import { LANG } from "../../pages/contact/LANG";
import { urlsAPIs } from "../../helpers/urlsAPIs";
import { Grid } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import { opcionesApps, opcionesViajes } from "../viajes/selectOptions";

function FormularioComponent({ formTitle, data }) {        
    const sUnidad =  `${data?.marca} ${data?.modelo}`
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const [nombreValid, setNombreValid] = useState(false);
    const [celularValid, setcelularValid] = useState(false);
    const [appTValid, setAppTValid] = useState(false);
    const [mensajeValid, setMensajeValid] = useState(false);
    const [nombre, setNombre] = useState('');
    const [celular, setCelular] = useState('');
    const [appT, setAppT] = useState('');
    const [mensaje, setMensaje] = useState('');
    const nombreRef = useRef(null);
    const celularRef = useRef(null);
    const appTRef = useRef(null);
    const mensajeRef = useRef(null);
    const [viajesValid, setViajesValid] = useState(false);
    const [viajes, setViajes] = useState('');
    const viajesRef = useRef(null);
    const [localidadValid, setLocalidadValid] = useState(false);
    const [localidad, setLocalidad] = useState('');
    const localidadRef = useRef(null);
    const [submitAttempted, setSubmitAttempted] = useState(false);

    /**
     * Función que maneja el envío de datos de un formulario a Google Sheets y el envío de un correo electrónico usando EmailJS.   
     * @author ERL 2024-05-26 08:12 am
     * @param {Event} e - Evento de envío del formulario.
     * @returns {void}
     */
    const enviarCorreo = (e) => {
        e.preventDefault();        
        const uuid = uuidv4().slice(0, 8);
        // Enviar los datos a Google Sheets
        const fecha = new Date();
        const fechaFormateada = `${fecha.getDate().toString().padStart(2, '0')}/${(fecha.getMonth() + 1).toString().padStart(2, '0')}/${fecha.getFullYear()}`;
        const formData = new FormData(form.current);
        formData.append('uuid', uuid);
        formData.append('fecha', fechaFormateada);
        formData.append('unidad', sUnidad);
        const data = new URLSearchParams(formData);
        if (nombreValid && celularValid && viajesValid && appTValid && localidadValid) {
            setLoading(true);          
            setSubmitAttempted(false);   
            emailjs.sendForm('service_fst7gdm', 'template_3il5sqt', form.current, 'QQvoOXLmHkR29h36W')
                .then(
                    (result) => {                        
                        setDone(true)                        
                        fetch(urlsAPIs.urlGoogleSheetFormAutos, {
                        method: 'POST',
                        body: data
                        })
                        .then(response => {
                            if (!response.ok) {
                            throw new Error('Error ' + response.statusText);
                            }
                            return response.text();
                        })
                        .then(result => {      
                            if(result){
                                setLoading(false);
                            }
                        })
                        .catch(error => {            
                            setLoading(false);
                            toast.error(globalMessage.errorServidor)
                        });
                    },
                    (error) => {
                        console.log(error)
                        toast.error(globalMessage.errorServidor)
                    });            
        } else {
            setSubmitAttempted(true);
        }        

      };
 
    const options3 = [
        { value: 'Nuevo León', label: 'Nuevo León' },
        { value: 'Guadalajara', label: 'Guadalajara' },
        { value: 'Otro', label: 'Otro' },
    ];
    
    return (
        <>            
            {
                !loading && !done ? <div className="custom-form">
                    <div className="form-title">
                        <h2>{formTitle}</h2>
                    </div>
                    <form ref={form} onSubmit={enviarCorreo}>
                        <div className="form-item">
                            <CustomInput
                                name="nombre"
                                label="nombre completo"
                                type="input"
                                setIsValidForm={setNombreValid}
                                isValidForm={nombreValid}
                                value={nombre}
                                callback={setNombre}
                                inputRef={nombreRef}
                                submitAttempted={submitAttempted}
                                setSubmitAttempted={setSubmitAttempted}
                            />
                            
                        </div>
                        <div className="form-item">
                            <CustomInput
                                name="telefono"
                                label="celular con whatsapp"
                                type="input"
                                setIsValidForm={setcelularValid}
                                isValidForm={celularValid}
                                value={celular}
                                callback={setCelular}
                                inputRef={celularRef}
                                submitAttempted={submitAttempted}
                                setSubmitAttempted={setSubmitAttempted}
                            />
                        </div>
                        <div className="form-item">
                            <CustomSelect
                                name="apps"
                                label="¿Actualmente eres conductor de UBER y DIDI?"
                                setIsValidForm={setAppTValid}
                                isValidForm={appTValid}
                                value={appT}
                                callback={setAppT}
                                inputRef={appTRef}
                                submitAttempted={submitAttempted}
                                setSubmitAttempted={setSubmitAttempted}
                                options={opcionesApps}
                            />
                        </div>
                        <div className="form-item">
                            <CustomSelect
                                name="viajes"
                                label="¿Cuántos viajes llevas realizados?"
                                setIsValidForm={setViajesValid}
                                isValidForm={viajesValid}
                                value={viajes}
                                callback={setViajes}
                                inputRef={viajesRef}
                                submitAttempted={submitAttempted}
                                setSubmitAttempted={setSubmitAttempted}
                                options={opcionesViajes}
                            />
                        </div>
                        <div className="form-item">
                            <CustomSelect
                                name="estado"
                                label="¿En que parte de México vives?"
                                setIsValidForm={setLocalidadValid}
                                isValidForm={localidadValid}
                                value={localidad}
                                callback={setLocalidad}
                                inputRef={localidadRef}
                                submitAttempted={submitAttempted}
                                setSubmitAttempted={setSubmitAttempted}
                                options={options3}
                            />
                        </div>
                        <div className="form-item txt">
                            <CustomTextarea
                                name="mensaje"
                                label="mensaje"
                                setIsValidForm={setMensajeValid}
                                isValidForm={mensajeValid}
                                value={mensaje}
                                callback={setMensaje}
                                inputRef={mensajeRef}
                                submitAttempted={submitAttempted}
                                setSubmitAttempted={setSubmitAttempted}
                            />
                        </div>
                        <div className="submit-form">
                            <StyledButton type="submit">Enviar</StyledButton>
                        </div>
                    </form>
                </div> :
                    <Grid
                        sx={{
                            width: {
                                xl: '620px',
                                lg: '620px',
                                md: '620px',
                                sm: '420px',
                            }
                        }}>
                        {loading ? <CircularLoader /> : done ? 
                            <Grid className="correo-enviado-container">
                                <Grid>
                                    <Grid>
                                        <CheckCircleOutlineIcon color="success" className="succes-icon" />
                                    </Grid>
                                    <Grid className="loader-msg-1">
                                        {LANG.labelListoEstas}
                                    </Grid>
                                </Grid>
                                <Grid className="loader-msg-2">
                                    {LANG.labelTeContactaremos}
                                </Grid>
                            </Grid> : ""
                        }
                    </Grid>
            }
        </>
    );
}

export default FormularioComponent;
