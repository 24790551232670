import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
/**
 * Componente para filtrar unidades basado en estados.
 * Permite seleccionar uno o varios estados para filtrar las unidades por su estado.
 * @author ERL 2023-08-07 09:02 pm
 * @param {array} aStatus - Lista de estados disponibles.
 * @param {function} handleFilterSelect - Función callback para manejar la selección de filtros.
 * @param {array} selectedStatus - Lista de estados seleccionados.
 * @returns {JSX.Element}
 */
export const UnidadFiltroStatus = ({ aStatus, handleFilterSelect, selectedStatus: initialSelectedStatus }) => {
  const [selectedStatus, setSelectedStatus] = useState(initialSelectedStatus || []);


  /**
   * Efecto que se ejecuta cuando cambia el valor de 'initialSelectedStatus'.
   * Se encarga de actualizar el estado 'setSelectedStatus' con el valor de 'initialSelectedStatus'.
   * Si no se proporciona un 'initialSelectedStatus', se establece un array vacío por defecto.
   * @author ERL 2023-08-08 03:40 pm
   */
  useEffect(() => {
    setSelectedStatus(initialSelectedStatus || []);
  }, [initialSelectedStatus]);


  /**
   * Maneja la selección o deselección de un estado específico.
   * Actualiza la lista de estados seleccionados y llama a la función callback 'handleFilterSelect'.
   * @author ERL 2023-08-07 09:09 pm
   * @param {object} statusObj - Objeto del estado con su ID y nombre.
   * @returns {void}
   */
  const seleccionarStatus = (statusObj) => {
    let updatedSelectedStatus;
    if (selectedStatus.includes(statusObj.nIdStatus)) {
      updatedSelectedStatus = selectedStatus.filter(id => id !== statusObj.nIdStatus);
    } else {
      updatedSelectedStatus = [...selectedStatus, statusObj.nIdStatus];
    }
    setSelectedStatus(updatedSelectedStatus);
    handleFilterSelect && handleFilterSelect('nIdStatus', updatedSelectedStatus);
  };

  return (
    <Box display="flex" flexWrap={{ xl: "no-wrap", }} gap={1} padding={1}>
      {aStatus.map(statusObj => (
        <Button
          key={statusObj.nIdStatus}
          onClick={() => seleccionarStatus(statusObj)}
          variant={selectedStatus.includes(statusObj.nIdStatus) ? "contained" : "outlined"}
          color="primary"
        >
          {statusObj.sNombre}
        </Button>
      ))}
    </Box>
  );
}

export default UnidadFiltroStatus;
