import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
/**
 * Componente que muestra un modal (o diálogo) de pantalla completa utilizando MUI (Material-UI).
 * Incorpora un AppBar en la parte superior con un botón de cerrar y un botón opcional proporcionado por los propios usuarios.
 * El contenido del modal es flexible y puede ser proporcionado a través de la propiedad 'children'.
 * @author ERL 2023-08-08 09:45 am
 * @param {Object} props - Propiedades del componente
 * @param {boolean} props.oOpen - Indica si el modal debe estar abierto o cerrado.
 * @param {Function} props.setOpen - Función que actualiza el estado de apertura del modal.
 * @param {JSX.Element} props.children - Contenido que se mostrará dentro del modal.
 * @param {JSX.Element} [props.button] - Botón opcional que se mostrará en el AppBar.
 * @returns {JSX.Element}
 */
export default function ModalMUI({ oOpen, setOpen, children, button }) {

    /**
     * Componente que muestra un modal (o diálogo) de pantalla completa utilizando MUI (Material-UI).
     * Incorpora un AppBar en la parte superior con un botón de cerrar y un botón opcional proporcionado por los propios usuarios.
     * El contenido del modal es flexible y puede ser proporcionado a través de la propiedad 'children'.
     * @author ERL 2023-08-08 09:46 am
     * @returns {void}
     */
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={oOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', background: '#483e6b', border: '0' }}>
                    <Toolbar >
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

                        </Typography>
                        {button}
                    </Toolbar>
                </AppBar>
                <Grid>
                    {children}
                </Grid>
            </Dialog>
        </div>
    );
}