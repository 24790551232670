import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { LANG } from '../LANG';

/**
 * UnidadGaleria360 - Componente que muestra una galería de imágenes en 360 grados.
 * Utiliza la librería 'js-cloudimage-360-view' para presentar la funcionalidad 360 a partir de 
 * un conjunto de imágenes proporcionadas.
 * 
 * @author ERL 2023-08-11 11:29 am
 * @param {string} id - Identificador único para el elemento.
 * @param {string} folder - Carpeta donde se encuentran las imágenes.
 * @param {string} filename - Nombre del archivo de las imágenes.
 * @param {number} amount - Cantidad de imágenes para la vista 360.
 * @param {string} width - Ancho de la vista 360.
 * @param {string} height - Altura de la vista 360.
 * @param {string} magnifierSize - Tamaño del magnificador para la vista 360.
 * @returns {JSX.Element}
 */
const UnidadGaleria360 = ({ id, folder, filename, amount, width, height, magnifierSize, imagesList }) => {
    const viewerRef = useRef(null);
    const view360 = "/assets/view360Text.svg";    

    /**
     * Añade el script js-cloudimage-360-view al cuerpo del documento.
     * Una vez que el script se ha cargado, inicializa la vista CloudImage 360 si 
     * viewerRef y el objeto CI360 existen. Al desmontar el componente, se elimina el script 
     * del cuerpo del documento.
     * 
     * @author ERL 2023-08-17 01:48 pm
     * @returns {void}
     */
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://cdn.scaleflex.it/plugins/js-cloudimage-360-view/3.0.0/js-cloudimage-360-view.min.js";
        script.async = true;
        script.onload = () => {
            if (viewerRef.current && window.CI360) {
                window.CI360.init(viewerRef.current);
            }
        };
        document.body.appendChild(script);
        return () => { document.body.removeChild(script) };
    }, []);
    
    return (
        <Grid sx={{ width: '100%', margin: '0 auto', maxWidth: '1200px' }} >
            <Box>
                <img src={view360} alt="" />
            </Box>
            <Box
                sx={{
                    width: '200px',
                    margin: '15px auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottom: '3px solid #483e6b',
                    padding: '6px 0',
                }}
            >
                <Typography>
                    {LANG.labelVer360}
                </Typography>
            </Box>
            <div
                id={id}
                ref={viewerRef}
                className="ci-360-viewer cloudimage-360"
                data-folder={folder}
                data-image-list-x={JSON.stringify(imagesList)}
                // data-filename-x={filename}
                // data-amount-x={amount}
                data-magnifier={magnifierSize}
               // data-pointer-zoom="2"
                style={{ width: width, height: height }}
            ></div>
            <Typography
                sx={{
                    textAlign: 'center',
                    maxWidth: '1200px',
                    margin: {
                        xl:'40px auto',
                        lg:'50px auto',
                        md:'30px auto',
                        sm:'20px auto',
                        xs:'15px auto',
                    },
                }}
            >
                {LANG.labelVista360}
            </Typography>
        </Grid>
    );
}

export default UnidadGaleria360;
