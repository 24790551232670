import React from 'react'
/**
 * @author ERL 2023-09-20 03:04 pm
 * @param {object} props - Propiedades del componente
 * @field {string} sNombre - Nombre del conductor 
 * @returns {JSX.Element}
 */
const DriverName = ({sNombre}) => {
  return (
    <p className='driver-name'>
      {sNombre}
    </p>
  )
}

export default DriverName
