import SliderDetallesMain from "../../slider/SliderDetallesMain";


/**
 * UnidadCarrusel - Componente que muestra un carrusel de imágenes.
 * Utiliza el componente SliderDetallesMain para visualizar las imágenes proporcionadas en forma de carrusel.
 * @author ERL 2023-08-10 03:51 pm
 * @param {array} aImages - Arreglo de imágenes para el carrusel.
 * @param {Function} callback - Función callback a ejecutar 
 * @returns {JSX.Element}
 */
const UnidadCarrusel = ({ aImages, callback }) => {    
    return (
        <SliderDetallesMain images={aImages} callback={callback} principalSlider={true} />
    )
}

export default UnidadCarrusel;
