import { Grid } from '@mui/material'
import React, { useState } from 'react'
import UnidadFiltroUbicacion from './UnidadFiltroUbicacion';
import UnidadesFiltro from './UnidadesFiltro';
import UnidadFiltroMarcaModelo from './UnidadFiltroMarcaModelo';
import UnidadFiltroColor from './UnidadFiltroColor';
import UnidadFiltroYear from './UnidadFiltroYear';
import UnidadFiltroStatus from './UnidadFiltroStatus';
import { useEffect } from 'react';

import { createTheme, ThemeProvider, } from '@mui/material/styles'; // Herramientas de MUI para el tema
// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});
/**
 * Componente que muestra una lista de filtros para las unidades.
 * Cada filtro es una categoría (por ejemplo, modelo, marca, ubicación, etc.)
 * y contiene sus propios elementos filtrables.
 * @author ERL 2023-08-07 04:21 pm
 * @param {object} props Propiedades pasadas al componente.
 * @field {array} aFiltros Array de objetos que representan cada filtro.
 * @field {function} handleFilterSelect Función que maneja la selección de un elemento filtrable.
 * @field {object} filtrosAplicados Objeto de arrays con los filtros aplicados
 * @field {object} oStyles Objeto con estilos css.
 * @returns {JSX.Element} 
 */
const UnidadesFiltros = ({ aFiltros, handleFilterSelect, filtrosAplicados, oStyles }) => {



    const modeloObj = aFiltros?.find(filtro => filtro.sNombreCorto === 'aModelos');


    const [modelos, setModelos] = useState([])
    useEffect(() => {
        if (aFiltros.length > 0) {
            setModelos(modeloObj ? modeloObj.aFiltros : [])
        }
    }, [modeloObj])

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid sx={{ ...oStyles, width: '85%', margin: '0 auto', }}>
                {aFiltros.map((filtro) => {
                    return (
                        <React.Fragment key={filtro.sNombre}>
                            {filtro.sNombreCorto !== 'aModelos' ? (
                                <UnidadesFiltro sNombre={filtro.sNombre} >
                                    {filtro.sNombreCorto === "aMarcas" && (
                                        <UnidadFiltroMarcaModelo
                                            aMarcas={filtro.aFiltros}
                                            aModelos={modelos}
                                            handleFilterSelect={handleFilterSelect}
                                            selectedMarcas={filtrosAplicados.nIdMarca}
                                            selectedModelos={filtrosAplicados.nIdModelo}

                                        />
                                    )}
                                    {filtro.sNombreCorto === "aUbicacion" && (
                                        <UnidadFiltroUbicacion aFiltros={filtro.aFiltros} handleFilterSelect={handleFilterSelect} selectedUbicaciones={filtrosAplicados.nIdUbicacion} />
                                    )}
                                    {filtro.sNombreCorto === "aColores" && (
                                        <UnidadFiltroColor aColores={filtro.aFiltros} handleFilterSelect={handleFilterSelect} selectedColors={filtrosAplicados.nIdColor} />
                                    )}
                                    {filtro.sNombreCorto === "aYears" && (
                                        <UnidadFiltroYear aYears={filtro.aFiltros} handleFilterSelect={handleFilterSelect} selectedYears={filtrosAplicados.nIdYear} />
                                    )}
                                    {filtro.sNombreCorto === "aStatusUnidad" && (
                                        <UnidadFiltroStatus aStatus={filtro.aFiltros} handleFilterSelect={handleFilterSelect} selectedStatus={filtrosAplicados.nIdStatus} />
                                    )}
                                </UnidadesFiltro>
                            ) : null}
                        </React.Fragment>
                    );
                })}
            </Grid>
        </ThemeProvider>
    )
}

export default UnidadesFiltros
