import React, { useEffect } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './sliderDetalles.scss';
import { IosArrowLtr, IosArrowRtl } from '@styled-icons/fluentui-system-regular'
import { Grid } from '@mui/material';
/**
 * Styled component para el ícono de la flecha de navegación derecha.
 * 
 * @author ERL 2023-08-10 03:00 pm
 */

const ArrowN = styled(IosArrowRtl)`
      color: #000;
    `
/**
 * Styled component para el ícono de la flecha de navegación izquierda.
 * 
 * @author ERL 2023-08-10 03:15 pm
 */
const ArrowP = styled(IosArrowLtr)`
      color: #000;
    `
/**
 * Componente funcional para el botón de navegación hacia la siguiente imagen del slider.
 * @author ERL 2023-08-10 03:30 pm
 * @param {Object} props - Propiedades pasadas al componente.
 * @param {function} props.onClick - Función callback para el evento de click.
 * @returns {JSX.Element}
 */

function NextArrow(props) {
    const { onClick } = props;
    return (

        <button className='slick-next' onClick={onClick}><ArrowN /></button>

    );
}

/**
 * Componente funcional para el botón de navegación hacia la imagen anterior del slider.
 * @author ERL 2023-08-10 03:45 pm
 * @param {Object} props - Propiedades pasadas al componente.
 * @param {function} props.onClick - Función callback para el evento de click.
 * @returns {JSX.Element}
 */
function PrevArrow(props) {
    const { onClick } = props;
    return (

        <button className='slick-prev' onClick={onClick}><ArrowP /></button>

    );
}

/**
 * SliderDetalles - Componente React para mostrar un conjunto de imágenes en un carousel.
 * Utiliza la biblioteca 'react-slick' para la presentación de las imágenes.
 * @author ERL 2023-08-10 03:27 pm
 * @param {Array} images - Arreglo con las imágenes a mostrar, cada objeto debe contener la URL en la propiedad 'sURL'.
 * @param {boolean} mostrarMimiaturas - Indica si se deben mostrar miniaturas. Por defecto es 'true'.
 * @param {Function} callback - Función callback a ejecutar 
 * @returns {JSX.Element}
 * 
 */

function SliderDetalles({ images, mostrarMimiaturas = true, callback }) {
    const sliderRef = React.useRef(); // Referencia para el slider    
    const view360 = "/assets/view360.svg";
    const handleThumbnailClick = (index) => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);  // Mueve el slider a la imagen correspondiente
        }
    };

    const settings = {
        dots: mostrarMimiaturas,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        customPaging: function (i) {
            return (
                <>
                    {mostrarMimiaturas &&


                        <Grid sx={{ position: 'relative' }} className="slider-thumbnail-item" onClick={() => handleThumbnailClick(i)}>
                            <Grid
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    background: '#0000005c',
                                    display: images[i].bVista360 ? "block" : "none",
                                    borderRadius: '9px'
                                }}
                            >
                                <img src={view360} alt="" style={{ width: '33px' }} />
                            </Grid>
                            <img src={images[i].sURL} alt={`Imagen ${i}`} loading="lazy" />

                        </Grid>
                    }
                </>
            );
        },
       
    };
    const StyledSlider = styled(Slider)`
        height: 100%;

        & .slick-slide, 
        & .slick-slide div, 
        & .slider-main-image {
            height: 100%;
        }
    `;



    return (
        <div className="slider" style={{ height: '100%' }}>
            <StyledSlider ref={sliderRef} {...settings} style={{ height: '100%' }}>
                {images?.map((image, index) => (

                    <div className="slider-main-image"
                        style={{
                            height: '100%',
                            cursor: image.bVista360 ? "pointer" : "",


                        }}
                        onClick={() => image.bVista360 ? callback(true) : () => { return; }}
                        key={image.sURL}
                    >

                        <Grid sx={{ position: 'relative', }}>
                            <Grid
                                sx={{
                                    display: image.bVista360 ? "flex" : "none",
                                }}
                                className="view-bg-360"
                            >
                                <Grid sx={{
                                    position: 'relative',
                                    alignSelf: 'end',
                                    display: 'flex',
                                    width: '100%',
                                    height: '60px !important',
                                    flexDirection: 'column',
                                    color: '#fff',
                                    textAlign: 'center',
                                    top: '-25px'
                                }}>
                                    <img src={view360} alt="" style={{ width: '33px' }} />

                                    <span>Toca para ver detalles</span>
                                </Grid>

                            </Grid>
                            <img src={image?.sURL} alt={`Imagen ${index}`} loading="lazy"
                                style={{
                                    height: '100%',
                                    cursor: image.bVista360 ? "pointer" : "",
                                }}
                            />
                        </Grid>


                    </div>

                ))
                }
            </StyledSlider >
        </div >
    );
}

export default SliderDetalles;
