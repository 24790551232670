import React, { useState } from 'react';
import { useEffect } from 'react';

const CustomInput = ({ name, label, type, setIsValidForm, callback, value, inputRef, validEmail, setValidEmail, isValidForm }) => {
  const [touched, setTouched] = useState(value.trim() !== '');
  

  const handleFocus = () => {
    setTouched(true);
  };

  const handleBlur = (e) => {
    if (e.target.value.trim() === '') {
      setIsValidForm(!touched);
    }

    if (type === 'email') {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      setValidEmail(regex.test(e.target.value));
    }

    setTouched(false);
  };

  const handleChange = (e) => {
    callback(e.target.value);

    // if (!touched) {
    //   setIsValidForm(false);
    // }
    setIsValidForm(e.target.value.trim() !== '');
    if (type === 'email') {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        setValidEmail(regex.test(e.target.value));
    }
    setTouched(e.target.value.trim() !== '');
  };  
  useEffect(() => {
    
    if(isValidForm){
        if(value.length < 1){
            setTouched(true);
        }
    }
  }, [value, isValidForm]);
  return (
    <div className="form-item">
      <label className={`${touched || value !== '' ? 'label-a ' : ''}`} htmlFor={label}>
        {label}:
      </label>
      {type === 'input' && (
        <input
          type="text"
          className={`input-style ${value.length < 1 && touched ? 'invalid' : ''}`}
          name={name}
          id={label}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value}
          ref={inputRef}
        />
      )}
      {type === 'email' && (
        <input
          type="email"
          className={`input-style ${(value.length < 1 && !validEmail && touched) || (value.length > 1 && !validEmail && touched) ? 'invalid' : ''}`}
          name={name}
          id={label}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value}
          ref={inputRef}
        />
      )}
      {type === 'textarea' && (
        <textarea
          type="text"
          className="input-style"
          name={name}
          id={label}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          ref={inputRef}
          onChange={handleChange}
        />
      )}
      {type !== 'textarea' && value.length < 1 && touched && <span>Campo requerido</span>}
      {type === 'email' && value.trim() !== '' && !validEmail && <span>Correo inválido</span>}
    </div>
  );
};

export default CustomInput;
