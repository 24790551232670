// Importando módulos necesarios
import React, { useState } from 'react';
import { Checkbox, Divider, FormControlLabel, Grid, InputLabel, Link, Stack, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './registro.scss';
import { Google } from '@mui/icons-material';
import { IconButton, InputAdornment } from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { CircularProgress, useTheme } from '@mui/material';
import { useRegistrar } from '../../../hooks/usuariosApi/useRegistrar';
import ConfirmarRegistro from './ConfirmarRegistro';
import { useGoogleAuth } from '../../../hooks/googleApi/useGoogleRegistro';
import { StyledButton2 } from '../../../components/customButtons/StyledButton';
import { regex } from '../../../helpers/regex';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import { LANG } from '../LANG';
import { globalMessage } from '../../../helpers/globalMessage';


// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});

/**
 * Componente de Registro de usuarios
 * @author ERL 2023-06-09 09:41 am 
 * @returns {JSX.Element} 
*/
export default function Registro() {
    const { handleSubmit, setValue, watch, formState: { errors }, control, setError, trigger, clearErrors } = useForm();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();
    const [loading, setLoading] = useState(false);
    // Definición de estado inicial para los campos de registro
    const [correo, setCorreo] = useState('');
    const [accountCreated, setAccountCreated] = useState(false);
    // Usando el hook de autenticación de Google para obtener datos de registro del usuario
    const { registro, googleId } = useGoogleAuth(setValue, setError, trigger)
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmacionPendiente, setConfirmacionPendiente] = useState(localStorage.getItem('confirmacionPendiente') ? localStorage.getItem('confirmacionPendiente') : false);

    // Usando el hook para registrar al usuario
    const { registrarUsuario, uppercaseText } = useRegistrar(setAccountCreated, setLoading);


    /**
     * Redirige al usuario a la página de inicio de sesión.
     * @author ERL 2023-08-11 12:20 pm
     * @returns {void}
     */
    const loginUser = () => navigate("/login")


    /**
     * Maneja la lógica del envío del formulario de registro.
     * Esta función verifica si el usuario está registrándose mediante Google o Correo.
     * Si el usuario está registrándose con Google, se omite la entrada del correo.
     * Luego, define los datos del usuario y muestra un modal de confirmación.
     * Si el usuario confirma, se procede con el registro del usuario.
     * @author ERL 2023-06-12 11:15 am
     * @param {Object} data - Datos ingresados por el usuario en el formulario.
     * @returns {void}
     */
    const onSubmit = async (data) => {
        if (googleId === null || googleId === '') {
            setCorreo(data.sCorreo);
        };

        // Definiendo los datos del usuario para registrar        
        const datosUsuario = {
            sNombre: uppercaseText(data.sNombre),
            sApellidoP: uppercaseText(data.sApellidoP),
            sApellidoM: uppercaseText(data.sApellidoM),
            dFechaNacimiento: data.dFecha,
            sCorreo: data.sCorreo,
            sClientId: googleId,
            sPassword: data.sPassword,
            sTipoLogin: `${googleId !== null && googleId !== '' ? "Google" : "Correo"}`
        }

        try {
            // Mostrar modal y esperar confirmación
            await ModalConfirm.askConfirmation();
            // Si el usuario confirma, se procede con el registro
            registrarUsuario(datosUsuario);
        } catch (e) {
            
        }
    };
    // Hook para la navegación
    const navigate = useNavigate();

    /**
     * Determina si una fecha dada es al menos 18 años anterior a la fecha actual.
     * @author ERL 2023-06-15 03:35 pm
     * @param {string} dateString - Una cadena que representa una fecha en formato yyyy-mm-dd.
     * @returns {boolean} - Retorna `true` si la fecha dada es al menos 18 años anterior a la fecha actual, `false` en caso contrario.
     */
    const isMoreThan18YearsAgo = (dateString) => {
        const inputDate = new Date(dateString);
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
        return inputDate <= eighteenYearsAgo;
    };
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    eighteenYearsAgo.setMonth(0);
    eighteenYearsAgo.setDate(1);

    const [checked, setChecked] = useState(false);

    /**
     * Método que maneja el cambio de estado del checkbox asociado al aviso.
     * Previene la propagación del evento y actualiza el estado del checkbox.
     * @author ERL 2023-08-15 12:38pm
     * @param {Event} event - Evento del cambio en el checkbox.
     * @returns {void}
     */
    const aceptarAviso = (event) => {
        event.stopPropagation();
        setChecked(event.target.checked);
    };
    return (
        <ThemeProvider theme={defaultTheme}>
            {(accountCreated || confirmacionPendiente === 'true') && googleId === null ? <ConfirmarRegistro correo={correo} /> :
                <Grid container component="main" sx={{ height: '100vh' }} >
                    <ModalConfirm open={modalOpen} setOpen={setModalOpen} sTitulo={LANG.labelConfirmacion}>
                        {LANG.labelConfirmacionNombre}
                    </ModalConfirm>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={false}
                        md={7}
                        sx={{
                            backgroundImage: 'url(/assets/INICIO-CARRO-W.webp)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'contain',
                            backgroundPosition: 'left',
                        }}
                    />
                    <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>

                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Regístrate
                            </Typography>
                            <Box component="form"
                                noValidate
                                onSubmit={handleSubmit(onSubmit)}
                                sx={{
                                    mt: 1,
                                    mx: 0,
                                    width: {
                                        xl: '100%',
                                        xs: '100%',
                                    }
                                }}

                            >
                                <Grid sx={{
                                    color: 'red',
                                    textAlign: 'center',
                                    width:'100%'
                                }}>
                                    <i className='ine-warning'>
                                        {LANG.labelCoincide}*
                                    </i>
                                </Grid>
                                <Controller
                                    name="sNombre"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: globalMessage.errorInformacionRequerida }}
                                    render={({ field }) => (
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Nombre(s)"
                                            autoFocus
                                            {...field}
                                            aria-invalid={errors.sNombre ? "true" : "false"}
                                            error={!field.value || Boolean(errors.sNombre)}
                                            helperText={<center>{errors.sNombre?.message}</center>}
                                        />
                                    )}
                                />

                                <Controller
                                    name="sApellidoP"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: globalMessage.errorInformacionRequerida }}
                                    render={({ field }) => (
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Apellido paterno"
                                            aria-invalid={errors.sApellidoP ? "true" : "false"}
                                            id={`${errors.sApellidoP?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                            error={!field.value || Boolean(errors.sApellidoP)}
                                            helperText={<center>{errors.sApellidoP?.message}</center>}
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                if (errors.sApellidoP) {
                                                    clearErrors("sApellidoP");
                                                }
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="sApellidoM"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: globalMessage.errorInformacionRequerida }}
                                    render={({ field }) => (
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Apellido materno"
                                            aria-invalid={errors.sApellidoM ? "true" : "false"}
                                            id={`${errors.sApellidoM?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                            error={!field.value || Boolean(errors.sApellidoM)}
                                            helperText={<center>{errors.sApellidoM?.message}</center>}
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                if (errors.sApellidoM) {
                                                    clearErrors("sApellidoM");
                                                }
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name="dFecha"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: globalMessage.errorInformacionRequerida,
                                        validate: {
                                            isMoreThan18YearsAgo: value =>
                                                isMoreThan18YearsAgo(value) || 'Debes ser mayor de edad para continuar',
                                        },

                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="dFecha"
                                            autoComplete="family-name"
                                            label="Fecha de nacimiento"
                                            type='date'
                                            aria-invalid={errors.dFecha ? "true" : "false"}
                                            id={`${errors.dFecha?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                            inputProps={{
                                                className: 'date-input',
                                                maxLength: 10,
                                                style: { color: `${(field.value === '' || field.value === null) ? "transparent" : "inherit"} ` },
                                            }}
                                            error={Boolean(errors.dFecha)}
                                            helperText={errors.dFecha?.message}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="sCorreo"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: globalMessage.errorInformacionRequerida,
                                        pattern: {
                                            value: regex.isValidCorreo,
                                            message: "Ingresa un correo valido",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            label="Correo"

                                            aria-invalid={errors.sCorreo ? "true" : "false"}
                                            id={`${errors.sCorreo?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                            error={Boolean(errors.sCorreo)}
                                            helperText={<><center>{errors.sCorreo?.message} </center></>}
                                            {...field}
                                            disabled={(googleId && googleId !== '')}
                                        />
                                    )}
                                />
                                {googleId && googleId !== '' ? "" :
                                    <Controller
                                        name="sPassword"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: globalMessage.errorInformacionRequerida,
                                            pattern: {
                                                value: regex.isValidPassword,
                                                message: "Contraseña inválida. Necesita 8+ caracteres, un número, minúscula, mayúscula y símbolo ",
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                required
                                                fullWidth
                                                label="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                {...field}
                                                aria-invalid={errors.sPassword ? "true" : "false"}
                                                id={`${errors.sPassword?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                                error={Boolean(errors.sPassword)}
                                                helperText={<><center>{errors.sPassword?.message}</center></>}
                                                InputProps={{ // este es el nuevo objeto de props
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                }
                                <Grid textAlign='center' xs={12} >

                                    <FormControlLabel

                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={aceptarAviso}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <>
                                                {"Acepto los "}
                                                <Link href="/privacidad" target="_blank" rel="noopener noreferrer">
                                                    términos y condiciones
                                                </Link>
                                            </>
                                        }
                                    />
                                </Grid>
                                <Grid container sx={{ my: 2, ml: 0, mr: 0, p: 0, mx: 0 }} justifyContent="center" display={"flex"}>
                                    <StyledButton2 width="100%"
                                        style={{
                                            background: checked ? '' : 'gray'
                                        }}
                                        disabled={!checked}
                                    >
                                        {loading ? <CircularProgress color="inherit" /> : "Regístrate"}
                                    </StyledButton2>
                                </Grid>
                                <Grid container fullWidth display={"flex"} justifyContent={"center"}>
                                    <Grid>
                                        <Typography variant="p" onClick={loginUser} className='link-style'>
                                            ¿Ya tienes una cuenta? Inicia sesión
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid spacing={1} 
                                xs={12}
                                sx={{ fontSize: '15px', mt: '10px', mb: '10px' }}>
                                    <Divider sx={{ '--Divider-childPosition': `${50}%` }}>
                                        O
                                    </Divider>
                                </Grid>
                                <Grid container justifyContent="center">
                                    <Avatar sx={{ m: 1, bgcolor: '#ea4335' }} className='google-btn'>
                                        <Google onClick={registro} />
                                    </Avatar>
                                </Grid>


                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            }

        </ThemeProvider>
    );
}
