// Importando módulos necesarios
import React from 'react';
import '../../../../../styles/registro/global.scss'
import '../../../../../styles/registro/prospectos/pasos/documentos.scss'
import { Grid, InputLabel, IconButton, Tooltip } from '@mui/material';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import BadgeIcon from '@mui/icons-material/Badge';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DescriptionIcon from '@mui/icons-material/Description';
import { useFormContext, Controller, } from 'react-hook-form';
import PaidIcon from '@mui/icons-material/Paid';


/**
 * Componente Documento: Se encarga de renderizar un control de entrada para cargar archivos.
 * Proporciona una lógica de validación personalizada para tipos de archivo según la prop `question`.
 * @author ERL 2023-09-06 01:04 pm
 * @param {Object} question - Proporciona información sobre el documento (como tipo, ícono, validación, etc.).
 * @param {number} index - Índice del control de documento actual.
 * @param {Function} enviarImagenGoogle - Función para enviar el archivo seleccionado a Google para su procesamiento.
 * @param {Object} statusResponse - Mapea el estado del documento a su información (como color, mensaje, ícono).
 * @param {Function} getStatusDocs - Función para obtener el estado del documento.
 * @param {Array} lastIdFlujo - Lista de IDs de flujo.
 * @param {Object} LANG - Proporciona cadenas de texto localizadas.
 * @returns {JSX.Element} 
 */

const Documento = ({ question, index, enviarImagenGoogle, statusResponse, getStatusDocs, lastIdFlujo, LANG }) => {
    const { formState: { errors }, control, } = useFormContext();

    /**
     * Determina los tipos de archivo aceptados basados en el identificador proporcionado.
     * Devuelve una cadena con los types aceptados para cargar archivos, variando según el identificador de entrada.     
     * @author ERL 2024-02-20 4:33pm
     * @param {number} nIdentificador - Identificador que determina los tipos de archivo aceptados.
     * @returns {string}
     */

    const obtenerTiposDeArchivoAceptados = (nIdentificador) => {
        // Primera condición: para el identificador 1 - Constancia fiscal
        if ([1].includes(nIdentificador)) {
            return 'application/pdf, application/msword';
        }
        // Segunda condición: para el identificador 27 - Ingresos adicionales
        else if (nIdentificador === 27) {
            return 'application/pdf'; // Reemplazar con los tipos correctos
        }
        // Caso default para cualquier otro identificador
        else {
            return 'image/jpeg, image/png';
        }
    }

    const iconStyles = {
        mx: 0,
        mt: 0,
        p: 0,
        fontSize: {
            xl: 40,
            md: 30,
            sm: 25,
            xs: 20,
        },
    };
    const iconComponents = {
        INE: <BadgeIcon sx={{ ...iconStyles }} />,
        LIC: <DriveEtaIcon sx={{ ...iconStyles }} />,
        DOM: <OtherHousesIcon sx={{ ...iconStyles }} />,
        CON: <DescriptionIcon sx={{ ...iconStyles }} />,
        ING: <PaidIcon sx={{ ...iconStyles }} />
    };
    return (


        <Controller
            name={`doc${question?.nIdentificador}`}
            control={control}
            defaultValue=""
            rules={{
                required: "Información requerida",
                validate: {
                    checkFileType: (value) => {
                        if (value && value[0] && !obtenerTiposDeArchivoAceptados(value[0].type)) {
                            return 'Tipo de archivo no soportado';
                        }
                        return true;
                    }
                }
            }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Grid style={{
                    textAlign: "center",
                }}>
                    <Grid
                        sx={{
                            textAlign: "center",
                            border: '1px solid',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            boxSizing: 'border-box',
                            padding: {
                                xl: '0 20px',
                                md: '0 20px',
                                xs: '0 10px'
                            },
                            borderRadius: '10px',
                            width: {
                                xl: "100%",
                                md: "100%",
                                xs: '100%',
                            },
                            maxWidth: {
                                xl: "563px",
                                md: "563px",
                                xs: '100%',
                            },
                            minHeight: '62.22px'
                        }}

                        mx={"auto"}
                    >

                        <InputLabel sx={{
                            fontWeight: "bold",
                            fontSize: {
                                xl: "16px",
                                sm: "16px",
                                xs: '12px'
                            },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            boxSizing: 'border-box',
                            gap: '10px',
                            whiteSpace: "pre-line",
                            width: '100%',
                            minHeight: '62.22px',
                            cursor: 'pointer'
                        }}
                            htmlFor={`file${index}`}
                        >
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                mx={0}
                                mt={0}
                                p={0}
                                sx={{
                                    padding: "0 !important",
                                }}
                            >
                                <>
                                    {iconComponents[question?.sIcono]}
                                </>
                                <input
                                    type="file"
                                    id={`file${index}`}
                                    hidden
                                    accept={obtenerTiposDeArchivoAceptados(question?.nIdentificador)}
                                    disabled={
                                        question?.nIdDocumento > 0 &&
                                        ["VALIDANDO", "PENDIENTE", "VALIDADO"].includes(question?.sStatus)
                                    }
                                    onChange={(e) => {
                                        onChange(e.target.files);
                                        if (obtenerTiposDeArchivoAceptados(e.target.files[0].type)) {
                                            enviarImagenGoogle(
                                                e,
                                                e.target.files,
                                                question?.sValor,
                                                question?.sStatus,
                                                question?.nIdentificador,
                                                question?.sTipo,
                                                question?.bValidacion,
                                                question?.sIdFlujo,
                                                question?.sPagina,
                                                question?.sSolicita,
                                            );
                                        }
                                    }}
                                    onBlur={onBlur}
                                    ref={ref}
                                    name={name}
                                />
                            </IconButton>
                            {question?.sValor}
                        </InputLabel>

                        <InputLabel sx={{
                            fontWeight: "light",
                            fontSize: "1em",
                            display: "flex",
                            alignItems: "center",
                            color: (question?.bValidacionAdicional > 0 && (question.sStatus !== '' && !['RECHAZADO', 'ERROR'].includes(question.sStatus))) ? "orange" : statusResponse[getStatusDocs(question)].sStatusColor
                        }}>
                            <Tooltip title={question?.sStatus !== "" ? statusResponse[getStatusDocs(question)].sStatus : ""} arrow >
                                {
                                    statusResponse[getStatusDocs(question)].sIcono
                                }
                            </Tooltip>
                        </InputLabel>
                    </Grid>
                    <Grid pt={1}

                        sx={{
                            textAlign: "center",
                            margin: '0 auto',
                            boxSizing: 'border-box',

                            borderRadius: '10px',
                            width: {
                                xl: "100%",
                                md: "100%",
                                xs: '100%',
                            },
                            maxWidth: {
                                xl: "563px",
                                md: "563px",
                                xs: '100%',
                            },
                        }}
                    >
                        {(value && value[0]) || (question?.sStatus !== '') ?
                            <Grid sx={{ color: ["RECHAZADO", "ERROR", "ERROR_METAMAP", "ERROR_PDF"].includes(question.sStatus) ? "red" : "" }}>
                                {
                                    Boolean(question.bValidacionAdicional && ["CARGADO", "PENDIENTE", "VALIDADO"].includes(question.sStatus)) ? statusResponse["CARGADO"].sStatusMessage : statusResponse[getStatusDocs(question)].sStatusMessage
                                }
                            </Grid> :
                            <Grid pt={1} style={{
                                fontSize: "12px",
                                color: '#810000'
                            }}>
                                {question?.sPagina === "back" && lastIdFlujo.includes(question?.sIdFlujo) ? LANG.labelCargaFrontal : ""}
                                {question?.sPagina === "front" && lastIdFlujo.includes(question?.sIdFlujo) ? LANG.labelCargaTrasera : ""}
                            </Grid>
                        }
                    </Grid>
                </Grid>
            )}
        />
    );
};

export default Documento;
