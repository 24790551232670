
import Beneficios from "../beneficios/Beneficios";
import Intro from "../intro/Intro";
import Autos2 from "../autos/Autos2";
import Contact from "../contact/Contact";
import Requisitos from "../requisitos/Requisitos";
import React from 'react';
import AutosFinal from "../final/AutosFinal";
import FooterHome from "../../components/footer/FooterHome";
import Drivers from "../drivers/Drivers";



function Home() {
  
  return (
        <>
        <Intro/>        
        <Autos2/>
        <Requisitos/>
        <Beneficios/>
        <Drivers/>        
        <AutosFinal/>         
        </>
  );
}

export default Home;
