import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { routes } from './routes'

const DashboardRoutes = () => {

  return (
    <>
      <Routes>
        {Object.entries(routes).map(([path, component]) => (
          <Route key={path} path={path} element={component} />
        ))}
      </Routes>
    </>
  )
}

export default DashboardRoutes