import React from 'react'
import { Helmet } from 'react-helmet'
import { Box, Typography, Icon } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { StyledButton } from '../../components/customButtons/StyledButton';
import { useNavigate } from 'react-router-dom';

/**
 * Componente que muestra un mensaje de agradecimiento después de que un usuario se pone en contacto.
 * @autor ERL 2024-05-21 03:15 pm
 * @returns {JSX.Element}
 */
const MensajeContacto = () => {
    const navigate = useNavigate();    
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fff',
                padding: 4,
                borderRadius: 2,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                maxWidth: 400,
                margin: '5% auto',
                textAlign: 'center',
            }}
        >
            <Helmet>
                <script>
                    {`
                        gtag('event', 'conversion_event_signup', {
                            // <event_parameters>
                        });
                    `}
                </script>
            </Helmet>
            <Icon sx={{ fontSize: 50, color: 'green' }}>
                <CheckCircleOutlineIcon />
            </Icon>
            <Typography variant="h5" sx={{ marginY: 2 }}>
                Gracias por contactarnos
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 3 }}>
                Alguno de nuestros asesores se pondrá en contacto contigo.
            </Typography>
            <StyledButton onClick={() => navigate('/')}>Volver al inicio</StyledButton>
        </Box>

    )
}

export default MensajeContacto
