import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, RadioGroup } from '@mui/material'
import React from 'react'
/**
 * @author ERL 2022-11-16 04:06pm
 * @returns 
 */
const PalencaPlataformas = ({ selectedPlatformsCheck, setSelectedPlatformsCheck }) => {
    return (
        <Grid sx={{ justifyContent: 'center', display: 'flex' }}>

            <FormControl>
                <FormLabel id="" sx={{ textAlign: 'center' }}>{/*LANG.labelIngresosAdicionales*/}Plataformas</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{
                        justifyContent: 'center'
                    }}
                >
                    <FormControlLabel
                        //key={radio.sIdFlujo}
                        sx={{ textTransform: 'capitalize' }}
                        value={1}
                        control={<Checkbox
                            // checked={bIngresosAdicionales === 1}
                            onChange={(e) => {
                                setSelectedPlatformsCheck(prev => {
                                    // Verifica si el valor ya está en el array
                                    if (prev.includes(e.target.value)) {
                                        return prev.filter(p => p !== e.target.value); // Si ya está, no hagas cambios
                                    } else {
                                        return [...prev, e.target.value]; // Si no está, agrégalo
                                    }
                                });
                            }}

                        //disabled={!!sIdFlujoEncontrado}
                        />}
                        label={'Uber'}
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        //key={radio.sIdFlujo}
                        sx={{ textTransform: 'capitalize' }}
                        value={2}
                        control={<Checkbox
                            // checked={bIngresosAdicionales === 0}
                            onChange={(e) => {
                                setSelectedPlatformsCheck(prev => {
                                    // Verifica si el valor ya está en el array
                                    if (prev.includes(e.target.value)) {
                                        return prev.filter(p => p !== e.target.value); // Si ya está, no hagas cambios
                                    } else {
                                        return [...prev, e.target.value]; // Si no está, agrégalo
                                    }
                                });
                            }}

                        //     disabled={!!sIdFlujoEncontrado}
                        />}
                        label={'Didi'}
                        labelPlacement="start"
                    />
                </RadioGroup>
            </FormControl>
        </Grid>
    )
}

export default PalencaPlataformas