export const LANG = {
    errorBlurryText: 'El texto del documento está borroso.',
    errorTypeMismatch: 'El tipo de documento no es válido.',
    errorNoFace: 'No se puede identificar el rostro en el documento.',
    successDocumentSend: 'Archivo enviado correctamente.',
    labelCargaFrontal: 'Deberás cargar el reverso de este documento.',
    labelCargaTrasera: 'Deberás cargar el frente de este documento.',
    labelDocsValidos: 'Todos los documentos son obligatorios para continuar el proceso.',
    labelTipoDoc: 'Tipo de identificación',
    labelIngresosAdicionales: '¿Deseas agregar ingresos adicionales?',
}
