import React, { useState } from 'react'
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { urlsAPIs } from '../../helpers/urlsAPIs';
import { toast } from 'sonner';
import { useNavigate } from 'react-router';

/**
 * Devuelve la función registro con Google, el correo electrónico, Google id y Google token
 * @author ERL - 2023-06-09 10:03 am 
 * @param {Function} setValue - Función para establecer el valor de los campos del formulario
 * @param {Function} setError - Función para establecer errores de validación en el formulario
 * @param {Function} trigger - Función para desencadenar la validación de los campos del formulario
 * @returns {Object} - Retorna un objeto con la función de registro, el token de Google, y el id de Google
 */
export function useGoogleAuth(setValue, setError, trigger) {
  const [googleToken, setGoogleToken] = useState(null);
  const [googleId, setGoogleId] = useState(null);
  const navigate = useNavigate();
  const registro = useGoogleLogin({
    scope: urlsAPIs.urlGoogleScope,
    onSuccess: async (response) => {
      const token = response.access_token;
      setGoogleToken(token);
      const result = await axios.get(urlsAPIs.urlGooglePeople, {
        headers: { 'Authorization': 'Bearer ' + token }
      });
      const data = result.data;
      const googleId = data.emailAddresses[0]?.metadata.source.id;
      const name = data.names[0]?.givenName;
      const apellidoCompleto = data.names[0]?.familyName;
      const palabras = apellidoCompleto.split(" ");

      let apellidoPaterno, apellidoMaterno;
      // Si el apellido completo contiene más de dos palabras, asumimos que todo es el apellido paterno
      // y dejamos el apellido materno vacío
      if (palabras.length > 2) {
        apellidoPaterno = apellidoCompleto;
        apellidoMaterno = "";
        setError("sApellidoP", {
          type: "manual",
          message: "El formato no es correcto."
        });
      } else {
        // En caso contrario, la primera palabra es el apellido paterno y la segunda es el apellido materno
        apellidoPaterno = palabras[0];
        apellidoMaterno = palabras[1];
      }

      // Si el apellido materno quedó vacío, establecemos un error manual pidiendo al usuario que lo introduzca
      if (apellidoMaterno === "") {
        setError("sApellidoM", {
          type: "manual",
          message: "Por favor, introduce tu apellido materno."
        });
      }
      // const apellidoCompleto = data.names[0]?.familyName;
      // const ultimoEspacio = apellidoCompleto.lastIndexOf(" ");
      // const apellidoPaterno = apellidoCompleto.substring(0, ultimoEspacio);
      // const apellidoMaterno = apellidoCompleto.substring(ultimoEspacio + 1);
      const bDate = `${data.birthdays[0]?.date.day} ${data.birthdays[0]?.date.month} ${data.birthdays[0]?.date.year}`;
      const dateParts = bDate.split(" ");      
      const formattedDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]).toISOString().split("T")[0];
      const email = data.emailAddresses[0]?.value;
      setValue("sNombre", name);
      setValue("sApellidoP", apellidoPaterno);
      setValue("sApellidoM", apellidoMaterno);
      setValue("dFecha", formattedDate);
      setValue("sCorreo", email);
      setValue("sPassword", googleId);
      setGoogleId(googleId);            
           
    },
    onFailure: (error) => {
      console.log(error);
    },
  });

  return { registro, googleToken, googleId };
}
