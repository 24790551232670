import React from 'react'
import { CircularProgress, Grid } from '@mui/material'
import { createTheme, ThemeProvider, } from '@mui/material/styles'; // Herramientas de MUI para el tema

// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});
/**
* Componente loader
* @author ERL 2023-07-17 05:20 pm 
* @param {string} sColor - Color del loader
* @param {int} nSize - Tamaño del icono
* @param {int} nHeight - Tamaño del contenedor del loader
* @returns {JSX.Element} 
*/
const FullScreenLoader = ({ sColor = "primary", nSize = 100, nHeight = 50, fullScreen = false }) => {
    const GridStyles = {
        width: "100%", display: 'flex', justifyContent: "center", alignItems: "center",
        height: '100%',
        position: 'fixed !important',
        zIndex: 9999,
        background: fullScreen ? '#ffffff' : '#ffffffbd',
        top: fullScreen ? '0 !important' : '70px !important',

    };
    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid
                sx={GridStyles}
            >
                <CircularProgress color={sColor} size={`${nSize}px`} />
            </Grid>
        </ThemeProvider>
    )
}
export default FullScreenLoader
