import React from 'react'
import { useLocation } from 'react-router';
const whatsapp = "/assets/whatsapp.png";
const logoUber = '/assets/bestapart.png';
const Iconos = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const unallowedPaths = [
    {
      "/vacante": true,
      "/registro": true,
      "/login": true,
      "/proceso": true,
    }
  ]
  return (
    <>
      {unallowedPaths[0][pathname] ?
        "" : <>
          <img className="button_float" src={logoUber} alt="" />
          <a href="https://bit.ly/3pGO0pN"><img className="whatsapp-icon" src={whatsapp} alt="" /></a>

        </>
      }
    </>
  )
}

export default Iconos
