import React from 'react'
import { LANG } from './LANG'
import './drivers.scss';
import SliderDrivers from '../slider/SliderDrivers'
/**
 * @author ERL 2023-09-20 02:32 pm
 * @returns {JSX.Element}
 */
const DriversMain = () => {

  const aDrivers = [
    {
      sNombre: "Eder Reyes",
      sImg: "/assets/drivers/clientes/Opinion1-BESTA.png",
      sComentario: "Excelente servicio buena actitud entregas a tiempo 100% recomendable",
      sSucursal: "Monterrey",
      nCalificacion: 5
    },
    {
      sNombre: "Gustavo Bautista",
      sImg: "/assets/drivers/clientes/Opinion2-BESTA.png",
      sComentario: "Excelente servicio recomendable la mejor atención los vendedores excelente",
      sSucursal: "Guadalajara",
      nCalificacion: 5
    },
    {
      sNombre: "Chuy Rodríguez",
      sImg: "/assets/drivers/clientes/Opinion3-BESTA.png",
      sComentario: "Excelente servicio, facilidades de financiamiento y la atención a clientes tanto en taller y la atención personalizada del vendedor José Ramón, muy amable, dudas y con respuestas inmediatas",
      sSucursal: "Monterrey",
      nCalificacion: 5
    },
    {
      sNombre: "José Luis",
      sImg: "/assets/drivers/clientes/Opinion4-BESTA.png",
      sComentario: "Excelente servicio",
      sSucursal: "Guadalajara",
      nCalificacion: 5
    },
    {
      sNombre: "Leo Carrizales",
      sImg: "/assets/drivers/clientes/Opinion5-BESTA.png",
      sComentario: "Excelente servicio, facilidad para adquirir un vehículo con ellos y sobre todo Kidder es un excelente vendedor. Ante cualquier duda, rápidamente te da una solución. 100% recomendable y además cuentan con cómodas instalaciones para llevar tu vehículo a mantenimiento",
      sSucursal: "Monterrey",
      nCalificacion: 5
    },
    {
      sNombre: "Junior González",
      sImg: "/assets/drivers/clientes/Opinion6-BESTA.png",
      sComentario: "Excelente servicio, 100% recomendable, desde la información hasta la atención, totalmente accesible. Arrendadora confiable, atención 24 hrs, trámites sin dar tanta vuelta. ¿Qué esperan? Tramiten ya el suyo, no se arrepentirán y si se arrepienten, es porque no realizaron el trámite",
      sSucursal: "Monterrey",
      nCalificacion: 5
    },
    {
      sNombre: "Amilcar López",
      sImg: "/assets/drivers/clientes/Opinion7-BESTA.png",
      sComentario: "En mayo hago un año y sin problema",
      sSucursal: "Guadalajara",
      nCalificacion: 5
    },
  ]
  return (
    <div className='drivers'>
      <div className='drivers-title'>
        <h2>{LANG.labelDrivers}</h2>
      </div>
      <div className='drivers-desc'>
        <h3>{LANG.labelTestimonios}</h3>
      </div>
      <div className='drivers-container'>
        <SliderDrivers aSlider={aDrivers} />       
      </div>
    </div>
  )
}

export default DriversMain
