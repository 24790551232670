import React from 'react'
import { globalMessage } from '../../../../../helpers/globalMessage';
import { Grid } from '@mui/material';
import ReferenciasStep from './ReferenciasStep';
import { InputNumber, InputSelect, InputText } from './ReferenciasInputs';
import { regex } from '../../../../../helpers/regex';
/**
 * Componente que renderiza los pasos de las referencias enviadas en un proceso de stepper.
 * Cada paso muestra las preguntas relacionadas a la referencia correspondiente.
 * 
 * @author ERL 2023-07-19 06:35 am 
 * @param {array} dataReferencia - Lista de preguntas que se deben mostrar en cada referencia.
 * @param {array} steps - Lista de pasos en el stepper.
 * @param {number} nCurrentStep - El paso actual en el stepper.
 * @param {function} consultarColonias - Función que maneja la consulta de colonias.
 * @param {function} seleccionarColonia - Función que maneja la selección de una colonia. 
 * @param {boolean} loadingColonias - Determina si se muestra el loader al consultar colonias
 * @returns {JSX.Element}
 */

const ReferenciasEnviadasStepper = ({
    dataReferencia,
    steps,
    nCurrentStep,
    consultarColonias,
    seleccionarColonia,
    loadingColonias
}) => {

    return (
        <>

            {steps.map((step, i) => {
                return (

                    <ReferenciasStep nStep={i + 1} nCurrentStep={nCurrentStep} sStatus={step.sStatus} >
                        {dataReferencia.map((question, index) => {
                            if (question.nIdPadre === steps[nCurrentStep - 1].nIdentificador) {
                                return (
                                    <Grid item xs={12} p="15px 0" key={index}>
                                        {question.sTipo === 'text' && (
                                            <InputText
                                                nIdentificadorRefInput={`ref${step.nIdentificador}-${question.nIdentificador}`}
                                                sDefaultValue={question.respuestas}
                                                bRequired={globalMessage.errorInformacionRequerida}
                                                consultarColonias={consultarColonias}
                                                sLabel={question.sValor}
                                                sNombreCorto={question.sNombreCorto}
                                                sStatusStep={step.sStatus}
                                                nIdentificadorInput={question.nIdentificador}
                                                nIdentificadorRef={step.nIdentificador}
                                                readOnly={question.sTipo === "readonly"}
                                                bMaxLength={["sCodigoPostal"].includes(question.sNombreCorto) ? 5 : ""}
                                                bCodigoPostal={["sCodigoPostal"].includes(question.sNombreCorto)}
                                            />
                                        )}
                                        {question.sTipo === 'readonly' && (
                                            <InputText
                                                nIdentificadorRefInput={`ref${step.nIdentificador}-${question.nIdentificador}`}
                                                sDefaultValue={question.respuestas}
                                                bRequired={globalMessage.errorInformacionRequerida}
                                                consultarColonias={consultarColonias}
                                                sLabel={question.sValor}
                                                sNombreCorto={question.sNombreCorto}
                                                sStatusStep={step.sStatus}
                                                nIdentificadorInput={question.nIdentificador}
                                                nIdentificadorRef={step.nIdentificador}
                                                bReadOnly={question.sTipo === "readonly"}
                                                bMaxLength={["sCodigoPostal"].includes(question.sNombreCorto) ? 5 : ""}
                                                bCodigoPostal={["sCodigoPostal"].includes(question.sNombreCorto)}

                                            />


                                        )}

                                        {(question.sTipo === "number") && (
                                            <>

                                                <InputNumber
                                                    nIdentificadorRefInput={`ref${step.nIdentificador}-${question.nIdentificador}`}
                                                    sDefaultValue={question.respuestas}
                                                    bRequired={globalMessage.errorInformacionRequerida}
                                                    consultarColonias={consultarColonias}
                                                    sLabel={question.sValor}
                                                    sNombreCorto={question.sNombreCorto}
                                                    sStatusStep={step.sStatus}
                                                    nIdentificadorInput={question.nIdentificador}
                                                    nIdentificadorRef={step.nIdentificador}
                                                    readOnly={question.sTipo === "readonly"}
                                                    bMaxLength={["sCodigoPostal"].includes(question.sNombreCorto) ? 5 : ""}
                                                    bCodigoPostal={["sCodigoPostal"].includes(question.sNombreCorto)}
                                                    nMinLength={["sTelefono"].includes(question.sNombreCorto) ? 10 : ""}
                                                    isTelefono={["sTelefono"].includes(question.sNombreCorto)}
                                                    loadingColonias={loadingColonias}
                                                />

                                            </>

                                        )}

                                        {question.sTipo === 'select' &&
                                            (
                                                <>
                                                    <InputSelect
                                                        nIdentificadorRefInput={`ref${step.nIdentificador}-${question.nIdentificador}`}
                                                        bRequired={globalMessage.errorInformacionRequerida}
                                                        consultarColonias={consultarColonias}
                                                        sLabel={question.sValor}
                                                        sNombreCorto={question.sNombreCorto}
                                                        sStatusStep={step.sStatus}
                                                        nIdentificadorInput={step.nIdentificador}
                                                        nIdentificadorRef={step.nIdentificador}
                                                        bReadOnly={question.sTipo === "readonly"}
                                                        bMaxLength={["sCodigoPostal"].includes(question.sNombreCorto) ? 5 : ""}
                                                        bCodigoPostal={["nIdColonia"].includes(question.sNombreCorto)}
                                                        sDefaultValue={question.sRespuesta}
                                                        bIdColonia={["nIdColonia"].includes(question.sNombreCorto)}
                                                        respuestas={question.respuestas}
                                                        seleccionarColonia={seleccionarColonia}
                                                        loadingColonias={loadingColonias}

                                                    />
                                                </>
                                            )}
                                    </Grid>
                                );
                            }
                            return null;
                        })}


                    </ReferenciasStep>
                );
            })}


        </>
    );
};


export default ReferenciasEnviadasStepper



