import React from 'react'
/**
 * @author ERL 2023-09-20 03:02 pm
 * @param {object} props - Propiedades del componente
 * @field {string} sImg - Imagen del conductor 
 * @returns {JSX.Element}
 */
const DriverPhoto = ({sImg}) => {
  return (
    <>
        <img src={sImg} alt="" />
    </>
  )
}

export default DriverPhoto
