// documentosSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// Importando módulos necesarios
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

const statusResponse = {
  VALIDADO: {
    sStatusColor: "green",
    sStatusMessage: "Archivo validado",
    sIcono: <CheckCircleIcon />,
  },
  RECHAZADO: {
    sStatusColor: "red",
    sStatusMessage: "Archivo rechazado",
    sIcono: <CancelIcon />,
  },
  ERROR: {
    sStatusColor: "red",
    sStatusMessage: "Error en la carga del archivo",
    sIcono: <CancelIcon />,
  },
  ERROR_METAMAP: {
    sStatusColor: "red",
    sStatusMessage: "",
    sIcono: <CancelIcon />,
  },
  ERROR_PDF: {
    sStatusColor: "red",
    sStatusMessage: "Tipo de archivo no soportado",
    sIcono: <CancelIcon />,
  },
  CARGADO: {
    sStatusColor: "orange",
    sStatusMessage: "Validando documentos",
    sIcono: <AccessTimeFilledIcon />,
  },
  ENVIADO: {
    sStatusColor: "blue",
    sStatusMessage: "Archivo enviado",
    sIcono: <AccessTimeFilledIcon />,
  },
  REEMPLAZADO: {
    sStatusColor: "blue",
    sStatusMessage: "Archivo validado",
    sIcono: <CheckCircleIcon />,
  },
  PENDIENTE: {
    sStatusColor: "orange",
    sStatusMessage: "Pendiente",
    sIcono: <AccessTimeFilledIcon />,
  },
  VALIDANDO: {
    sStatusColor: "orange",
    sStatusMessage: "Validando archivo",
    sIcono: <AccessTimeFilledIcon />,
  },
  "": {
    sStatusColor: "black",
    sStatusMessage: "",
    sIcono: <RadioButtonUncheckedIcon />,
  },
};

const documentosSlice = createSlice({
  name: "documentos",
  initialState: {
    dataDocumentos: [],
    loadingComponent: true,
    aNidentificadores: [],
    aRadios: [],
    sTipoDoc: "64f8bf049bd233001cbe00eb",
    statusResponse: statusResponse,
    socket: null,
    rejectedStatus: [],
    entit: "",
  },
  reducers: {
    setDataDocumentos: (state, action) => {
      state.dataDocumentos = action.payload;
    },
    addRejectedStatus: (state, action) => {
      state.rejectedStatus.push(action.payload);
    },
    toggleLoadingComponent: (state) => {
      state.loadingComponent = !state.loadingComponent;
    },
    setNIdentificadores: (state, action) => {
      state.aNidentificadores = action.payload;
    },
    setRadios: (state, action) => {
      state.aRadios = action.payload;
    },
    setTipoDoc: (state, action) => {
      state.sTipoDoc = action.payload;
    },
    setStatusResponses: (state, action) => {
      const updaterFunction = action.payload;
      state.statusResponse = updaterFunction(state.statusResponse);
    },
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setEntit: (state, action) => {
      state.entit = action.payload;
    },
    resetRejectedStatus: (state) => {
      state.rejectedStatus = [];
    },
  },
});

export const {
  setDataDocumentos,
  toggleLoadingComponent,
  setNIdentificadores,
  setRadios,
  setTipoDoc,
  setStatusResponses,
  setSocket,
  addRejectedStatus,
  setEntit, 
  resetRejectedStatus
} = documentosSlice.actions;

export default documentosSlice.reducer;
