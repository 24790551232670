// Importando módulos necesarios
import React, { useEffect } from 'react';
import '../../../../../styles/registro/global.scss'
import '../../../../../styles/registro/prospectos/pasos/documentos.scss'

import CssBaseline from '@mui/material/CssBaseline';
import { Container, Box, Typography, Grid, CircularProgress, } from '@mui/material';
import { createTheme, ThemeProvider, } from '@mui/material/styles'; // Herramientas de MUI para el tema

import { FormProvider } from 'react-hook-form';
import { LANG } from './LANG';
import useDocumentos from '../../../../../hooks/prospectosApi/pasos/useDocumentos';
import DocumentosTipo from './DocumentosTipo';
import { ListItem } from './ListItem';
import Documento from './Documento';
import { useContext } from 'react';
import { ProgressBarContext } from '../../../../../context/ProgressBarContext';
import FullScreenLoader from '../../../../loader/FullScreenLoader';
import DocumentosIngresoAdicional from './DocumentosIngresoAdicional';
import { useState } from 'react';


// Tema por defecto
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});
/**
 * Documentos - Componente React para gestionar y visualizar la carga de múltiples documentos.
 * Este componente recopila información sobre diferentes tipos de documentos y permite 
 * interacciones para cargar, validar y eliminar esos documentos. Además, presenta
 * una lista de documentos, cada uno de ellos representado por el componente `Documento`.
 * @author ERL 2023-09-06 05:34 pm
 * @param {Function} nextStep - Función que maneja el siguiente paso en un flujo.
 * @param {string} status - Estado actual de los documentos.
 * @param {string} sActivo - Estado que indica si un componente o función está activa.
 * @param {Object} headers - Headers para las llamadas API.
 * @returns {JSX.Element} 
 */

const Documentos = ({ nextStep, status, sActivo, headers }) => {

    const {
        metamapValidos,
        getStatusDocs,
        handleRemove,
        lastIdFlujo,
        mostrarDocumento,
        enviarImagenGoogle,
        methods,
        docLoading,
        setDocLoading,
        bIngresosAdicionales,
        setBIngresosAdicionales,
        sinIngresosAdicionales
    } = useDocumentos({ nextStep, status, sActivo, headers });

    const {
        dataDocumentos,
        loadingComponent,
        aRadios,
        sTipoDoc,
        setTipoDoc,
        statusResponse,
        setTipoDefaultDoc,
        bSinIngresoAdicional
    } = useContext(ProgressBarContext)

    const propsDocumento = {
        width: {
            xl: '90%',
            sm: '80%',
            xs: '100%',
        },
    }


    useEffect(() => {
        if (aRadios.length > 0) {
            const defaultTipo = aRadios.filter(r => r.sTipo === 'ine');
            const defaultTipoV = aRadios.filter(r => r.nIdDocumento !== 0);
            const tieneDoc = aRadios.some(r => r.nIdDocumento !== 0);

            const { sIdFlujo: sIdFlujoDefault } = defaultTipo[0] || [];
            setTipoDefaultDoc(sIdFlujoDefault)
            if (tieneDoc) {
                const { sIdFlujo } = defaultTipoV[0] || [];
                setTipoDoc(sIdFlujo)

            } else {
                const { sIdFlujo } = defaultTipo[0];
                setTipoDoc(sIdFlujo)


            }
        }
    }, [aRadios])



    // Renderizando el componente
    return (
        <ThemeProvider theme={defaultTheme}>

            <Container
                component="main"
                maxWidth="sm"
                sx={{
                    maxWidth: {
                        xs: '100%',
                        sm: '100%',
                        md: '600px',
                        lg: '1600px',
                    },
                    padding: {
                        xs: '0'
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Box

                        noValidate

                        sx={{
                            mt: {
                                xl: 3,
                                xs: 0,
                            },
                            mx: {
                                xl: 3,
                                xs: 0,
                            },
                            mb: {
                                xl: 3,
                                xs: 0,
                            },
                            minHeight: {
                                lg: '500px',
                            },
                            width: '100%'
                        }}
                    >

                        <Grid container spacing={2} sx={{
                            ml: 0,
                            mt: 0,
                            width: "100%",
                        }}>
                            <Grid
                                container
                                sx={{
                                    m: '0 auto',
                                    p: 0,
                                    maxWidth: {
                                        xl: '800px',
                                        xs: '100%',
                                    },
                                    display: "flex",
                                    justifyContent: "space-around",
                                }}
                            >
                                <>
                                    {
                                        docLoading ?
                                            <FullScreenLoader />
                                            : ""
                                    }
                                    {
                                        loadingComponent ?
                                            <Grid
                                                sx={{
                                                    height: "50vh",
                                                    width: "100%",
                                                    display: 'flex',
                                                    justifyContent: "center",
                                                    alignItems: "center",

                                                }}><CircularProgress color="primary" size={'100px'} /></Grid>

                                            : <>
                                                <Typography variant="h6"
                                                    sx={{
                                                        color: "red",
                                                        fontSize: {
                                                            xl: '1.25rem',
                                                            sm: '1rem',
                                                            xs: '0.8rem'
                                                        },
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {metamapValidos ? "" : LANG.labelDocsValidos}
                                                </Typography>
                                                <DocumentosTipo
                                                    aRadios={aRadios}
                                                    setTipoDoc={setTipoDoc}
                                                    sTipoDoc={sTipoDoc}
                                                />

                                                <FormProvider {...methods}>
                                                    {


                                                        dataDocumentos.map((question, index) => (

                                                            ["VALIDADO", "VALIDANDO", "ENVIADO", "CARGADO"].includes(getStatusDocs(question)) && (question.nIdentificador !== 20) && Boolean(!question.bValidacionAdicional) ?
                                                                <>
                                                                    {(question.sStatus !== 'VALIDADO') &&
                                                                        <ListItem
                                                                            key={question.nIdentificador}
                                                                            question={question}
                                                                            onRemove={handleRemove}
                                                                            index={index}
                                                                            delay={index * 420}
                                                                            statusMsgTxt
                                                                            lastIdFlujo={lastIdFlujo}
                                                                            statusResponse={statusResponse}
                                                                            getStatusDocs={getStatusDocs}

                                                                        />}

                                                                </>
                                                                :
                                                                <>
                                                                    {(question.nIdReferenciado) !== 0 && (question.sLanguaje !== '') ?

                                                                        sTipoDoc === question.sIdFlujo &&
                                                                        <Grid item p="15px 0" key={index}
                                                                            mx={"auto"}
                                                                            sx={{
                                                                                ...propsDocumento,
                                                                                display: mostrarDocumento(question?.nIdentificador) ? 'block' : 'none'
                                                                            }}
                                                                        >
                                                                            <Documento
                                                                                question={question}
                                                                                index={index}
                                                                                enviarImagenGoogle={enviarImagenGoogle}
                                                                                statusResponse={statusResponse}
                                                                                getStatusDocs={getStatusDocs}
                                                                                lastIdFlujo={lastIdFlujo}
                                                                                LANG={LANG}



                                                                            />
                                                                        </Grid>
                                                                        :

                                                                        <>
                                                                            {
                                                                                question?.sStatus === 'VALIDADO' ?

                                                                                    Boolean(question.bValidacionAdicional) ?
                                                                                        question.sDescripcion !== "" ? "" : // Si tiene descripcion ya fue revisado por lili y desaparece de la lista
                                                                                            <Grid item p="15px 0" key={index}
                                                                                                mx={"auto"}
                                                                                                sx={{
                                                                                                    ...propsDocumento,
                                                                                                    display: mostrarDocumento(question?.nIdentificador) ? 'block' : 'none'
                                                                                                }}
                                                                                            >

                                                                                                <Documento
                                                                                                    question={question}
                                                                                                    index={index}
                                                                                                    enviarImagenGoogle={enviarImagenGoogle}
                                                                                                    statusResponse={statusResponse}
                                                                                                    getStatusDocs={getStatusDocs}
                                                                                                    lastIdFlujo={lastIdFlujo}
                                                                                                    LANG={LANG}

                                                                                                />
                                                                                            </Grid> :
                                                                                        ""
                                                                                    :
                                                                                    <Grid item p="15px 0" key={index}
                                                                                        mx={"auto"}
                                                                                        sx={{
                                                                                            ...propsDocumento,
                                                                                            display: mostrarDocumento(question?.nIdentificador) ? 'block' : 'none'
                                                                                        }}
                                                                                    >

                                                                                        {
                                                                                            question.sSolicita === "I" && !bSinIngresoAdicional ?
                                                                                                <>

                                                                                                    <DocumentosIngresoAdicional
                                                                                                        bIngresosAdicionales={bIngresosAdicionales}
                                                                                                        setBIngresosAdicionales={setBIngresosAdicionales}
                                                                                                        sinIngresosAdicionales={sinIngresosAdicionales}
                                                                                                    />
                                                                                                    {Boolean(bIngresosAdicionales) &&
                                                                                                        <Documento
                                                                                                            question={question}
                                                                                                            index={index}
                                                                                                            enviarImagenGoogle={enviarImagenGoogle}
                                                                                                            statusResponse={statusResponse}
                                                                                                            getStatusDocs={getStatusDocs}
                                                                                                            lastIdFlujo={lastIdFlujo}
                                                                                                            LANG={LANG}

                                                                                                        />
                                                                                                    }

                                                                                                </>
                                                                                                :

                                                                                                question.sSolicita === "I" && bSinIngresoAdicional ?
                                                                                                    "" : <Documento
                                                                                                        question={question}
                                                                                                        index={index}
                                                                                                        enviarImagenGoogle={enviarImagenGoogle}
                                                                                                        statusResponse={statusResponse}
                                                                                                        getStatusDocs={getStatusDocs}
                                                                                                        lastIdFlujo={lastIdFlujo}
                                                                                                        LANG={LANG}

                                                                                                    />

                                                                                        }

                                                                                    </Grid>
                                                                            }


                                                                        </>
                                                                    }
                                                                </>
                                                        )
                                                        )
                                                    }
                                                </FormProvider>
                                            </>
                                    }

                                </>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Documentos;

