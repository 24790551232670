import { Grid, Typography } from '@mui/material'
import React from 'react'
import { LANG } from '../LANG';

/**
 * Componente contenedor de los detalles de la unidad.
 * @author ERL 2023-08-10 02:11 pm
 * @param {Object} aUidadInfoBasica - Objeto que contiene la información básica de la unidad.
 * @returns {JSX.Element}
 */
const UnidadDetallesBasicos = ({ aUidadInfoBasica }) => {

    const { nYear, sDescripcion, sModelo, sTransmision, sUbicacion } = aUidadInfoBasica;
    return (
        <Grid
            sx={{
                marginBlockStart: '0',
                borderRadius: '0.5rem',
                border: '1px solid #e9e9e9',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',                
            }}
            
        >
            <Grid sx={{
                width: '100%',
                border: 'none',
                borderBottom: '1px solid #e9e9e9',
                boxSizing: 'border-box',
                padding: '15px 20px',
            }}>
                <Typography variant='b' component={'h4'} color={'primary'}>

                    {sDescripcion} {sModelo}

                </Typography>
                <Typography variant='p' sx={{ color: "#333333" }} >
                    {sUbicacion}
                </Typography>
            </Grid>
            <Grid sx={{
                width: '100%',
                border: 'none',
                borderBottom: '1px solid #e9e9e9',
                boxSizing: 'border-box',
                padding: '15px 20px',
            }}>
                <Typography variant='b' component={'h4'} color={'primary'}>
                    {LANG.labelYear}
                </Typography>
                <Typography>
                    {nYear}
                </Typography>
            </Grid>
            <Grid sx={{
                width: '100%',
                border: 'none',

                boxSizing: 'border-box',
                padding: '15px 20px',
            }}>
                <Typography variant='b' component={'h4'} color={'primary'}>
                    {LANG.labelTransmision}
                </Typography>
                <Typography>
                    {sTransmision}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default UnidadDetallesBasicos
