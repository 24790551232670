import React, { useState } from 'react'
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { urlsAPIs } from '../../helpers/urlsAPIs';
import { globalMessage } from '../../helpers/globalMessage';

/**
 * Devuelve la función login con Google, el correo electrónico, Google id y Google token
 * @author ERL 2023-06-09 10:03 am 
 * @param {Function} setCorreo - Función para establecer el correo electrónico del usuario
 
 * @returns {Object} - Retorna un objeto con la función de login, el token de Google, y el id de Google
 */
export function useGoogleAuth(setCorreo, setLoading, setError, setValue) {
  // Creando estados locales para el token y el id de Google
  const [googleToken, setGoogleToken] = useState(null);
  const [googleId, setGoogleId] = useState(null);

  const navigate = useNavigate();

  // Configurando el hook de inicio de sesión con Google
  const login = useGoogleLogin({
    scope: urlsAPIs.urlGoogleScope,
    onSuccess: async (responseGoogle) => {

      const token = responseGoogle.access_token;
      setGoogleToken(token);
      const result = await axios.get(urlsAPIs.urlGooglePeople, {
        headers: { 'Authorization': 'Bearer ' + token }
      });
      const data = result.data;
      const googleId = data.emailAddresses[0]?.metadata.source.id;
      const name = data.names[0]?.givenName;
      const apellidoCompleto = data.names[0]?.familyName;
      const palabras = apellidoCompleto.split(" ");

      let apellidoPaterno, apellidoMaterno;
      // Si el apellido completo contiene más de dos palabras, asumimos que todo es el apellido paterno
      // y dejamos el apellido materno vacío
      if (palabras.length > 2) {
        apellidoPaterno = apellidoCompleto;
        apellidoMaterno = "";
        setError("sApellidoP", {
          type: "manual",
          message: "El formato no es correcto."
        });
      } else {
        // En caso contrario, la primera palabra es el apellido paterno y la segunda es el apellido materno
        apellidoPaterno = palabras[0];
        apellidoMaterno = palabras[1];
      }

      // Si el apellido materno quedó vacío, establecemos un error manual pidiendo al usuario que lo introduzca
      if (apellidoMaterno === "") {
        setError("sApellidoM", {
          type: "manual",
          message: "Por favor, introduce tu apellido materno."
        });
      }

      const bDate = `${data.birthdays[0]?.date.day} ${data.birthdays[0]?.date.month} ${data.birthdays[0]?.date.year}`;
      const dateParts = bDate.split(" ");
      const formattedDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]).toISOString().split("T")[0];
      const email = data.emailAddresses[0]?.value;
      setValue("sNombre", name);
      setValue("sApellidoP", apellidoPaterno);
      setValue("sApellidoM", apellidoMaterno);
      setValue("dFecha", formattedDate);
      setValue("sCorreo", email);
      //setValue("sPassword", googleId);
      setGoogleId(googleId);
      // Extrayendo el correo electrónico del usuario
      // Actualizando el estado del componente padre con el correo electrónico del usuario
      setCorreo(email);
      const dataLogin = { sCorreo: email, sClientId: googleId, sTipoLogin: "Google" }
      setLoading(true);
      const responseAPI = await axios.post(urlsAPIs.urlLoginApi, dataLogin);
      const { jwt, sMensaje, nIdCliente, nIdUsuario, nIdStatusProspecto, nIdProspecto } = responseAPI.data;
      sessionStorage.setItem('p', nIdProspecto); // Prospecto id
      if (jwt.length > 0) {
        if (nIdStatusProspecto !== 8) {

          sessionStorage.setItem('t', jwt); // Token
          sessionStorage.setItem('u', nIdUsuario); // Usuario id
          sessionStorage.setItem('p', nIdProspecto); // Prospecto id
          sessionStorage.setItem('c', email); // Correo usuario
          sessionStorage.setItem('ci', nIdCliente); // Cliente id
          sessionStorage.setItem('s', nIdStatusProspecto); // Status
          toast.success(sMensaje);
          navigate('/proceso');
        } else {
          toast.error(globalMessage.errorProspecto)
        }

      } else {
        // navigate('/registro');
        toast.error(sMensaje)
      }
      setLoading(false);
    },
    onFailure: (error) => {
      // Registrando el error en caso de que la solicitud falla
      toast.error(globalMessage.errorServidor)
    },
  });

  const registro = useGoogleLogin({
    scope: urlsAPIs.urlGoogleScope,
    onSuccess: async (response) => {


    },
    onFailure: (error) => {
      toast.error(globalMessage.errorServidor)
    },
  });



  // Devolviendo los valores y funciones relevantes
  return { login, googleToken, googleId };
}
