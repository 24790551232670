// Importando módulos necesarios
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'sonner';
import { urlsAPIs } from '../../../helpers/urlsAPIs';
import { globalMessage } from '../../../helpers/globalMessage';
import { LANG } from '../../../components/registro/prospectos/pasos/documentos/LANG';
import { CustomToast } from '../../../components/alerts/CustomToast';
import { useContext } from 'react';
import { ProgressBarContext } from '../../../context/ProgressBarContext';
import { errorsMetamap } from '../../../helpers/validationErrorsMetamap';

/**
 * Hook personalizado para obtener y manejar documentos.
 * Este hook se encarga de gestionar los documentos del usuario, incluyendo la 
 * obtención, ordenamiento y establecimiento en el contexto correspondiente.
 * 
 * @param {Object} config - Configuración para el hook, incluye métodos y estados a utilizar.
 * @returns {Object} 
 * @author ERL 2023-09-06 02:27 pm
 */
const useDocumentos = (config = {}) => {

    const { nextStep, status, sActivo, headers, } = config;

    const methods = useForm();

    const [docLoading, setDocLoading] = useState(null);
    const {
        dataDocumentos,
        setDataDocumentos,
        setLoadingComponent,
        aNidentificadores,
        setNIdentificadores,
        setRadios,
        sTipoDoc,
        defaultTipoDoc,
        setStatusResponses,
        activeStep,
        bIngresosAdicionales,
        setBIngresosAdicionales,
        getStepsFromAPI,
        bIngresoAdicional,
        setIngresoAdicional,
        logout
    } = useContext(ProgressBarContext)

    const aPassportIds = [25];
    const aINEIds = [11, 16];
    const VALIDATED_IDS_TO_VERIFY = [...(sTipoDoc !== defaultTipoDoc ? aPassportIds : aINEIds), 13, 12, 17];
    const VALIDATED_IDS = [...(sTipoDoc !== defaultTipoDoc ? aPassportIds : aINEIds), 1, 13, 12, 17];
    const BLOCK_DISPLAY_IDS = [9, 18];
    const socketRef = useRef(null);
    const idUsuario = sessionStorage.getItem('u');
    const [entit, setEntit] = useState("")
    const [idFlujo, setIdFlujo] = useState("")
    const [errorMeta, setErrorMeta] = useState(false);
    const [uniqHasCode, setUniqHashCode] = useState([]);
    const [statusSend, setStatusSend] = useState(JSON.parse(localStorage.getItem('documentsSend') || '[]'));
    const [lastIdFlujo, setLastIdFlujo] = useState([]);


    /**
     * Se encarga de obtener los documentos del usuario desde una API.
     * A partir del ID del usuario almacenado en la sesión, se realiza una solicitud al endpoint de documentos.
     * Una vez obtenida la respuesta, esta función organiza, filtra y procesa la información de los documentos, 
     * y posteriormente actualiza el estado del componente con dicha información.
     * @author ERL 2023-09-08 01:04 pm
     * @returns {void} No retorna nada, pero actualiza el estado del componente con la información procesada.
     */
    const getData = async () => {

        const id = sessionStorage.getItem('u');
        try {
            const response = await axios.get(`${urlsAPIs.urlDocumentos}/${id}`, {
                params: {
                    sTipo: "P"
                },
                headers: headers
            });
            const { nCodigo, sMensaje, data: formDocumentos } = response.data;

            // Ordena los documentos por su número de orden
            formDocumentos.sort((a, b) => a.nOrden - b.nOrden);

            if (nCodigo === 0) {
                setDataDocumentos(formDocumentos);
                const documentoIngresoAdicional = formDocumentos.filter((f => f.sSolicita === 'I'));

                setIngresoAdicional(documentoIngresoAdicional[0].sLink !== '')
                const filteredDataDocumentos = formDocumentos.filter((f => f.bValidacion === 1 && ["PENDIENTE"].includes(f.sStatus)));
                const documentosRechazados = formDocumentos.filter((f => f.bValidacion === 1 && ["RECHAZADO", "VALIDADO", "PENDIENTE"].includes(f.sStatus)));

                const groupedByFlujo = documentosRechazados.reduce((acc, item) => {
                    if (!acc[item.sIdFlujo]) {
                        acc[item.sIdFlujo] = [];
                    }
                    acc[item.sIdFlujo].push(item);
                    return acc;
                }, {});
                for (let sIdFlujo in groupedByFlujo) {
                    const documentos = groupedByFlujo[sIdFlujo];
                    const todosRechazados = documentos.every(doc => doc.sStatus === 'RECHAZADO');
                    const todosValidados = documentos.every(doc => doc.sStatus === 'VALIDADO');

                    if (todosRechazados || todosValidados) {
                        borrarEntityRechazado(sIdFlujo);
                    }
                }

                setNIdentificadores(filteredDataDocumentos.map(objeto => objeto.nIdentificador));

                const oTipoDocumentos = formDocumentos.reduce((acc, item) => {
                    if (item.nIdReferenciado !== 0 && item.sLanguaje !== '') {
                        if (!acc[item.sTipo]) {
                            acc[item.sTipo] = {
                                sTipo: item.sLanguaje,
                                sIdFlujo: item.sIdFlujo,
                                nIdDocumento: item.nIdDocumento,
                                sStatus: item.sStatus
                            };
                        }
                    }
                    return acc;
                }, {});
                const aOpcionesTipo = Object.values(oTipoDocumentos);
                setRadios(aOpcionesTipo)
            }
        } catch (error) {
            const { responseCode = 0, message = "" } = error?.response.data
            if (responseCode === '401' && message === 'jwt expired') {
                logout()
            } else {
                toast.error(globalMessage.errorServidor)
            }
        } finally {

            setLoadingComponent(false);
        }
    }
    /**
     * Efecto que se ejecuta después de que el componente se haya renderizado, se utiliza para obtener los datos de los documentos 
     * del usuario y actualizar el estado del componente.
     * @author ERL 2023-06-28 10:27 am
     * @returns {void}
    */
    useEffect(() => {
        getData();

    }, [sActivo]);

    /**
     * Efecto para manejar la conexión del WebSocket basada en cambios en aNidentificadores.
     * 
     * Al detectar cambios en aNidentificadores, si la longitud de aNidentificadores es mayor que 0, 
     * intenta abrir una conexión WebSocket usando la función openSocket.
     * @author ERL 2023-07-06 09:44 am
     * @returns {void}
     */
    useEffect(() => {

        // Verifica si el array aNidentificadores tiene elementos.
        if (aNidentificadores.length > 0) {
            // Si tiene elementos, intenta abrir la conexión WebSocket.            
            openSocket(aNidentificadores);
            localStorage.setItem('documentsSend', JSON.stringify(aNidentificadores));
        }

    },
        [aNidentificadores]  // Dependencia del useEffect, se ejecuta cada vez que aNidentificadores cambie.
    );

    /**
     * Efecto para manejar la conexión del WebSocket basada en cambios en statusSend.
     * 
     * Al detectar cambios en aNidentificadores, si la longitud de aNidentificadores es mayor que 0, 
     * intenta abrir una conexión WebSocket usando la función openSocket.
     * @author ERL 2023-07-06 09:44 am
     * @returns {void}
     */
    useEffect(() => {
        if (statusSend.length > 0) {
            openSocket(statusSend);
        }
    }, [statusSend]);


    /**
    * Abre una conexión WebSocket para recibir actualizaciones en tiempo real sobre el estado de validación de documentos.
    * @author ERL 2023-07-03 09:44 am
    * @param {Array} documentsSend - Array de documentos enviados para validación. Si no se proporciona, se usa statusSend.
    * @returns {void} 
    */
    const openSocket = async (documentsSend = []) => {
        return await new Promise((resolve, reject) => {
            try {
                // Solo abre una nueva conexión si no hay una ya abierta.
                if (!socketRef.current || socketRef.current.readyState !== WebSocket.OPEN) {
                    socketRef.current = new WebSocket(urlsAPIs.urlSocketDocumentos);

                    // Maneja el evento de apertura de la conexión.
                    socketRef.current.onopen = (event) => {
                        if (socketRef.current.readyState === WebSocket.OPEN) {
                            // Envía el ID del usuario una vez que la conexión está abierta.
                            socketRef.current.send(JSON.stringify({ action: 'sendUser', nIdUsuario: idUsuario }));
                        }
                    };
                }

                // Configura el evento para recibir mensajes.
                socketRef.current.onmessage = async (event) => {
                    const response = JSON.parse(event.data);
                    const { sTipoNotificacion } = response;
                    if (sTipoNotificacion === 'documentos') {
                        // Actualiza el estado de validación del documento basado en el mensaje recibido.              
                        updateDocumentValidationStatus(response, documentsSend);
                    } else if (sTipoNotificacion === 'prospecto') {
                        const { sTipo, sMensaje, nCodigo } = response;
                        toast.custom((t) => (
                            <CustomToast type={`${!Boolean(nCodigo) ? sTipo : 'error'}`} message={sMensaje} toastRef={t} />
                        ), {
                            duration: Infinity,
                        });
                    }
                };

                // Maneja cualquier error que ocurra durante la comunicación.
                socketRef.current.onerror = (error) => {

                    toast.custom((t) => (
                        <CustomToast type="error" message={globalMessage.errorServidor} toastRef={t} />
                    ));
                };

                // Maneja el evento de cierre de la conexión.
                socketRef.current.onclose = () => {
                    if (activeStep !== 7) {
                        openSocket(documentsSend);
                    }
                };

                // Si todo es exitoso, resuelve la promesa.
                resolve();
            } catch (error) {
                // En caso de error, rechaza la promesa.
                reject(error);
            }
        });
    }





    /**
      * Envía un documento a un servicio para validación utilizando Metamap.
      * @author ERL 2023-07-04 03:45 PM
      * @param {File} archivo - Archivo del documento que se enviará.
      * @param {string} nombreArchivo - Nombre del archivo.
      * @param {string} sTipo - Tipo del documento (ej. "PASSPORT", "DRIVER_LICENSE").
      * @param {string} sIdFlujo - ID relacionado a un proceso o flujo específico.
      * @param {string} sPagina - Indica si es la página "front" o "back" del documento.
      * @param {number} nIdUsuario - Identificador único del usuario.
      * @param {number} nIdDocumento - Identificador único del documento.
      * @param {string} sURLPublica - URL pública del documento en Google Cloud Storage.
      * 
      * @returns {Object} - Retorna un objeto con la propiedad 'send' que indica si el documento fue enviado exitosamente y la propiedad 'msg' con un mensaje descriptivo.
      */
    const enviarDocumento = async (archivo, nombreArchivo, sTipo, sIdFlujo, sPagina, nIdUsuario, nIdDocumento, sURLPublica) => {
        const entitysArray = JSON.parse(localStorage.getItem('aEntitys') || '[]');
        // Busca si en el array existe un Entity en base al id flujo
        const tieneEntity = entitysArray.some(item => item.nIdFlujo === sIdFlujo);
        // Separa del array de entitys el elemento que coincide con el id flujo
        const sEntity = entitysArray.find(item => item.nIdFlujo === sIdFlujo);
        // Actualiza el estado del ID del flujo.
        setIdFlujo(sIdFlujo)

        // Obtiene el token para Metamap.
        const sToken = await getTokenMetamap();

        // Obtiene la identidad para el documento en base al IdFlujo, o genera una nueva si es necesario.        
        const sIdentity = tieneEntity ? sEntity.sIdentity : await getEntity(sToken, sIdFlujo, nIdUsuario, nIdDocumento)
        // Agrega nuevos entitys al arreglo de entitiys almacenado en la sesion
        const entitiysNuevoArray = [...entitysArray, { nIdFlujo: sIdFlujo, sIdentity: sIdentity }];
        // Busca si existe un entity duplicado en el array
        const entityDuplicado = entitysArray.some(obj => obj.nIdFlujo === sIdFlujo);

        // Si no es un duplicado, agrega el nuevo objeto al array                        
        if (!entityDuplicado) {
            localStorage.setItem('aEntitys', JSON.stringify(entitiysNuevoArray));
        }

        // Actualiza el estado de la entidad/identidad.
        setEntit(sIdentity)

        // Determina si el documento es una constancia fiscal.
        const bConstanciaFiscal = nIdDocumento === 1;

        // Define los encabezados específicos para constancia fiscal.
        const constanciaHeader = { 'Content-Type': `multipart/form-data` };

        // Construye los encabezados para la solicitud de la API.
        const myHeaders = {
            'Authorization': `Bearer ${sToken}`,
            ...(bConstanciaFiscal ? constanciaHeader : {})
        }

        // Datos adicionales para la foto del documento.
        const dataPhoto = {
            country: "MX",
            region: '',
        }

        // Define los inputs para la solicitud.
        const inputs = [
            {
                inputType: bConstanciaFiscal ? 'custom-document-photo' : 'document-photo',
                group: 0,
                data: {
                    type: sTipo,
                    page: sPagina,
                    filename: nombreArchivo,
                    ...(!bConstanciaFiscal ? dataPhoto : {})
                }
            }
        ];

        // Construye el objeto FormData para la solicitud.
        const formdata = new FormData();
        formdata.append("inputs", JSON.stringify(inputs));
        formdata.append(`${nIdDocumento === 1 ? "custom-document" : "document"}`, archivo);

        const requestOptions = {
            headers: myHeaders,
            maxContentLength: Infinity,
            maxBodyLength: Infinity
        };

        try {

            // Envía la solicitud POST al endpoint correspondiente en Metamap.
            const response = await axios.post(`${urlsAPIs.urlMetamapIdentities}/${sIdentity}/send-input`, formdata, requestOptions);
            setDocLoading(false);
            const data = response.data[0];
            const { result = false, error } = data;

            // Maneja los errores específicos retornados por Metamap.

            if (error) {
                
                await axios.post(`${urlsAPIs.urlDocumentosRechazo}`, { nIdUsuario: Number(nIdUsuario), nIdTipoDocumento: nIdDocumento }, { headers: headers });                
                
                setErrorMeta(true);
                setEntit('');
                if (errorsMetamap[error.code]) {
                    return { send: false, msg: errorsMetamap[error.code].response };
                }

                return { send: false, msg: LANG.errorTypeMismatch };
            }

            // Retorna un mensaje de éxito.
            return { send: result, msg: LANG.successDocumentSend };

        } catch (error) {
            // Maneja los errores generales durante la solicitud.
            const { responseCode = 0, message = "" } = error?.response.data
            if (responseCode === '401' && message === 'jwt expired') {
                toast.error(globalMessage.labelSesionExpirada)
                logout()
            } else {
                throw new Error(`No se pudo cargar el archivo`);
            }
        } finally {
            setDocLoading(false);
        }
    }


    /**
    * Obtiene un token de metamap
    * @author #RCuamatzi 2023-07-04 03:45:07 PM
    * @returns {string}
    */
    const HASH_METAMAP = process.env.REACT_APP_HASH_METAMAP;

    const getTokenMetamap = async () => {

        const axios = require('axios');
        let sToken = ''

        const url = urlsAPIs.urlMetamap; // Reemplaza con la URL de la API que deseas llamar
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic NjQ1M2QzNjA4YjNmMzUwMDFiMGExYjM4OlNETzRDRUo1SExaSERCMkJUUlM0VkFURUpaU09CWDI1'
        };

        const dataD = new URLSearchParams();
        dataD.append('grant_type', 'client_credentials');

        try {

            const response = await axios.post(url, dataD, { headers: headers });
            const data = response.data;
            sToken = data?.access_token ? data.access_token : '';


        } catch (error) {
            // No se pudo cargar el documento            
            toast.custom((t) => (
                <CustomToast type="error" message={globalMessage.errorServidor} toastRef={t} />
            ));
        }
        return sToken;
    }

    /**
    * Obtiene una entidad desde la API de Mati basándose en los parámetros proporcionados.
    * @author ERL 2023-07-04 03:45 PM
    * @param {string} sToken - Token utilizado para la autorización de la API.
    * @param {string} sIdFlujo - ID relacionado a un proceso o flujo específico.
    * @param {number} nIdUsuario - Identificador único del usuario.
    * @param {number} nIdDocumento - Identificador único del documento.
    * 
    * @returns {string} - Devuelve la cadena de identidad asociada con la solicitud o una cadena vacía si ocurre un error.
    */
    const getEntity = async (sToken, sIdFlujo, nIdUsuario, nIdDocumento) => {

        // Inicializa la cadena de identidad.
        let sIdentity = '';

        // Define los encabezados para la solicitud de la API.
        const headers = {
            'Authorization': `Bearer ${sToken}`,
            'Content-Type': 'application/json',
        };

        // Genera un ID único basado en la marca temporal actual.
        const uniQID = new Date().getTime();
        setUniqHashCode(prev => [...prev, { [sIdFlujo]: uniQID }]);

        // Construye los datos para la solicitud de la API.
        const dataD = JSON.stringify({
            "flowId": sIdFlujo,
            "metadata": {
                "nIdUsuario": nIdUsuario,
                "nIdDocumento": nIdDocumento,
                "hashcode": HASH_METAMAP + uniQID
            }
        });

        try {
            // Envía la solicitud POST al endpoint de la API de Mati.
            const response = await axios.post("https://api.getmati.com/v2/verifications", dataD, { headers });
            const data = response.data;

            // Extrae la identidad de la respuesta.
            sIdentity = data.identity;
        } catch (error) {
            // Maneja los errores mostrando un mensaje.
            toast.custom((t) => (
                <CustomToast type="error" message={globalMessage.errorServidor} toastRef={t} />
            ));
        }

        // Retorna la identidad obtenida.
        return sIdentity;
    };

    /**
     * Envía una imagen o documento al Google Cloud Storage y realiza operaciones adicionales basadas en las respuestas del servidor y otros factores.
     * @author ERL 2023-07-03 02:48 pm
     * @param {Object} event - Evento que activó la llamada a la función (ej. evento de clic).
     * @param {Array} archivo - Arreglo que contiene información sobre el archivo a enviar.
     * @param {string} nombreArchivo - Nombre del archivo que se va a enviar.
     * @param {string} status - Estado actual del documento.
     * @param {number} nIdentificador - Identificador único del documento.
     * @param {string} sTipoDoc - Tipo de documento.
     * @param {boolean} bValidacion - Indicador para decidir si se realiza o no una validación.
     * @param {string} sIdFlujo - ID relacionado con un proceso o flujo específico.
     * @param {string} sPagina - Página o sección relacionada.
     */
    const enviarImagenGoogle = async (event, archivo, nombreArchivo, status, nIdentificador, sTipoDoc, bValidacion, sIdFlujo, sPagina, sSolicita) => {
        setDocLoading(true);
        //Reseteo de rejectedStatus
        if (rejectedStatus.includes(sIdFlujo)) {
            setRejectedStatus(rejectedStatus.filter(i => i !== sIdFlujo))
        }


        if (sPagina === "front" || sPagina === "back") {
            setLastIdFlujo(prev => prev.includes(sIdFlujo) ? prev : [...prev, sIdFlujo]);
        }

        // Extraer información del archivo
        const { type, name: nombreArchivoO, size } = archivo[0];
        const originalName = nombreArchivo;
        nombreArchivo = nombreArchivo.toString().trim().toUpperCase().replace(/[-\s()]/g, "");
        const sTipo = type.split("/").pop();

        if (![1, 27].includes(nIdentificador) && type === "application/pdf") {
            updateDocumentStatus(nIdentificador, "ERROR_PDF");
            return;
        }

        // Si el estado es 'VALIDADO' o 'PENDIENTE', no se realiza el envío
        if (["VALIDADO", "VALIDANDO", "ENVIADO", "CARGADO"].includes(status)) {
            return;
        }

        let objeto = {};

        updateDocumentStatus(nIdentificador, "ENVIADO");
        try {
            // Obtener la URL pública y firmada para subir el archivo a Google Cloud Storage
            const response = await axios.get(`${urlsAPIs.urlGoogleBucket}/${idUsuario}?sNombre=${nombreArchivo}&sTipo=${sTipo}`, { headers: headers });

            if (response.status === 200) {
                const { sURLPublica, sUrlFirmada } = response.data;

                let myHeaders = new Headers();
                myHeaders.append("Content-Type", type);
                const fieldName = `doc${nIdentificador}`; // Crear fieldName a partir de nIdentificador
                objeto[fieldName] = {
                    nIdDocumento: nIdentificador,
                    sRuta: sURLPublica,
                    nIdUsuario: idUsuario
                };
                let requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: archivo[0],
                    redirect: 'follow'
                };

                // Realizar la carga del archivo a Google Cloud Storage
                const responseGoogleStorage = await fetch(sUrlFirmada, requestOptions);

                const { status: uploadStatus } = responseGoogleStorage;

                if (uploadStatus === 200) {

                    bValidacion = Boolean(Number(bValidacion));
                    objeto[fieldName] = {
                        nIdDocumento: nIdentificador,
                        sRuta: sURLPublica,
                    };
                    try {

                        if (bValidacion) {
                            setStatusSend(prevStatusSend => {
                                // Si el valor nIdentificador no existe en el estado anterior, añadirlo
                                if (!prevStatusSend.includes(nIdentificador)) {
                                    return [...prevStatusSend, nIdentificador];
                                }
                                // Si ya existe, simplemente devuelve el estado anterior
                                return prevStatusSend;
                            });

                            let bEnviado = true;
                            if (Boolean(Number(process.env.REACT_APP_VALIDAR_METAMAP))) {
                                bEnviado = await enviarDocumento(archivo[0], nombreArchivoO, sTipoDoc, sIdFlujo, sPagina, idUsuario, nIdentificador, sURLPublica);
                                console.log(bEnviado)
                            }
                            const { send, msg } = bEnviado;
                            if (!send) {
                                // toast.custom((t) => (
                                //     <CustomToast type="error" message={msg} toastRef={t} />
                                // ));
                                throw new Error(msg);
                            }

                        } else {
                            updateDocumentStatus(nIdentificador, "VALIDANDO_NOMETA");
                        }
                        //Enviar los datos del documento a la API
                        const bIngresosAdicionalesFile = sSolicita === 'I' ? true : false;



                        const response = await axios.post(`${urlsAPIs.urlEnviarDocumentos}/${idUsuario}`, { ...objeto, nIdStatus: bValidacion ? 1 : bIngresosAdicionalesFile ? 2 : 5 }, { headers: headers });
                        const { nCodigo, sMensaje } = response.data;
                        if (nCodigo === 0) {
                            if (bIngresosAdicionalesFile || !bValidacion) {
                                setDocLoading(false)
                                updateDocumentStatus(nIdentificador, "VALIDADO");
                            }
                            toast.custom((t) => (
                                <CustomToast type="success" message={sMensaje} toastRef={t} />
                            ));
                        } else {
                            toast.custom((t) => (
                                <CustomToast type="error" message={sMensaje} toastRef={t} />
                            ));
                        }
                    } catch (error) {

                        if (error?.response?.data) {
                            const { responseCode = 0, message = "" } = error.response.data;

                            if ((responseCode && responseCode === '401') && (message && message === 'jwt expired')) {
                                toast.error(globalMessage.labelSesionExpirada)
                                logout();
                            }
                        } else {
                            updateDocumentStatus(nIdentificador, "ERROR", error.toString());                            
                        }

                    }
                } else {
                    // Si hay un error en la carga del archivo, se actualiza el estado del documento a 'ERROR'
                    updateDocumentStatus(nIdentificador, 'ERROR');
                }
            } else {
                // Si hay un error al obtener la URL pública y firmada, se actualiza el estado del documento a 'ERROR'
                updateDocumentStatus(nIdentificador, 'ERROR');
            }
        } catch (error) {

            console.log(error)
            // Si hay un error en la llamada a la API, se actualiza el estado del documento a 'ERROR'
            updateDocumentStatus(nIdentificador, 'ERROR');

        }

    };
    const [metamapValidos, setMetamapValidos] = useState(null)
    /**
     * Actualiza el estado de progreso del componente a "completado" si todos los documentos en dataDocumentos tienen el estado "VALIDADO".
     * Se ejecuta cada vez que dataDocumentos cambia.
     * @author ERL 2023-07-06 12:16 pm
     */
    useEffect(() => {

        // Llamar a la función para actualizar el estado de progreso de los documentos
        if (dataDocumentos.length > 0) {

            const documentosIngresosAdicionales = dataDocumentos.filter((doc) => doc?.sSolicita === "I") // Documentos con ingresos adicionales
            const documentosSinValidacion = dataDocumentos.filter((doc) => Number(doc?.bValidacion) === 0) // Documentos sin validacion
            const documentosConValidacion = dataDocumentos.filter((doc) => Number(doc?.bValidacion) === 1) // Documentos con validacion
            const documentosValidados = dataDocumentos.filter((doc) => doc?.sStatus === "VALIDADO") // Documentos validados
            const bValidacionMultiOpciones = dataDocumentos.filter((question, index) => {
                // Si el documento cumple con las condiciones...
                if (question.nIdReferenciado !== 0 && question.sLanguaje !== '' && sTipoDoc === question.sIdFlujo) {
                    // devolvemos el objeto
                    return question;
                }
                // de lo contrario, devolvemos null.
                return null;
            }); // Documentos con validacion de multiples opciones, entre INE y Pasaporte 
            const bTodosValidados = documentosValidados.length === (Number((documentosConValidacion.length) - Number(bValidacionMultiOpciones.length) - Number(documentosIngresosAdicionales.length))); // Obtener el length de los documentos validados restando las validaciones que no pasan por metamap
            const bTodosDocumentosEnviados = documentosSinValidacion.length > 0 ? documentosSinValidacion.every((doc) => ["VALIDANDO_NOMETA", "CARGADO", "ENVIADO"].includes(doc?.sStatus)) : true; // Evalua si todos los documentos sin validacion fueron enviados
            const bTodosValidadosNoMetamap = documentosSinValidacion.length > 0 && documentosSinValidacion.every((doc) => ["VALIDADO"].includes(doc?.sStatus)); // Evalua si todos los documentos sin validacion de metamap ya vueron validados
            const bValidacionIngresosAdicionales = documentosIngresosAdicionales.length > 0 && documentosIngresosAdicionales.every((doc) => (bIngresosAdicionales === 1 && ["CARGADO", "ENVIADO", "VALIDADO"].includes(doc?.sStatus)) || bIngresosAdicionales === 0); // Evalua si los documentos de ingresos adicionales tiene alguno de los 3 estatus del arreglo o si seleccionaron No en la pregunta de ingresos adicionales

            const todosValidados = (dataDocumentos.length - bValidacionMultiOpciones.length) > 0 && bValidacionMultiOpciones.every(doc => doc.sStatus === "VALIDADO"); // Evalua si todos los documentos estan con estatus validado restando el length de los documentos que pueden cambiar entre INE y Pasaporte

            setMetamapValidos(bTodosValidados);
            if (bTodosValidados && bTodosDocumentosEnviados && sActivo === "documentos") {
                nextStep();
            }

            if (todosValidados && bValidacionIngresosAdicionales && bTodosValidadosNoMetamap) {
                getStepsFromAPI();
                setIngresoAdicional(true)
            }

        }
    }, [dataDocumentos, status, bIngresosAdicionales]);


    /**
     * Actualiza el estado de un documento.
     * @author ERL 2023-07-06 02:15 pm
     * @param {number} nIdentificador - Identificador del documento.
     * @param {string} newStatus - Nuevo estado del documento.
     * @param {string} sMensaje - Mensaje para el sttus .
     * @param {string} flowId - Id flujo del documento.
     * @returns {void}
     */
    const updateDocumentStatus = (nIdentificador, newStatus, sMensaje, flowId = "") => {

        if (sMensaje && newStatus === "ERROR") {
            setStatusResponses(prevState => ({
                ...prevState,
                "ERROR": {
                    ...prevState["ERROR"],
                    sStatusMessage: sMensaje
                },
            }));
        }
        // Actualizamos dataDocumentos con la nueva lista de documentos
        setDataDocumentos(prevDataDocumentos => {
            // Mapeamos la lista de documentos actualizada
            const updatedDataDocumentos = prevDataDocumentos.map(document => {
                if (flowId !== "" && flowId === document.sIdFlujo) {

                    return { ...document, sStatus: newStatus };
                }
                if (document.nIdentificador === nIdentificador) {
                    // Si es el documento correcto, actualizamos su status
                    return { ...document, sStatus: newStatus };
                } else {
                    // Si no es el documento que estamos buscando, lo devolvemos sin cambios
                    return document;
                }
            });

            // Actualizamos el estado de validación del documento            

            // Retornamos la lista de documentos actualizada
            return updatedDataDocumentos;
        });
    };

    /**
     * Actualiza el estado de validación de un documento.
     * @author ERL 2023-07-29 03:32 pm
     * @param {number} nIdentificador - Identificador del documento.
     * @param {array} updatedDataDocumentos - Lista actualizada de documentos.
     * @returns {void}
     */

    const [rejectedStatus, setRejectedStatus] = useState([]);
    const updateDocumentValidationStatus = async (response, documentsSend) => {

        const { flowId, sMensaje } = response;
        const sStatus = getStatusDocs(response)

        if (sStatus === "RECHAZADO") {
            setRejectedStatus([...rejectedStatus, flowId]);
            setEntit('');
            borrarEntityRechazado(flowId);
        }

        setDataDocumentos(prevDataDocumentos => {
            // Mapeamos la lista de documentos actualizada
            let originalName = "";
            const newSendIdsRejected = [];

            const updatedDataDocumentosVerify = prevDataDocumentos.map(document => {

                if (documentsSend.includes(document.nIdentificador) && document.sIdFlujo === flowId) {
                    originalName = (document.sValor);

                    // 
                    // Mostramos una notificación de éxito
                    if (!["RECHAZADO", "VALIDADO", "ERROR"].includes(document.sStatus)) {
                        if (["VALIDADO"].includes(sStatus)) {
                            toast.custom((t) => (
                                <CustomToast type="success" message={`${originalName} válido`} toastRef={t} />
                            ));
                            setRadios(prev => (
                                prev.map(radio => ({
                                    ...radio,
                                    sStatus: radio.sIdFlujo === flowId ? sStatus : radio.sStatus
                                }))
                            ));

                        }
                        if (["RECHAZADO", "ERROR"].includes(sStatus)) {
                            newSendIdsRejected.push(document.nIdentificador);
                            const message = sMensaje !== '' ? sMensaje : `No se pudo validar el documento: ${originalName}`;
                            toast.custom((t) => (
                                <CustomToast type="error" message={message} toastRef={t} />
                            ), {
                                duration: Infinity,
                            });
                            if (sStatus === "ERROR") {
                                setStatusResponses(prevState => ({
                                    ...prevState,
                                    "ERROR": {
                                        ...prevState["ERROR"],
                                        sStatusMessage: message
                                    },
                                }));
                            } else {
                                setStatusResponses(prevState => ({
                                    ...prevState,
                                    "RECHAZADO": {
                                        ...prevState["RECHAZADO"],
                                        sStatusMessage: message
                                    },
                                }));
                            }



                        }
                        // Si es el documento correcto, actualizamos su estado de validación
                        return { ...document, sStatus: sStatus };
                    } else {
                        return document;
                    }

                } else {

                    // Si no es el documento que estamos buscando, lo devolvemos sin cambios
                    return document;
                }
            });


            return updatedDataDocumentosVerify;
        });

    };


    /**
    * Elmina el entity de los documentos rechazados
    * @author ERL 2023-09-19 04:30 pm
    * @param {string} flowId - Id flujo del documento.
    * @returns {void}
    */
    const borrarEntityRechazado = (flowId) => {
        const entitysArrayEntitys = JSON.parse(localStorage.getItem('aEntitys') || '[]');
        const newArrayEntity = entitysArrayEntitys.filter(item => item.nIdFlujo !== flowId);
        localStorage.setItem('aEntitys', JSON.stringify(newArrayEntity));
    }


    /**
     * Función para manejar la eliminación de un documento.
     * @author ERL 2023-08-17 02:32 pm
     * @param {number} id - Identificador del documento a eliminar.
     * @returns {void}
     */
    const handleRemove = (id) => {
        // Filtrar los documentos actuales y actualizar el estado con los documentos restantes
        setDataDocumentos((currentQuestions) =>
            currentQuestions.filter((question) => question.nIdentificador !== id)
        );
    };

    /**
     * Obtiene el estado (status) de un documento. Si el documento no tiene estado definido, 
     * devuelve una cadena vacía. Siempre retorna el estado en mayúsculas.
     * @author ERL 2023-08-21 03:14 pm
     * @param {Object} documento - Objeto que representa el documento. Debe contener una propiedad sStatus que representa el estado del documento.
     * @returns {string} 
     */
    const getStatusDocs = (documento = {}) => {
        const status = documento?.sStatus ? documento.sStatus : "";
        return status.toString().toUpperCase();
    }

    /**
     * Verifica si un documento, identificado por su ID, tiene un estado de "VALIDADO".
     * @author ERL 2023-08-21 03:48 pm
     * @param {number|string} id - Identificador único del documento a verificar.
     * @returns {boolean} Retorna true si el documento está validado, de lo contrario retorna false.
    */
    const hasValidStatus = (id) => {
        return dataDocumentos.some(doc => doc.nIdentificador === id && doc.sStatus === 'VALIDADO');
    }

    /**
     * Determina si se debe mostrar un documento basado en su identificador.
     * Si el identificador es 14, verifica si los identificadores 18 y 9 están validados.
     * Para los identificadores en BLOCK_DISPLAY_IDS, verifica si todos los documentos en VALIDATED_IDS_TO_VERIFY están validados.
     * Si el identificador está en VALIDATED_IDS, simplemente lo muestra sin verificar el estado.
     * Por defecto, se muestra el índice.
     * @author ERL 2023-08-21 03:06 pm
     * @param {number} index - Identificador único del documento a verificar.
     * @returns {boolean} 
     */
    const mostrarDocumento = (index) => {
        // Si el identificador es 14, verificamos si ambos, los identificadores 18 y 9, tienen el estado 'VALIDADO'
        if (index === 14) {
            return hasValidStatus(18) && hasValidStatus(9);
        }

        // Para los índices en BLOCK_DISPLAY_IDS, verificamos si TODOS los documentos en VALIDATED_IDS_TO_VERIFY están 'VALIDADO'
        if (BLOCK_DISPLAY_IDS.includes(index)) {
            return dataDocumentos.every(doc => !VALIDATED_IDS_TO_VERIFY.includes(doc.nIdentificador) || (VALIDATED_IDS_TO_VERIFY.includes(doc.nIdentificador) && doc.sStatus === 'VALIDADO'));
        }

        // Si el índice está en VALIDATED_IDS, simplemente lo mostramos sin verificar el estado
        if (VALIDATED_IDS.includes(index)) {
            return true;
        }

        // Por defecto, mostramos el índice
        return true;
    }

    /**
     * El prospecto seleciona "No" en pregunta: ¿Deseas agregar ingresos adicionales?
     * @author ERL 2023-11-17 03:04 pm     
     * @returns {void} 
     */
    const sinIngresosAdicionales = async () => {

        const oDocumento = {};
        oDocumento['doc28'] = {
            "nIdDocumento": 28,
            "sRuta": ""
        }
        await axios.post(`${urlsAPIs.urlEnviarDocumentos}/${idUsuario}`, {
            ...oDocumento,
            nIdStatus: 2,
            sTipo: "I",
            bSinIngresosAdicionales: 1
        }, { headers: headers });
    }
    return {
        metamapValidos,
        getStatusDocs,
        handleRemove,
        lastIdFlujo,
        mostrarDocumento,
        enviarImagenGoogle,
        methods,
        openSocket,
        socketRef,
        docLoading,
        setDocLoading,
        bIngresosAdicionales,
        setBIngresosAdicionales,
        sinIngresosAdicionales
    };
};

export default useDocumentos;